import React, { useState, useEffect } from "react";
import { Layout, Menu, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import OrganizationsIcon from "../../assets/organizations.svg";
import DashboardIcon from "../../assets/dashboard.svg";
import PropertiesIcon from "../../assets/properties.svg";
import ConnectsIcon from "../../assets/connects.svg";
import TransactionsIcon from "../../assets/transactions.svg";
import CollapseIcon from "../../assets/collapse.svg";
import CollapseRightIcon from "../../assets/collapseright.svg";
import whitelogo from "../../assets/trythat_White.svg";
import LogoutIcon from "../../assets/logout.svg";
import "./SideNav.css";
import ReportIcon from "../../assets/report.svg";
import MasterIcon from "../../assets/master.svg";
import Skip from "../../assets/skip.svg";
import { setSelectedNav } from "../../feature/slice/FiltersSlice";
import {
  setAccessToken,
  setPassword,
  setEmail,
  setUserInitialState,
} from "../../feature/slice/UserSlice";
import { setPropertyInitialState } from "../../feature/slice/PropertyDetailsSlice";
import { setTransactionInitialState } from "../../feature/slice/TransactionDetailsSlice";

// Function to define each Menu Item
function getMenuItem(label, key, icon, children, type, disabled) {
  return {
    key,
    icon,
    children,
    label,
    type,
    disabled,
  };
}

function SideNav(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedNav } = useSelector((store) => store.filters);
  const { Sider } = Layout;
  const [collapsed, setCollapsed] = useState(props.collapsed);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    navigate(`/${selectedNav.toLowerCase()}`);
  }, [selectedNav])

  const items = [
    getMenuItem(
      "Dashboard",
      "Dashboard",
      <img src={DashboardIcon} style={{ width: "24px" }} alt="" />,
      null,
      null,
      false
    ),
    getMenuItem(
      "Property",
      "Property",
      <img src={PropertiesIcon} style={{ width: "24px" }} alt="" />,
      null,
      null,
      false
    ),
    getMenuItem(
      "Transactions",
      "Transactions",
      <img src={TransactionsIcon} style={{ width: "24px" }} alt="" />,
      null,
      null,
      false
    ),
    getMenuItem(
      "Company",
      "Company",
      <img src={OrganizationsIcon} style={{ width: "24px" }} alt="" />,
      null,
      null,
      false
    ),
    getMenuItem(
      "Connect",
      "Connect",
      <img src={ConnectsIcon} style={{ width: "24px" }} alt="" />,
      null,
      null,
      false
    ),
    getMenuItem(
      "Report",
      "Report",
      <img src={ReportIcon} style={{ width: "24px" }} alt="" />,
      null,
      null,
      false),
    getMenuItem("Master",
        "MasterList",
        <img src={MasterIcon} style={{ width: "24px" }} alt="" />,
        null,
        null,
        false),  
    getMenuItem("Skipped Records",
        "skipped-records",
        <img src={Skip} style={{ width: "24px" }} alt="" />,
        null,
        null,
        false),
  ];

  const handleMenuClick = (nav) => {
    setLoading(true);
    dispatch(setSelectedNav(nav));
    navigate(`/${nav.toLowerCase()}`);
  };

  return (
    <Layout>
      <Sider
        breakpoint="lg"
        collapsedWidth="80"
        onBreakpoint={(broken) => {
          if (broken) {
            setCollapsed(true);
            props.setCollapsed(true);
          }
        }}
        collapsed={collapsed}
        onCollapse={(collapseStatus) => {
          setCollapsed(collapseStatus);
          props.setCollapsed(collapseStatus);
        }}
        style={{
          background: "#fb923c",
          position: "fixed",
          top: collapsed ? "4.57em" : "0",
          left: 0,
          bottom: 0,
          height: "100vh", // Ensure it spans the entire viewport height
          zIndex: 1000, // Ensure it's above other content
        }}
        width={200} // Adjust width based on collapsed state
      >
        {loading && <Spin spinning={loading} size="large" fullscreen />}
        {/* Content inside the Sider */}
        {!collapsed && (
          <img
            src={whitelogo}
            style={{ width: "150px", marginLeft: "24px", marginTop: "10px" }}
            alt="Logo"
          />
        )}

        {/* Toggle button */}
        <div
          className="toggle-btn"
          style={{
            textAlign: "center",
            padding: "10px",
            background: "#fb923c",
            color: "#fff",
            cursor: "pointer",
            position: "absolute",
            top: collapsed ? "0px" : "60px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => {
            const newCollapsed = !collapsed;
            setCollapsed(newCollapsed);
            props.setCollapsed(newCollapsed);
          }}
        >
          {collapsed ? (
            <img
              src={CollapseRightIcon}
              style={{ width: "40px", marginLeft: "80px" }}
              alt="Collapse"
            />
          ) : (
            <img
              src={CollapseIcon}
              style={{ width: "40px", marginLeft: "190px" }}
              alt="Expand"
            />
          )}
        </div>

        {/* Menu items */}
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={[selectedNav]}
          items={items}
          style={{
            background: "#fb923c",
            marginTop: "60px",
            overflowY: "auto",
            height: "calc(100vh - 80px)", // Adjust height to account for the header if present
          }}
          onClick={({ key }) => handleMenuClick(key)}
        />

        {/* Logout button */}
        <span
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            padding: "20px 32px",
            color: "#fff",
            background: "#fb923c",
            position: "absolute",
            bottom: "0",
            width: collapsed ? "80px" : "200px",
            zIndex: 1000,
          }}
          onClick={() => {
            dispatch(setEmail(null));
            dispatch(setPassword(null));
            dispatch(setAccessToken(null));
            navigate("/", { replace: true });
            dispatch(setPropertyInitialState());
            dispatch(setUserInitialState());
            dispatch(setTransactionInitialState());
            dispatch(setSelectedNav("Property"));
          }}
        >
          <img
            src={LogoutIcon}
            style={{
              width: "24px",
              marginRight: collapsed ? "0px" : "10px",
              marginBottom: collapsed ? "4em" : "0",
            }}
            alt="Logout"
          />
          {!collapsed && <span>Logout</span>}
        </span>
      </Sider>
    </Layout>
  );
}

export default SideNav;
