import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import TabContent from "../TabContent";
import {
  deleteCity,
  fetchAllCities,
  setCurrentPage,
  setPageSize,
} from "../../../feature/slice/CitySlice";

const CityTab = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();

  const {
    cityMaster,
    currentPage = 1,
    pageSize = 10,
    totalCityCount,
    loading,
  } = useSelector((state) => state.cityDetails);

  useEffect(() => {
    dispatch(fetchAllCities({ page: currentPage, pageSize }));
  }, [dispatch, currentPage, pageSize]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentPage(1));
      dispatch(setPageSize(10));
    };
  }, [dispatch]);

  const columns = [
    {
      title: "City Name",
      dataIndex: "city",
      key: "city",
      sorter: (a, b) => a.city.localeCompare(b.city),
    },
    {
      title: "State Name",
      dataIndex: ["stateId", "state"],
      key: "stateName",
    },
    {
      title: "Created On",
      dataIndex: "createdOn",
      key: "createdOn",
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Last Updated On",
      dataIndex: "lastUpdatedOn",
      key: "lastUpdatedOn",
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: "Updated By",
      dataIndex: "updatedBy",
      key: "updatedBy",
    },
  ];

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this city?",
      onOk: () => {
        dispatch(deleteCity(id))
          .then(() => {
            dispatch(fetchAllCities({ page: currentPage, pageSize }));
          })
          .catch((err) => {
            console.error("Failed to delete city:", err);
          });
      },
    });
  };

  const handleRowClick = (record) => ({
    onClick: () =>
      navigateTo("/cityDetails", { state: { cityId: record._id } }),
  });

  const handleTableChange = (pagination) => {
    dispatch(setCurrentPage(pagination.current));
    dispatch(setPageSize(pagination.pageSize));
  };

  const fetchData = async ({ page, pageSize }) => {
    dispatch(fetchAllCities({ page, pageSize }));
  };

  return (
    <TabContent
      title="City"
      fetchData={fetchData}
      data={cityMaster}
      columns={columns}
      buttonLabel="Add City"
      onButtonClick={() => navigateTo("/cityDetails")}
      handleDelete={handleDelete}
      onRowClick={handleRowClick}
      currentPage={currentPage}
      pageSize={pageSize}
      totalRecords={totalCityCount}
      loading={loading}
      handleTableChange={handleTableChange}
    />
  );
};

export default CityTab;
