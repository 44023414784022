import { buildCreateSlice, asyncThunkCreator } from "@reduxjs/toolkit";
import { get } from "lodash";

import {
  getAllCities as _getAllCities,
  addCityData as _addCityData,
  updateCityData as _updateCityData,
  deleteCityById as _deleteCityById,
  getAllCitiesByStateId as _getAllCitiesByStateId,
  getCityById as _getCityById,
} from "../services/CityAPI";

const createSliceWithThunks = buildCreateSlice({
  creators: {
    asyncThunk: asyncThunkCreator,
  },
});

const initialState = {
  activeTab: "state",
  loading: false,
  cityMaster: [],
  citiesByStateId: [],
  cityDataById: [],
  cityId: null,
  totalCityCount: 0,
  currentPage: 1,
  pageSize: 10,
};

const CitySlice = createSliceWithThunks({
  name: "city",
  initialState,
  reducers: (create) => ({
    setCurrentPage: (state, action) => {
      state.currentPage = action?.payload;
    },

    setPageSize: (state, action) => {
      state.pageSize = action?.payload;
    },

    addCityData: create.asyncThunk(
      async (cityData, thunkAPI) => {
        try {
          let data = await _addCityData(cityData);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.cityMaster.push(action.payload.data);
          state.cityId = action.payload.data._id;
        },
      }
    ),

    fetchAllCities: create.asyncThunk(
      async ({ page, pageSize }, thunkAPI) => {
        try {
          const data = await _getAllCities(page, pageSize);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          return thunkAPI.rejectWithValue({ message });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.cityMaster = action.payload.data.data;
          state.totalCityCount = action.payload.data.total;
        },
      }
    ),

    fetchAllCitiesByStateId: create.asyncThunk(
      async (stateId, thunkAPI) => {
        try {
          const data = await _getAllCitiesByStateId(stateId);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          return thunkAPI.rejectWithValue({ message });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.citiesByStateId = action.payload.data;
        },
      }
    ),

    fetchCityById: create.asyncThunk(
      async (id, thunkAPI) => {
        try {
          const data = await _getCityById(id);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          return thunkAPI.rejectWithValue({ message });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.cityDataById = action.payload.data;
        },
      }
    ),

    updateCityData: create.asyncThunk(
      async ({ cityId, cityData }, thunkAPI) => {
        try {
          const data = await _updateCityData(cityId, cityData);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          return thunkAPI.rejectWithValue({ message });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          const index = state.cityMaster.findIndex(
            (state) => state._id === action.payload.data._id
          );
          if (index !== -1) {
            state.cityMaster[index] = action.payload.data;
          }
        },
      }
    ),

    deleteCity: create.asyncThunk(
      async (id, thunkAPI) => {
        try {
          const data = await _deleteCityById(id);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          return thunkAPI.rejectWithValue({ message });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.cityMaster = state.cityMaster.filter(
            (state) => state._id !== action.payload.data._id
          );
        },
      }
    ),
  }),
});

export const {
  fetchCityById,
  fetchAllCities,
  fetchAllCitiesByStateId,
  addCityData,
  updateCityData,
  deleteCity,
  setCurrentPage,
  setPageSize,
} = CitySlice.actions;

export default CitySlice.reducer;
