import { client } from "../../utils/apiClient";
import { asyncHandler } from "../../utils/asyncHandler";

const addLocalityData = async (param) => {
  try {
    const [data, err] = await asyncHandler(
      client.post(`/api/create-locality`, param, {
        headers: {
          "Content-Type": "application/json",
        },
      })
    );
    return data || err;
  } catch (err) {
    console.error(err);
  }
};

const updateLocalityData = async (id, param) => {
  try {
    const [data, err] = await asyncHandler(
      client.put(`/api/update-locality/${id}`, param, {
        headers: {
          "Content-Type": "application/json",
        },
      })
    );
    return data || err;
  } catch (err) {
    console.error(err);
  }
};

const deleteLocalityById = async (id) => {
  try {
    const [data, err] = await asyncHandler(
      client.delete(`/api/delete-locality/${id}`)
    );
    return data || err;
  } catch (err) {
    console.error(err);
  }
};

const getAllLocalitiesWithPagination = async (page, pageSize) => {
  try {
    const [data, err] = await asyncHandler(
      client.get(`/api/get-all-localities?page=${page}&pageSize=${pageSize}`)
    );
    return data || err;
  } catch (err) {
    console.error(err);
  }
};

const getLocalityById = async (id) => {
  try {
    const [data, err] = await asyncHandler(
      client.get(`/api/get-locality/${id}`)
    );
    return data || err;
  } catch (err) {
    console.error(err);
  }
};

export {
  addLocalityData,
  updateLocalityData,
  deleteLocalityById,
  getAllLocalitiesWithPagination,
  getLocalityById,
};
