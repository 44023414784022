import React, { useState, useEffect } from "react";
import {
  Form,
  Col,
  Row,
  Divider,
  Select,
  Radio,
  Button,
  Collapse,
  message,
  Tabs,
  Checkbox,
  Upload,
  Image,
} from "antd";
import Title from "antd/es/typography/Title";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import FormElement from "../../components/formElement/FormElement";
import {
  getTransactionDetailsData,
  setTransactionInfoEditable,
  updateTransactionInfo,
  setTransactionInfo,
  setTransactionId,
  getPropertyDetailsData,
  getCompanyWithSearchableText,
  setTransactionInitialState,
  saveTransactionInfo,
  setLegacyPartiesInfo,
  getAllDocumentMaster,
} from "../../feature/slice/TransactionDetailsSlice";
import {
  setCompanyId,
  setNavigatedFrom,
} from "../../feature/slice/CompanyDetailsSlice";
import { isAadhar, isEmail, isMobileNumber, isPAN } from "../../utils/helpers";
import { FaArrowLeft } from "react-icons/fa";
import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid"; // Import v4 function as uuidv4

const getUnitConditionListingEle = () => {
  let data = [
    {
      label: "Bareshell",
      key: "Bareshell",
    },
    {
      label: "Warmshell",
      key: "Warmshell",
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

const getUnitStatusListingEle = () => {
  let data = [
    {
      label: "To be confirmed",
      key: "To be confirmed",
    },
    {
      label: "Occupied",
      key: "Occupied",
    },
    {
      label: "Vacant",
      key: "Vacant",
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

const getRightOfFirstRefusalListingEle = () => {
  let data = [
    {
      label: "NA",
      key: "NA",
      value: "NA",
    },
    {
      label: "Yes",
      key: "Yes",
      value: "Yes",
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

function TransactionDetailsCard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [showPartiesInfoBuyerForm, setShowPartiesInfoBuyerForm] =
    useState(false);
  const [showPartiesInfoSellerForm, setShowPartiesInfoSellerForm] =
    useState(false);
  const [showPartiesInfoBuyerRadioGroup, setShowPartiesInfoBuyerRadioGroup] =
    useState(false);
  const [showPartiesInfoSellerRadioGroup, setShowPartiesInfoSellerRadioGroup] =
    useState(false);
  const [buyerType, setBuyerType] = useState(null);
  const [sellerType, setSellerType] = useState(null);
  const [formTransactionData, setFormTransactionData] = useState({});
  const [partiesSaveBuyerInfo, setPartiesSaveBuyerInfo] = useState({});
  const [partiesSaveSellerInfo, setPartiesSaveSellerInfo] = useState({});
  const [savedBuyersList, setSavedBuyersList] = useState(null);
  const [savedSellersList, setSavedSellersList] = useState(null);
  const [savedBuyersCnt, setSavedBuyersCnt] = useState(0);
  const [savedSellersCnt, setSavedSellersCnt] = useState(0);
  const [showBuyerPartiesInfoKey, setShowBuyerPartiesInfoKey] = useState(0);
  const [showSellerPartiesInfoKey, setShowSellerPartiesInfoKey] = useState(0);
  const [isPartiesBuyerInfoEditable, setIsPartiesBuyerInfoEditable] =
    useState(null);
  const [isPartiesSellerInfoEditable, setIsPartiesSellerInfoEditable] =
    useState(null);
  const { TabPane } = Tabs;

  const {
    isTransactionInfoEditable,
    transactionData,
    documentMaster,
    companies,
    documentNameMaster,
  } = useSelector((store) => store.transactionDetails);
  const { propertyId } = useSelector((store) => store.propertyDetails);
  const { navigatedFrom } = useSelector((store) => store.companyDetails);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [deletedFileList, setDeletedFileList] = useState([]);

  const getDocumentMasterListingEle = () => {
    if (!documentNameMaster) return [];

    let data = [...documentNameMaster];
    if (formTransactionData?.transactionInformation?.unitStatus === 'Vacant') {
      data = data.filter(
        (item) => item.label === 'To Sale' || item.label === 'To Rent'
      );
    }
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  useEffect(() => {
    form.setFieldsValue({
      buildingId: formTransactionData?.buildingInformation?.propertyId,
      buildingStructure: formTransactionData?.buildingInformation?.buildingStructure,
      buildingType: formTransactionData?.buildingInformation?.buildingType,
      street: formTransactionData?.buildingInformation?.street,
      block: formTransactionData?.buildingInformation?.block,
      city: formTransactionData?.buildingInformation?.city,
      coordinates: formTransactionData?.buildingInformation?.coordinates?.latitude,
      buildingName: formTransactionData?.buildingInformation?.buildingName,
      buildingStatus: formTransactionData?.buildingInformation?.buildingStatus,
      plot: formTransactionData?.buildingInformation?.plot,
      state: formTransactionData?.buildingInformation?.state,
      pincode: formTransactionData?.buildingInformation?.pincode,
      microMarket: formTransactionData?.buildingInformation?.microMarket,
      longitude: formTransactionData?.buildingInformation?.coordinates?.longitude,
      developer: formTransactionData?.buildingInformation?.developer,
      complete_address: formTransactionData?.buildingInformation?.complete_address,
      state: formTransactionData?.buildingInformation?.state,
      locality: formTransactionData?.buildingInformation?.locality,
    });
  }, [formTransactionData, form]);

  useEffect(() => {
    const prevFormTransactionData = formTransactionData;
    setFormTransactionData({ ...prevFormTransactionData, ...transactionData });
    dispatch(getCompanyWithSearchableText(""));
  }, [transactionData]);

  useEffect(() => {
    let propertyImages = [];
    if (transactionData?.propertyImages) {
      propertyImages = [
        ...transactionData?.propertyImages
      ];
    }
    setFileList(propertyImages);
  }, [transactionData?.propertyImages]);

  const handleImageClick = (file) => {
    if (isTransactionInfoEditable) {
      if (selectedImage === file) {
        setSelectedImage(null); // Deselect the image if it's already selected
        // Deselect the profile picture checkbox
      } else {
        setSelectedImage(file); // Select the new image

      }
    }
  };
  const handleDeleteImage = (fileToDelete) => {
    if (isTransactionInfoEditable) {
      // Find the index of the file to delete based on a unique identifier (e.g., id or another unique property)
      const indexToDelete = fileList.findIndex(
        (file) => file.uid === fileToDelete.uid
      );

      const deletedFiles = [...deletedFileList, fileList[indexToDelete]?.url];

      setDeletedFileList(deletedFiles);

      if (indexToDelete !== -1) {
        // Create a new array without the deleted file
        const newFileList = fileList.filter(
          (_, index) => index !== indexToDelete
        );

        // Update state with the new file list
        setFileList(newFileList);
        if (
          (newFileList && newFileList?.length === 0) ||
          selectedImage?.uid === fileToDelete?.uid
        )
          setSelectedImage(null);

        // Dispatch an action to update Redux with the new file list
        dispatch(
          setTransactionInfo({
            name: "propertyImages",
            value: newFileList,
          })
        );
      }
    }
  };

  const props = {
    onRemove: handleDeleteImage,
    beforeUpload: (file) => {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      const isAllowed = allowedTypes.includes(file.type);
      if (!isAllowed) {
        message.error("You can only upload JPG, JPEG, or PNG files!");
        return false;
      }
      const uuid = uuidv4(); // Generate UUID for the file
      file.uid = uuid; // Assign UUID as unique identifier for Ant Design Upload
      file.name = uuid; // Append UUID to the file name
      setFileList([...fileList, file]);
      return file;
    },
    onChange: (info) => {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      const isAllowed = allowedTypes.includes(info.file.type);
      if (!isAllowed) return;
      const currFile = info.fileList.filter(
        (file) => file.uid === info.file.uid
      );
      dispatch(
        setTransactionInfo({
          name: "propertyImages",
          value: [...(transactionData?.propertyImages || []), currFile[0]],
        })
      );
    },
  };

  const getCompanyListingEle = () => {
    if (!companies) return [];

    let data = [...companies];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const handleSearchDropdownOptions = (text) => {
    if (!text) return;
    dispatch(getCompanyWithSearchableText(text));
  };

  const agreementInfoFormElementsCol1 = [
    {
      type: "inputNumber",
      label: "Document No.",
      name: "documentNo",
      placeholder: "Document No.",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.agreement_information?.documentNo,
    },
    {
      type: "datepicker",
      label: "Registration Date",
      required: true,
      name: "registrationDate",
      placeholder: "DD-MM-YYYY",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.agreement_information?.registrationDate
        ? dayjs(formTransactionData?.agreement_information?.registrationDate)
        : "",
      defaultValue: formTransactionData?.agreement_information?.registrationDate
        ? dayjs(formTransactionData?.agreement_information?.registrationDate)
        : "",
    },
    {
      type: "input",
      label: "SRO Name",
      name: "sroName",
      placeholder: "SRO Name",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.agreement_information?.sroName,
    },
  ];
  const agreementInfoFormElementsCol2 = [
    {
      type: "dropdown",
      label: "Document Name",
      required: true,
      name: "documentName",
      placeholder: "Document Name",
      children: getDocumentMasterListingEle(),
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.agreement_information?.documentName,
    },
    {
      type: "datepicker",
      label: "Document Submission Date",
      name: "documentSubmissionDate",
      placeholder: "DD-MM-YYYY",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.agreement_information?.documentSubmissionDate
        ? dayjs(
          formTransactionData?.agreement_information?.documentSubmissionDate
        )
        : "",
      defaultValue: formTransactionData?.agreement_information
        ?.documentSubmissionDate
        ? dayjs(
          formTransactionData?.agreement_information?.documentSubmissionDate
        )
        : "",
    },
    {
      type: "input",
      label: "Sub Division House No",
      name: "subdivisionHouseNo",
      placeholder: "Sub Division House No",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.agreement_information?.subdivisionHouseNo,
    },
  ];
  const agreementInfoFormElementsCol3 = [
    {
      type: "input",
      label: "Document Type",
      name: "documentType",
      placeholder: "Document Type",
      disabled: true,
      value: formTransactionData?.agreement_information?.documentType,
    },
    {
      type: "datepicker",
      label: "Expiry Date",
      name: "expiryDate",
      placeholder: "DD-MM-YYYY",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.agreement_information?.expiryDate
        ? dayjs(formTransactionData?.agreement_information?.expiryDate)
        : "",
      defaultValue: formTransactionData?.agreement_information?.expiryDate
        ? dayjs(formTransactionData?.agreement_information?.expiryDate)
        : "",
    },
  ];
  const transInfoFormElementsCol1 = [
    {
      type: "input",
      label: "Unit No.",
      name: "unitNo",
      placeholder: "Unit No.",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.unitNo,
    },
    {
      type: "dropdown",
      label: "Unit Status",
      name: "unitStatus",
      placeholder: "Select Unit Status",
      children: getUnitStatusListingEle(),
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.unitStatus,
    },
    {
      type: "inputNumber",
      label: "Market Price",
      name: "market_price",
      placeholder: "Market Price",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.market_price,
    },
    {
      type: "inputNumber",
      label: "Bharlele Mudrank Shulkh Or Stamp Duty Paid",
      name: "bharlele_mudrank_shulkh_or_Stamp_Duty_paid",
      placeholder: "Bharlele Mudrank Shulkh Or Stamp Duty Paid",
      disabled: !isTransactionInfoEditable,
      value:
        formTransactionData?.transactionInformation
          ?.bharlele_mudrank_shulkh_or_Stamp_Duty_paid,
    },
    {
      type: "inputNumber",
      label: "Rent Licensee Fees",
      name: "rent_licence_fees",
      placeholder: "Rent Licensee Fees",
      disabled: true,
      value: formTransactionData?.transactionInformation?.rent_licence_fees,
    },
    {
      type: "inputNumber",
      label: "Sale Rate (Sqft)",
      name: "sale_rate_sq_feet",
      placeholder: "Sale Rate (Sqft)",
      disabled: true,
      value: formTransactionData?.transactionInformation?.sale_rate_sq_feet,
    },
    {
      type: "input",
      label: "Notice Period",
      name: "noticePeriod",
      placeholder: "Notice Period",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.noticePeriod,
    },
    {
      type: "inputNumber",
      label: "Carpet Area",
      name: "carpetArea",
      placeholder: "Carpet Area",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.carpetArea,
    },
    {
      type: "inputNumber",
      label: "Security Deposit",
      name: "security_deposit",
      placeholder: "Security Deposit",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.security_deposit,
    },
  ];
  const transInfoFormElementsCol2 = [
    {
      type: "input",
      label: "Floor No.",
      name: "floorNo",
      placeholder: "Floor No.",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.floorNo,
    },
    {
      type: "inputNumber",
      label: "DHC Fees or Document Handling Charges",
      name: "DHC_fees_or_document_handling_charges",
      placeholder: "DHC Fees or Document Handling Charges",
      disabled: !isTransactionInfoEditable,
      value:
        formTransactionData?.transactionInformation
          ?.DHC_fees_or_document_handling_charges,
    },
    {
      type: "inputNumber",
      label: "Declared Circle Rate",
      name: "declared_circle_rate",
      placeholder: "Declared Circle Rate",
      disabled: true,
      value: formTransactionData?.transactionInformation?.declared_circle_rate,
    },
    {
      type: "inputNumber",
      label: "License Period",
      name: "licence_period",
      placeholder: "License Period",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.licence_period,
    },
    {
      type: "inputNumber",
      label: "Rent Rate",
      name: "rent_rate_sq_feet",
      placeholder: "Rent Rate",
      disabled: true,
      value: formTransactionData?.transactionInformation?.rent_rate_sq_feet,
    },
    {
      type: "input",
      label: "Shera",
      name: "shera",
      placeholder: "Shera",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.shera,
    },
    {
      type: "inputNumber",
      label: "Levy",
      name: "levy",
      placeholder: "Levy",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.levy,
    },
    {
      type: "inputNumber",
      label: "Chargeable Area",
      name: "chargeableSquareFeet",
      placeholder: "Chargeable Area",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.chargeableSquareFeet,
    },
    {
      type: "inputNumber",
      label: "CAM Or Common Area Maintenance",
      name: "CAM_Or_Common_Area_Maintenance",
      placeholder: "CAM Or Common Area Maintenance",
      disabled: !isTransactionInfoEditable,
      value:
        formTransactionData?.transactionInformation
          ?.CAM_Or_Common_Area_Maintenance,
    },
  ];
  const transInfoFormElementsCol3 = [
    {
      type: "dropdown",
      label: "Unit Condition",
      name: "unitcondition",
      placeholder: "Select Unit Condition",
      children: getUnitConditionListingEle(),
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.unitcondition,
    },
    {
      type: "inputNumber",
      label: "Registration Fees",
      name: "registration_fees",
      placeholder: "Registration Fees",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.registration_fees,
    },
    {
      type: "inputNumber",
      label: "Compensation",
      name: "compensation",
      placeholder: "Compensation",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.compensation,
    },
    {
      type: "inputNumber",
      label: "Lock In Period",
      name: "lock_in_period",
      placeholder: "Lock In Period",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.lock_in_period,
    },
    {
      type: "inputNumber",
      label: "Sale Value",
      name: "saleValue",
      placeholder: "Sale Value",
      disabled: true,
      value: formTransactionData?.transactionInformation?.saleValue,
    },
    {
      type: "input",
      label: "Fitout Period",
      name: "fitoutPeriod",
      placeholder: "Fitout Period",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.fitoutPeriod,
    },
    {
      type: "input",
      label: "Current Rent Rate",
      name: "currentRentRate",
      placeholder: "Current Rent Rate",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.currentRentRate,
    },
    {
      type: "inputNumber",
      label: "Escalation in licensee fees(%)",
      name: "escalation_in_licence_fees_percentage",
      placeholder: "Escalation in licensee fees(%)",
      disabled: !isTransactionInfoEditable,
      value:
        formTransactionData?.transactionInformation
          ?.escalation_in_licence_fees_percentage,
    },
    {
      type: "inputNumber",
      label: "Payment of Furniture Fixture Charges(Rs.)",
      name: "Payment_of_furniture_fixture_charges_rs",
      placeholder: "Payment of Furniture Fixture Charges(Rs.)",
      disabled: !isTransactionInfoEditable,
      value:
        formTransactionData?.transactionInformation
          ?.Payment_of_furniture_fixture_charges_rs,
    },
  ];
  const otherInfoFormElementsCol1 = [
    {
      type: "input",
      label: "Parking Information of Two Wheeler",
      name: "parking_information_of_two_wheeler",
      placeholder: "Parking Information of Two Wheeler",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.others?.parking_information_of_two_wheeler,
    },
    {
      type: "dropdown",
      label: "Right Of First Refusal Active",
      name: "rightOfFirstRefusalActive",
      placeholder: "Right Of First Refusal Active",
      children: getRightOfFirstRefusalListingEle(),
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.others?.rightOfFirstRefusalActive
        ? "Yes"
        : "NA",
    },
    {
      type: "input",
      label: "Remarks",
      name: "remarks",
      placeholder: "Remarks",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.others?.remarks,
    },
  ];
  const otherInfoFormElementsCol2 = [
    {
      type: "input",
      label: "Parking Information of Four Wheeler",
      name: "parking_information_of_four_wheeler",
      placeholder: "Parking Information of Four Wheeler",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.others?.parking_information_of_four_wheeler,
    },
    {
      type: "inputNumber",
      label: "Right Of First Refusal Floors",
      name: "rightOfFirstRefusalFloors",
      placeholder: "Right Of First Refusal Floors",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.others?.rightOfFirstRefusalFloors,
    },
    {
      type: "textArea",
      label: "Property Description",
      name: "property_description",
      placeholder: "Property Description",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.others?.property_description,
      width: "500px",
    },
  ];
  const otherInfoFormElementsCol3 = [
    {},
    {
      type: "datepicker",
      label: "Expiry",
      name: "expiry",
      placeholder: "YYYY/MM/DD",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.others?.expiry
        ? dayjs(formTransactionData?.others?.expiry)
        : "",
      defaultValue: formTransactionData?.others?.expiry
        ? dayjs(formTransactionData?.others?.expiry)
        : "",
    },
    {
      type: "input",
      label: "Others",
      name: "other",
      placeholder: "Details Considered For Assessment",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.others?.other,
    },
  ];
  const partiesInfoFormElementsForCompanyBuyer = [
    {
      type: "dropdown",
      label: "Company Name",
      name: "companyName",
      placeholder: "Company Name",
      showSearch: true,
      disabled:
        isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
        isPartiesBuyerInfoEditable === null,
      value:
        formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
          ?.companyName,
      children: getCompanyListingEle(),
      onSearch: handleSearchDropdownOptions,
      createOptionLabel: "Create Company",
      createRecord: () => {
        dispatch(setNavigatedFrom("Transaction"));
        dispatch(setCompanyId(null));
        navigate("/companyDetails");
      },
    },
    {
      type: "input",
      label: "Representative Person Name",
      name: "representativeName",
      placeholder: "Representative Person Name",
      disabled:
        isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
        isPartiesBuyerInfoEditable === null,
      value:
        formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
          ?.representativeName,
    },
    {
      type: "input",
      label: "Representative Role",
      name: "representativeRole",
      placeholder: "Representative Role",
      disabled:
        isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
        isPartiesBuyerInfoEditable === null,
      value:
        formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
          ?.representativeRole,
    },
    {
      type: "input",
      label: "Representative Aadhar",
      name: "representativeAadhar",
      placeholder: "Representative Aadhar",
      disabled:
        isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
        isPartiesBuyerInfoEditable === null,
      value:
        formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
          ?.representativeAadhar,
    },
    {
      type: "input",
      label: "Representative PAN",
      name: "representativePan",
      placeholder: "Representative PAN",
      disabled:
        isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
        isPartiesBuyerInfoEditable === null,
      value:
        formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
          ?.representativePan,
    },
    {
      type: "input",
      label: "Representative E-mail",
      name: "representativeEmail",
      placeholder: "Representative E-mail",
      disabled:
        isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
        isPartiesBuyerInfoEditable === null,
      value:
        formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
          ?.representativeEmail,
    },
    {
      type: "input",
      label: "Representative Contact",
      name: "representativeContact",
      placeholder: "Representative Contact",
      disabled:
        isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
        isPartiesBuyerInfoEditable === null,
      value:
        formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
          ?.representativeContact,
    },
  ];
  const partiesInfoFormElementsForIndividualBuyer = [
    {
      type: "input",
      label: "Name",
      name: "name",
      placeholder: "Name",
      disabled:
        isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
        isPartiesBuyerInfoEditable === null,
      value:
        formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]?.name,
    },
    {
      type: "input",
      label: "PAN",
      name: "pan",
      placeholder: "PAN",
      disabled:
        isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
        isPartiesBuyerInfoEditable === null,
      value:
        formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]?.pan,
    },
    {
      type: "input",
      label: "Aadhar",
      name: "aadhar",
      placeholder: "Aadhar",
      disabled:
        isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
        isPartiesBuyerInfoEditable === null,
      value:
        formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
          ?.aadhar,
    },
    {
      type: "input",
      label: "Contact",
      name: "contact",
      placeholder: "Contact",
      disabled:
        isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
        isPartiesBuyerInfoEditable === null,
      value:
        formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
          ?.contact,
    },
    {
      type: "input",
      label: "E-mail",
      name: "email",
      placeholder: "E-mail",
      disabled:
        isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
        isPartiesBuyerInfoEditable === null,
      value:
        formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
          ?.email,
    },
  ];
  const partiesInfoFormElementsForCompanySeller = [
    {
      type: "dropdown",
      label: "Company Name",
      name: "companyName",
      placeholder: "Company Name",
      showSearch: true,
      disabled:
        isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
        isPartiesSellerInfoEditable === null,
      value:
        formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
          ?.companyName,
      children: getCompanyListingEle(),
      onSearch: handleSearchDropdownOptions,
      createOptionLabel: "Create Company",
      createRecord: () => {
        dispatch(setNavigatedFrom("Transaction"));
        dispatch(setCompanyId(null));
        navigate("/companyDetails");
      },
    },
    {
      type: "input",
      label: "Representative Person Name",
      name: "representativeName",
      placeholder: "Representative Person Name",
      disabled:
        isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
        isPartiesSellerInfoEditable === null,
      value:
        formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
          ?.representativeName,
    },
    {
      type: "input",
      label: "Representative Role",
      name: "representativeRole",
      placeholder: "Representative Role",
      disabled:
        isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
        isPartiesSellerInfoEditable === null,
      value:
        formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
          ?.representativeRole,
    },
    {
      type: "input",
      label: "Representative Aadhar",
      name: "representativeAadhar",
      placeholder: "Representative Aadhar",
      disabled:
        isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
        isPartiesSellerInfoEditable === null,
      value:
        formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
          ?.representativeAadhar,
    },
    {
      type: "input",
      label: "Representative PAN",
      name: "representativePan",
      placeholder: "Representative PAN",
      disabled:
        isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
        isPartiesSellerInfoEditable === null,
      value:
        formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
          ?.representativePan,
    },
    {
      type: "input",
      label: "Representative E-mail",
      name: "representativeEmail",
      placeholder: "Representative E-mail",
      disabled:
        isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
        isPartiesSellerInfoEditable === null,
      value:
        formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
          ?.representativeEmail,
    },
    {
      type: "input",
      label: "Representative Contact",
      name: "representativeContact",
      placeholder: "Representative Contact",
      disabled:
        isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
        isPartiesSellerInfoEditable === null,
      value:
        formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
          ?.representativeContact,
    },
  ];
  const partiesInfoFormElementsForIndividualSeller = [
    {
      type: "input",
      label: "Name",
      name: "name",
      placeholder: "Name",
      disabled:
        isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
        isPartiesSellerInfoEditable === null,
      value:
        formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
          ?.name,
    },
    {
      type: "input",
      label: "PAN",
      name: "pan",
      placeholder: "PAN",
      disabled:
        isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
        isPartiesSellerInfoEditable === null,
      value:
        formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
          ?.pan,
    },
    {
      type: "input",
      label: "Aadhar",
      name: "aadhar",
      placeholder: "Aadhar",
      disabled:
        isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
        isPartiesSellerInfoEditable === null,
      value:
        formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
          ?.aadhar,
    },
    {
      type: "input",
      label: "Contact",
      name: "contact",
      placeholder: "Contact",
      disabled:
        isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
        isPartiesSellerInfoEditable === null,
      value:
        formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
          ?.contact,
    },
    {
      type: "input",
      label: "E-mail",
      name: "email",
      placeholder: "E-mail",
      disabled:
        isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
        isPartiesSellerInfoEditable === null,
      value:
        formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
          ?.email,
    },
  ];
  const buildingInfoFormElementsCol1 = [
    {
      type: "input",
      label: "Property ID",
      name: "buildingId",
      placeholder: "Property ID",
      required: true,
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.buildingInformation?.propertyId,
      onKeyDown: async (event) => {
        if (event.key === "Enter") {
          dispatch(getPropertyDetailsData(event.target.value));
        }
      }
    },
    {
      type: "input",
      label: "Property Structure (2B+G+12)",
      name: "buildingStructure",
      placeholder: "Property Structure (2B+G+12)",
      disabled: true,
      value: formTransactionData?.buildingInformation?.buildingStructure,
    },
    {
      type: "input",
      label: "Property Type",
      name: "buildingType",
      placeholder: "Property Type",
      disabled: true,
      value: formTransactionData?.buildingInformation?.buildingType,
    },
    {
      type: "input",
      label: "Street",
      name: "street",
      placeholder: "Street",
      disabled: true,
      value: formTransactionData?.buildingInformation?.street,
    },
    {
      type: "input",
      label: "Block No",
      name: "block",
      placeholder: "Block No",
      disabled: true,
      value: formTransactionData?.buildingInformation?.block,
    },
    {
      type: "input",
      label: "City",
      name: "city",
      placeholder: "City",
      disabled: true,
      value: formTransactionData?.buildingInformation?.city,
    },
    {
      type: "input",
      label: "Coordinates LAT",
      name: "coordinates",
      placeholder: "Coordinates LAT",
      disabled: true,
      value: formTransactionData?.buildingInformation?.coordinates?.latitude,
    },
  ];
  const buildingInfoFormElementsCol2 = [
    {
      type: "input",
      label: "Property Name",
      name: "buildingName",
      placeholder: "Property Name",
      disabled: true,
      value: formTransactionData?.buildingInformation?.buildingName,
    },
    {
      type: "input",
      label: "Property Status",
      name: "buildingStatus",
      placeholder: "Property Status",
      disabled: true,
      value: formTransactionData?.buildingInformation?.buildingStatus,
    },
    {
      type: "input",
      label: "Plot No.",
      name: "plot",
      placeholder: "Plot No.",
      disabled: true,
      value: formTransactionData?.buildingInformation?.plot,
    },
    {
      type: "input",
      label: "Pin Code",
      name: "pincode",
      placeholder: "Pin Code",
      disabled: true,
      value: formTransactionData?.buildingInformation?.pincode,
    },
    {
      type: "input",
      label: "Micro Market",
      name: "microMarket",
      placeholder: "Micro Market",
      disabled: true,
      value:
        typeof formTransactionData?.buildingInformation?.microMarket ===
          "object" &&
          formTransactionData?.buildingInformation?.microMarket?.length > 1
          ? formTransactionData?.buildingInformation?.microMarket?.join(", ")
          : formTransactionData?.buildingInformation?.microMarket,
    },
    {
      type: "input",
      label: "Coordinates LOG",
      name: "longitude",
      placeholder: "Coordinates LOG",
      disabled: true,
      value: formTransactionData?.buildingInformation?.coordinates?.longitude,
    },
  ];
  const buildingInfoFormElementsCol3 = [
    {
      type: "input",
      label: "Developer",
      name: "developer",
      placeholder: "Developer",
      disabled: true,
      value: formTransactionData?.buildingInformation?.developer,
    },
    {
      type: "textArea",
      label: "Address",
      name: "complete_address",
      placeholder: "Address",
      disabled: true,
      value: formTransactionData?.buildingInformation?.complete_address,
      width: "500px",
    },
    {
      type: "input",
      label: "State",
      name: "state",
      placeholder: "State",
      disabled: true,
      value: formTransactionData?.buildingInformation?.state,
    },
    {
      type: "input",
      label: "Locality",
      name: "locality",
      placeholder: "Locality",
      disabled: true,
      value: formTransactionData?.buildingInformation?.locality,
    },
  ];

  useEffect(() => {
    if (id) {
      dispatch(getTransactionDetailsData(id));
      dispatch(setTransactionId(id));
      dispatch(setTransactionInfoEditable(false));
      setShowBuyerPartiesInfoKey(null);
      setShowSellerPartiesInfoKey(null);
    } else {
      if (navigatedFrom === "Transaction") {
        setFormTransactionData(transactionData);
        dispatch(setNavigatedFrom(null));
      } else {
        dispatch(getPropertyDetailsData(propertyId));
      }
      dispatch(setTransactionInfoEditable(true));
    }
    dispatch(getAllDocumentMaster());
  }, []);

  useEffect(() => {
    if (showBuyerPartiesInfoKey === null) {
      handleShowPartiesInfo(0, "buyerInfo");
    } else {
      handleShowPartiesInfo(showBuyerPartiesInfoKey, "buyerInfo");
    }
    const mappedData = formTransactionData?.buyerInfo?.buyers?.map(
      (item, index) => {
        const isEditable =
          isPartiesBuyerInfoEditable === index && isTransactionInfoEditable;
        const label =
          item?.buyerType === "Company" ? item?.companyName : item?.name;
        const uniqueSuffix = index; // Unique suffix based on index

        return {
          key: index,
          label: label,
          children: (
            <React.Fragment key={index}>
              <div style={{ float: "right" }}>
                {!isEditable && (
                  <Button
                    onClick={() => {
                      setIsPartiesBuyerInfoEditable(index);
                      setShowBuyerPartiesInfoKey(index);
                    }}
                    disabled={!isTransactionInfoEditable}
                  >
                    Edit
                  </Button>
                )}
                &nbsp;&nbsp;
                {/* {isEditable && (
                  <Button onClick={handleSaveBuyerPartiesInfo(formTransactionData?.buyerInfo?.buyers?.[index])}>Save</Button>
                )}
                &nbsp;&nbsp; */}
                <Button
                  onClick={() => handleDeletePartiesInfo("buyerInfo", index)}
                  disabled={!isTransactionInfoEditable}
                >
                  Delete
                </Button>
              </div>
              <div>
                {item?.buyerType === "Company" ? (
                  <Col span={6}>
                    {partiesInfoFormElementsForCompanyBuyer.map(
                      (element, i) => {
                        return (
                          <FormElement
                            key={i}
                            {...element}
                            name={`buyers_${element.name}_${uniqueSuffix}`}
                            onChange={(e, obj) => {
                              const updatedBuyers = [
                                ...formTransactionData?.buyerInfo?.buyers,
                              ];
                              updatedBuyers[index] = {
                                ...updatedBuyers?.[index],
                                [element.name]:
                                  element.name === "companyName"
                                    ? e
                                    : e.target.value,
                                companyId: obj?.key
                                  ? obj?.key
                                  : updatedBuyers?.[index]?.companyId, // Assuming this is how you update companyId
                              };
                              dispatch(
                                setTransactionInfo({
                                  name: "buyerType",
                                  value: updatedBuyers,
                                })
                              );
                            }}
                          />
                        );
                      }
                    )}
                  </Col>
                ) : (
                  <Col span={6}>
                    {partiesInfoFormElementsForIndividualBuyer.map(
                      (element, i) => {
                        return (
                          <FormElement
                            key={i}
                            {...element}
                            name={`buyers_${element.name}_${uniqueSuffix}`}
                            onChange={(e) => {
                              const updatedBuyers = [
                                ...formTransactionData?.buyerInfo?.buyers,
                              ];
                              updatedBuyers[index] = {
                                ...updatedBuyers[index],
                                [element.name]: e.target.value,
                              };
                              dispatch(
                                setTransactionInfo({
                                  name: "buyerType",
                                  value: updatedBuyers,
                                })
                              );
                            }}
                          />
                        );
                      }
                    )}
                  </Col>
                )}
              </div>
            </React.Fragment>
          ),
        };
      }
    );
    setSavedBuyersList(mappedData);
  }, [
    savedBuyersCnt,
    showBuyerPartiesInfoKey,
    isPartiesBuyerInfoEditable,
    formTransactionData,
    isTransactionInfoEditable,
    partiesSaveBuyerInfo,
    companies,
  ]);

  useEffect(() => {
    if (showSellerPartiesInfoKey === null) {
      handleShowPartiesInfo(0, "sellerInfo");
    } else {
      handleShowPartiesInfo(showSellerPartiesInfoKey, "sellerInfo");
    }
    const mappedData = formTransactionData?.sellerInfo?.sellers?.map(
      (item, index) => {
        const isEditable =
          isPartiesSellerInfoEditable === index && isTransactionInfoEditable;
        const label =
          item?.sellerType === "Company" ? item?.companyName : item?.name;
        const uniqueSuffix = index; // Unique suffix based on index

        return {
          key: index,
          label: label,
          children: (
            <React.Fragment key={index}>
              <div style={{ float: "right" }}>
                {!isEditable && (
                  <Button
                    onClick={() => {
                      setIsPartiesSellerInfoEditable(index);
                      setShowSellerPartiesInfoKey(index);
                    }}
                    disabled={!isTransactionInfoEditable}
                  >
                    Edit
                  </Button>
                )}
                &nbsp;&nbsp;
                {/* {isEditable && (
                  <Button
                    onClick={() =>
                      handleSaveSellerPartiesInfo(
                        formTransactionData?.sellerInfo?.sellers?.[index]
                      )
                    }
                  >
                    Save
                  </Button>
                )}
                &nbsp;&nbsp; */}
                <Button
                  onClick={() => handleDeletePartiesInfo("sellerInfo", index)}
                  disabled={!isTransactionInfoEditable}
                >
                  Delete
                </Button>
              </div>
              {item?.sellerType === "Company" ? (
                <Col span={6}>
                  {partiesInfoFormElementsForCompanySeller.map((element, i) => (
                    <FormElement
                      key={i}
                      {...element}
                      name={`sellers_${element.name}_${uniqueSuffix}`}
                      onChange={(e, obj) => {
                        const updatedSellers = [
                          ...formTransactionData?.sellerInfo?.sellers,
                        ];
                        updatedSellers[index] = {
                          ...updatedSellers[index],
                          [element.name]:
                            element.name === "companyName" ? e : e.target.value,
                          companyId: obj?.key
                            ? obj?.key
                            : updatedSellers?.[index]?.companyId, // Assuming this is how you update companyId
                        };
                        dispatch(
                          setTransactionInfo({
                            name: "sellerType",
                            value: updatedSellers,
                          })
                        );
                      }}
                    />
                  ))}
                </Col>
              ) : (
                <Col span={6}>
                  {partiesInfoFormElementsForIndividualSeller.map(
                    (element, i) => (
                      <FormElement
                        key={i}
                        {...element}
                        name={`sellers_${element.name}_${uniqueSuffix}`}
                        onChange={(e) => {
                          const updatedSellers = [
                            ...formTransactionData?.sellerInfo?.sellers,
                          ];
                          updatedSellers[index] = {
                            ...updatedSellers[index],
                            [element.name]: e.target.value,
                          };
                          dispatch(
                            setTransactionInfo({
                              name: "sellerType",
                              value: updatedSellers,
                            })
                          );
                        }}
                      />
                    )
                  )}
                </Col>
              )}
            </React.Fragment>
          ),
        };
      }
    );
    setSavedSellersList(mappedData);
  }, [
    savedSellersCnt,
    showSellerPartiesInfoKey,
    isPartiesSellerInfoEditable,
    formTransactionData,
    isTransactionInfoEditable,
    partiesSaveSellerInfo,
    companies,
  ]);

  const validatePartiesInfo = (obj, type) => {
    const isCompany =
      obj?.[`${type === "buyerInfo" ? "buyer" : "seller"}Type`] === "Company";

    if (type === "buyerInfo" || type === "sellerInfo") {
      if (isEmpty(obj?.[`${type === "buyerInfo" ? "buyer" : "seller"}Type`]))
        return false;

      if (isCompany) {
        if (obj?.representativeAadhar && !isAadhar(obj?.representativeAadhar)) {
          partiesInfoAadharValidationMessage();
          return false;
        }
        if (obj?.representativePan && !isPAN(obj?.representativePan)) {
          partiesInfoPANValidationMessage();
          return false;
        }
        if (obj?.representativeEmail && !isEmail(obj?.representativeEmail)) {
          partiesInfoEmailValidationMessage();
          return false;
        }
        if (
          !isEmpty(obj?.representativeContact) &&
          !isMobileNumber(obj?.representativeContact)
        ) {
          partiesInfoContactValidationMessage();
          return false;
        }
        if (isEmpty(obj?.companyName)) {
          partiesInfoValidationMessage();
          return false;
        }
      } else {
        // Individual
        if (obj?.aadhar && !isAadhar(obj?.aadhar)) {
          partiesInfoAadharValidationMessage();
          return false;
        }
        if (obj?.pan && !isPAN(obj?.pan)) {
          partiesInfoPANValidationMessage();
          return false;
        }
        if (!isEmpty(obj?.email) && !isEmail(obj?.email)) {
          partiesInfoEmailValidationMessage();
          return false;
        }
        if (!isEmpty(obj?.contact) && !isMobileNumber(obj?.contact)) {
          partiesInfoContactValidationMessage();
          return false;
        }
        if (isEmpty(obj?.name)) {
          partiesInfoValidationMessage();
          return false;
        }
      }
    }

    return true;
  };

  const validateTransactionInfo = () => {
    if (
      isEmpty(formTransactionData?.agreement_information?.documentName) ||
      isEmpty(formTransactionData?.agreement_information?.registrationDate) ||
      isEmpty(formTransactionData?.buildingInformation?.propertyId)
    ) {
      transactionInfoValidationMessage();
      return false;
    }
    return true;
  };

  const handleAddPartiesInfo = (type, index) => {
    if (type === "buyerInfo") setShowPartiesInfoBuyerRadioGroup(true);
    else setShowPartiesInfoSellerRadioGroup(true);
  };

  const handleDeletePartiesInfo = (type, index) => {
    if (type === "buyerInfo") {
      let partiesBuyerInfo = [...formTransactionData?.buyerInfo?.buyers];
      partiesBuyerInfo?.splice(index, 1);
      dispatch(
        setTransactionInfo({
          name: "buyerType",
          value: partiesBuyerInfo,
        })
      );
      setSavedBuyersCnt(savedBuyersCnt - 1);
      setShowPartiesInfoBuyerForm(false);
      setShowPartiesInfoBuyerRadioGroup(false);
      setPartiesSaveBuyerInfo({});
      setBuyerType(null);
    } else if (type === "sellerInfo") {
      let partiesSellerInfo = [...formTransactionData?.sellerInfo?.sellers];
      partiesSellerInfo?.splice(index, 1);
      dispatch(
        setTransactionInfo({
          name: "sellerType",
          value: partiesSellerInfo,
        })
      );
      setSavedSellersCnt(savedSellersCnt - 1);
      setShowPartiesInfoSellerForm(false);
      setShowPartiesInfoSellerRadioGroup(false);
      setPartiesSaveSellerInfo({});
      setSellerType(null);
    }
  };

  const handleSaveBuyerPartiesInfo = () => {

    partiesSaveBuyerInfo.representativePan && (partiesSaveBuyerInfo.representativePan = partiesSaveBuyerInfo.representativePan.toUpperCase());
    partiesSaveBuyerInfo.pan && (partiesSaveBuyerInfo.pan = partiesSaveBuyerInfo.pan.toUpperCase());
    if (validatePartiesInfo(partiesSaveBuyerInfo, "buyerInfo")) {
      dispatch(
        setTransactionInfo({
          name: "buyerType",
          value: formTransactionData?.buyerInfo?.buyers
            ? formTransactionData?.buyerInfo?.buyers.concat(
              partiesSaveBuyerInfo
            )
            : [partiesSaveBuyerInfo],
        })
      );
      setSavedBuyersCnt(savedBuyersCnt + 1);
      setShowPartiesInfoBuyerForm(false);
      setShowPartiesInfoBuyerRadioGroup(false);
      setPartiesSaveBuyerInfo({});
      setBuyerType(null);
      setShowBuyerPartiesInfoKey(null);

      // Extract fields to set in the form using destructuring
      const {
        companyName,
        representativeName,
        representativeAadhar,
        representativePan,
        representativeContact,
        representativeEmail,
        representativeRole,
        name,
        email,
        pan,
        aadhar,
        contact,
      } = partiesSaveBuyerInfo;

      // Generate unique field name suffix based on some identifier (e.g., index)
      const uniqueSuffix = savedBuyersCnt; // Use appropriate unique identifier here

      // Update form fields with the saved buyer info using unique field names
      form.setFieldsValue({
        [`buyers_companyName_${uniqueSuffix}`]: companyName,
        [`buyers_representativeName_${uniqueSuffix}`]: representativeName,
        [`buyers_representativeAadhar_${uniqueSuffix}`]: representativeAadhar,
        [`buyers_representativePan_${uniqueSuffix}`]: representativePan,
        [`buyers_representativeContact_${uniqueSuffix}`]: representativeContact,
        [`buyers_representativeEmail_${uniqueSuffix}`]: representativeEmail,
        [`buyers_representativeRole_${uniqueSuffix}`]: representativeRole,
        [`buyers_name_${uniqueSuffix}`]: name,
        [`buyers_email_${uniqueSuffix}`]: email,
        [`buyers_pan_${uniqueSuffix}`]: pan,
        [`buyers_aadhar_${uniqueSuffix}`]: aadhar,
        [`buyers_contact_${uniqueSuffix}`]: contact,
      });
    }
  };

  const handleSaveSellerPartiesInfo = () => {
    partiesSaveSellerInfo.representativePan && (partiesSaveSellerInfo.representativePan = partiesSaveSellerInfo.representativePan.toUpperCase());
    partiesSaveSellerInfo.pan && (partiesSaveSellerInfo.pan = partiesSaveSellerInfo.pan.toUpperCase());
    if (validatePartiesInfo(partiesSaveSellerInfo, "sellerInfo")) {
      dispatch(
        setTransactionInfo({
          name: "sellerType",
          value: formTransactionData?.sellerInfo?.sellers
            ? formTransactionData?.sellerInfo?.sellers.concat(
              partiesSaveSellerInfo
            )
            : [partiesSaveSellerInfo],
        })
      );
      setSavedSellersCnt(savedSellersCnt + 1);
      setShowPartiesInfoSellerForm(false);
      setShowPartiesInfoSellerRadioGroup(false);
      setPartiesSaveSellerInfo({});
      setSellerType(null);
      setShowSellerPartiesInfoKey(null);
      // Extract fields to set in the form using destructuring
      const {
        companyName,
        representativeName,
        representativeAadhar,
        representativePan,
        representativeContact,
        representativeEmail,
        representativeRole,
        name,
        email,
        pan,
        aadhar,
        contact,
      } = partiesSaveSellerInfo;

      // Generate unique field name suffix based on some identifier (e.g., index)
      const uniqueSuffix = savedSellersCnt; // Use appropriate unique identifier here

      // Update form fields with the saved buyer info using unique field names
      form.setFieldsValue({
        [`sellers_companyName_${uniqueSuffix}`]: companyName,
        [`sellers_representativeName_${uniqueSuffix}`]: representativeName,
        [`sellers_representativeAadhar_${uniqueSuffix}`]: representativeAadhar,
        [`sellers_representativePan_${uniqueSuffix}`]: representativePan,
        [`sellers_representativeContact_${uniqueSuffix}`]:
          representativeContact,
        [`sellers_representativeEmail_${uniqueSuffix}`]: representativeEmail,
        [`sellers_representativeRole_${uniqueSuffix}`]: representativeRole,
        [`sellers_name_${uniqueSuffix}`]: name,
        [`sellers_email_${uniqueSuffix}`]: email,
        [`sellers_pan_${uniqueSuffix}`]: pan,
        [`sellers_aadhar_${uniqueSuffix}`]: aadhar,
        [`sellers_contact_${uniqueSuffix}`]: contact,
      });
    }
  };

  const partiesInfoValidationMessage = () => {
    messageApi.open({
      type: "warning",
      content:
        "Please fill in all required fields in Parties Information Section",
    });
  };

  const partiesInfoAadharValidationMessage = () => {
    messageApi.open({
      type: "warning",
      content: "Please input valid aadhar number",
    });
  };

  const partiesInfoPANValidationMessage = () => {
    messageApi.open({
      type: "warning",
      content: "Please input valid PAN number",
    });
  };

  const partiesInfoEmailValidationMessage = () => {
    messageApi.open({
      type: "warning",
      content: "Please input valid Email address",
    });
  };

  const partiesInfoContactValidationMessage = () => {
    messageApi.open({
      type: "warning",
      content: "Please input valid Contact number",
    });
  };

  const handleShowPartiesInfo = (key, type) => {
    const suffix = key !== undefined ? `_${key}` : ""; // Unique suffix logic

    if (key === undefined && type === "buyerInfo") {
      setShowBuyerPartiesInfoKey(0);
      setIsPartiesBuyerInfoEditable(null);
      form.setFieldsValue({
        ...form,
        [`buyers_companyName${suffix}`]:
          formTransactionData?.buyerInfo?.buyers?.[0]?.companyName,
        [`buyers_representativeName${suffix}`]:
          formTransactionData?.buyerInfo?.buyers?.[0]?.representativeName,
        [`buyers_representativeAadhar${suffix}`]:
          formTransactionData?.buyerInfo?.buyers?.[0]?.representativeAadhar,
        [`buyers_representativePan${suffix}`]:
          formTransactionData?.buyerInfo?.buyers?.[0]?.representativePan,
        [`buyers_representativeContact${suffix}`]:
          formTransactionData?.buyerInfo?.buyers?.[0]?.representativeContact,
        [`buyers_representativeEmail${suffix}`]:
          formTransactionData?.buyerInfo?.buyers?.[0]?.representativeEmail,
        [`buyers_representativeRole${suffix}`]:
          formTransactionData?.buyerInfo?.buyers?.[0]?.representativeRole,
        [`buyers_name${suffix}`]:
          formTransactionData?.buyerInfo?.buyers?.[0]?.name,
        [`buyers_email${suffix}`]:
          formTransactionData?.buyerInfo?.buyers?.[0]?.email,
        [`buyers_pan${suffix}`]:
          formTransactionData?.buyerInfo?.buyers?.[0]?.pan,
        [`buyers_aadhar${suffix}`]:
          formTransactionData?.buyerInfo?.buyers?.[0]?.aadhar,
        [`buyers_contact${suffix}`]:
          formTransactionData?.buyerInfo?.buyers?.[0]?.contact,
      });
    } else if (key === undefined && type === "sellerInfo") {
      setShowSellerPartiesInfoKey(0);
      setIsPartiesSellerInfoEditable(null);
      form.setFieldsValue({
        ...form,
        [`sellers_companyName${suffix}`]:
          formTransactionData?.sellerInfo?.sellers?.[0]?.companyName,
        [`sellers_representativeName${suffix}`]:
          formTransactionData?.sellerInfo?.sellers?.[0]?.representativeName,
        [`sellers_representativeAadhar${suffix}`]:
          formTransactionData?.sellerInfo?.sellers?.[0]?.representativeAadhar,
        [`sellers_representativePan${suffix}`]:
          formTransactionData?.sellerInfo?.sellers?.[0]?.representativePan,
        [`sellers_representativeContact${suffix}`]:
          formTransactionData?.sellerInfo?.sellers?.[0]?.representativeContact,
        [`sellers_representativeEmail${suffix}`]:
          formTransactionData?.sellerInfo?.sellers?.[0]?.representativeEmail,
        [`sellers_representativeRole${suffix}`]:
          formTransactionData?.sellerInfo?.sellers?.[0]?.representativeRole,
        [`sellers_name${suffix}`]:
          formTransactionData?.sellerInfo?.sellers?.[0]?.name,
        [`sellers_email${suffix}`]:
          formTransactionData?.sellerInfo?.sellers?.[0]?.email,
        [`sellers_pan${suffix}`]:
          formTransactionData?.sellerInfo?.sellers?.[0]?.pan,
        [`sellers_aadhar${suffix}`]:
          formTransactionData?.sellerInfo?.sellers?.[0]?.aadhar,
        [`sellers_contact${suffix}`]:
          formTransactionData?.sellerInfo?.sellers?.[0]?.contact,
      });
    } else if (key) {
      if (type === "buyerInfo") {
        setShowBuyerPartiesInfoKey(Number(key));
        form.setFieldsValue({
          ...form,
          [`buyers_companyName${suffix}`]:
            formTransactionData?.buyerInfo?.buyers?.[Number(key)]?.companyName,
          [`buyers_representativeName${suffix}`]:
            formTransactionData?.buyerInfo?.buyers?.[Number(key)]
              ?.representativeName,
          [`buyers_representativeAadhar${suffix}`]:
            formTransactionData?.buyerInfo?.buyers?.[Number(key)]
              ?.representativeAadhar,
          [`buyers_representativePan${suffix}`]:
            formTransactionData?.buyerInfo?.buyers?.[Number(key)]
              ?.representativePan,
          [`buyers_representativeContact${suffix}`]:
            formTransactionData?.buyerInfo?.buyers?.[Number(key)]
              ?.representativeContact,
          [`buyers_representativeEmail${suffix}`]:
            formTransactionData?.buyerInfo?.buyers?.[Number(key)]
              ?.representativeEmail,
          [`buyers_representativeRole${suffix}`]:
            formTransactionData?.buyerInfo?.buyers?.[Number(key)]
              ?.representativeRole,
          [`buyers_name${suffix}`]:
            formTransactionData?.buyerInfo?.buyers?.[Number(key)]?.name,
          [`buyers_email${suffix}`]:
            formTransactionData?.buyerInfo?.buyers?.[Number(key)]?.email,
          [`buyers_pan${suffix}`]:
            formTransactionData?.buyerInfo?.buyers?.[Number(key)]?.pan,
          [`buyers_aadhar${suffix}`]:
            formTransactionData?.buyerInfo?.buyers?.[Number(key)]?.aadhar,
          [`buyers_contact${suffix}`]:
            formTransactionData?.buyerInfo?.buyers?.[Number(key)]?.contact,
        });
      } else if (type === "sellerInfo") {
        setShowSellerPartiesInfoKey(Number(key));
        form.setFieldsValue({
          ...form,
          [`sellers_companyName${suffix}`]:
            formTransactionData?.sellerInfo?.sellers?.[Number(key)]
              ?.companyName,
          [`sellers_representativeName${suffix}`]:
            formTransactionData?.sellerInfo?.sellers?.[Number(key)]
              ?.representativeName,
          [`sellers_representativeAadhar${suffix}`]:
            formTransactionData?.sellerInfo?.sellers?.[Number(key)]
              ?.representativeAadhar,
          [`sellers_representativePan${suffix}`]:
            formTransactionData?.sellerInfo?.sellers?.[Number(key)]
              ?.representativePan,
          [`sellers_representativeContact${suffix}`]:
            formTransactionData?.sellerInfo?.sellers?.[Number(key)]
              ?.representativeContact,
          [`sellers_representativeEmail${suffix}`]:
            formTransactionData?.sellerInfo?.sellers?.[Number(key)]
              ?.representativeEmail,
          [`sellers_representativeRole${suffix}`]:
            formTransactionData?.sellerInfo?.sellers?.[Number(key)]
              ?.representativeRole,
          [`sellers_name${suffix}`]:
            formTransactionData?.sellerInfo?.sellers?.[Number(key)]?.name,
          [`sellers_email${suffix}`]:
            formTransactionData?.sellerInfo?.sellers?.[Number(key)]?.email,
          [`sellers_pan${suffix}`]:
            formTransactionData?.sellerInfo?.sellers?.[Number(key)]?.pan,
          [`sellers_aadhar${suffix}`]:
            formTransactionData?.sellerInfo?.sellers?.[Number(key)]?.aadhar,
          [`sellers_contact${suffix}`]:
            formTransactionData?.sellerInfo?.sellers?.[Number(key)]?.contact,
        });
      }
    }
  };

  const getPartiesInfoPayloadForBuyer = () => {
    let individual = formTransactionData?.buyerInfo?.buyers?.filter(
      (item) => item?.buyerType === "Individual"
    );
    let company = formTransactionData?.buyerInfo?.buyers?.filter(
      (item) => item?.buyerType === "Company"
    );
    let payloadObjIndividual = individual?.map((item) => {
      return {
        _id: item?._id,  // Include _id
        personName: item?.name,
        panNumber: item?.pan,
        aadharNumber: item?.aadhar,
        contactNumber: item?.contact,
        emailId: item?.email,
      };
    });
    let payloadObjCompany = company?.map((item) => {
      return {
        companyName: item?.companyName,
        companyId: item?.companyId,
        representative: [
          {
            _id: item?.representativeId,  // Include _id
            personName: item?.representativeName,
            panNumber: item?.representativePan,
            aadharNumber: item?.representativeAadhar,
            role: item?.representativeRole,
            contactNumber: item?.representativeContact,
            emailId: item?.representativeEmail,
          },
        ],
      };
    });
    return {
      Individual: payloadObjIndividual,
      Company: payloadObjCompany,
    };
  };

  const getPartiesInfoPayloadForSeller = () => {
    let individual = formTransactionData?.sellerInfo?.sellers?.filter(
      (item) => item?.sellerType === "Individual"
    );
    let company = formTransactionData?.sellerInfo?.sellers?.filter(
      (item) => item?.sellerType === "Company"
    );
    let payloadObjIndividual = individual?.map((item) => {
      return {
        _id: item?._id,  // Include _id
        personName: item?.name,
        panNumber: item?.pan,
        aadharNumber: item?.aadhar,
        contactNumber: item?.contact,
        emailId: item?.email,
      };
    });
    let payloadObjCompany = company?.map((item) => {
      return {
        companyName: item?.companyName,
        companyId: item?.companyId,
        representative: [
          {
            _id: item?.representativeId,  // Include _id
            personName: item?.representativeName,
            panNumber: item?.representativePan,
            aadharNumber: item?.representativeAadhar,
            role: item?.representativeRole,
            contactNumber: item?.representativeContact,
            emailId: item?.representativeEmail,
          },
        ],
      };
    });
    return {
      Individual: payloadObjIndividual,
      Company: payloadObjCompany,
    };
  };

  const getUpdateTransactionPayload = (transactionDetails) => {
    const formData = new FormData();
    if (
      transactionDetails?.propertyImages &&
      transactionDetails?.propertyImages?.length > 0
    ) {
      for (
        let index = 0;
        index < transactionDetails?.propertyImages.length;
        index++
      ) {
        if (transactionDetails?.propertyImages?.[index]?.originFileObj) {
          formData.append(
            "images",
            transactionDetails?.propertyImages?.[index]?.originFileObj
          );
        }
      }
    }

    transactionDetails?.agreement_information?.registrationDate &&
      formData.append(
        "registrationDate",
        transactionDetails?.agreement_information?.registrationDate
      );
    transactionDetails?.agreement_information?.documentSubmissionDate &&
      formData.append(
        "documentSubmissionDate",
        transactionDetails?.agreement_information?.documentSubmissionDate
      );
    transactionDetails?.agreement_information?.expiryDate &&
      formData.append(
        "expiryDate",
        transactionDetails?.agreement_information?.expiryDate
      );

    let filteredDeletedFiles = deletedFileList.filter(Boolean);

    const data = {
      propertyId: transactionDetails?.buildingInformation?.propertyId,
      buildingName: transactionDetails?.buildingInformation?.buildingName,
      developerName: transactionDetails?.buildingInformation?.developerName,
      buildingStructure: transactionDetails?.buildingInformation,
      buildingStatus: transactionDetails?.buildingInformation?.buildingStatus,
      complete_address: transactionDetails?.buildingInformation?.complete_address,
      buildingType: transactionDetails?.buildingInformation?.buildingType,
      plot: transactionDetails?.buildingInformation?.plot,
      stateId: transactionDetails?.buildingInformation?.stateId,
      street: transactionDetails?.buildingInformation?.street,
      pincodeId: transactionDetails?.buildingInformation?.pincodeId,
      localityId: transactionDetails?.buildingInformation?.localityId,
      block: transactionDetails?.buildingInformation?.block,
      microMarketId: transactionDetails?.buildingInformation?.microMarketId,
      cityId: transactionDetails?.buildingInformation?.cityId,
      documentNumber: transactionDetails?.agreement_information?.documentNo,
      documentName: transactionDetails?.agreement_information?.documentName,
      documentType: transactionDetails?.agreement_information?.documentType,
      sroName: transactionDetails?.agreement_information?.sroName,
      subdivisionHouseNo:
        transactionDetails?.agreement_information?.subdivisionHouseNo,
      address: {
        unitNo: transactionDetails?.transactionInformation?.unitNo,
        floorNo: transactionDetails?.transactionInformation?.floorNo,
      },
      unitCondition: transactionDetails?.transactionInformation?.unitcondition,
      unitStatus: transactionDetails?.transactionInformation?.unitStatus,
      dhcFeesOrDocumentHandlingCharges:
        transactionDetails?.transactionInformation
          ?.DHC_fees_or_document_handling_charges,
      registrationFees:
        transactionDetails?.transactionInformation?.registration_fees,
      securityDeposit:
        transactionDetails?.transactionInformation?.security_deposit,
      paymentOfFurnitureCharges:
        transactionDetails?.transactionInformation
          ?.Payment_of_furniture_fixture_charges_rs,
      parkingInfoTwoWheeler:
        transactionDetails?.others?.parking_information_of_two_wheeler,
      parkingInfoFourWheeler:
        transactionDetails?.others?.parking_information_of_four_wheeler,
      propertyDescription: transactionDetails?.others?.property_description,
      otherDetails: transactionDetails?.others?.other,
      marketPrice: transactionDetails?.transactionInformation?.market_price,
      declaredCircleRate:
        transactionDetails?.transactionInformation?.declared_circle_rate,
      compensation: transactionDetails?.transactionInformation?.compensation,
      bharlelMudrankSulkOrStampDutyPaid:
        transactionDetails?.transactionInformation
          ?.bharlele_mudrank_shulkh_or_Stamp_Duty_paid,
      licensePeriod: transactionDetails?.transactionInformation?.licence_period,
      lockinPeriod: transactionDetails?.transactionInformation?.lock_in_period,
      rentLicenseFee:
        transactionDetails?.transactionInformation?.rent_licence_fees,
      rentRate: transactionDetails?.transactionInformation?.rent_rate_sq_feet,
      saleRate: transactionDetails?.transactionInformation?.sale_rate_sq_feet,
      saleValue: transactionDetails?.transactionInformation?.saleValue,
      shera: transactionDetails?.transactionInformation?.shera,
      fitoutPeriod: transactionDetails?.transactionInformation?.fitoutPeriod,
      noticePeriod: transactionDetails?.transactionInformation?.noticePeriod,
      levy: transactionDetails?.transactionInformation?.levy,
      currentRentRate:
        transactionDetails?.transactionInformation?.currentRentRate,
      carpetArea: transactionDetails?.transactionInformation?.carpetArea,
      chargableArea:
        transactionDetails?.transactionInformation?.chargeableSquareFeet,
      escalationInLicenseFees:
        transactionDetails?.transactionInformation
          ?.escalation_in_licence_fees_percentage,
      cam: transactionDetails?.transactionInformation
        ?.CAM_Or_Common_Area_Maintenance,
      partiesInformation: {
        buyer: getPartiesInfoPayloadForBuyer(),
        seller: getPartiesInfoPayloadForSeller(),
      },
      legacyPartiesInformation: {
        allBuyerName:
          transactionDetails?.legacy_propertiesInformation?.buyers?.[0]
            ?.personName,
        allSellerName:
          transactionDetails?.legacy_propertiesInformation?.sellers?.[0]
            ?.personName,
      },
      rightOfRefusalFloors:
        transactionDetails?.others?.rightOfFirstRefusalFloors,
      rightOfRefusalActive:
        transactionDetails?.others?.rightOfFirstRefusalActive === "Yes"
          ? true
          : false,
      rightOfRefusalExpiry: transactionDetails?.others?.expiry,
      remarks: transactionDetails?.others?.remarks,
      deletedFiles: deletedFileList.length > 0 ? filteredDeletedFiles : null
    }
    for (const key in data) {
      if (data[key] !== null && data[key] !== undefined) {
        if (typeof data[key] === "object" && !(data[key] instanceof Date)) {
          formData.append(key, JSON.stringify(data[key]));
        } else {
          formData.append(key, data[key]);
        }
      }
    }
    return formData
  };

  const updateTransactionInfoSuccessMessage = () => {
    messageApi.open({
      type: "success",
      content: "Transaction Updated successfully",
    });
  };

  const saveTransactionInfoSuccessMessage = () => {
    messageApi.open({
      type: "success",
      content: "Transaction Created successfully",
    });
  };

  const saveTransactionInfoErrorMessage = (error) => {
    messageApi.open({
      type: "warning",
      content: error || "Something went wrong",
    });
  };

  const handleChangeTransactionsInfo = (element, e, dateVal, obj) => {
    if (element?.type === "datepicker") {
      form.setFieldsValue({
        ...form,
        [element?.name]: dateVal
          ? dayjs(dateVal, "DD-MM-YYYY").add(5, "hour").add(30, "minute")
          : "",
      });
      dispatch(
        setTransactionInfo({
          name: element?.name,
          value: dateVal ? dayjs(dateVal, "DD-MM-YYYY").add(5, "hour").add(30, "minute") : "",
          obj: obj,
        })
      );
    } else if (
      element?.type === "inputNumber" ||
      element?.type === "dropdown"
    ) {
      if (element?.name === 'unitStatus' && e === 'Vacant' && formTransactionData?.agreement_information?.documentName !== 'To Sale' &&
        formTransactionData?.agreement_information?.documentName !== 'To Rent') {
        form.setFieldsValue({
          ...form,
          documentName: null,
          documentType: null
        });
        dispatch(
          setTransactionInfo({
            name: "documentName",
            value: null,
            obj: "agreement_information",
          })
        );
      }

      form.setFieldsValue({
        ...form,
        [element?.name]: e,
      });
      dispatch(
        setTransactionInfo({
          name: element?.name,
          value: e,
          obj: obj,
        })
      );
    } else {
      form.setFieldsValue({
        ...form,
        [element?.name]: e.target.value,
      });
      dispatch(
        setTransactionInfo({
          name: element?.name,
          value: e.target.value,
          obj: obj,
        })
      );
    }
  };

  const handleCalculateDerivedFields = (element, e) => {
    switch (element?.name) {
      case "documentType":
        if (e === "Rent") {
          if (formTransactionData?.transactionInformation?.compensation) {
            form.setFieldsValue({
              ...form,
              rent_licence_fees: Number(
                formTransactionData?.transactionInformation?.compensation
              ),
              saleValue: 0,
              sale_rate_sq_feet: 0,
            });
            dispatch(
              setTransactionInfo({
                name: "rent_licence_fees",
                value: Number(
                  formTransactionData?.transactionInformation?.compensation
                ),
                obj: "transactionInformation",
              })
            );
            dispatch(
              setTransactionInfo({
                name: "saleValue",
                value: 0,
                obj: "transactionInformation",
              })
            );
            dispatch(
              setTransactionInfo({
                name: "sale_rate_sq_feet",
                value: 0,
                obj: "transactionInformation",
              })
            );
            if (
              formTransactionData?.transactionInformation
                ?.chargeableSquareFeet &&
              Number(
                formTransactionData?.transactionInformation
                  ?.chargeableSquareFeet
              )
            ) {
              form.setFieldsValue({
                ...form,
                rent_rate_sq_feet:
                  formTransactionData?.transactionInformation?.compensation /
                  formTransactionData?.transactionInformation
                    ?.chargeableSquareFeet,
              });
              dispatch(
                setTransactionInfo({
                  name: "rent_rate_sq_feet",
                  value:
                    formTransactionData?.transactionInformation?.compensation /
                    formTransactionData?.transactionInformation
                      ?.chargeableSquareFeet,
                  obj: "transactionInformation",
                })
              );
            } else {
              form.setFieldsValue({
                ...form,
                rent_rate_sq_feet: 0,
              });
              dispatch(
                setTransactionInfo({
                  name: "rent_rate_sq_feet",
                  value: 0,
                  obj: "transactionInformation",
                })
              );
            }
          } else {
            form.setFieldsValue({
              ...form,
              rent_rate_sq_feet: null,
              rent_licence_fees: null,
            });
            dispatch(
              setTransactionInfo({
                name: "rent_licence_fees",
                value: null,
                obj: "transactionInformation",
              })
            );
            dispatch(
              setTransactionInfo({
                name: "rent_rate_sq_feet",
                value: null,
                obj: "transactionInformation",
              })
            );
          }
        } else if (e === "Sale") {
          if (formTransactionData?.transactionInformation?.compensation) {
            form.setFieldsValue({
              ...form,
              saleValue:
                formTransactionData?.transactionInformation?.compensation,
              rent_licence_fees: 0,
              rent_rate_sq_feet: 0,
            });
            dispatch(
              setTransactionInfo({
                name: "saleValue",
                value:
                  formTransactionData?.transactionInformation?.compensation,
                obj: "transactionInformation",
              })
            );
            dispatch(
              setTransactionInfo({
                name: "rent_licence_fees",
                value: 0,
                obj: "transactionInformation",
              })
            );
            dispatch(
              setTransactionInfo({
                name: "rent_rate_sq_feet",
                value: 0,
                obj: "transactionInformation",
              })
            );
            if (
              formTransactionData?.transactionInformation
                ?.chargeableSquareFeet &&
              Number(
                formTransactionData?.transactionInformation
                  ?.chargeableSquareFeet
              )
            ) {
              form.setFieldsValue({
                ...form,
                sale_rate_sq_feet:
                  formTransactionData?.transactionInformation?.compensation /
                  formTransactionData?.transactionInformation
                    ?.chargeableSquareFeet,
              });
              dispatch(
                setTransactionInfo({
                  name: "sale_rate_sq_feet",
                  value:
                    formTransactionData?.transactionInformation?.compensation /
                    formTransactionData?.transactionInformation
                      ?.chargeableSquareFeet,
                  obj: "transactionInformation",
                })
              );
            } else {
              form.setFieldsValue({
                ...form,
                sale_rate_sq_feet: 0,
              });
              dispatch(
                setTransactionInfo({
                  name: "sale_rate_sq_feet",
                  value: 0,
                  obj: "transactionInformation",
                })
              );
            }
          } else {
            form.setFieldsValue({
              ...form,
              saleValue: null,
              sale_rate_sq_feet: null,
            });
            dispatch(
              setTransactionInfo({
                name: "saleValue",
                value: null,
                obj: "transactionInformation",
              })
            );
            dispatch(
              setTransactionInfo({
                name: "sale_rate_sq_feet",
                value: null,
                obj: "transactionInformation",
              })
            );
          }
        } else {
          form.setFieldsValue({
            ...form,
            saleValue: null,
            sale_rate_sq_feet: null,
            rent_licence_fees: null,
            rent_rate_sq_feet: null,
          });
          dispatch(
            setTransactionInfo({
              name: "saleValue",
              value: null,
              obj: "transactionInformation",
            })
          );
          dispatch(
            setTransactionInfo({
              name: "sale_rate_sq_feet",
              value: null,
              obj: "transactionInformation",
            })
          );
          dispatch(
            setTransactionInfo({
              name: "rent_licence_fees",
              value: null,
              obj: "transactionInformation",
            })
          );
          dispatch(
            setTransactionInfo({
              name: "rent_rate_sq_feet",
              value: null,
              obj: "transactionInformation",
            })
          );
        }
        break;
      case "compensation":
        if (
          formTransactionData?.agreement_information?.documentType === "Rent"
        ) {
          if (
            e &&
            Number(e) &&
            formTransactionData?.transactionInformation?.chargeableSquareFeet &&
            Number(
              formTransactionData?.transactionInformation?.chargeableSquareFeet
            )
          ) {
            form.setFieldsValue({
              ...form,
              rent_rate_sq_feet:
                Number(e) /
                Number(
                  formTransactionData?.transactionInformation
                    ?.chargeableSquareFeet
                ),
              rent_licence_fees: e,
            });
            dispatch(
              setTransactionInfo({
                name: "rent_rate_sq_feet",
                value:
                  Number(e) /
                  Number(
                    formTransactionData?.transactionInformation
                      ?.chargeableSquareFeet
                  ),
                obj: "transactionInformation",
              })
            );
            dispatch(
              setTransactionInfo({
                name: "rent_licence_fees",
                value: e,
                obj: "transactionInformation",
              })
            );
          } else {
            form.setFieldsValue({
              ...form,
              rent_licence_fees: e,
              rent_rate_sq_feet: 0,
            });
            dispatch(
              setTransactionInfo({
                name: "rent_licence_fees",
                value: e,
                obj: "transactionInformation",
              })
            );
            dispatch(
              setTransactionInfo({
                name: "rent_rate_sq_feet",
                value: 0,
                obj: "transactionInformation",
              })
            );
          }
        } else if (
          formTransactionData?.agreement_information?.documentType === "Sale"
        ) {
          if (
            e &&
            Number(e) &&
            formTransactionData?.transactionInformation?.chargeableSquareFeet &&
            Number(
              formTransactionData?.transactionInformation?.chargeableSquareFeet
            )
          ) {
            form.setFieldsValue({
              ...form,
              sale_rate_sq_feet:
                Number(e) /
                Number(
                  formTransactionData?.transactionInformation
                    ?.chargeableSquareFeet
                ),
              saleValue: e,
            });
            dispatch(
              setTransactionInfo({
                name: "sale_rate_sq_feet",
                value:
                  Number(e) /
                  Number(
                    formTransactionData?.transactionInformation
                      ?.chargeableSquareFeet
                  ),
                obj: "transactionInformation",
              })
            );
            dispatch(
              setTransactionInfo({
                name: "saleValue",
                value: e,
                obj: "transactionInformation",
              })
            );
          } else {
            form.setFieldsValue({
              ...form,
              sale_rate_sq_feet: 0,
              saleValue: e,
            });
            dispatch(
              setTransactionInfo({
                name: "saleValue",
                value: e,
                obj: "transactionInformation",
              })
            );
            dispatch(
              setTransactionInfo({
                name: "sale_rate_sq_feet",
                value: 0,
                obj: "transactionInformation",
              })
            );
          }
        }
        break;
      case "chargeableSquareFeet":
        if (
          formTransactionData?.agreement_information?.documentType === "Rent"
        ) {
          if (
            e &&
            Number(e) &&
            formTransactionData?.transactionInformation?.compensation &&
            Number(formTransactionData?.transactionInformation?.compensation)
          ) {
            form.setFieldsValue({
              ...form,
              rent_rate_sq_feet:
                Number(
                  formTransactionData?.transactionInformation?.compensation
                ) / Number(e),
            });
            dispatch(
              setTransactionInfo({
                name: "rent_rate_sq_feet",
                value:
                  Number(
                    formTransactionData?.transactionInformation?.compensation
                  ) / Number(e),
                obj: "transactionInformation",
              })
            );
          } else {
            form.setFieldsValue({
              ...form,
              rent_licence_fees: 0,
              rent_rate_sq_feet: 0,
            });
            dispatch(
              setTransactionInfo({
                name: "rent_licence_fees",
                value: 0,
                obj: "transactionInformation",
              })
            );
            dispatch(
              setTransactionInfo({
                name: "rent_rate_sq_feet",
                value: 0,
                obj: "transactionInformation",
              })
            );
          }
        } else if (
          formTransactionData?.agreement_information?.documentType === "Sale"
        ) {
          if (
            e &&
            Number(e) &&
            formTransactionData?.transactionInformation?.compensation &&
            Number(formTransactionData?.transactionInformation?.compensation)
          ) {
            form.setFieldsValue({
              ...form,
              sale_rate_sq_feet:
                Number(
                  formTransactionData?.transactionInformation?.compensation
                ) / Number(e),
            });
            dispatch(
              setTransactionInfo({
                name: "sale_rate_sq_feet",
                value:
                  Number(
                    formTransactionData?.transactionInformation?.compensation
                  ) / Number(e),
                obj: "transactionInformation",
              })
            );
          } else {
            form.setFieldsValue({
              ...form,
              saleValue: 0,
              sale_rate_sq_feet: 0,
            });
            dispatch(
              setTransactionInfo({
                name: "saleValue",
                value: 0,
                obj: "transactionInformation",
              })
            );
            dispatch(
              setTransactionInfo({
                name: "sale_rate_sq_feet",
                value: 0,
                obj: "transactionInformation",
              })
            );
          }
        }
        if (
          formTransactionData?.transactionInformation?.market_price &&
          Number(formTransactionData?.transactionInformation?.market_price)
        ) {
          form.setFieldsValue({
            ...form,
            declared_circle_rate:
              Number(
                formTransactionData?.transactionInformation?.market_price
              ) / Number(e),
          });
          dispatch(
            setTransactionInfo({
              name: "declared_circle_rate",
              value:
                Number(
                  formTransactionData?.transactionInformation?.market_price
                ) / Number(e),
              obj: "transactionInformation",
            })
          );
        }
        break;
      case "market_price":
        if (
          formTransactionData?.transactionInformation?.chargeableSquareFeet &&
          Number(
            formTransactionData?.transactionInformation?.chargeableSquareFeet
          )
        ) {
          form.setFieldsValue({
            ...form,
            declared_circle_rate:
              Number(e) /
              Number(
                formTransactionData?.transactionInformation
                  ?.chargeableSquareFeet
              ),
          });
          dispatch(
            setTransactionInfo({
              name: "declared_circle_rate",
              value:
                Number(e) /
                Number(
                  formTransactionData?.transactionInformation
                    ?.chargeableSquareFeet
                ),
              obj: "transactionInformation",
            })
          );
        }
        break;
      default:
        break;
    }
  };

  const transactionInfoValidationMessage = () => {
    messageApi.open({
      type: "warning",
      content: "Please check field validation",
    });
  };

  const [activeTab, setActiveTab] = useState("1");


  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {formTransactionData && "buildingInformation" in formTransactionData && (
          <Form
            form={form}
            name="basic"
            onFinish={async () => {
              if (!validateTransactionInfo()) return;
              if (isTransactionInfoEditable === true && id) {
                const data = await dispatch(updateTransactionInfo(getUpdateTransactionPayload(formTransactionData))).unwrap();
                if (data?.status === 201) {
                  message.success("Transaction updated successfully");
                  setTimeout(() => {
                    dispatch(setTransactionId(null));
                    dispatch(setTransactionInitialState());
                    dispatch(setTransactionInfoEditable(false));
                    navigate(`/transactions`);
                  }, 1000);
                } else {
                  message.error(data?.response?.data?.error || "An unexpected error occurred.");
                }
              } else if (isTransactionInfoEditable === true && !id) {
                dispatch(
                  saveTransactionInfo(
                    { formData: getUpdateTransactionPayload(formTransactionData), propertyId: formTransactionData?.buildingInformation?.propertyId }
                  )
                )
                  .unwrap()
                  .then((data) => {
                    if (data?.status === 201) {
                      message.success("Transaction created successfully");
                      setTimeout(() => {
                        dispatch(setTransactionId(null));
                        dispatch(setTransactionInitialState());
                        dispatch(setTransactionInfoEditable(false));
                        navigate("/transactions");
                      }, 1000);
                    } else {
                      message.error(data?.response?.data?.error || "An unexpected error occurred.");
                    }
                  });
              }
            }}
            onFinishFailed={() => {
              transactionInfoValidationMessage();
            }}
            style={{ padding: "10px", flexGrow: 1 }}
          >
            <div
              style={{
                padding: "10px",
                backgroundColor: "#fff",
                flexWrap: "wrap",
                borderRadius: "10px 10px 0 0",
                width: "auto",
                position: "sticky",
                top: "-17px",
                zIndex: 1000,
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", }}>
                <h1 className="text-2xl font-[500] mb-4">
                  <Button
                    onClick={() => {
                      dispatch(setTransactionId(null));
                      dispatch(setTransactionInitialState());
                      dispatch(setTransactionInfoEditable(false));
                      navigate(-1);
                    }}
                    style={{ border: 'none', backgroundColor: 'transparent', boxShadow: 'none' }}
                  >
                    <FaArrowLeft style={{ marginRight: '8px' }} />
                  </Button>
                  Transaction
                </h1>
                <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "10px" }}>
                  <Button disabled={true}>View Index-II</Button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button disabled={true}>View Agreement</Button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {isTransactionInfoEditable === true && id && (
                    <Button htmlType="submit">Update Transaction</Button>
                  )}
                  {isTransactionInfoEditable === true && !id && (
                    <Button htmlType="submit">Save Transaction</Button>
                  )}
                  &nbsp;&nbsp;
                  {isTransactionInfoEditable === false && (
                    <Button onClick={() => dispatch(setTransactionInfoEditable(true))}>
                      Edit Transaction
                    </Button>
                  )}
                  &nbsp;&nbsp;
                </div>
              </div>

              {/* Tab section */}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid #ccc",
                  padding: "10px 0 0",
                  backgroundColor: "white",
                }}
              >
                {/* Tab buttons with checkboxes */}
                <div style={{ display: "flex", flexGrow: 1, justifyContent: "space-between", flexWrap: "wrap" }}>
                  {/* Agreement Information Tab */}
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    boxShadow: "none",
                    borderTopColor: "#fff",
                    borderRightColor: "#fff",
                    borderLeftColor: "#fff",
                    color: activeTab === "1" ? "#fb923c" : "#000",
                    borderBottom: activeTab === "1" ? "2px solid #fb923c" : "2px solid #fff",
                    borderBottomWidth: "3px",
                    borderBottomStyle: "solid"
                  }}>
                    <Checkbox
                      checked={["1", "2", "3", "4", "5", "6"].includes(activeTab)}
                      style={{ color: activeTab !== "1" ? "#ccc" : "#fb923c" }}
                      onChange={() => handleTabChange("1")}
                    />
                    <Button
                      type={activeTab === "1" ? "#fb923c" : "default"}
                      onClick={() => handleTabChange("1")}
                      style={{
                        border: "none",
                      }}
                    >
                      Agreement Information
                    </Button>
                  </div>

                  {/* parties Information Tab */}
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    boxShadow: "none",
                    borderTopColor: "#fff",
                    borderRightColor: "#fff",
                    borderLeftColor: "#fff",
                    color: activeTab === "2" ? "#fb923c" : "#000",
                    borderBottom: activeTab === "2" ? "2px solid #fb923c" : "2px solid #fff",
                    borderBottomWidth: "3px",
                    borderBottomStyle: "solid"
                  }}>
                    <Checkbox
                      checked={["2", "3", "4", "5", "6"].includes(activeTab)}
                      style={{ color: activeTab !== "2" ? "#ccc" : "#fb923c" }}
                      onChange={() => handleTabChange("2")}
                    />
                    <Button
                      type={activeTab === "2" ? "#fb923c" : "default"}
                      onClick={() => handleTabChange("2")}
                      style={{
                        border: "none",
                      }}
                    >
                      Parties Information
                    </Button>
                  </div>
                  {/* Transaction Information Tab */}
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    boxShadow: "none",
                    borderTopColor: "#fff",
                    borderRightColor: "#fff",
                    borderLeftColor: "#fff",
                    color: activeTab === "3" ? "#fb923c" : "#000",
                    borderBottom: activeTab === "3" ? "2px solid #fb923c" : "2px solid #fff",
                    borderBottomWidth: "3px",
                    borderBottomStyle: "solid"
                  }}>
                    <Checkbox
                      checked={["3", "4", "5", "6"].includes(activeTab)}
                      style={{ color: activeTab !== "3" ? "#ccc" : "#fb923c" }}
                      onChange={() => handleTabChange("3")}
                    />
                    <Button
                      type={activeTab === "3" ? "#fb923c" : "default"}
                      onClick={() => handleTabChange("3")}
                      style={{
                        border: "none",
                      }}
                    >
                      Transaction Information
                    </Button>
                  </div>

                  {/* Property Information Tab */}
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    boxShadow: "none",
                    borderTopColor: "#fff",
                    borderRightColor: "#fff",
                    borderLeftColor: "#fff",
                    color: activeTab === "4" ? "#fb923c" : "#000",
                    borderBottom: activeTab === "4" ? "2px solid #fb923c" : "2px solid #fff",
                    borderBottomWidth: "3px",
                    borderBottomStyle: "solid"
                  }}>
                    <Checkbox
                      checked={["4", "5", "6"].includes(activeTab)}
                      style={{ color: activeTab !== "4" ? "#ccc" : "#fb923c" }}
                      onChange={() => handleTabChange("4")}
                    />
                    <Button
                      type={activeTab === "4" ? "#fb923c" : "default"}
                      onClick={() => handleTabChange("4")}
                      style={{
                        border: "none",
                      }}
                    >
                      Property Information
                    </Button>
                  </div>

                  {/* Other Information Tab */}
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    boxShadow: "none",
                    borderTopColor: "#fff",
                    borderRightColor: "#fff",
                    borderLeftColor: "#fff",
                    color: activeTab === "5" ? "#fb923c" : "#000",
                    borderBottom: activeTab === "5" ? "2px solid #fb923c" : "2px solid #fff",
                    borderBottomWidth: "3px",
                    borderBottomStyle: "solid"
                  }}>
                    <Checkbox
                      checked={["5", "6"].includes(activeTab)}
                      style={{ color: activeTab !== "5" ? "#ccc" : "#fb923c" }}
                      onChange={() => handleTabChange("5")}
                    />
                    <Button
                      type={activeTab === "5" ? "#fb923c" : "default"}
                      onClick={() => handleTabChange("5")}
                      style={{
                        border: "none",
                      }}
                    >
                      Other Information
                    </Button>
                  </div>

                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    boxShadow: "none",
                    borderTopColor: "#fff",
                    borderRightColor: "#fff",
                    borderLeftColor: "#fff",
                    color: activeTab === "6" ? "#fb923c" : "#000",
                    borderBottom: activeTab === "6" ? "2px solid #fb923c" : "2px solid #fff",
                    borderBottomWidth: "3px",
                    borderBottomStyle: "solid"
                  }}>
                    <Checkbox
                      checked={activeTab === "6"}
                      style={{ color: activeTab !== "6" ? "#ccc" : "#fb923c" }}
                      onChange={() => handleTabChange("6")}
                    />
                    <Button
                      type={activeTab === "6" ? "#fb923c" : "default"}
                      onClick={() => handleTabChange("6")}
                      style={{
                        border: "none",
                      }}
                    >
                      Add Buildings Images
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            {/* Tab content */}
            <div style={{
              width: "100%",
              padding: "20px ",
              borderRadius: "0 0 10px 10px",
              backgroundColor: "white",
            }}
            >
              {activeTab === "1" && (
                <>
                  <Title level={4}>Agreement Information</Title>
                  <Row gutter={[16, 16]} style={{ justifyContent: "space-between" }}>
                    <Col xs={24} sm={12} md={8} lg={6}>
                      {agreementInfoFormElementsCol1.map((element, index) => (
                        <FormElement
                          key={index}
                          {...element}
                          onChange={(e, dateVal) => {
                            handleChangeTransactionsInfo(element, e, dateVal, "agreement_information");
                          }}
                        />
                      ))}
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6}>
                      {agreementInfoFormElementsCol2.map((element, index) => (
                        <FormElement
                          key={index}
                          {...element}
                          onChange={(e, dateVal) => {
                            handleChangeTransactionsInfo(element, e, dateVal, "agreement_information");
                          }}
                        />
                      ))}
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6}>
                      {agreementInfoFormElementsCol3.map((element, index) => (
                        <FormElement
                          key={index}
                          {...element}
                          onChange={(e, dateVal) => {
                            handleChangeTransactionsInfo(element, e, dateVal, "agreement_information");
                          }}
                        />
                      ))}
                    </Col>
                  </Row>
                </>
              )}

              {activeTab === "2" && (
                <>
                  <Title level={4}>Parties Information</Title>
                  <Row gutter={[16, 16]} style={{ justifyContent: "center" }}> {/* Centered content in the row */}
                    <Col xs={24} sm={12} md={8} lg={6} style={{ textAlign: "center" }}>
                      Buyer
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} style={{ textAlign: "center" }}>
                      Seller
                    </Col>
                  </Row>
                  <Divider
                    style={{
                      backgroundColor: "black",
                      marginTop: "0",
                      marginBottom: "5px",
                      position: "relative",
                    }}
                  />
                  <Row span={12}>
                    <Col span={11}>
                      <FormElement
                        type="textArea"
                        label="All Buyer Name"
                        name="allBuyerName"
                        placeholder="All Buyer Name"
                        width={"92%"}
                        value={
                          formTransactionData?.buyerInfo?.buyers?.length > 0
                            ? formTransactionData?.buyerInfo?.buyers[0]?.buyerType === 'Individual'
                              ? formTransactionData?.buyerInfo?.buyers[0]?.name
                              : formTransactionData?.buyerInfo?.buyers[0]?.buyerType === 'Company'
                                ? formTransactionData?.buyerInfo?.buyers[0]?.representativeName
                                : null
                            : null
                        }
                        disabled={id ? true : false}
                        onChange={(e) => {
                          if (!id) {
                            dispatch(
                              setLegacyPartiesInfo({
                                name: "buyers",
                                value: e.target.value,
                                obj: "legacy_propertiesInformation",
                              })
                            );
                          }
                        }}
                      />
                      {savedBuyersList?.length > 0 && (
                        <Collapse
                          accordion
                          items={savedBuyersList}
                          defaultActiveKey={[showBuyerPartiesInfoKey]}
                          onChange={(key) => {
                            handleShowPartiesInfo(key?.[0], "buyerInfo");
                          }}
                          style={{ marginBottom: "20px", width: "92%" }}
                        />
                      )}
                      {showPartiesInfoBuyerRadioGroup && (
                        <Radio.Group
                          onChange={(e) => {
                            setPartiesSaveBuyerInfo({
                              ...partiesSaveBuyerInfo,
                              buyerType: e.target.value,
                            });
                            setBuyerType(e.target.value);
                            setShowPartiesInfoBuyerForm(true);
                          }}
                          disabled={!isTransactionInfoEditable}
                          value={buyerType}
                          style={{
                            position: "absolute",
                            display: "inline-flex",
                            justifyContent: "center",
                          }}
                        >
                          <Radio key={"Company"} value="Company">
                            Company
                          </Radio>
                          <Radio key={"Individual"} value="Individual">
                            Individual
                          </Radio>
                        </Radio.Group>
                      )}
                      <Button
                        style={{ float: "right" }}
                        disabled={
                          !isTransactionInfoEditable || showPartiesInfoBuyerForm
                        }
                        onClick={() => {
                          if (showPartiesInfoBuyerForm) return;
                          setBuyerType(null);
                          handleAddPartiesInfo(
                            "buyerInfo",
                            formTransactionData?.buyerInfo?.length
                          );
                          setPartiesSaveBuyerInfo({});
                          form.setFieldsValue({
                            ...form,
                            companyName: null,
                            representativeName: null,
                            representativeEmail: null,
                            representativeAadhar: null,
                            representativeContact: null,
                            representativePan: null,
                            representativeRole: null,
                            name: null,
                            pan: null,
                            aadhar: null,
                            email: null,
                            contact: null,
                          });
                        }}
                      >
                        Create
                      </Button>
                      {showPartiesInfoBuyerForm && (
                        <div
                          style={{
                            maxHeight: "1000px",
                            marginTop: "70px",
                          }}
                        >
                          {buyerType === "Company" && (
                            <Col span={10}>
                              <FormElement
                                type="dropdown"
                                label="Company Name"
                                required={true}
                                name="companyName"
                                placeholder="Company Name"
                                showSearch={true}
                                children={getCompanyListingEle()}
                                onSearch={handleSearchDropdownOptions}
                                createOptionLabel="Create Company"
                                createRecord={() => {
                                  dispatch(setNavigatedFrom("Transaction"));
                                  dispatch(setCompanyId(null));
                                  navigate("/companyDetails");
                                }}
                                value={partiesSaveBuyerInfo?.companyName}
                                onChange={(e, obj) => {
                                  setPartiesSaveBuyerInfo({
                                    ...partiesSaveBuyerInfo,
                                    companyName: e,
                                    companyId: obj?.key,
                                  });
                                }}
                              />
                              <Title level={5}>Representative Person</Title>
                              <FormElement
                                type="input"
                                label="Name"
                                name="representativeName"
                                placeholder="Name"
                                value={partiesSaveBuyerInfo?.representativeName}
                                onChange={(e) =>
                                  setPartiesSaveBuyerInfo({
                                    ...partiesSaveBuyerInfo,
                                    representativeName: e.target.value,
                                  })
                                }
                              />
                              <FormElement
                                type="input"
                                label="Role"
                                name="representativeRole"
                                placeholder="Role"
                                value={partiesSaveBuyerInfo?.representativeRole}
                                onChange={(e) =>
                                  setPartiesSaveBuyerInfo({
                                    ...partiesSaveBuyerInfo,
                                    representativeRole: e.target.value,
                                  })
                                }
                              />
                              <FormElement
                                type="input"
                                label="Aadhar"
                                name="representativeAadhar"
                                placeholder="Aadhar"
                                value={partiesSaveBuyerInfo?.representativeAadhar}
                                onChange={(e) =>
                                  setPartiesSaveBuyerInfo({
                                    ...partiesSaveBuyerInfo,
                                    representativeAadhar: e.target.value,
                                  })
                                }
                              />
                              <FormElement
                                type="input"
                                label="PAN"
                                name="representativePan"
                                placeholder="PAN"
                                value={partiesSaveBuyerInfo?.representativePan}
                                onChange={(e) =>
                                  setPartiesSaveBuyerInfo({
                                    ...partiesSaveBuyerInfo,
                                    representativePan: e.target.value,
                                  })
                                }
                              />
                              <FormElement
                                type="input"
                                label="E-mail"
                                name="representativeEmail"
                                placeholder="E-mail"
                                value={partiesSaveBuyerInfo?.representativeEmail}
                                onChange={(e) =>
                                  setPartiesSaveBuyerInfo({
                                    ...partiesSaveBuyerInfo,
                                    representativeEmail: e.target.value,
                                  })
                                }
                              />
                              <FormElement
                                type="input"
                                label="Contact"
                                name="representativeContact"
                                placeholder="Contact"
                                value={partiesSaveBuyerInfo?.representativeContact}
                                onChange={(e) =>
                                  setPartiesSaveBuyerInfo({
                                    ...partiesSaveBuyerInfo,
                                    representativeContact: e.target.value,
                                  })
                                }
                              />
                            </Col>
                          )}
                          {buyerType === "Individual" && (
                            <Col span={10}>
                              <FormElement
                                type="input"
                                label="Name"
                                required={true}
                                name="name"
                                placeholder="Name"
                                value={partiesSaveBuyerInfo?.name}
                                onChange={(e) =>
                                  setPartiesSaveBuyerInfo({
                                    ...partiesSaveBuyerInfo,
                                    name: e.target.value,
                                  })
                                }
                              />
                              <FormElement
                                type="input"
                                label="PAN"
                                name="pan"
                                placeholder="PAN"
                                value={partiesSaveBuyerInfo?.pan}
                                onChange={(e) =>
                                  setPartiesSaveBuyerInfo({
                                    ...partiesSaveBuyerInfo,
                                    pan: e.target.value,
                                  })
                                }
                              />
                              <FormElement
                                type="input"
                                label="Aadhar"
                                name="aadhar"
                                placeholder="Aadhar"
                                value={partiesSaveBuyerInfo?.aadhar}
                                onChange={(e) =>
                                  setPartiesSaveBuyerInfo({
                                    ...partiesSaveBuyerInfo,
                                    aadhar: e.target.value,
                                  })
                                }
                              />
                              <FormElement
                                type="input"
                                label="E-mail"
                                name="email"
                                placeholder="E-mail"
                                value={partiesSaveBuyerInfo?.email}
                                onChange={(e) =>
                                  setPartiesSaveBuyerInfo({
                                    ...partiesSaveBuyerInfo,
                                    email: e.target.value,
                                  })
                                }
                              />
                              <FormElement
                                type="input"
                                label="Contact"
                                name="contact"
                                placeholder="Contact"
                                value={partiesSaveBuyerInfo?.contact}
                                onChange={(e) =>
                                  setPartiesSaveBuyerInfo({
                                    ...partiesSaveBuyerInfo,
                                    contact: e.target.value,
                                  })
                                }
                              />
                            </Col>
                          )}
                          <div style={{ float: "right" }}>
                            <Button
                              onClick={() => {
                                handleSaveBuyerPartiesInfo();
                              }}
                            >
                              Save
                            </Button>
                            <Button
                              style={{ marginLeft: "10px" }}
                              onClick={() => {
                                setShowPartiesInfoBuyerRadioGroup(false);
                                setShowPartiesInfoBuyerForm(false);
                                setPartiesSaveBuyerInfo({});
                                form.setFieldsValue({
                                  ...form,
                                  companyName: null,
                                  representativeName: null,
                                  representativeEmail: null,
                                  representativeAadhar: null,
                                  representativeContact: null,
                                  representativePan: null,
                                  representativeRole: null,
                                  name: null,
                                  pan: null,
                                  aadhar: null,
                                  email: null,
                                  contact: null,
                                });
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      )}
                    </Col>
                    <Col>
                      <Divider
                        type="vertical"
                        style={{
                          backgroundColor: "black",
                          marginTop: "0",
                          height: "100%",
                        }}
                      />
                    </Col>
                    <Col span={11} style={{ marginLeft: '16px' }}>
                      <FormElement
                        type="textArea"
                        label="All Seller Name"
                        name="allSellerName"
                        width={"92%"}
                        placeholder="All Seller Name"
                        value={
                          formTransactionData?.sellerInfo?.sellers?.length > 0
                            ? formTransactionData?.sellerInfo?.sellers[0]?.sellerType === 'Individual'
                              ? formTransactionData?.sellerInfo?.sellers[0]?.name
                              : formTransactionData?.sellerInfo?.sellers[0]?.sellerType === 'Company'
                                ? formTransactionData?.sellerInfo?.sellers[0]?.representativeName
                                : null
                            : null

                        }
                        disabled={id ? true : false}
                        onChange={(e) => {
                          if (!id) {
                            dispatch(
                              setLegacyPartiesInfo({
                                name: "sellers",
                                value: e.target.value,
                                obj: "legacy_propertiesInformation",
                              })
                            );
                          }
                        }}
                      />
                      {savedSellersList?.length > 0 && (
                        <Collapse
                          accordion
                          items={savedSellersList}
                          defaultActiveKey={[showSellerPartiesInfoKey]}
                          onChange={(key) => {
                            handleShowPartiesInfo(key?.[0], "sellerInfo");
                          }}
                          style={{ marginBottom: "20px", width: "92%" }}
                        />
                      )}
                      {showPartiesInfoSellerRadioGroup && (
                        <Radio.Group
                          onChange={(e) => {
                            setPartiesSaveSellerInfo({
                              ...partiesSaveSellerInfo,
                              sellerType: e.target.value,
                            });
                            setSellerType(e.target.value);
                            setShowPartiesInfoSellerForm(true);
                          }}
                          disabled={!isTransactionInfoEditable}
                          value={sellerType}
                          style={{
                            position: "absolute",
                            display: "inline-flex",
                            justifyContent: "center",
                          }}
                        >
                          <Radio key={"Company"} value="Company">
                            Company
                          </Radio>
                          <Radio key={"Individual"} value="Individual">
                            Individual
                          </Radio>
                        </Radio.Group>
                      )}
                      <Button
                        style={{ float: "right" }}
                        disabled={
                          !isTransactionInfoEditable || showPartiesInfoSellerForm
                        }
                        onClick={() => {
                          if (showPartiesInfoSellerForm) return;
                          setSellerType(null);
                          handleAddPartiesInfo(
                            "sellerInfo",
                            formTransactionData?.sellerInfo?.length
                          );
                          setPartiesSaveSellerInfo({});
                          form.setFieldsValue({
                            ...form,
                            companyName: null,
                            representativeName: null,
                            representativeEmail: null,
                            representativeAadhar: null,
                            representativeContact: null,
                            representativePan: null,
                            representativeRole: null,
                            name: null,
                            pan: null,
                            aadhar: null,
                            email: null,
                            contact: null,
                          });
                        }}
                      >
                        Create
                      </Button>
                      {showPartiesInfoSellerForm && (
                        <div
                          style={{
                            maxHeight: "1000px",
                            marginTop: "70px",
                          }}
                        >
                          {sellerType === "Company" && (
                            <Col span={10}>
                              <FormElement
                                type="dropdown"
                                label="Company Name"
                                required={true}
                                name="companyName"
                                placeholder="Company Name"
                                showSearch={true}
                                children={getCompanyListingEle()}
                                onSearch={handleSearchDropdownOptions}
                                createOptionLabel="Create Company"
                                createRecord={() => {
                                  dispatch(setNavigatedFrom("Transaction"));
                                  dispatch(setCompanyId(null));
                                  navigate("/companyDetails");
                                }}
                                value={partiesSaveSellerInfo?.companyName}
                                onChange={(e, obj) =>
                                  setPartiesSaveSellerInfo({
                                    ...partiesSaveSellerInfo,
                                    companyName: e,
                                    companyId: obj?.key,
                                  })
                                }
                              />
                              <Title level={5}>Representative Person</Title>
                              <FormElement
                                type="input"
                                label="Name"
                                name="representativeName"
                                placeholder="Name"
                                value={partiesSaveSellerInfo?.representativeName}
                                onChange={(e) =>
                                  setPartiesSaveSellerInfo({
                                    ...partiesSaveSellerInfo,
                                    representativeName: e.target.value,
                                  })
                                }
                              />
                              <FormElement
                                type="input"
                                label="Role"
                                name="representativeRole"
                                placeholder="Role"
                                value={partiesSaveSellerInfo?.representativeRole}
                                onChange={(e) =>
                                  setPartiesSaveSellerInfo({
                                    ...partiesSaveSellerInfo,
                                    representativeRole: e.target.value,
                                  })
                                }
                              />
                              <FormElement
                                type="input"
                                label="Aadhar"
                                name="representativeAadhar"
                                placeholder="Aadhar"
                                value={partiesSaveSellerInfo?.representativeAadhar}
                                onChange={(e) =>
                                  setPartiesSaveSellerInfo({
                                    ...partiesSaveSellerInfo,
                                    representativeAadhar: e.target.value,
                                  })
                                }
                              />
                              <FormElement
                                type="input"
                                label="PAN"
                                name="representativePan"
                                placeholder="PAN"
                                value={partiesSaveSellerInfo?.representativePan}
                                onChange={(e) =>
                                  setPartiesSaveSellerInfo({
                                    ...partiesSaveSellerInfo,
                                    representativePan: e.target.value,
                                  })
                                }
                              />
                              <FormElement
                                type="input"
                                label="E-mail"
                                name="representativeEmail"
                                placeholder="E-mail"
                                value={partiesSaveSellerInfo?.representativeEmail}
                                onChange={(e) =>
                                  setPartiesSaveSellerInfo({
                                    ...partiesSaveSellerInfo,
                                    representativeEmail: e.target.value,
                                  })
                                }
                              />
                              <FormElement
                                type="input"
                                label="Contact"
                                name="representativeContact"
                                placeholder="Contact"
                                value={partiesSaveSellerInfo?.representativeContact}
                                onChange={(e) =>
                                  setPartiesSaveSellerInfo({
                                    ...partiesSaveSellerInfo,
                                    representativeContact: e.target.value,
                                  })
                                }
                              />
                            </Col>
                          )}
                          {sellerType === "Individual" && (
                            <Col span={10}>
                              <FormElement
                                type="input"
                                label="Name"
                                required={true}
                                name="name"
                                placeholder="Name"
                                value={partiesSaveSellerInfo?.name}
                                onChange={(e) =>
                                  setPartiesSaveSellerInfo({
                                    ...partiesSaveSellerInfo,
                                    name: e.target.value,
                                  })
                                }
                              />
                              <FormElement
                                type="input"
                                label="PAN"
                                name="pan"
                                placeholder="PAN"
                                value={partiesSaveSellerInfo?.pan}
                                onChange={(e) =>
                                  setPartiesSaveSellerInfo({
                                    ...partiesSaveSellerInfo,
                                    pan: e.target.value,
                                  })
                                }
                              />
                              <FormElement
                                type="input"
                                label="Aadhar"
                                name="aadhar"
                                placeholder="Aadhar"
                                value={partiesSaveSellerInfo?.aadhar}
                                onChange={(e) =>
                                  setPartiesSaveSellerInfo({
                                    ...partiesSaveSellerInfo,
                                    aadhar: e.target.value,
                                  })
                                }
                              />
                              <FormElement
                                type="input"
                                label="E-mail"
                                name="email"
                                placeholder="E-mail"
                                value={partiesSaveSellerInfo?.email}
                                onChange={(e) =>
                                  setPartiesSaveSellerInfo({
                                    ...partiesSaveSellerInfo,
                                    email: e.target.value,
                                  })
                                }
                              />
                              <FormElement
                                type="input"
                                label="Contact"
                                name="contact"
                                placeholder="Contact"
                                value={partiesSaveSellerInfo?.contact}
                                onChange={(e) =>
                                  setPartiesSaveSellerInfo({
                                    ...partiesSaveSellerInfo,
                                    contact: e.target.value,
                                  })
                                }
                              />
                            </Col>
                          )}
                          <div style={{ float: "right" }}>
                            <Button
                              onClick={() => {
                                handleSaveSellerPartiesInfo();
                              }}
                            >
                              Save
                            </Button>
                            <Button
                              style={{ marginLeft: "10px" }}
                              onClick={() => {
                                setShowPartiesInfoSellerRadioGroup(false);
                                setShowPartiesInfoSellerForm(false);
                                setPartiesSaveSellerInfo({});
                                form.setFieldsValue({
                                  ...form,
                                  companyName: null,
                                  representativeName: null,
                                  representativeEmail: null,
                                  representativeAadhar: null,
                                  representativeContact: null,
                                  representativePan: null,
                                  representativeRole: null,
                                  name: null,
                                  pan: null,
                                  aadhar: null,
                                  email: null,
                                  contact: null,
                                });
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                </>
              )}


              {activeTab === "3" && (
                <>
                  <Title level={4}>Transaction Information</Title>
                  <Row
                    gutter={[16, 16]} // Adds spacing between rows and columns
                    style={{ justifyContent: "space-between", marginRight: "0px" }} // Remove fixed margin for better responsiveness
                  >
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}> {/* Responsive column sizes */}
                      {transInfoFormElementsCol1.map((element, index) => (
                        <FormElement
                          key={index}
                          {...element}
                          onChange={(e, dateVal) => {
                            handleChangeTransactionsInfo(element, e, dateVal, "transactionInformation");
                          }}
                        />
                      ))}
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                      {transInfoFormElementsCol2.map((element, index) => (
                        <FormElement
                          key={index}
                          {...element}
                          onChange={(e, dateVal) => {
                            handleChangeTransactionsInfo(element, e, dateVal, "transactionInformation");
                          }}
                        />
                      ))}
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                      {transInfoFormElementsCol3.map((element, index) => (
                        <FormElement
                          key={index}
                          {...element}
                          onChange={(e, dateVal) => {
                            handleChangeTransactionsInfo(element, e, dateVal, "transactionInformation");
                          }}
                        />
                      ))}
                    </Col>
                  </Row>
                </>
              )}

              {activeTab === "4" && (
                <>
                  <Title level={4}>Property Information</Title>
                  <Row
                    gutter={[16, 16]} // Adds spacing between rows and columns
                    style={{ justifyContent: "space-between", marginRight: "0px" }} // Remove fixed margin for better responsiveness
                  >
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}> {/* Responsive column sizes */}
                      {buildingInfoFormElementsCol1.map((element, index) => (
                        <FormElement key={index} {...element} />
                      ))}
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                      {buildingInfoFormElementsCol2.map((element, index) => (
                        <FormElement key={index} {...element} />
                      ))}
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                      {buildingInfoFormElementsCol3.map((element, index) => (
                        <FormElement key={index} {...element} />
                      ))}
                    </Col>
                  </Row>
                </>
              )}

              {activeTab === "5" && (
                <>
                  <Title level={4}>Other Information</Title>
                  <Row
                    gutter={[16, 16]} // Adds spacing between rows and columns
                    style={{ justifyContent: "space-between", marginRight: "0px" }} // Remove fixed margin for better responsiveness
                  >
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}> {/* Responsive column sizes */}
                      {otherInfoFormElementsCol1.map((element, index) => (
                        <FormElement
                          key={index}
                          {...element}
                          onChange={(e, dateVal) => {
                            handleChangeTransactionsInfo(element, e, dateVal, "others");
                          }}
                        />
                      ))}
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                      {otherInfoFormElementsCol2.map((element, index) => (
                        <FormElement
                          key={index}
                          {...element}
                          onChange={(e, dateVal) => {
                            handleChangeTransactionsInfo(element, e, dateVal, "others");
                          }}
                        />
                      ))}
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                      {otherInfoFormElementsCol3.map((element, index) => (
                        <FormElement
                          key={index}
                          {...element}
                          onChange={(e, dateVal) => {
                            handleChangeTransactionsInfo(element, e, dateVal, "others");
                          }}
                        />
                      ))}
                    </Col>
                  </Row>
                </>
              )}

              {activeTab === "6" && (
                <>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Title level={4}>Add Buildings Images</Title>
                  </div>
                  <Title level={5}>Note: Extensions allowed - jpg, png, jpeg</Title>
                  <Divider style={{ backgroundColor: "black", marginTop: "0" }} />
                  <Row gutter={[16, 16]} style={{ marginRight: "0" }}> {/* Use gutter for spacing */}
                    {isTransactionInfoEditable && (
                      <Col xs={24} sm={12} md={8} lg={6}>
                        <Upload
                          {...props}
                          fileList={
                            isTransactionInfoEditable
                              ? transactionData?.propertyImages
                              : fileList
                          }
                          disabled={!isTransactionInfoEditable}
                          preview={false}
                          maxCount={14}
                          showUploadList={false}
                          multiple={false}
                        >
                          <Button
                            icon={<UploadOutlined />}
                            loading={uploading}
                            disabled={!isTransactionInfoEditable}
                            style={{ width: "100%" }} // Make button full width
                          >
                            Select File
                          </Button>
                        </Upload>
                      </Col>
                    )}
                    <Col xs={24} sm={12} md={16} lg={18}>
                      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                        {transactionData?.propertyImages?.length > 0 ? (
                          transactionData?.propertyImages.map((file, index) => (
                            <div
                              key={index}
                              style={{
                                position: "relative",
                                margin: "0px 10px 10px 10px", // Adjusted margin for better spacing
                                flex: "1 0 30%", // Allow flexible growth for images
                              }}
                            >
                              {isTransactionInfoEditable && (
                                <Checkbox
                                  style={{
                                    position: "absolute",
                                    top: 3,
                                    left: 5,
                                    zIndex: 1,
                                  }}
                                  onChange={(e) => handleImageClick(file)}
                                  checked={selectedImage === file}
                                  disabled={!isTransactionInfoEditable}
                                />
                              )}
                              {isTransactionInfoEditable && (
                                <CloseOutlined
                                  style={{
                                    color: "black",
                                    position: "absolute",
                                    top: 3,
                                    right: 5,
                                    zIndex: 1,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleDeleteImage(file)}
                                />
                              )}
                              {(file.originFileObj || file.url) && (
                                <Image
                                  width={"100%"} // Responsive width
                                  height={150}
                                  preview={false}
                                  src={
                                    file.url ||
                                    URL.createObjectURL(file.originFileObj)
                                  }
                                  alt={`Uploaded image ${index + 1}`}
                                  style={
                                    file?.isProfile
                                      ? {
                                        border: "4px solid orange",
                                        padding: "2px",
                                        borderRadius: "8px",
                                        maxWidth: "100%",
                                      }
                                      : { borderRadius: "8px", maxWidth: "100%" }
                                  }
                                />
                              )}
                            </div>
                          ))
                        ) : (
                          <>
                            {!isTransactionInfoEditable && <h2>No Images to display</h2>}
                          </>
                        )}
                      </div>

                      {isTransactionInfoEditable && (
                        <div
                          style={{
                            marginLeft: 20,
                            flex: "1",
                            margin: "0px 10px 0px 10px",
                          }}
                        >
                          {selectedImage ? (
                            <Row>
                              {(selectedImage.url ||
                                selectedImage.originFileObj) && (
                                  <Image
                                    width={"100%"} // Responsive width for preview
                                    height={280}
                                    src={
                                      selectedImage.url ||
                                      (selectedImage.originFileObj &&
                                        URL.createObjectURL(
                                          selectedImage.originFileObj
                                        ))
                                    }
                                    alt="Selected Image Preview"
                                    style={{ borderRadius: "8px" }}
                                  />
                                )}
                            </Row>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "20px",
                                border: "1px dashed #d9d9d9",
                                borderRadius: "8px",
                                width: "100%",
                                maxWidth: "320px", // Ensure a maximum width
                                height: "280px",
                                margin: "auto", // Center the placeholder
                              }}
                            >
                              Your image preview will display here
                            </div>
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </Form>
        )}
      </div>
    </>
  );
}

export default TransactionDetailsCard;
