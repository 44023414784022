import React, { useState, useEffect } from "react";
import Title from "antd/es/typography/Title";
import { Form, Button, Divider, Input, message, Select } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCitiesByStateId } from "../../../feature/slice/CitySlice";
import {
  addMicroMarketData,
  fetchMicroMarketById,
  updateMicroMarketData,
} from "../../../feature/slice/MicroMarketSlice";

const MicroMarketDetailsCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [isEditable, setIsEditable] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const { microMarketId } = location.state || {};

  const { statesForDD } = useSelector((state) => state.masterDetails);
  const { citiesByStateId } = useSelector((state) => state.cityDetails);
  const { microMarketById } = useSelector((state) => state.microMarketDetails);

  useEffect(() => {
    if (microMarketId) {
      dispatch(fetchMicroMarketById(microMarketId));
    }
  }, [microMarketId, dispatch]);

  useEffect(() => {
    if (microMarketId && microMarketById) {
      dispatch (fetchAllCitiesByStateId(microMarketById.stateId));
      setSelectedState(microMarketById.stateId);
      setSelectedCity(microMarketById.cityId);
      setIsEditable(true);
  
      form.setFieldsValue({
        microMarketName: microMarketById.microMarket,
        stateId: microMarketById.stateId,
        cityId: microMarketById.cityId,
      });
    }
  }, [microMarketId, microMarketById, form, dispatch]);


  const handleAdd = (values) => {
    dispatch(addMicroMarketData(values))
      .then(() => {
        message.success("Micro Market added successfully");
        navigate("/masterlist");
      })
      .catch((err) => {
        message.error(
          "Failed to add Micro Market data, Micro Market already exists!"
        );
        console.error("Failed to add Micro Market data:", err);
      });
  };

  const handleUpdate = (microMarketId, values) => {
    const {
      microMarketName: newMicroMarketName,
      cityId: newCityId,
      stateId: newStateId,
    } = values;

    const originalMicroMarketName = microMarketById.microMarket || null;
    const originalCityId = microMarketById.cityId || null;
    const originalStateId = microMarketById.stateId || null;

    if (
      newMicroMarketName === originalMicroMarketName &&
      newCityId === originalCityId &&
      newStateId === originalStateId
    ) {
      message.warning(
        "No changes detected in Micro Market name, city, or state."
      );
      return;
    }

    dispatch(updateMicroMarketData({ microMarketId, microMarketData: values }))
      .then(() => {
        message.success("Micro Market updated successfully");
        navigate("/masterlist");
      })
      .catch((err) => {
        message.error("Failed to update Micro Market data!");
        console.error("Failed to update Micro Market data:", err);
      });
  };

  const handleSubmit = (values) => {
    if (microMarketId) {
      handleUpdate(microMarketId, values); // Call the update function
    } else {
      handleAdd(values); // Call the add function
    }
  };

  const handleStateChange = (value) => {
    setSelectedState(value);
    setIsEditable(true);
    setSelectedCity(null);
    dispatch(fetchAllCitiesByStateId(value));
    form.setFieldsValue({
      cityId: null,
      // microMarketName: null,
    });
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
  };

  return (
    <>
      <div
        style={{
          alignItems: "center",
          padding: "10px",
          marginBottom: "1rem",
          width: "100%",
          backgroundColor: "white",
          boxShadow: "0 8px 8px -8px rgba(0, 0, 0, 0.5)",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1rem",
            padding: "10px",
            marginBottom: "20px",
            width: "100%",
            position: "sticky",
            top: 0,
            zIndex: 1000,
            backgroundColor: "white",
            boxShadow: "0 8px 8px -8px rgba(0, 0, 0, 0.5)",
          }}
        >
          <Title level={4}>
            {microMarketId
              ? "Update Micro Market Information"
              : "Add Micro Market Information"}
          </Title>
          <Button onClick={() => navigate("/masterlist")}>Back</Button>
        </div>
        <Divider style={{ backgroundColor: "black", marginTop: "0" }} />
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          style={{ maxWidth: "400px", margin: "0 auto" }}
        >
          <Form.Item
            label="State Name"
            name="stateId"
            rules={[{ required: true, message: "Please select a state!" }]}
          >
            <Select
              placeholder="Select a State"
              onChange={handleStateChange}
              value={selectedState}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {statesForDD?.length > 0 ? (
                statesForDD?.map((state) => (
                  <Select.Option key={state._id} value={state._id}>
                    {state.state}
                  </Select.Option>
                ))
              ) : (
                <Select.Option disabled></Select.Option>
              )}
            </Select>
          </Form.Item>

          <Form.Item
            label="City Name"
            name="cityId"
            rules={[{ required: true, message: "Please select a city!" }]}
          >
            <Select
              placeholder="Select a City"
              onChange={handleCityChange}
              value={selectedCity}
              disabled={!selectedState}
              showSearch // Enable search
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {citiesByStateId?.length > 0 ? (
                citiesByStateId
                  .filter((city) => city.stateId._id === selectedState) // Filter cities based on selected state
                  ?.map((city) => (
                    <Select.Option key={city._id} value={city._id}>
                      {city.city}
                    </Select.Option>
                  ))
              ) : (
                <Select.Option disabled></Select.Option>
              )}
            </Select>
          </Form.Item>

          <Form.Item
            label="Micro Market Name"
            name="microMarketName"
            rules={[
              {
                required: true,
                message: "Please enter the Micro Market name!",
              },
            ]}
          >
            <Input
              placeholder="Enter Micro Market Name"
              disabled={!isEditable || !selectedCity}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              style={{ marginTop: "1rem" }}
            >
              {microMarketId ? "Update" : "Submit"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default MicroMarketDetailsCard;
