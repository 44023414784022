import { buildCreateSlice, asyncThunkCreator } from "@reduxjs/toolkit";
import { get } from "lodash";

import {
    getAllPincodesWithPagination as _getAllPincodesWithPagination,
  addPincodeData as _addPincodeData,
  updatePincodeData as _updatePincodeData,
  deletePincodeById as _deletePincodeById,
  getPincodeById as _getPincodeById,
  getAllPincodesByMicroMarketId as _getAllPincodesByMicroMarketId
} from "../services/PincodeAPI";

const createSliceWithThunks = buildCreateSlice({
  creators: {
    asyncThunk: asyncThunkCreator,
  },
});

const initialState = {
  loading: false,
  pincodeMaster: [],
  pincodeDataById: [],
  pincodesByMicroMarketId:[],
  pincodeId: null,
  totalPincodeCount:0,
  currentPage: 1,
  pageSize: 10
};

const PincodeSlice = createSliceWithThunks({
  name: "pincode",
  initialState,
  reducers: (create) => ({

    setCurrentPage: (state, action) => {
        state.currentPage = action?.payload;
      },
  
      setPageSize: (state, action) => {
        state.pageSize = action?.payload;
      },

    fetchAllPincodesWithPagination: create.asyncThunk(
      async ({ page, pageSize }, thunkAPI) => {
        try {
          const data = await _getAllPincodesWithPagination(page, pageSize);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          return thunkAPI.rejectWithValue({ message });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.pincodeMaster = action.payload.data.data;
          state.totalPincodeCount = action.payload.data.total;
        },
      }
    ),

    fetchPincodeById: create.asyncThunk(
        async (id, thunkAPI) => {
          try {
            const data = await _getPincodeById(id);
            return data;
          } catch (err) {
            const message = get(err, "message", "Something Went Wrong!");
            return thunkAPI.rejectWithValue({ message });
          }
        },
        {
          pending: (state) => {
            state.loading = true;
          },
          rejected: (state) => {
            state.loading = false;
          },
          fulfilled: (state, action) => {
            state.loading = false;
            state.pincodeDataById = action.payload.data;
          },
        }
      ),

    addPincodeData: create.asyncThunk(
      async (pincodeData, thunkAPI) => {
        try {
          let data = await _addPincodeData(pincodeData);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.pincodeMaster.push(action.payload.data);
          state.pincodeId = action.payload.data._id;
        },
      }
    ),

    updatePincodeData: create.asyncThunk(
      async ({ pincodeId, pincodeData }, thunkAPI) => {
        try {
          const data = await _updatePincodeData(pincodeId, pincodeData);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          return thunkAPI.rejectWithValue({ message });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          const index = state.pincodeMaster.findIndex(
            (pincode) => pincode._id === action.payload.data._id
          );
          if (index !== -1) {
            state.pincodeMaster[index] = action.payload.data;
          }
        },
      }
    ),

    deletePincode: create.asyncThunk(
      async (id, thunkAPI) => {
        try {
          const data = await _deletePincodeById(id);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          return thunkAPI.rejectWithValue({ message });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.pincodeMaster = state.pincodeMaster.filter(
            (pincode) => pincode._id !== action.payload.data._id
          );
        },
      }
    ),

    fetchAllPincodesByMicroMarketId: create.asyncThunk(
        async (microMarketId, thunkAPI) => {
          try {
            const data = await _getAllPincodesByMicroMarketId(microMarketId);
            return data;
          } catch (err) {
            const message = get(err, "message", "Something Went Wrong!");
            return thunkAPI.rejectWithValue({ message });
          }
        },
        {
          pending: (state) => {
            state.loading = true;
          },
          rejected: (state) => {
            state.loading = false;
          },
          fulfilled: (state, action) => {
            state.loading = false;
            state.pincodesByMicroMarketId = action.payload.data;
          },
        }
      ),      

  }),
});

export const {
  addPincodeData,
  updatePincodeData,
  deletePincode,
  fetchAllPincodesWithPagination,
  setCurrentPage,
  setPageSize,
  fetchPincodeById,
  fetchAllPincodesByMicroMarketId,

} = PincodeSlice.actions;

export default PincodeSlice.reducer;
