import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setActiveTab } from "../../feature/slice/MasterSlice";
import StateTab from "./state/StateTab";
import CityTab from "./city/CityTab";
import MicroMarketTab from "./microMarket/MicroMarketTab";
import LocalityTab from "./locality/LocalityTab";
import PincodeTab from "./pincode/PincodeTab";
import { fetchAllStatesData } from "../../feature/slice/MasterSlice";

const MasterList = () => {
  const dispatch = useDispatch();
  const { activeTab } = useSelector((state) => state.masterDetails);

  useEffect(() => {
    dispatch(fetchAllStatesData());
  }, [dispatch]);

  const tabs = [
    { id: "state", label: "State", component: StateTab },
    { id: "city", label: "City", component: CityTab },
    { id: "microMarket", label: "Micro Market", component: MicroMarketTab },
    { id: "locality", label: "Locality", component: LocalityTab },
    { id: "pincode", label: "Pincode", component: PincodeTab },
  ];

  const handleTabClick = (tabId) => {
    dispatch(setActiveTab(tabId));
  };

  const ActiveTabComponent =
    tabs.find((tab) => tab.id === activeTab)?.component || null;

  return (
    <div className="table-list-portion" style={{ width: '100%' }}>
      <div className="p-4">
        <div className="flex border-b">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => handleTabClick(tab.id)}
              className={`px-4 py-2 font-medium ${
                activeTab === tab.id
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : "text-gray-500 hover:text-gray-700"
              }`}
            >
              {tab.label}
            </button>
          ))}
        </div>
        <div className="mt-4">
          {ActiveTabComponent && <ActiveTabComponent />}
        </div>
      </div>
    </div>
  );
};

export default MasterList;
