import { client } from "../../utils/apiClient";
import { asyncHandler } from "../../utils/asyncHandler";

const getDirectorsList = async (body) => {
  const [data, err] = await asyncHandler(
    client.get(
      `/api/get-company-directors/${body?.companyId}?pageSize=${body?.pageSize}&page=${body?.page}`
    )
  );
  if (err) return err;
  return data;
};

const getDirectorDetailsData = async (directorId, companyId) => {
  const [data, err] = await asyncHandler(
    client.get(
      `/api/get-company-directors-details/director/${directorId}/company/${companyId}`
    )
  );
  if (err) return err;
  return data;
};

const updateDirectorDetailsData = async (param, directorId) => {
  try {
    const response = await client.patch(
      `/api/update-director-detail/${directorId}`,
      param
    );
    return response;
  } catch (err) {
    console.error(err);
    return err;
  }
};

const saveDirectorDetailsData = async (param, companyId) => {
  try {
    const [res, err] = await asyncHandler(client.post(
      `/api/create-director/company/${companyId}`,
      param
    ));

    return res || err;
  } catch (err) {
    console.error(err);
    return err;
  }
};

const getAllDistinctCountryMaster = async () => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-mca-director-distinct-country-master`)
  );
  if (err) return err;
  return data;
};

const getAllDistinctStateByCountry = async (country) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-mca-director-distinct-state-master/${country}`)
  );
  if (err) return err;
  return data;
};

const getAllDistinctDistrictByState = async (state) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-mca-director-distinct-district-master/${state}`)
  );
  if (err) return err;
  return data;
};

export {
  getDirectorsList,
  getDirectorDetailsData,
  updateDirectorDetailsData,
  saveDirectorDetailsData,
  getAllDistinctCountryMaster,
  getAllDistinctStateByCountry,
  getAllDistinctDistrictByState,
};
