import { buildCreateSlice, asyncThunkCreator } from "@reduxjs/toolkit";
import { get } from "lodash";
import {
  saveStateDetailsData as _saveStateData,
  getAllStatesData as _getAllStatesData,
  getAllStatesWithPagination as _getAllStatesWithPagination,
  updateStateDetailsData as _updateStateData,
  deleteStateById as _deleteStateById,
  getStateById as _getStateById,
} from "../services/MasterAPI";

const createSliceWithThunks = buildCreateSlice({
  creators: {
    asyncThunk: asyncThunkCreator,
  },
});

const initialState = {
  activeTab: "state",
  loading: false,
  stateMaster: [],
  statesForDD: [],
  stateDataById: [],
  stateId: null,
  totalStateCount: 0,
  currentPage: 1,
  pageSize: 10,
};

const MasterSlice = createSliceWithThunks({
  name: "master",
  initialState,
  reducers: (create) => ({
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },

    setCurrentPage: (state, action) => {
      state.currentPage = action?.payload;
    },

    setPageSize: (state, action) => {
      state.pageSize = action?.payload;
    },

    saveStateData: create.asyncThunk(
      async (stateData, thunkAPI) => {
        try {
          let data = await _saveStateData(stateData);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.stateMaster.push(action.payload.data);
          state.stateId = action.payload.data._id;
        },
      }
    ),

    fetchAllStatesData: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          const data = await _getAllStatesData();
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          return thunkAPI.rejectWithValue({ message });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.statesForDD = action.payload.data;
        },
      }
    ),

    fetchAllStatesWithPagination: create.asyncThunk(
      async ({ page, pageSize }, thunkAPI) => {
        try {
          const data = await _getAllStatesWithPagination(page, pageSize);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          return thunkAPI.rejectWithValue({ message });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.stateMaster = action.payload.data.data;
          state.totalStateCount = action.payload.data.total;
        },
      }
    ),

    fetchStateById: create.asyncThunk(
      async (id, thunkAPI) => {
        try {
          const data = await _getStateById(id);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          return thunkAPI.rejectWithValue({ message });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.stateDataById = action.payload.data;
        },
      }
    ),

    updateStateData: create.asyncThunk(
      async ({ stateId, stateData }, thunkAPI) => {
        try {
          const data = await _updateStateData(stateId, stateData);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          return thunkAPI.rejectWithValue({ message });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          const index = state.stateMaster.findIndex(
            (state) => state._id === action.payload.data._id
          );
          if (index !== -1) {
            state.stateMaster[index] = action.payload.data;
          }
        },
      }
    ),

    deleteState: create.asyncThunk(
      async (id, thunkAPI) => {
        try {
          const data = await _deleteStateById(id);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          return thunkAPI.rejectWithValue({ message });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.stateMaster = state.stateMaster.filter(
            (state) => state._id !== action.payload.data._id
          );
        },
      }
    ),
  }),
});

export const {
  fetchStateById,
  setActiveTab,
  saveStateData,
  fetchAllStatesWithPagination,
  fetchAllStatesData,
  updateStateData,
  deleteState,
  setCurrentPage,
  setPageSize,
} = MasterSlice.actions;

export default MasterSlice.reducer;
