import React, { useEffect, useState } from "react";
import { Table, Typography, Pagination, Button, Modal, message, Spin } from "antd";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompaniesList,
  setCurrentPage,
  setPageSize,
  setCompanyId,
  getFilteredCompany,
  setCompanyInitialState,
  setDirectorsCnt,
  deleteCompany,
} from "../../feature/slice/CompanyDetailsSlice";
import { setFilters } from "../../feature/slice/FiltersSlice";
import { ReloadOutlined } from "@ant-design/icons";
import { MdDeleteForever } from "react-icons/md";
import "./Company.css";
import { CommonFilter } from '../../components/filterCard/FilterCard'
import dayjs from "dayjs";
import BulkUpload from "../../assets/bulk.svg";
import AddProperty from "../../assets/add.svg";
import DownloadIcon from '../../assets/download.svg';

const Company = (props) => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { loading, companyDetailsData, currentPage, pageSize, companiesCnt } =
    useSelector((store) => store.companyDetails);
  const { citiesData, localitiesData, companyFilters } = useSelector((store) => store.filters);
  const [messageApi, contextHolder] = message.useMessage();
  const filter = {
    companyName: null,
    companyNameFilter: 'contains',
    completionRatio: null,
    completionRatioFilter: 'equals',
    companyType: [],
    city: [],
    locality: [],
    startDate: null,
    endDate: null,
    cin: null,
    cinFilter: 'contains',
    industryType: null,
    industryTypeFilter: 'contains',
    companyStrength: null,
    companyStrengthFilter: 'equals',
    numberOfDesignatedPartners: null,
    numberOfDesignatedPartnersFilter: 'equals'
  }
  const [filterCompany, setFilterCompany] = useState(filter)

  useEffect(() => {
    const companyFilterStatus = JSON.parse(localStorage.getItem('companyFilterStatus')) || filter;
    setFilterCompany(companyFilterStatus);
    dispatch(setCompanyId(null));
    dispatch(setCompanyInitialState());
    dispatch(setCurrentPage(1));
    dispatch(setPageSize(10));
    if (
      companyFilterStatus?.cin ||
      companyFilterStatus?.companyName ||
      companyFilterStatus?.companyType?.length > 0 ||
      companyFilterStatus?.city?.length > 0 ||
      companyFilterStatus?.startDate ||
      companyFilterStatus?.endDate ||
      companyFilterStatus?.cin ||
      companyFilterStatus?.industryType ||
      companyFilterStatus?.completionRatio ||
      companyFilterStatus?.companyStrength ||
      companyFilterStatus?.numberOfDesignatedPartners ||
      companyFilterStatus?.locality?.length > 0
    ) {
      const citiesDataForFilter = [];
      for (let index = 0; index < companyFilters?.cities.length; index++) {
        citiesDataForFilter.push(
          citiesData?.[companyFilters?.cities?.[index]]?.title
        );
      }

      const body = {
        cin: companyFilterStatus?.cin || null,
        cinFilter: companyFilterStatus?.cinFilter,
        companyName: companyFilterStatus?.companyName || null,
        companyNameFilter: companyFilterStatus?.companyNameFilter,
        companyType: companyFilterStatus?.companyType || [],
        city: companyFilterStatus.city || [],
        locality: companyFilterStatus.locality || [],
        startDate: companyFilterStatus?.startDate,
        endDate: companyFilterStatus?.endDate,
        industryType: companyFilterStatus?.industryType || null,
        industryTypeFilter: companyFilterStatus?.industryTypeFilter,
        completionRatio: +companyFilterStatus?.completionRatio || null,
        completionRatioFilter: companyFilterStatus?.completionRatioFilter,
        companyStrength: +companyFilterStatus?.companyStrength || null,
        companyStrengthFilter: companyFilterStatus?.companyStrengthFilter,
        numberOfDesignatedPartners: +companyFilterStatus?.numberOfDesignatedPartners || null,
        numberOfDesignatedPartnersFilter: companyFilterStatus?.numberOfDesignatedPartnersFilter,
        currentPage: 1,
        pageSize: 10,
      };
      dispatch(getFilteredCompany(body));
    } else {
      const body = {
        pageSize: 10,
        page: 1,
      };
      dispatch(getCompaniesList(body));
    }
  }, []);


  const handleDelete = (companyId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this Company?",
      onOk: async () => {
        try {
          const response = await dispatch(deleteCompany(companyId));
          messageApi.success(response.message || "Company deleted successfully");

          // Fetch the companies list with the current page and page size
          setTimeout(() => {
            if (
              filterCompany?.cin ||
              filterCompany?.companyName ||
              filterCompany?.companyType?.length > 0 ||
              filterCompany?.city?.length > 0 ||
              filterCompany?.startDate ||
              filterCompany?.endDate ||
              filterCompany?.cin ||
              filterCompany?.industryType ||
              filterCompany?.completionRatio ||
              filterCompany?.companyStrength ||
              filterCompany?.numberOfDesignatedPartners ||
              filterCompany?.locality?.length > 0
            ) {
              const citiesDataForFilter = [];
              for (let index = 0; index < companyFilters?.cities.length; index++) {
                citiesDataForFilter.push(
                  citiesData?.[companyFilters?.cities?.[index]]?.title
                );
              }

              const body = {
                cin: filterCompany?.cin || null,
                cinFilter: filterCompany?.cinFilter,
                companyName: filterCompany?.companyName || null,
                companyNameFilter: filterCompany?.companyNameFilter,
                companyType: filterCompany?.companyType || [],
                city: filterCompany.city || [],
                locality: filterCompany.locality || [],
                startDate: filterCompany?.startDate,
                endDate: filterCompany?.endDate,
                industryType: filterCompany?.industryType || null,
                industryTypeFilter: filterCompany?.industryTypeFilter,
                completionRatio: +filterCompany?.completionRatio || null,
                completionRatioFilter: filterCompany?.completionRatioFilter,
                companyStrength: +filterCompany?.companyStrength || null,
                companyStrengthFilter: filterCompany?.companyStrengthFilter,
                numberOfDesignatedPartners: +filterCompany?.numberOfDesignatedPartners || null,
                numberOfDesignatedPartnersFilter: filterCompany?.numberOfDesignatedPartnersFilter,
                currentPage: currentPage,
                pageSize: pageSize,
              };
              dispatch(getFilteredCompany(body));
            } else {
              const body = {
                pageSize: pageSize,
                page: currentPage,
              };
              dispatch(getCompaniesList(body));
            }
          }, 3000);
        } catch (error) {
          messageApi.error(error.message || "Failed to delete company");
        }
      },
    });
  };

  const handleResetCompanySearch = () => {
    dispatch(
      setFilters({
        name: "localities",
        value: [],
      })
    );
    const buildingNameBody = {
      value: null,
      name: "buildingName",
    };
    dispatch(setFilters(buildingNameBody));

    const documentNoBody = {
      value: null,
      name: "documentNo",
    };
    dispatch(setFilters(documentNoBody));
  };

  const handleRowClick = (record) => {
    handleResetCompanySearch();
    navigateTo(`/companyDetails/${record?._id}`);
    dispatch(setCompanyId(record?._id));
  };


  const companyTypeOptions = [
    {
      label: "Private Limited Company",
      key: "Private Limited Company",
      value: "CIN",
    },
    {
      label: "Limited Liability Partnership",
      key: "Limited Liability Partnership",
      value: "LLPIN",
    },
    {
      label: "One Person Company",
      key: "One Person Company",
      value: "CIN",
    },
    {
      label: "Not for profit license company",
      key: "Not for profit license company",
      value: "CIN",
    },
    {
      label: "Public Limited Company",
      key: "Public Limited Company",
      value: "CIN",
    },
    {
      label: "Subsidiary of a foreign company",
      key: "Subsidiary of a foreign company",
      value: "CIN",
    },
    {
      label: "Foreign Company",
      key: "Foreign Company",
      value: "FCRN",
    },
    {
      label: "General Association Private",
      key: "General Association Private",
      value: "CIN",
    },
    {
      label: "General Association Public",
      key: "General Association Public",
      value: "CIN",
    },
    {
      label: "Companies owned by government of india",
      key: "Companies owned by government of india",
      value: "CIN",
    },
    {
      label: "Companies owned by state government",
      key: "Companies owned by state government",
      value: "CIN",
    },
    {
      label: "Private limited companies with unlimited liability",
      key: "Private limited companies with unlimited liability",
      value: "CIN",
    },
    {
      label: "Financial lease company",
      key: "Financial lease company",
      value: "CIN",
    },
    {
      label: "Proprietorship firm",
      key: "Proprietorship firm",
      value: "-",
    },
    {
      label: "Partnership firms",
      key: "Partnership firms",
      value: "-",
    },
  ];


  const applyFilter = (confirm) => {
    localStorage.setItem('companyFilterStatus', JSON.stringify(filterCompany));
    dispatch(setCurrentPage(1));
    dispatch(setPageSize(10));
    dispatch(getFilteredCompany({ ...filterCompany, currentPage: 1, pageSize: 10 }));
    confirm();
  }

  const resetFilter = (columnKey, defaultFilterValue, defaultFilterType, clearFilters, confirm) => {
    const updatedFilterCompany = { ...filterCompany, [columnKey]: defaultFilterValue, [`${columnKey}Filter`]: defaultFilterType };
    localStorage.setItem('companyFilterStatus', JSON.stringify(updatedFilterCompany));
    setFilterCompany(updatedFilterCompany);
    dispatch(setCurrentPage(1));
    dispatch(setPageSize(10));
    dispatch(getFilteredCompany({ ...updatedFilterCompany, currentPage: 1, pageSize: 10, }));
    clearFilters();
    confirm();
  };

  const columns = [
    {
      title: () => (
        <div className={filterCompany.companyName ? 'titleHighlight' : 'titleDefault'}>
          Company Name
        </div>
      ),
      column: "Company Name",
      dataIndex: "companyName",
      width: 250,
      editable: true,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterCompany.companyName}
          filterType={filterCompany.companyNameFilter}
          placeholder="Enter the company name here"
          onFilterChange={(value) => setFilterCompany({ ...filterCompany, companyName: value })}
          onFilterTypeChange={(value) => setFilterCompany({ ...filterCompany, companyNameFilter: value })}
          onApply={() => {
            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("companyName", null, "contains", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'contains', label: 'Contains' },
            { value: 'startsWith', label: 'Starts with' },
            { value: 'endsWith', label: 'Ends with' },
          ]}
        />
      ),
      filterSearch: true,
    },
    {
      title: () => (
        <div className={filterCompany.cin ? 'titleHighlight' : 'titleDefault'}>
          CIN
        </div>
      ),
      column: "CIN",
      dataIndex: "cin",
      width: 200,
      editable: true,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterCompany.cin}
          filterType={filterCompany.cinFilter}
          placeholder="Enter the company CIN number here"
          onFilterChange={(value) => setFilterCompany({ ...filterCompany, cin: value })}
          onFilterTypeChange={(value) => setFilterCompany({ ...filterCompany, cinFilter: value })}
          onApply={() => {
            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("cin", null, "contains", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'contains', label: 'Contains' },
            { value: 'startsWith', label: 'Starts with' },
            { value: 'endsWith', label: 'Ends with' },
          ]}
        />
      ),
      filterSearch: true,
    },
    {
      title: () => (
        <div className={filterCompany.companyType.length > 0 ? 'titleHighlight' : 'titleDefault'}>
          Company Type
        </div>
      ),
      column: "Company Type",
      dataIndex: "companyType",
      width: 200,
      editable: true,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          placeholder="Select the company type here"
          filterValue={filterCompany.companyType || []}  // Initialize with an empty array if no filter value
          onFilterChange={(value) => {
            setFilterCompany({ ...filterCompany, companyType: value });  // Update filter value
          }}
          onApply={() => {
            localStorage.setItem('companyFilterStatus', JSON.stringify(filterCompany));
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            dispatch(getFilteredCompany({ ...filterCompany, currentPage: 1, pageSize: 10, }));
            confirm();
          }}
          onReset={() => {
            const updatedFilterCompany = { ...filterCompany, companyType: [] }
            localStorage.setItem('companyFilterStatus', JSON.stringify(updatedFilterCompany));
            setFilterCompany(updatedFilterCompany);
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            dispatch(getFilteredCompany({ ...updatedFilterCompany, currentPage: 1, pageSize: 10, }));
            clearFilters();
            confirm();
          }}
          inputType="select"  // Set input type to 'select'
          mode="multiple"  // Allow multiple selections
          selectOptions={companyTypeOptions.map(type => ({  // Map localities to options
            value: type.label,
            label: type.label,
          }))}
        />
      ),
      onFilter: (value, record) => record.locality.includes(value),
    },

    {
      title: () => (
        <div className={filterCompany.industryType ? 'titleHighlight' : 'titleDefault'}>
          Industry Type
        </div>
      ),
      column: "Industry Type",
      dataIndex: "industryType",
      width: 150,
      editable: true,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterCompany.industryType}
          filterType={filterCompany.industryTypeFilter}
          placeholder="Enter the industry type here"
          onFilterChange={(value) => setFilterCompany({ ...filterCompany, industryType: value })}
          onFilterTypeChange={(value) => setFilterCompany({ ...filterCompany, industryTypeFilter: value })}
          onApply={() => {
            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("industryType", null, "contains", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'contains', label: 'Contains' },
            { value: 'startsWith', label: 'Starts with' },
            { value: 'endsWith', label: 'Ends with' },
          ]}
        />
      ),
      filterSearch: true,
    },
    {
      title: "Website",
      column: "Website",
      dataIndex: "websiteLink",
      width: 150,
    },
    {
      title: () => (
        <div className={filterCompany.companyStrength ? 'titleHighlight' : 'titleDefault'}>
          Company Strength
        </div>
      ),
      column: "Company Strength",
      dataIndex: "companyStrength",
      width: 100,
      editable: true,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          inputType="number"
          placeholder="Enter the company strength number here"
          filterValue={filterCompany.companyStrength}
          filterType={filterCompany.companyStrengthFilter}
          onFilterChange={(value) => setFilterCompany({ ...filterCompany, companyStrength: value })}
          onFilterTypeChange={(value) => setFilterCompany({ ...filterCompany, companyStrengthFilter: value })}
          onApply={() => {
            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("companyStrength", null, "equals", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'equals', label: 'Equal' },
            { value: 'lessthan', label: 'Less Than' },
            { value: 'greaterthan', label: 'Greater Than' },
          ]}
        />
      ),
      filterSearch: true,
    },
    {
      title: () => (
        <div className={filterCompany.locality.length > 0 ? 'titleHighlight' : 'titleDefault'}>
          Locality
        </div>
      ),
      column: "Locality",
      dataIndex: "locality",
      width: 100,
      editable: true,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterCompany.locality || []}
          onFilterChange={(value) => {
            const updatedFilterCompany = { ...filterCompany, locality: value };
            setFilterCompany(updatedFilterCompany);
          }}
          onApply={() => {
            localStorage.setItem('companyFilterStatus', JSON.stringify(filterCompany));
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            dispatch(getFilteredCompany({ ...filterCompany, currentPage: 1, pageSize: 10, }));
            confirm();
          }}
          onReset={() => {
            const updatedFilterCompany = { ...filterCompany, locality: [] }
            localStorage.setItem('companyFilterStatus', JSON.stringify(updatedFilterCompany));
            setFilterCompany(updatedFilterCompany);
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            dispatch(getFilteredCompany({ ...updatedFilterCompany, currentPage: 1, pageSize: 10, }));
            clearFilters();
            confirm();
          }}

          inputType="select"
          mode="multiple"
          placeholder="Select a Locality"
          inputRef= 'locality'
          selectOptions={localitiesData?.map(locality => ({
            value: locality.value,
            label: locality.title,
          }))}
        />
      ),
    },
    {
      title: () => (
        <div className={filterCompany.city.length > 0 ? 'titleHighlight' : 'titleDefault'}>
          City
        </div>
      ),
      column: "City",
      dataIndex: "city",
      width: 100,
      editable: true,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterCompany.city || []}
          onFilterChange={(value) => {
            const updatedFilterCompany = { ...filterCompany, city: value };
            setFilterCompany(updatedFilterCompany);
          }}
          onApply={() => {
            localStorage.setItem('companyFilterStatus', JSON.stringify(filterCompany));
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            dispatch(getFilteredCompany({ ...filterCompany, currentPage: 1, pageSize: 10, }));
            confirm();
          }}
          onReset={() => {
            const updatedFilterCompany = { ...filterCompany, city: [] }
            localStorage.setItem('companyFilterStatus', JSON.stringify(updatedFilterCompany));
            setFilterCompany(updatedFilterCompany);
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            dispatch(getFilteredCompany({ ...updatedFilterCompany, currentPage: 1, pageSize: 10, }));
            clearFilters();
            confirm();
          }}
          inputType="select"
          mode="multiple"
          placeholder="Select a city"
          inputRef= 'city'
          selectOptions={citiesData?.map(city => ({
            value: city.value,
            label: city.title,
          }))}
        />
      ),
    },
    {
      title: () => (
        <div className={filterCompany.numberOfDesignatedPartners ? 'titleHighlight' : 'titleDefault'}>
          Designated Partners
        </div>
      ),
      column: "Designated Partners",
      dataIndex: "numberOfDesignatedPartners",
      width: 100,
      editable: true,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          inputType="number"
          placeholder="Enter the designated partners number here"
          filterValue={filterCompany.numberOfDesignatedPartners}
          filterType={filterCompany.numberOfDesignatedPartnersFilter}
          onFilterChange={(value) => setFilterCompany({ ...filterCompany, numberOfDesignatedPartners: value })}
          onFilterTypeChange={(value) => setFilterCompany({ ...filterCompany, numberOfDesignatedPartnersFilter: value })}
          onApply={() => {
            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("numberOfDesignatedPartners", null, "equals", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'equals', label: 'Equal' },
            { value: 'lessthan', label: 'Less Than' },
            { value: 'greaterthan', label: 'Greater Than' },
          ]}
        />
      ),
      filterSearch: true,
    },

    {
      title: () => (
        <div className={filterCompany.startDate || filterCompany.endDate ? 'titleHighlight' : 'titleDefault'}>
          Created Date
        </div>
      ),
      column: "Created Date",
      dataIndex: "createdAt",
      width: 100,
      render: (createdAt) =>
        createdAt ? dayjs(createdAt).format("DD-MM-YYYY") : null,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          inputType="dateRange"
          startDate={filterCompany.startDate}
          endDate={filterCompany.endDate}
          onStartDateChange={(date) => setFilterCompany({ ...filterCompany, startDate: dayjs(date, "DD-MM-YYYY").add(5, "hour").add(30, "minute") })}
          onEndDateChange={(date) => setFilterCompany({ ...filterCompany, endDate: dayjs(date, "DD-MM-YYYY").add(5, "hour").add(30, "minute") })}
          onApply={() => {
            localStorage.setItem('companyFilterStatus', JSON.stringify(filterCompany));
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            dispatch(getFilteredCompany({ ...filterCompany, currentPage: 1, pageSize: 10, }));
            confirm();
          }}
          onReset={() => {
            const updatedFilterCompany = { ...filterCompany, startDate: null, endDate: null }
            localStorage.setItem('companyFilterStatus', JSON.stringify(updatedFilterCompany));
            setFilterCompany(updatedFilterCompany);
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            dispatch(getFilteredCompany({ ...updatedFilterCompany, currentPage: 1, pageSize: 10, }));
            clearFilters();
            confirm();
          }}
        />
      ),
    },
    {
      title: "Operation",
      column: "Operation",
      dataIndex: "_id",
      width: 85,
      render: (_, record) => {
        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography.Link
              onClick={(e) => {
                e.stopPropagation();
                handleResetCompanySearch();
                dispatch(setCompanyId(record?._id));
                dispatch(setDirectorsCnt(record?.numberOfDirectors));
                dispatch(setCurrentPage(1));
                dispatch(setPageSize(10));
                navigateTo(`/directorsList/${record?._id}`);
              }}
            >
              Director
            </Typography.Link>
          </div>
        );
      },
    },
    {
      title: "Completion Ratio",
      column: "Completion Ratio",
      dataIndex: "completionRatio",
      width: 100,
      render: (_, record) => {
        return <div>{record?.completionRatio}</div>;
      },
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          inputType="number"
          placeholder="Enter the completion ratio here"
          filterValue={filterCompany.completionRatio}
          filterType={filterCompany.completionRatioFilter}
          onFilterChange={(value) => setFilterCompany({ ...filterCompany, completionRatio: value })}
          onFilterTypeChange={(value) => setFilterCompany({ ...filterCompany, completionRatioFilter: value })}
          onApply={() => {
            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("completionRatio", null, "equals", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'equals', label: 'Equal' },
            { value: 'lessthan', label: 'Less Than' },
            { value: 'greaterthan', label: 'Greater Than' },
          ]}
        />
      ),
      filterSearch: true,
    },
    {
      title: "",
      dataIndex: "delete",
      width: 30,
      editable: true,
      render: (_, record) => {
        return (
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(record?._id);
            }}
          >
            <MdDeleteForever size={20} className="text-secondary" />
          </div>
        )
      }
    },
  ];

  const handlePaginationChange = (page, size) => {
    dispatch(setCurrentPage(page));
    if (size === pageSize) {
      if (
        filterCompany?.cin ||
        filterCompany?.companyName ||
        filterCompany?.companyType?.length > 0 ||
        filterCompany?.city?.length > 0 ||
        filterCompany?.startDate ||
        filterCompany?.endDate ||
        filterCompany?.cin ||
        filterCompany?.industryType ||
        filterCompany?.completionRatio ||
        filterCompany?.companyStrength ||
        filterCompany?.numberOfDesignatedPartners ||
        filterCompany?.locality?.length > 0
      ) {
        const citiesDataForFilter = [];
        for (let index = 0; index < companyFilters?.cities.length; index++) {
          citiesDataForFilter.push(
            citiesData?.[companyFilters?.cities?.[index]]?.title
          );
        }

        const body = {
          cin: filterCompany?.cin || null,
          cinFilter: filterCompany?.cinFilter,
          companyName: filterCompany?.companyName || null,
          companyNameFilter: filterCompany?.companyNameFilter,
          companyType: filterCompany?.companyType || [],
          city: filterCompany.city || [],
          locality: filterCompany.locality || [],
          startDate: filterCompany?.startDate,
          endDate: filterCompany?.endDate,
          industryType: filterCompany?.industryType || null,
          industryTypeFilter: filterCompany?.industryTypeFilter,
          completionRatio: +filterCompany?.completionRatio || null,
          completionRatioFilter: filterCompany?.completionRatioFilter,
          companyStrength: +filterCompany?.companyStrength || null,
          companyStrengthFilter: filterCompany?.companyStrengthFilter,
          numberOfDesignatedPartners: +filterCompany?.numberOfDesignatedPartners || null,
          numberOfDesignatedPartnersFilter: filterCompany?.numberOfDesignatedPartnersFilter,
          currentPage: page,
          pageSize: pageSize,
        };
        dispatch(getFilteredCompany(body));
      } else {
        const body = {
          pageSize: pageSize,
          page,
        };
        dispatch(getCompaniesList(body));
      }
    } else dispatch(setCurrentPage(1));
  };

  const onShowSizeChange = (current, pageSize) => {
    dispatch(setPageSize(pageSize));
    dispatch(setCurrentPage(1));
    if (
      filterCompany?.cin ||
      filterCompany?.companyName ||
      filterCompany?.companyType?.length > 0 ||
      filterCompany?.city?.length > 0 ||
      filterCompany?.startDate ||
      filterCompany?.endDate ||
      filterCompany?.cin ||
      filterCompany?.industryType ||
      filterCompany?.completionRatio ||
      filterCompany?.companyStrength ||
      filterCompany?.numberOfDesignatedPartners ||
      filterCompany?.locality?.length > 0
    ) {
      const citiesDataForFilter = [];
      for (let index = 0; index < companyFilters?.cities.length; index++) {
        citiesDataForFilter.push(
          citiesData?.[companyFilters?.cities?.[index]]?.title
        );
      }

      const body = {
        cin: filterCompany?.cin || null,
        cinFilter: filterCompany?.cinFilter,
        companyName: filterCompany?.companyName || null,
        companyNameFilter: filterCompany?.companyNameFilter,
        companyType: filterCompany?.companyType || [],
        city: filterCompany.city || [],
        locality: filterCompany.locality || [],
        startDate: filterCompany?.startDate,
        endDate: filterCompany?.endDate,
        industryType: filterCompany?.industryType || null,
        industryTypeFilter: filterCompany?.industryTypeFilter,
        completionRatio: filterCompany?.completionRatio || null,
        completionRatioFilter: filterCompany?.completionRatioFilter,
        companyStrength: filterCompany?.companyStrength || null,
        companyStrengthFilter: filterCompany?.companyStrengthFilter,
        numberOfDesignatedPartners: filterCompany?.numberOfDesignatedPartners || null,
        numberOfDesignatedPartnersFilter: filterCompany?.numberOfDesignatedPartnersFilter,
        currentPage: 1,
        pageSize: pageSize,
      };
      dispatch(getFilteredCompany(body));
    } else {
      const body = {
        pageSize: pageSize,
        page: 1,
      };
      dispatch(getCompaniesList(body));
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch("/api/upload", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        console.log("File uploaded successfully");
      } else {
        console.error("Failed to upload file");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };


  return (
    <React.Fragment>
      {loading ? (
        <Spin spinning={loading} size="large" fullscreen />
      ) : (
        <>
          {contextHolder}
          <div className="table-list-portion"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "auto",
              flexGrow: 1,
              height: "100%",
            }}>
            
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="m-4 pl-4">
              <h1 style={{ fontSize: "18px", fontWeight: "bold" }}>Company</h1>
            </div>
              <div className="list-options m-4 flex justify-center items-center">
                <a href="/Registry_After_fix.xlsx" download>
                  <Button className="mr-4" color="primary">
                    <img src={DownloadIcon} alt="download" />
                    Download Sample
                  </Button>
                </a>
                <Button className="mr-4" onClick={() => document.getElementById("bulkUploadInput").click()}>
                  <img src={BulkUpload} alt="bulk" />
                  Bulk Upload
                </Button>
                <input
                  type="file"
                  id="bulkUploadInput"
                  accept=".xlsx, .xls"
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
                <Button
                  className="mr-4"
                  style={{ backgroundColor: "#fb923c", color: "#FFFFFF" }}
                  onClick={() => {
                    handleResetCompanySearch();
                    navigateTo(`/companyDetails`);
                    dispatch(setCompanyId(null));
                    dispatch(setCompanyInitialState());
                  }}
                >
                  <img src={AddProperty} alt="add" />
                  Add Company
                </Button>
                <ReloadOutlined
                  style={{ cursor: "pointer" }}
                  title="Reload Company List"
                  onClick={() => {
                    const body = {
                      pageSize: 10,
                      page: 1,
                    };
                    setFilterCompany(filter)
                    localStorage.setItem('companyFilterStatus', JSON.stringify(filter));
                    dispatch(getCompaniesList(body));
                    dispatch(setPageSize(10));
                    dispatch(setCurrentPage(1));
                  }}
                />
              </div>
            </div>
            <div className="m-4" style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', backgroundColor: '#FFFFFF', borderRadius: '8px' }}>
              <Table
                className="no-column-lines"
                style={{ width: "auto" }}
                bordered
                dataSource={companyDetailsData}
                tableLayout="fixed"
                scroll={{ x: 200 }}
                columns={columns}
                size="small"
                loading={loading}
                pagination={false}
                rowKey={"_id"}
                onRow={(record) => {
                  return {
                    onClick: () => handleRowClick(record),
                    style: { cursor: "pointer" },
                  };
                }}
              />
              {companyDetailsData?.length > 0 && (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
                  <div style={{ margin: '10px 0' }}>
                    Showing {((currentPage - 1) * pageSize) + 1} - {Math.min(currentPage * pageSize, companiesCnt)} / {companiesCnt} Results
                  </div>
                  <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    showSizeChanger
                    onShowSizeChange={onShowSizeChange}
                    onChange={(currPage, pageSize) =>
                      handlePaginationChange(currPage, pageSize)
                    }
                    total={companiesCnt}
                    style={{ marginLeft: "10px", marginTop: "10px", marginBottom: "20px" }}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};
export default Company;
