import { client } from "../../utils/apiClient";
import { asyncHandler } from "../../utils/asyncHandler";

const addCityData = async (param) => {
  try {
    const [data, err] = await asyncHandler(
      client.post(`/api/create-city`, param, {
        headers: {
          "Content-Type": "application/json",
        },
      })
    );
    return data || err;
  } catch (err) {
    console.error(err);
  }
};

const getAllCities = async (page, pageSize) => {
  try {
    const [data, err] = await asyncHandler(
      client.get(`/api/get-all-cities?page=${page}&pageSize=${pageSize}`)
    );
    return data || err;
  } catch (err) {
    console.error(err);
  }
};

const getCityById = async (id) => {
  try {
    const [data, err] = await asyncHandler(client.get(`/api/get-city/${id}`));
    return data || err;
  } catch (err) {
    console.error(err);
  }
};

const getAllCitiesByStateId = async (id) => {
  try {
    const { data } = await client.get(
      `/api/get-all-cities-by-stateId?stateId=${id}`
    );
    return data;
  } catch (err) {
    console.error("Error fetching cities by stateId:", err);
  }
};

const updateCityData = async (id, param) => {
  try {
    const [data, err] = await asyncHandler(
      client.put(`/api/update-city/${id}`, param, {
        headers: {
          "Content-Type": "application/json",
        },
      })
    );
    return data || err;
  } catch (err) {
    console.error(err);
  }
};

const deleteCityById = async (id) => {
  try {
    const [data, err] = await asyncHandler(
      client.delete(`/api/delete-city/${id}`)
    );
    return data || err;
  } catch (err) {
    console.error(err);
  }
};

export {
  getAllCities,
  addCityData,
  updateCityData,
  deleteCityById,
  getAllCitiesByStateId,
  getCityById,
};
