import React, { useEffect } from "react";
import Title from "antd/es/typography/Title";
import { Form, Button, Divider, Input, message } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  saveStateData,
  updateStateData,
  fetchStateById,
} from "../../../feature/slice/MasterSlice";

const StateDetailsCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { stateDataById } = useSelector((state) => state.masterDetails);
  const [form] = Form.useForm();

  const { stateId } = location.state || {};

  useEffect(() => {
    if (stateId) {
      dispatch(fetchStateById(stateId));
    }
  }, [stateId, dispatch]);

  useEffect(() => {
    if (stateId && stateDataById) {
      form.setFieldsValue({
        state: stateDataById.state,
      });
    }
  }, [stateId, stateDataById, form]);

  const handleSubmit = (values) => {
    const { state: newState } = values;
    const originalState = stateDataById.state || null;

    if (newState === originalState) {
      message.warning("No changes detected in the state data.");
      return;
    }

    if (stateId) {
      dispatch(updateStateData({ stateId, stateData: values }))
        .then(() => {
          message.success("State data updated successfully");
          navigate("/masterlist");
        })
        .catch((err) => {
          message.error("Failed to update state data, State already exists!!");
          console.error("Failed to update state data:", err);
        });
    } else {
      dispatch(saveStateData(values))
        .then(() => {
          message.success("State data saved successfully");
          navigate("/masterlist");
        })
        .catch((err) => {
          message.error("Failed to save state data, State already exists!!");
          console.error("Failed to save state data:", err);
        });
    }
  };

  return (
    <>
      <div
        style={{
          alignItems: "center",
          padding: "10px",
          marginBottom: "1rem",
          width: "100%",
          backgroundColor: "white",
          boxShadow: "0 8px 8px -8px rgba(0, 0, 0, 0.5)",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1rem",
            padding: "10px",
            marginBottom: "20px",
            width: "100%",
            position: "sticky",
            top: 0,
            zIndex: 1000,
            backgroundColor: "white",
            boxShadow: "0 8px 8px -8px rgba(0, 0, 0, 0.5)",
          }}
        >
          <Title level={4}>
            {stateId ? "Update State Information" : "Add State Information"}
          </Title>
          <Button
            onClick={() => {
              navigate("/masterlist");
            }}
          >
            Back
          </Button>
        </div>
        <Divider style={{ backgroundColor: "black", marginTop: "0" }} />
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            countryName: "India",
          }}
          style={{ maxWidth: "400px", margin: "0 auto" }}
        >
          <Form.Item
            label="State Name"
            name="state"
            rules={[
              { required: true, message: "Please enter the state name!" },
            ]}
          >
            <Input placeholder="Enter State Name" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              style={{ marginTop: "1rem" }}
            >
              {stateId ? "Update" : "Submit"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default StateDetailsCard;
