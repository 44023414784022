import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TabContent from "../TabContent";
import { Modal } from "antd";
import {
  deletePincode,
  fetchAllPincodesWithPagination,
  setCurrentPage,
  setPageSize,
} from "../../../feature/slice/PincodeSlice";

const PincodeTab = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const {
    pincodeMaster,
    currentPage = 1,
    pageSize = 10,
    totalPincodeCount,
    loading,
  } = useSelector((state) => state.pincodeDetails);

  useEffect(() => {
    dispatch(fetchAllPincodesWithPagination({ page: currentPage, pageSize }));
  }, [dispatch, currentPage, pageSize]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentPage(1));
      dispatch(setPageSize(10));
    };
  }, [dispatch]);

  const columns = [
    {
      title: "Pincode",
      dataIndex: "pincode",
      key: "pincode",
      sorter: (a, b) => a.pincode - b.pincode,
    },
    { title: "State Name", dataIndex: ["stateId", "state"], key: "stateName" },
    { title: "City Name", dataIndex: ["cityId", "city"], key: "cityName" },
    {
      title: "Micro Market Name",
      dataIndex: ["microMarketId", "microMarket"],
      key: "microMarketName",
    },
    {
      title: "Created On",
      dataIndex: "createdOn",
      key: "createdOn",
      render: (text) => new Date(text).toLocaleString(),
    },
    { title: "Created By", dataIndex: "createdBy", key: "createdBy" },
    {
      title: "Last Updated On",
      dataIndex: "lastUpdatedOn",
      key: "lastUpdatedOn",
      render: (text) => new Date(text).toLocaleString(),
    },
    { title: "Updated By", dataIndex: "updatedBy", key: "updatedBy" },
  ];

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this pincode?",
      onOk: () => {
        dispatch(deletePincode(id))
          .then(() => {
            dispatch(
              fetchAllPincodesWithPagination({ page: currentPage, pageSize })
            );
          })
          .catch((err) => {
            console.error("Failed to delete locality:", err);
          });
      },
    });
  };

  const handleRowClick = (record) => ({
    onClick: () =>
      navigateTo("/pincodeDetails", { state: { pincodeId: record._id } }),
  });

  const handleTableChange = (pagination) => {
    dispatch(setCurrentPage(pagination.current));
    dispatch(setPageSize(pagination.pageSize));
  };

  const fetchData = async ({ page, pageSize }) => {
    dispatch(fetchAllPincodesWithPagination({ page, pageSize }));
  };

  return (
    <TabContent
      title="Pincode"
      fetchData={fetchData}
      data={pincodeMaster}
      columns={columns}
      buttonLabel="Add Pincode"
      onButtonClick={() => navigateTo("/pincodeDetails")}
      handleDelete={handleDelete}
      onRowClick={handleRowClick}
      currentPage={currentPage}
      pageSize={pageSize}
      totalRecords={totalPincodeCount}
      loading={loading}
      handleTableChange={handleTableChange}
    />
  );
};

export default PincodeTab;
