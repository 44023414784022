import { client } from "../../utils/apiClient";
import { asyncHandler } from "../../utils/asyncHandler";

const getLocalities = async (search) => {
  // Use URLSearchParams to handle the search parameter
  const queryParams = new URLSearchParams();
  if (search) queryParams.append("search", search);

  const [data, err] = await asyncHandler(
    client.get(
      `/api/get-all-distinct-locality-master?${queryParams.toString()}`
    )
  );
  if (err) return err;
  return data;
};

const getCities = async (search) => {
  // Use URLSearchParams to handle the search parameter
  const queryParams = new URLSearchParams();
  if (search) queryParams.append("search", search);

  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-distinct-city-master?${queryParams.toString()}`)
  );
  if (err) return err;
  return data;
};

export { getLocalities, getCities };
