import React, { useState, useEffect } from "react";
import {
    Form,
    Col,
    Row,
    Divider,
    Select,
    Radio,
    Button,
    Collapse,
    message,
    Tabs,
    Checkbox,
    Upload,
    Image,
} from "antd";
import Title from "antd/es/typography/Title";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import FormElement from "../../components/formElement/FormElement";
import {
    getTransactionDetailsData,
    setTransactionInfoEditable,
    updateTransactionInfo,
    setTransactionInfo,
    setTransactionId,
    getPropertyDetailsData,
    getCompanyWithSearchableText,
    setTransactionInitialState,
    saveTransactionInfo,
    setLegacyPartiesInfo,
    getAllDocumentMaster,
} from "../../feature/slice/TransactionDetailsSlice";
import {
    setCompanyId,
    setNavigatedFrom,
} from "../../feature/slice/CompanyDetailsSlice";
import { isAadhar, isEmail, isMobileNumber, isPAN } from "../../utils/helpers";
import { FaArrowLeft } from "react-icons/fa";
import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid"; // Import v4 function as uuidv4

const getUnitConditionListingEle = () => {
    let data = [
        {
            label: "Bareshell",
            key: "Bareshell",
        },
        {
            label: "Warmshell",
            key: "Warmshell",
        },
    ];
    return (
        <>
            {data?.map((item) => (
                <Select.Option value={item?.label} key={item?.key}>
                    {item?.label}
                </Select.Option>
            ))}
        </>
    );
};

export const amenitiesLists = [
    {
        label: "Printing Facility",
        key: "Printing Facility",
        value: "Printing Facility",
    },
    {
        label: "Passenger Lifts",
        key: "Passenger Lifts",
        value: "Passenger Lifts",
    },
    {
        label: "24/7 Office Access",
        key: "24/7 Office Access",
        value: "24/7 Office Access",
    },
    {
        label: "Cafeteria",
        key: "Cafeteria",
        value: "Cafeteria",
    },
    {
        label: "Server Room",
        key: "Server Room",
        value: "Server Room",
    },
    {
        label: "Window Coverings",
        key: "Window Coverings",
        value: "Window Coverings",
    },
    {
        label: "Support Staff",
        key: "Support Staff",
        value: "Support Staff",
    },
    {
        label: "Managed Reception",
        key: "Managed Reception",
        value: "Managed Reception",
    },
    {
        label: "Prime Locations",
        key: "Prime Locations",
        value: "Prime Locations",
    },
    {
        label: "WIFI",
        key: "WIFI",
        value: "WIFI",
    },
    {
        label: "Drinking Water",
        key: "Drinking Water",
        value: "Drinking Water",
    },
    {
        label: "Cubicle",
        key: "Cubicle",
        value: "Cubicle",
    },
    {
        label: "Conference Room",
        key: "Conference Room",
        value: "Conference Room",
    },
    {
        label: "Beverages",
        key: "Beverages",
        value: "Beverages",
    },
    {
        label: "CCTV Surveillance",
        key: "CCTV Surveillance",
        value: "CCTV Surveillance",
    },
    {
        label: "Car Parking",
        key: "Car Parking",
        value: "Car Parking",
    },
    {
        label: "Bike Parking",
        key: "Bike Parking",
        value: "Bike Parking",
    },
    {
        label: "Meeting Room",
        key: "Meeting Room",
        value: "Meeting Room",
    },
    {
        label: "Air Conditioning",
        key: "Air Conditioning",
        value: "Air Conditioning",
    },
    {
        label: "Gym",
        key: "Gym",
        value: "Gym",
    },
    {
        label: "Parking",
        key: "Parking",
        value: "Parking",
    },
    {
        label: "Swimming pools",
        key: "Swimming pools",
        value: "Swimming pools",
    },
    {
        label: "Spa",
        key: "Spa",
        value: "Spa",
    },
    {
        label: "Billiards Table",
        key: "Billiards Table",
        value: "Billiards Table",
    },
    {
        label: "Kid's Play Area",
        key: "Kid's Play Area",
        value: "Kid's Play Area",
    },
    {
        label: "CCTV",
        key: "CCTV",
        value: "CCTV",
    },
    {
        label: "Clubhouse",
        key: "Clubhouse",
        value: "Clubhouse",
    },
    {
        label: "Social area or rooftop garden",
        key: "Social area or rooftop garden",
        value: "Social area or rooftop garden",
    },
    {
        label: "Balcony",
        key: "Balcony",
        value: "Balcony",
    },
    {
        label: "Smart home",
        key: "Smart home",
        value: "Smart home",
    },
    {
        label: "Security systems and intercom",
        key: "Security systems and intercom",
        value: "Security systems and intercom",
    },
    {
        label: "Bathtubs",
        key: "Bathtubs",
        value: "Bathtubs",
    },
    {
        label: "Community Garden",
        key: "Community Garden",
        value: "Community Garden",
    },
    {
        label: "Games Room",
        key: "Games Room",
        value: "Games Room",
    },
    {
        label: "Snooze Room",
        key: "Snooze Room",
        value: "Snooze Room",
    },
    {
        label: "EV Charger",
        key: "EV Charger",
        value: "EV Charger",
    },
    {
        label: "Pet Friendly",
        key: "Pet Friendly",
        value: "Pet Friendly",
    },
    {
        label: "Green Building/Sustainability",
        key: "Green Building/Sustainability",
        value: "Green Building/Sustainability",
    },
    {
        label: "Building Cafeteria",
        key: "Building Cafeteria",
        value: "Building Cafeteria",
    },
    {
        label: "Valet",
        key: "Valet",
        value: "Valet",
    },
    {
        label: "Retail",
        key: "Retail",
        value: "Retail",
    },
    {
        label: "Cafe",
        key: "Cafe",
        value: "Cafe",
    },
    {
        label: "Natural Lighting",
        key: "Natural Lighting",
        value: "Natural Lighting",
    },
    {
        label: "Garden",
        key: "Garden",
        value: "Garden",
    },
    {
        label: "Playground",
        key: "Playground",
        value: "Playground",
    },
];

const getAmenitiesListingEle = () => {
    return (
        <>
            {amenitiesLists?.map((item) => (
                <Select.Option value={item?.label} key={item?.key}>
                    {item?.label}
                </Select.Option>
            ))}
        </>
    );
};

const getUnitStatusListingEle = () => {
    let data = [
        {
            label: "Occupied",
            key: "Occupied",
        },
        {
            label: "Vacant",
            key: "Vacant",
        },
    ];
    return (
        <>
            {data?.map((item) => (
                <Select.Option value={item?.label} key={item?.key}>
                    {item?.label}
                </Select.Option>
            ))}
        </>
    );
};

const getRightOfFirstRefusalListingEle = () => {
    let data = [
        {
            label: "NA",
            key: "NA",
            value: "NA",
        },
        {
            label: "Yes",
            key: "Yes",
            value: "Yes",
        },
    ];
    return (
        <>
            {data?.map((item) => (
                <Select.Option value={item?.label} key={item?.key}>
                    {item?.label}
                </Select.Option>
            ))}
        </>
    );
};

function VacantDetailsCard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [showPartiesInfoBuyerForm, setShowPartiesInfoBuyerForm] =
        useState(false);
    const [showPartiesInfoSellerForm, setShowPartiesInfoSellerForm] =
        useState(false);
    const [showPartiesInfoBuyerRadioGroup, setShowPartiesInfoBuyerRadioGroup] =
        useState(false);
    const [showPartiesInfoSellerRadioGroup, setShowPartiesInfoSellerRadioGroup] =
        useState(false);
    const [buyerType, setBuyerType] = useState(null);
    const [sellerType, setSellerType] = useState(null);
    const [formTransactionData, setFormTransactionData] = useState({});
    const [partiesSaveBuyerInfo, setPartiesSaveBuyerInfo] = useState({});
    const [partiesSaveSellerInfo, setPartiesSaveSellerInfo] = useState({});
    const [savedBuyersList, setSavedBuyersList] = useState(null);
    const [savedSellersList, setSavedSellersList] = useState(null);
    const [savedBuyersCnt, setSavedBuyersCnt] = useState(0);
    const [savedSellersCnt, setSavedSellersCnt] = useState(0);
    const [showBuyerPartiesInfoKey, setShowBuyerPartiesInfoKey] = useState(0);
    const [showSellerPartiesInfoKey, setShowSellerPartiesInfoKey] = useState(0);
    const [isPartiesBuyerInfoEditable, setIsPartiesBuyerInfoEditable] =
        useState(null);
    const [isPartiesSellerInfoEditable, setIsPartiesSellerInfoEditable] =
        useState(null);
    const { TabPane } = Tabs;

    const {
        isTransactionInfoEditable,
        transactionData,
        documentMaster,
        companies,
        documentNameMaster,
    } = useSelector((store) => store.transactionDetails);
    const { propertyId } = useSelector((store) => store.propertyDetails);
    const { navigatedFrom } = useSelector((store) => store.companyDetails);
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [deletedFileList, setDeletedFileList] = useState([]);

    const getDocumentMasterListingEle = () => {
        if (!documentNameMaster) return [];

        let data = [...documentNameMaster];
        if (formTransactionData?.transactionInformation?.unitStatus === 'Vacant') {
            data = data.filter(
                (item) => item.label === 'To Sale' || item.label === 'To Rent'
            );
        }
        return (
            <>
                {data?.map((item) => (
                    <Select.Option value={item?.label} key={item?.key}>
                        {item?.label}
                    </Select.Option>
                ))}
            </>
        );
    };

    useEffect(() => {
        form.setFieldsValue({
            buildingId: formTransactionData?.buildingInformation?.propertyId,
            buildingStructure: formTransactionData?.buildingInformation?.buildingStructure,
            buildingType: formTransactionData?.buildingInformation?.buildingType,
            street: formTransactionData?.buildingInformation?.street,
            unitStatus: formTransactionData?.transactionInformation?.unitStatus || "Vacant",
            documentType: formTransactionData?.agreement_information?.documentType,
            block: formTransactionData?.buildingInformation?.block,
            city: formTransactionData?.buildingInformation?.city,
            coordinates: formTransactionData?.buildingInformation?.coordinates?.latitude,
            buildingName: formTransactionData?.buildingInformation?.buildingName,
            buildingStatus: formTransactionData?.buildingInformation?.buildingStatus,
            plot: formTransactionData?.buildingInformation?.plot,
            state: formTransactionData?.buildingInformation?.state,
            pincode: formTransactionData?.buildingInformation?.pincode,
            microMarket: formTransactionData?.buildingInformation?.microMarket,
            longitude: formTransactionData?.buildingInformation?.coordinates?.longitude,
            developer: formTransactionData?.buildingInformation?.developer,
            complete_address: formTransactionData?.buildingInformation?.complete_address,
            state: formTransactionData?.buildingInformation?.state,
            locality: formTransactionData?.buildingInformation?.locality,
        });
    }, [formTransactionData, form]);

    useEffect(() => {
        const prevFormTransactionData = formTransactionData;
        setFormTransactionData({ ...prevFormTransactionData, ...transactionData });
        dispatch(getCompanyWithSearchableText(""));
    }, [transactionData]);

    useEffect(() => {
        let propertyImages = [];
        if (transactionData?.propertyImages) {
            propertyImages = [
                ...transactionData?.propertyImages
            ];
        }
        setFileList(propertyImages);
    }, [transactionData?.propertyImages]);

    const handleImageClick = (file) => {
        if (isTransactionInfoEditable) {
            if (selectedImage === file) {
                setSelectedImage(null); // Deselect the image if it's already selected
                // Deselect the profile picture checkbox
            } else {
                setSelectedImage(file); // Select the new image

            }
        }
    };
    const handleDeleteImage = (fileToDelete) => {
        if (isTransactionInfoEditable) {
            // Find the index of the file to delete based on a unique identifier (e.g., id or another unique property)
            const indexToDelete = fileList.findIndex(
                (file) => file.uid === fileToDelete.uid
            );

            const deletedFiles = [...deletedFileList, fileList[indexToDelete]?.url];

            setDeletedFileList(deletedFiles);

            if (indexToDelete !== -1) {
                // Create a new array without the deleted file
                const newFileList = fileList.filter(
                    (_, index) => index !== indexToDelete
                );

                // Update state with the new file list
                setFileList(newFileList);
                if (
                    (newFileList && newFileList?.length === 0) ||
                    selectedImage?.uid === fileToDelete?.uid
                )
                    setSelectedImage(null);

                // Dispatch an action to update Redux with the new file list
                dispatch(
                    setTransactionInfo({
                        name: "propertyImages",
                        value: newFileList,
                    })
                );
            }
        }
    };

    const props = {
        onRemove: handleDeleteImage,
        beforeUpload: (file) => {
            const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
            const isAllowed = allowedTypes.includes(file.type);
            if (!isAllowed) {
                message.error("You can only upload JPG, JPEG, or PNG files!");
                return false;
            }
            const uuid = uuidv4(); // Generate UUID for the file
            file.uid = uuid; // Assign UUID as unique identifier for Ant Design Upload
            file.name = uuid; // Append UUID to the file name
            setFileList([...fileList, file]);
            return file;
        },
        onChange: (info) => {
            const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
            const isAllowed = allowedTypes.includes(info.file.type);
            if (!isAllowed) return;
            const currFile = info.fileList.filter(
                (file) => file.uid === info.file.uid
            );
            dispatch(
                setTransactionInfo({
                    name: "propertyImages",
                    value: [...(transactionData?.propertyImages || []), currFile[0]],
                })
            );
        },
    };

    const getCompanyListingEle = () => {
        if (!companies) return [];

        let data = [...companies];
        return (
            <>
                {data?.map((item) => (
                    <Select.Option value={item?.label} key={item?.key}>
                        {item?.label}
                    </Select.Option>
                ))}
            </>
        );
    };

    const handleSearchDropdownOptions = (text) => {
        if (!text) return;
        dispatch(getCompanyWithSearchableText(text));
    };

    const agreementInfoFormElementsCol1 = [
        {
            type: "inputNumber",
            label: "Document No.",
            name: "documentNo",
            placeholder: "Document No.",
            disabled: !isTransactionInfoEditable,
            value: formTransactionData?.agreement_information?.documentNo,
        },
        {
            type: "datepicker",
            label: "Registration Date",
            required: true,
            name: "registrationDate",
            placeholder: "DD-MM-YYYY",
            disabled: !isTransactionInfoEditable,
            value: formTransactionData?.transactionInformation?.registrationDate
                ? dayjs(formTransactionData?.transactionInformation?.registrationDate)
                : "",
            defaultValue: formTransactionData?.transactionInformation?.registrationDate
                ? dayjs(formTransactionData?.transactionInformation?.registrationDate)
                : "",
        },
        {
            type: "input",
            label: "SRO Name",
            name: "sroName",
            placeholder: "SRO Name",
            disabled: !isTransactionInfoEditable,
            value: formTransactionData?.agreement_information?.sroName,
        },
    ];
    const agreementInfoFormElementsCol2 = [
        {
            type: "dropdown",
            label: "Document Name",
            required: true,
            name: "documentName",
            placeholder: "Document Name",
            children: getDocumentMasterListingEle(),
            disabled: !isTransactionInfoEditable,
            value: formTransactionData?.agreement_information?.documentName,
        },
        {
            type: "datepicker",
            label: "Document Submission Date",
            name: "documentSubmissionDate",
            placeholder: "DD-MM-YYYY",
            disabled: !isTransactionInfoEditable,
            value: formTransactionData?.agreement_information?.documentSubmissionDate
                ? dayjs(
                    formTransactionData?.agreement_information?.documentSubmissionDate
                )
                : "",
            defaultValue: formTransactionData?.agreement_information
                ?.documentSubmissionDate
                ? dayjs(
                    formTransactionData?.agreement_information?.documentSubmissionDate
                )
                : "",
        },
        {
            type: "input",
            label: "Sub Division House No",
            name: "subdivisionHouseNo",
            placeholder: "Sub Division House No",
            disabled: !isTransactionInfoEditable,
            value: formTransactionData?.agreement_information?.subdivisionHouseNo,
        },
    ];

    const transInfoFormElementsCol1 = [
        {
            type: "input",
            label: "Unit No.",
            name: "unitNo",
            placeholder: "Unit No.",
            disabled: !isTransactionInfoEditable,
            value: formTransactionData?.transactionInformation?.unitNo,
        },
        {
            type: "dropdown",
            label: "Unit Status",
            name: "unitStatus",
            placeholder: "Select Unit Status",
            children: getUnitStatusListingEle(),
            disabled: !isTransactionInfoEditable,
            value: formTransactionData?.transactionInformation?.unitStatus,
        },
        {
            type: "dropdown",
            label: "Available for",
            name: "documentType",
            placeholder: "Available for",
            value: formTransactionData?.transactionInformation?.documentType,
            children: [
                { label: "To Sale", value: "Sale" },
                { label: "To Rent", value: "Rent" },
            ].map((item) => (
                <Select.Option key={item.value} value={item.value}>
                    {item.label}
                </Select.Option>
            )),
            onChange: (value) => {
                // Update documentType in formTransactionData
                setFormTransactionData((prevData) => ({
                    ...prevData,
                    agreement_information: {
                        ...prevData.agreement_information,
                        documentType: value,
                    },
                }));
            },
        },

        {
            type: "datepicker",
            label: "Registration Date",
            required: true,
            name: "registrationDate",
            placeholder: "DD-MM-YYYY",
            disabled: !isTransactionInfoEditable,
            value: formTransactionData?.agreement_information?.registrationDate
                ? dayjs(formTransactionData?.agreement_information?.registrationDate)
                : "",
            defaultValue: formTransactionData?.transactionInformation?.registrationDate
                ? dayjs(formTransactionData?.transactionInformation?.registrationDate)
                : "",
        },

    ];
    const transInfoFormElementsCol2 = [
        {
            type: "input",
            label: "Floor No.",
            name: "floorNo",
            placeholder: "Floor No.",
            disabled: !isTransactionInfoEditable,
            value: formTransactionData?.transactionInformation?.floorNo,
        },

        {
            type: "inputNumber",
            label: "Min License Period",
            name: "licence_period",
            placeholder: "Min License Period",
            disabled: !isTransactionInfoEditable,
            value: formTransactionData?.transactionInformation?.licence_period,
        },
        {
            type: "inputNumber",
            label: "Expected Rent Rate (Sqft)",
            name: "rent_rate_sq_feet",
            placeholder: "Expected Rent Rate (Sqft)",
            value: formTransactionData?.transactionInformation?.rent_rate_sq_feet,
        },
        {
            type: "inputNumber",
            label: "Expected Sale Rate (Sqft)",
            name: "sale_rate_sq_feet",
            placeholder: "Expected Sale Rate (Sqft)",
            value: formTransactionData?.transactionInformation?.sale_rate_sq_feet,
        },
    ];
    const transInfoFormElementsCol3 = [
        {
            type: "dropdown",
            label: "Unit Condition",
            name: "unitcondition",
            placeholder: "Select Unit Condition",
            children: getUnitConditionListingEle(),
            disabled: !isTransactionInfoEditable,
            value: formTransactionData?.transactionInformation?.unitcondition,
        },

        {
            type: "inputNumber",
            label: "Approx Lock In Period",
            name: "lock_in_period",
            placeholder: "Approx Lock In Period",
            disabled: !isTransactionInfoEditable,
            value: formTransactionData?.transactionInformation?.lock_in_period,
        },
        {
            type: "inputNumber",
            label: "Chargeable Area (Sqft)",
            name: "chargeableSquareFeet",
            placeholder: "Chargeable Area (Sqft)",
            disabled: !isTransactionInfoEditable,
            value: formTransactionData?.transactionInformation?.chargeableSquareFeet,
        },

    ];
    const otherInfoFormElementsCol1 = [
        {
            type: "dropdown",
            mode: "multiple",
            label: "Amenities",
            name: "amenities",
            placeholder: "Select Amenities",
            children: getAmenitiesListingEle(),

            value: formTransactionData?.others
                ?.amenities,
        },

    ];
    const otherInfoFormElementsCol2 = [

        {
            type: "textArea",
            label: "Unit Description",
            name: "property_description",
            placeholder: "Unit Description",
            disabled: !isTransactionInfoEditable,
            value: formTransactionData?.others?.property_description,
            width: "500px",
        },
    ];
    const otherInfoFormElementsCol3 = [
        {},

    ];
    const partiesInfoFormElementsForCompanyBuyer = [
        {
            type: "dropdown",
            label: "Company Name",
            name: "companyName",
            placeholder: "Company Name",
            showSearch: true,
            disabled:
                isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
                isPartiesBuyerInfoEditable === null,
            value:
                formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
                    ?.companyName,
            children: getCompanyListingEle(),
            onSearch: handleSearchDropdownOptions,
            createOptionLabel: "Create Company",
            createRecord: () => {
                dispatch(setNavigatedFrom("Transaction"));
                dispatch(setCompanyId(null));
                navigate("/companyDetails");
            },
        },
        {
            type: "input",
            label: "Representative Person Name",
            name: "representativeName",
            placeholder: "Representative Person Name",
            disabled:
                isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
                isPartiesBuyerInfoEditable === null,
            value:
                formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
                    ?.representativeName,
        },
        {
            type: "input",
            label: "Representative Role",
            name: "representativeRole",
            placeholder: "Representative Role",
            disabled:
                isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
                isPartiesBuyerInfoEditable === null,
            value:
                formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
                    ?.representativeRole,
        },
        {
            type: "input",
            label: "Representative Aadhar",
            name: "representativeAadhar",
            placeholder: "Representative Aadhar",
            disabled:
                isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
                isPartiesBuyerInfoEditable === null,
            value:
                formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
                    ?.representativeAadhar,
        },
        {
            type: "input",
            label: "Representative PAN",
            name: "representativePan",
            placeholder: "Representative PAN",
            disabled:
                isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
                isPartiesBuyerInfoEditable === null,
            value:
                formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
                    ?.representativePan,
        },
        {
            type: "input",
            label: "Representative E-mail",
            name: "representativeEmail",
            placeholder: "Representative E-mail",
            disabled:
                isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
                isPartiesBuyerInfoEditable === null,
            value:
                formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
                    ?.representativeEmail,
        },
        {
            type: "input",
            label: "Representative Contact",
            name: "representativeContact",
            placeholder: "Representative Contact",
            disabled:
                isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
                isPartiesBuyerInfoEditable === null,
            value:
                formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
                    ?.representativeContact,
        },
    ];
    const partiesInfoFormElementsForIndividualBuyer = [
        {
            type: "input",
            label: "Name",
            name: "name",
            placeholder: "Name",
            disabled:
                isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
                isPartiesBuyerInfoEditable === null,
            value:
                formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]?.name,
        },
        {
            type: "input",
            label: "PAN",
            name: "pan",
            placeholder: "PAN",
            disabled:
                isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
                isPartiesBuyerInfoEditable === null,
            value:
                formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]?.pan,
        },
        {
            type: "input",
            label: "Aadhar",
            name: "aadhar",
            placeholder: "Aadhar",
            disabled:
                isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
                isPartiesBuyerInfoEditable === null,
            value:
                formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
                    ?.aadhar,
        },
        {
            type: "input",
            label: "Contact",
            name: "contact",
            placeholder: "Contact",
            disabled:
                isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
                isPartiesBuyerInfoEditable === null,
            value:
                formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
                    ?.contact,
        },
        {
            type: "input",
            label: "E-mail",
            name: "email",
            placeholder: "E-mail",
            disabled:
                isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
                isPartiesBuyerInfoEditable === null,
            value:
                formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
                    ?.email,
        },
    ];
    const partiesInfoFormElementsForCompanySeller = [
        {
            type: "dropdown",
            label: "Company Name",
            name: "companyName",
            placeholder: "Company Name",
            showSearch: true,
            disabled:
                isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
                isPartiesSellerInfoEditable === null,
            value:
                formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
                    ?.companyName,
            children: getCompanyListingEle(),
            onSearch: handleSearchDropdownOptions,
            createOptionLabel: "Create Company",
            createRecord: () => {
                dispatch(setNavigatedFrom("Transaction"));
                dispatch(setCompanyId(null));
                navigate("/companyDetails");
            },
        },
        {
            type: "input",
            label: "Representative Person Name",
            name: "representativeName",
            placeholder: "Representative Person Name",
            disabled:
                isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
                isPartiesSellerInfoEditable === null,
            value:
                formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
                    ?.representativeName,
        },
        {
            type: "input",
            label: "Representative Role",
            name: "representativeRole",
            placeholder: "Representative Role",
            disabled:
                isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
                isPartiesSellerInfoEditable === null,
            value:
                formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
                    ?.representativeRole,
        },
        {
            type: "input",
            label: "Representative Aadhar",
            name: "representativeAadhar",
            placeholder: "Representative Aadhar",
            disabled:
                isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
                isPartiesSellerInfoEditable === null,
            value:
                formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
                    ?.representativeAadhar,
        },
        {
            type: "input",
            label: "Representative PAN",
            name: "representativePan",
            placeholder: "Representative PAN",
            disabled:
                isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
                isPartiesSellerInfoEditable === null,
            value:
                formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
                    ?.representativePan,
        },
        {
            type: "input",
            label: "Representative E-mail",
            name: "representativeEmail",
            placeholder: "Representative E-mail",
            disabled:
                isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
                isPartiesSellerInfoEditable === null,
            value:
                formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
                    ?.representativeEmail,
        },
        {
            type: "input",
            label: "Representative Contact",
            name: "representativeContact",
            placeholder: "Representative Contact",
            disabled:
                isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
                isPartiesSellerInfoEditable === null,
            value:
                formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
                    ?.representativeContact,
        },
    ];
    const partiesInfoFormElementsForIndividualSeller = [
        {
            type: "input",
            label: "Name",
            name: "name",
            placeholder: "Name",
            disabled:
                isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
                isPartiesSellerInfoEditable === null,
            value:
                formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
                    ?.name,
        },
        {
            type: "input",
            label: "PAN",
            name: "pan",
            placeholder: "PAN",
            disabled:
                isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
                isPartiesSellerInfoEditable === null,
            value:
                formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
                    ?.pan,
        },
        {
            type: "input",
            label: "Aadhar",
            name: "aadhar",
            placeholder: "Aadhar",
            disabled:
                isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
                isPartiesSellerInfoEditable === null,
            value:
                formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
                    ?.aadhar,
        },
        {
            type: "input",
            label: "Contact",
            name: "contact",
            placeholder: "Contact",
            disabled:
                isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
                isPartiesSellerInfoEditable === null,
            value:
                formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
                    ?.contact,
        },
        {
            type: "input",
            label: "E-mail",
            name: "email",
            placeholder: "E-mail",
            disabled:
                isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
                isPartiesSellerInfoEditable === null,
            value:
                formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
                    ?.email,
        },
    ];
    const buildingInfoFormElementsCol1 = [
        {
            type: "input",
            label: "Property ID",
            name: "buildingId",
            placeholder: "Property ID",
            required: true,
            disabled: !isTransactionInfoEditable,
            value: formTransactionData?.buildingInformation?.propertyId,
            onKeyDown: async (event) => {
                if (event.key === "Enter") {
                    dispatch(getPropertyDetailsData(event.target.value));
                }
            }
        },

        {
            type: "input",
            label: "State",
            name: "state",
            placeholder: "State",
            disabled: true,
            value: formTransactionData?.buildingInformation?.state,
        },
        {
            type: "input",
            label: "Pin Code",
            name: "pincode",
            placeholder: "Pin Code",
            disabled: true,
            value: formTransactionData?.buildingInformation?.pincode,
        },
    ];
    const buildingInfoFormElementsCol2 = [
        {
            type: "input",
            label: "Property Name",
            name: "buildingName",
            placeholder: "Property Name",
            disabled: true,
            value: formTransactionData?.buildingInformation?.buildingName,
        },
        {
            type: "input",
            label: "City",
            name: "city",
            placeholder: "City",
            disabled: true,
            value: formTransactionData?.buildingInformation?.city,
        },
    ];
    const buildingInfoFormElementsCol3 = [
        {
            type: "input",
            label: "Developer",
            name: "developer",
            placeholder: "Developer",
            disabled: true,
            value: formTransactionData?.buildingInformation?.developer,
        },
        {
            type: "input",
            label: "Locality",
            name: "locality",
            placeholder: "Locality",
            disabled: true,
            value: formTransactionData?.buildingInformation?.locality,
        },
    ];

    useEffect(() => {
        if (id) {
            dispatch(getTransactionDetailsData(id));
            dispatch(setTransactionId(id));
            dispatch(setTransactionInfoEditable(false));
            setShowBuyerPartiesInfoKey(null);
            setShowSellerPartiesInfoKey(null);
        } else {
            if (navigatedFrom === "Transaction") {
                setFormTransactionData(transactionData);
                dispatch(setNavigatedFrom(null));
            } else {
                dispatch(getPropertyDetailsData(propertyId));
            }
            dispatch(setTransactionInfoEditable(true));
        }
        dispatch(getAllDocumentMaster());
    }, []);

    useEffect(() => {
        if (showBuyerPartiesInfoKey === null) {
            handleShowPartiesInfo(0, "buyerInfo");
        } else {
            handleShowPartiesInfo(showBuyerPartiesInfoKey, "buyerInfo");
        }
        const mappedData = formTransactionData?.buyerInfo?.buyers?.map(
            (item, index) => {
                const isEditable =
                    isPartiesBuyerInfoEditable === index && isTransactionInfoEditable;
                const label =
                    item?.buyerType === "Company" ? item?.companyName : item?.name;
                const uniqueSuffix = index; // Unique suffix based on index

                return {
                    key: index,
                    label: label,
                    children: (
                        <React.Fragment key={index}>
                            <div style={{ float: "right" }}>
                                {!isEditable && (
                                    <Button
                                        onClick={() => {
                                            setIsPartiesBuyerInfoEditable(index);
                                            setShowBuyerPartiesInfoKey(index);
                                        }}
                                        disabled={!isTransactionInfoEditable}
                                    >
                                        Edit
                                    </Button>
                                )}
                                &nbsp;&nbsp;
                                <Button
                                    onClick={() => handleDeletePartiesInfo("buyerInfo", index)}
                                    disabled={!isTransactionInfoEditable}
                                >
                                    Delete
                                </Button>
                            </div>
                            <div>
                                {item?.buyerType === "Company" ? (
                                    <Col span={6}>
                                        {partiesInfoFormElementsForCompanyBuyer.map(
                                            (element, i) => {
                                                return (
                                                    <FormElement
                                                        key={i}
                                                        {...element}
                                                        name={`buyers_${element.name}_${uniqueSuffix}`}
                                                        onChange={(e, obj) => {
                                                            const updatedBuyers = [
                                                                ...formTransactionData?.buyerInfo?.buyers,
                                                            ];
                                                            updatedBuyers[index] = {
                                                                ...updatedBuyers?.[index],
                                                                [element.name]:
                                                                    element.name === "companyName"
                                                                        ? e
                                                                        : e.target.value,
                                                                companyId: obj?.key
                                                                    ? obj?.key
                                                                    : updatedBuyers?.[index]?.companyId, // Assuming this is how you update companyId
                                                            };
                                                            dispatch(
                                                                setTransactionInfo({
                                                                    name: "buyerType",
                                                                    value: updatedBuyers,
                                                                })
                                                            );
                                                        }}
                                                    />
                                                );
                                            }
                                        )}
                                    </Col>
                                ) : (
                                    <Col span={6}>
                                        {partiesInfoFormElementsForIndividualBuyer.map(
                                            (element, i) => {
                                                return (
                                                    <FormElement
                                                        key={i}
                                                        {...element}
                                                        name={`buyers_${element.name}_${uniqueSuffix}`}
                                                        onChange={(e) => {
                                                            const updatedBuyers = [
                                                                ...formTransactionData?.buyerInfo?.buyers,
                                                            ];
                                                            updatedBuyers[index] = {
                                                                ...updatedBuyers[index],
                                                                [element.name]: e.target.value,
                                                            };
                                                            dispatch(
                                                                setTransactionInfo({
                                                                    name: "buyerType",
                                                                    value: updatedBuyers,
                                                                })
                                                            );
                                                        }}
                                                    />
                                                );
                                            }
                                        )}
                                    </Col>
                                )}
                            </div>
                        </React.Fragment>
                    ),
                };
            }
        );
        setSavedBuyersList(mappedData);
    }, [
        savedBuyersCnt,
        showBuyerPartiesInfoKey,
        isPartiesBuyerInfoEditable,
        formTransactionData,
        isTransactionInfoEditable,
        partiesSaveBuyerInfo,
        companies,
    ]);

    useEffect(() => {
        if (showSellerPartiesInfoKey === null) {
            handleShowPartiesInfo(0, "sellerInfo");
        } else {
            handleShowPartiesInfo(showSellerPartiesInfoKey, "sellerInfo");
        }
        const mappedData = formTransactionData?.sellerInfo?.sellers?.map(
            (item, index) => {
                const isEditable =
                    isPartiesSellerInfoEditable === index && isTransactionInfoEditable;
                const label =
                    item?.sellerType === "Company" ? item?.companyName : item?.name;
                const uniqueSuffix = index; // Unique suffix based on index

                return {
                    key: index,
                    label: label,
                    children: (
                        <React.Fragment key={index}>
                            <div style={{ float: "right" }}>
                                {!isEditable && (
                                    <Button
                                        onClick={() => {
                                            setIsPartiesSellerInfoEditable(index);
                                            setShowSellerPartiesInfoKey(index);
                                        }}
                                        disabled={!isTransactionInfoEditable}
                                    >
                                        Edit
                                    </Button>
                                )}
                                &nbsp;&nbsp;
                                <Button
                                    onClick={() => handleDeletePartiesInfo("sellerInfo", index)}
                                    disabled={!isTransactionInfoEditable}
                                >
                                    Delete
                                </Button>
                            </div>
                            {item?.sellerType === "Company" ? (
                                <Col span={6}>
                                    {partiesInfoFormElementsForCompanySeller.map((element, i) => (
                                        <FormElement
                                            key={i}
                                            {...element}
                                            name={`sellers_${element.name}_${uniqueSuffix}`}
                                            onChange={(e, obj) => {
                                                const updatedSellers = [
                                                    ...formTransactionData?.sellerInfo?.sellers,
                                                ];
                                                updatedSellers[index] = {
                                                    ...updatedSellers[index],
                                                    [element.name]:
                                                        element.name === "companyName" ? e : e.target.value,
                                                    companyId: obj?.key
                                                        ? obj?.key
                                                        : updatedSellers?.[index]?.companyId, // Assuming this is how you update companyId
                                                };
                                                dispatch(
                                                    setTransactionInfo({
                                                        name: "sellerType",
                                                        value: updatedSellers,
                                                    })
                                                );
                                            }}
                                        />
                                    ))}
                                </Col>
                            ) : (
                                <Col span={6}>
                                    {partiesInfoFormElementsForIndividualSeller.map(
                                        (element, i) => (
                                            <FormElement
                                                key={i}
                                                {...element}
                                                name={`sellers_${element.name}_${uniqueSuffix}`}
                                                onChange={(e) => {
                                                    const updatedSellers = [
                                                        ...formTransactionData?.sellerInfo?.sellers,
                                                    ];
                                                    updatedSellers[index] = {
                                                        ...updatedSellers[index],
                                                        [element.name]: e.target.value,
                                                    };
                                                    dispatch(
                                                        setTransactionInfo({
                                                            name: "sellerType",
                                                            value: updatedSellers,
                                                        })
                                                    );
                                                }}
                                            />
                                        )
                                    )}
                                </Col>
                            )}
                        </React.Fragment>
                    ),
                };
            }
        );
        setSavedSellersList(mappedData);
    }, [
        savedSellersCnt,
        showSellerPartiesInfoKey,
        isPartiesSellerInfoEditable,
        formTransactionData,
        isTransactionInfoEditable,
        partiesSaveSellerInfo,
        companies,
    ]);

    const validatePartiesInfo = (obj, type) => {
        const isCompany =
            obj?.[`${type === "buyerInfo" ? "buyer" : "seller"}Type`] === "Company";

        if (type === "buyerInfo" || type === "sellerInfo") {
            if (isEmpty(obj?.[`${type === "buyerInfo" ? "buyer" : "seller"}Type`]))
                return false;

            if (isCompany) {
                if (obj?.representativeAadhar && !isAadhar(obj?.representativeAadhar)) {
                    partiesInfoAadharValidationMessage();
                    return false;
                }
                if (obj?.representativePan && !isPAN(obj?.representativePan)) {
                    partiesInfoPANValidationMessage();
                    return false;
                }
                if (obj?.representativeEmail && !isEmail(obj?.representativeEmail)) {
                    partiesInfoEmailValidationMessage();
                    return false;
                }
                if (
                    !isEmpty(obj?.representativeContact) &&
                    !isMobileNumber(obj?.representativeContact)
                ) {
                    partiesInfoContactValidationMessage();
                    return false;
                }
                if (isEmpty(obj?.companyName)) {
                    partiesInfoValidationMessage();
                    return false;
                }
            } else {
                // Individual
                if (obj?.aadhar && !isAadhar(obj?.aadhar)) {
                    partiesInfoAadharValidationMessage();
                    return false;
                }
                if (obj?.pan && !isPAN(obj?.pan)) {
                    partiesInfoPANValidationMessage();
                    return false;
                }
                if (!isEmpty(obj?.email) && !isEmail(obj?.email)) {
                    partiesInfoEmailValidationMessage();
                    return false;
                }
                if (!isEmpty(obj?.contact) && !isMobileNumber(obj?.contact)) {
                    partiesInfoContactValidationMessage();
                    return false;
                }
                if (isEmpty(obj?.name)) {
                    partiesInfoValidationMessage();
                    return false;
                }
            }
        }

        return true;
    };

    const validateTransactionInfo = () => {
        if (
            isEmpty(formTransactionData?.buildingInformation?.propertyId)
        ) {
            transactionInfoValidationMessage();
            return false;
        }
        return true;
    };

    const handleAddPartiesInfo = (type, index) => {
        if (type === "buyerInfo") setShowPartiesInfoBuyerRadioGroup(true);
        else setShowPartiesInfoSellerRadioGroup(true);
    };

    const handleDeletePartiesInfo = (type, index) => {
        if (type === "buyerInfo") {
            let partiesBuyerInfo = [...formTransactionData?.buyerInfo?.buyers];
            partiesBuyerInfo?.splice(index, 1);
            dispatch(
                setTransactionInfo({
                    name: "buyerType",
                    value: partiesBuyerInfo,
                })
            );
            setSavedBuyersCnt(savedBuyersCnt - 1);
            setShowPartiesInfoBuyerForm(false);
            setShowPartiesInfoBuyerRadioGroup(false);
            setPartiesSaveBuyerInfo({});
            setBuyerType(null);
        } else if (type === "sellerInfo") {
            let partiesSellerInfo = [...formTransactionData?.sellerInfo?.sellers];
            partiesSellerInfo?.splice(index, 1);
            dispatch(
                setTransactionInfo({
                    name: "sellerType",
                    value: partiesSellerInfo,
                })
            );
            setSavedSellersCnt(savedSellersCnt - 1);
            setShowPartiesInfoSellerForm(false);
            setShowPartiesInfoSellerRadioGroup(false);
            setPartiesSaveSellerInfo({});
            setSellerType(null);
        }
    };

    const handleSaveBuyerPartiesInfo = () => {

        partiesSaveBuyerInfo.representativePan && (partiesSaveBuyerInfo.representativePan = partiesSaveBuyerInfo.representativePan.toUpperCase());
        partiesSaveBuyerInfo.pan && (partiesSaveBuyerInfo.pan = partiesSaveBuyerInfo.pan.toUpperCase());
        if (validatePartiesInfo(partiesSaveBuyerInfo, "buyerInfo")) {
            dispatch(
                setTransactionInfo({
                    name: "buyerType",
                    value: formTransactionData?.buyerInfo?.buyers
                        ? formTransactionData?.buyerInfo?.buyers.concat(
                            partiesSaveBuyerInfo
                        )
                        : [partiesSaveBuyerInfo],
                })
            );
            setSavedBuyersCnt(savedBuyersCnt + 1);
            setShowPartiesInfoBuyerForm(false);
            setShowPartiesInfoBuyerRadioGroup(false);
            setPartiesSaveBuyerInfo({});
            setBuyerType(null);
            setShowBuyerPartiesInfoKey(null);

            // Extract fields to set in the form using destructuring
            const {
                companyName,
                representativeName,
                representativeAadhar,
                representativePan,
                representativeContact,
                representativeEmail,
                representativeRole,
                name,
                email,
                pan,
                aadhar,
                contact,
            } = partiesSaveBuyerInfo;

            // Generate unique field name suffix based on some identifier (e.g., index)
            const uniqueSuffix = savedBuyersCnt; // Use appropriate unique identifier here

            // Update form fields with the saved buyer info using unique field names
            form.setFieldsValue({
                [`buyers_companyName_${uniqueSuffix}`]: companyName,
                [`buyers_representativeName_${uniqueSuffix}`]: representativeName,
                [`buyers_representativeAadhar_${uniqueSuffix}`]: representativeAadhar,
                [`buyers_representativePan_${uniqueSuffix}`]: representativePan,
                [`buyers_representativeContact_${uniqueSuffix}`]: representativeContact,
                [`buyers_representativeEmail_${uniqueSuffix}`]: representativeEmail,
                [`buyers_representativeRole_${uniqueSuffix}`]: representativeRole,
                [`buyers_name_${uniqueSuffix}`]: name,
                [`buyers_email_${uniqueSuffix}`]: email,
                [`buyers_pan_${uniqueSuffix}`]: pan,
                [`buyers_aadhar_${uniqueSuffix}`]: aadhar,
                [`buyers_contact_${uniqueSuffix}`]: contact,
            });
        }
    };

    const handleSaveSellerPartiesInfo = () => {
        partiesSaveSellerInfo.representativePan && (partiesSaveSellerInfo.representativePan = partiesSaveSellerInfo.representativePan.toUpperCase());
        partiesSaveSellerInfo.pan && (partiesSaveSellerInfo.pan = partiesSaveSellerInfo.pan.toUpperCase());
        if (validatePartiesInfo(partiesSaveSellerInfo, "sellerInfo")) {
            dispatch(
                setTransactionInfo({
                    name: "sellerType",
                    value: formTransactionData?.sellerInfo?.sellers
                        ? formTransactionData?.sellerInfo?.sellers.concat(
                            partiesSaveSellerInfo
                        )
                        : [partiesSaveSellerInfo],
                })
            );
            setSavedSellersCnt(savedSellersCnt + 1);
            setShowPartiesInfoSellerForm(false);
            setShowPartiesInfoSellerRadioGroup(false);
            setPartiesSaveSellerInfo({});
            setSellerType(null);
            setShowSellerPartiesInfoKey(null);
            // Extract fields to set in the form using destructuring
            const {
                companyName,
                representativeName,
                representativeAadhar,
                representativePan,
                representativeContact,
                representativeEmail,
                representativeRole,
                name,
                email,
                pan,
                aadhar,
                contact,
            } = partiesSaveSellerInfo;

            // Generate unique field name suffix based on some identifier (e.g., index)
            const uniqueSuffix = savedSellersCnt; // Use appropriate unique identifier here

            // Update form fields with the saved buyer info using unique field names
            form.setFieldsValue({
                [`sellers_companyName_${uniqueSuffix}`]: companyName,
                [`sellers_representativeName_${uniqueSuffix}`]: representativeName,
                [`sellers_representativeAadhar_${uniqueSuffix}`]: representativeAadhar,
                [`sellers_representativePan_${uniqueSuffix}`]: representativePan,
                [`sellers_representativeContact_${uniqueSuffix}`]:
                    representativeContact,
                [`sellers_representativeEmail_${uniqueSuffix}`]: representativeEmail,
                [`sellers_representativeRole_${uniqueSuffix}`]: representativeRole,
                [`sellers_name_${uniqueSuffix}`]: name,
                [`sellers_email_${uniqueSuffix}`]: email,
                [`sellers_pan_${uniqueSuffix}`]: pan,
                [`sellers_aadhar_${uniqueSuffix}`]: aadhar,
                [`sellers_contact_${uniqueSuffix}`]: contact,
            });
        }
    };

    const partiesInfoValidationMessage = () => {
        messageApi.open({
            type: "warning",
            content:
                "Please fill in all required fields in Parties Information Section",
        });
    };

    const partiesInfoAadharValidationMessage = () => {
        messageApi.open({
            type: "warning",
            content: "Please input valid aadhar number",
        });
    };

    const partiesInfoPANValidationMessage = () => {
        messageApi.open({
            type: "warning",
            content: "Please input valid PAN number",
        });
    };

    const partiesInfoEmailValidationMessage = () => {
        messageApi.open({
            type: "warning",
            content: "Please input valid Email address",
        });
    };

    const partiesInfoContactValidationMessage = () => {
        messageApi.open({
            type: "warning",
            content: "Please input valid Contact number",
        });
    };

    const handleShowPartiesInfo = (key, type) => {
        const suffix = key !== undefined ? `_${key}` : ""; // Unique suffix logic

        if (key === undefined && type === "buyerInfo") {
            setShowBuyerPartiesInfoKey(0);
            setIsPartiesBuyerInfoEditable(null);
            form.setFieldsValue({
                ...form,
                [`buyers_companyName${suffix}`]:
                    formTransactionData?.buyerInfo?.buyers?.[0]?.companyName,
                [`buyers_representativeName${suffix}`]:
                    formTransactionData?.buyerInfo?.buyers?.[0]?.representativeName,
                [`buyers_representativeAadhar${suffix}`]:
                    formTransactionData?.buyerInfo?.buyers?.[0]?.representativeAadhar,
                [`buyers_representativePan${suffix}`]:
                    formTransactionData?.buyerInfo?.buyers?.[0]?.representativePan,
                [`buyers_representativeContact${suffix}`]:
                    formTransactionData?.buyerInfo?.buyers?.[0]?.representativeContact,
                [`buyers_representativeEmail${suffix}`]:
                    formTransactionData?.buyerInfo?.buyers?.[0]?.representativeEmail,
                [`buyers_representativeRole${suffix}`]:
                    formTransactionData?.buyerInfo?.buyers?.[0]?.representativeRole,
                [`buyers_name${suffix}`]:
                    formTransactionData?.buyerInfo?.buyers?.[0]?.name,
                [`buyers_email${suffix}`]:
                    formTransactionData?.buyerInfo?.buyers?.[0]?.email,
                [`buyers_pan${suffix}`]:
                    formTransactionData?.buyerInfo?.buyers?.[0]?.pan,
                [`buyers_aadhar${suffix}`]:
                    formTransactionData?.buyerInfo?.buyers?.[0]?.aadhar,
                [`buyers_contact${suffix}`]:
                    formTransactionData?.buyerInfo?.buyers?.[0]?.contact,
            });
        } else if (key === undefined && type === "sellerInfo") {
            setShowSellerPartiesInfoKey(0);
            setIsPartiesSellerInfoEditable(null);
            form.setFieldsValue({
                ...form,
                [`sellers_companyName${suffix}`]:
                    formTransactionData?.sellerInfo?.sellers?.[0]?.companyName,
                [`sellers_representativeName${suffix}`]:
                    formTransactionData?.sellerInfo?.sellers?.[0]?.representativeName,
                [`sellers_representativeAadhar${suffix}`]:
                    formTransactionData?.sellerInfo?.sellers?.[0]?.representativeAadhar,
                [`sellers_representativePan${suffix}`]:
                    formTransactionData?.sellerInfo?.sellers?.[0]?.representativePan,
                [`sellers_representativeContact${suffix}`]:
                    formTransactionData?.sellerInfo?.sellers?.[0]?.representativeContact,
                [`sellers_representativeEmail${suffix}`]:
                    formTransactionData?.sellerInfo?.sellers?.[0]?.representativeEmail,
                [`sellers_representativeRole${suffix}`]:
                    formTransactionData?.sellerInfo?.sellers?.[0]?.representativeRole,
                [`sellers_name${suffix}`]:
                    formTransactionData?.sellerInfo?.sellers?.[0]?.name,
                [`sellers_email${suffix}`]:
                    formTransactionData?.sellerInfo?.sellers?.[0]?.email,
                [`sellers_pan${suffix}`]:
                    formTransactionData?.sellerInfo?.sellers?.[0]?.pan,
                [`sellers_aadhar${suffix}`]:
                    formTransactionData?.sellerInfo?.sellers?.[0]?.aadhar,
                [`sellers_contact${suffix}`]:
                    formTransactionData?.sellerInfo?.sellers?.[0]?.contact,
            });
        } else if (key) {
            if (type === "buyerInfo") {
                setShowBuyerPartiesInfoKey(Number(key));
                form.setFieldsValue({
                    ...form,
                    [`buyers_companyName${suffix}`]:
                        formTransactionData?.buyerInfo?.buyers?.[Number(key)]?.companyName,
                    [`buyers_representativeName${suffix}`]:
                        formTransactionData?.buyerInfo?.buyers?.[Number(key)]
                            ?.representativeName,
                    [`buyers_representativeAadhar${suffix}`]:
                        formTransactionData?.buyerInfo?.buyers?.[Number(key)]
                            ?.representativeAadhar,
                    [`buyers_representativePan${suffix}`]:
                        formTransactionData?.buyerInfo?.buyers?.[Number(key)]
                            ?.representativePan,
                    [`buyers_representativeContact${suffix}`]:
                        formTransactionData?.buyerInfo?.buyers?.[Number(key)]
                            ?.representativeContact,
                    [`buyers_representativeEmail${suffix}`]:
                        formTransactionData?.buyerInfo?.buyers?.[Number(key)]
                            ?.representativeEmail,
                    [`buyers_representativeRole${suffix}`]:
                        formTransactionData?.buyerInfo?.buyers?.[Number(key)]
                            ?.representativeRole,
                    [`buyers_name${suffix}`]:
                        formTransactionData?.buyerInfo?.buyers?.[Number(key)]?.name,
                    [`buyers_email${suffix}`]:
                        formTransactionData?.buyerInfo?.buyers?.[Number(key)]?.email,
                    [`buyers_pan${suffix}`]:
                        formTransactionData?.buyerInfo?.buyers?.[Number(key)]?.pan,
                    [`buyers_aadhar${suffix}`]:
                        formTransactionData?.buyerInfo?.buyers?.[Number(key)]?.aadhar,
                    [`buyers_contact${suffix}`]:
                        formTransactionData?.buyerInfo?.buyers?.[Number(key)]?.contact,
                });
            } else if (type === "sellerInfo") {
                setShowSellerPartiesInfoKey(Number(key));
                form.setFieldsValue({
                    ...form,
                    [`sellers_companyName${suffix}`]:
                        formTransactionData?.sellerInfo?.sellers?.[Number(key)]
                            ?.companyName,
                    [`sellers_representativeName${suffix}`]:
                        formTransactionData?.sellerInfo?.sellers?.[Number(key)]
                            ?.representativeName,
                    [`sellers_representativeAadhar${suffix}`]:
                        formTransactionData?.sellerInfo?.sellers?.[Number(key)]
                            ?.representativeAadhar,
                    [`sellers_representativePan${suffix}`]:
                        formTransactionData?.sellerInfo?.sellers?.[Number(key)]
                            ?.representativePan,
                    [`sellers_representativeContact${suffix}`]:
                        formTransactionData?.sellerInfo?.sellers?.[Number(key)]
                            ?.representativeContact,
                    [`sellers_representativeEmail${suffix}`]:
                        formTransactionData?.sellerInfo?.sellers?.[Number(key)]
                            ?.representativeEmail,
                    [`sellers_representativeRole${suffix}`]:
                        formTransactionData?.sellerInfo?.sellers?.[Number(key)]
                            ?.representativeRole,
                    [`sellers_name${suffix}`]:
                        formTransactionData?.sellerInfo?.sellers?.[Number(key)]?.name,
                    [`sellers_email${suffix}`]:
                        formTransactionData?.sellerInfo?.sellers?.[Number(key)]?.email,
                    [`sellers_pan${suffix}`]:
                        formTransactionData?.sellerInfo?.sellers?.[Number(key)]?.pan,
                    [`sellers_aadhar${suffix}`]:
                        formTransactionData?.sellerInfo?.sellers?.[Number(key)]?.aadhar,
                    [`sellers_contact${suffix}`]:
                        formTransactionData?.sellerInfo?.sellers?.[Number(key)]?.contact,
                });
            }
        }
    };

    const getPartiesInfoPayloadForBuyer = () => {
        let individual = formTransactionData?.buyerInfo?.buyers?.filter(
            (item) => item?.buyerType === "Individual"
        );
        let company = formTransactionData?.buyerInfo?.buyers?.filter(
            (item) => item?.buyerType === "Company"
        );
        let payloadObjIndividual = individual?.map((item) => {
            return {
                _id: item?._id,
                personName: item?.name,
                panNumber: item?.pan,
                aadharNumber: item?.aadhar,
                contactNumber: item?.contact,
                emailId: item?.email,
            };
        });
        let payloadObjCompany = company?.map((item) => {
            return {
                companyName: item?.companyName,
                companyId: item?.companyId,
                representative: [
                    {
                        _id: item?.representativeId,
                        personName: item?.representativeName,
                        panNumber: item?.representativePan,
                        aadharNumber: item?.representativeAadhar,
                        role: item?.representativeRole,
                        contactNumber: item?.representativeContact,
                        emailId: item?.representativeEmail,
                    },
                ],
            };
        });
        return {
            Individual: payloadObjIndividual,
            Company: payloadObjCompany,
        };
    };

    const getPartiesInfoPayloadForSeller = () => {
        let individual = formTransactionData?.sellerInfo?.sellers?.filter(
            (item) => item?.sellerType === "Individual"
        );
        let company = formTransactionData?.sellerInfo?.sellers?.filter(
            (item) => item?.sellerType === "Company"
        );
        let payloadObjIndividual = individual?.map((item) => {
            return {
                _id: item?._id,
                personName: item?.name,
                panNumber: item?.pan,
                aadharNumber: item?.aadhar,
                contactNumber: item?.contact,
                emailId: item?.email,
            };
        });
        let payloadObjCompany = company?.map((item) => {
            return {
                companyName: item?.companyName,
                companyId: item?.companyId,
                representative: [
                    {
                        _id: item?.representativeId,
                        personName: item?.representativeName,
                        panNumber: item?.representativePan,
                        aadharNumber: item?.representativeAadhar,
                        role: item?.representativeRole,
                        contactNumber: item?.representativeContact,
                        emailId: item?.representativeEmail,
                    },
                ],
            };
        });
        return {
            Individual: payloadObjIndividual,
            Company: payloadObjCompany,
        };
    };

    const getUpdateTransactionPayload = (transactionDetails) => {
        const formData = new FormData();
        if (
            transactionDetails?.propertyImages &&
            transactionDetails?.propertyImages?.length > 0
        ) {
            for (
                let index = 0;
                index < transactionDetails?.propertyImages.length;
                index++
            ) {
                if (transactionDetails?.propertyImages?.[index]?.originFileObj) {
                    formData.append(
                        "images",
                        transactionDetails?.propertyImages?.[index]?.originFileObj
                    );
                }
            }
        }

        transactionDetails?.transactionInformation?.registrationDate &&
            formData.append(
                "registrationDate",
                transactionDetails?.transactionInformation?.registrationDate
            );
        transactionDetails?.agreement_information?.documentSubmissionDate &&
            formData.append(
                "documentSubmissionDate",
                transactionDetails?.agreement_information?.documentSubmissionDate
            );
        transactionDetails?.agreement_information?.expiryDate &&
            formData.append(
                "expiryDate",
                transactionDetails?.agreement_information?.expiryDate
            );

        let filteredDeletedFiles = deletedFileList.filter(Boolean);

        const data = {
            propertyId: transactionDetails?.buildingInformation?.propertyId,
            buildingName: transactionDetails?.buildingInformation?.buildingName,
            developerName: transactionDetails?.buildingInformation?.developerName,
            buildingStructure: transactionDetails?.buildingInformation,
            buildingStatus: transactionDetails?.buildingInformation?.buildingStatus,
            complete_address: transactionDetails?.buildingInformation?.complete_address,
            buildingType: transactionDetails?.buildingInformation?.buildingType,
            plot: transactionDetails?.buildingInformation?.plot,
            state: transactionDetails?.buildingInformation?.state,
            street: transactionDetails?.buildingInformation?.street,
            pincode: transactionDetails?.buildingInformation?.pincode,
            locality: transactionDetails?.buildingInformation?.locality,
            block: transactionDetails?.buildingInformation?.block,
            microMarket: transactionDetails?.buildingInformation?.microMarket,
            city: transactionDetails?.buildingInformation?.city,
            documentNumber: transactionDetails?.agreement_information?.documentNo,
            documentName: transactionDetails?.agreement_information?.documentName,
            documentType: transactionDetails?.transactionInformation?.documentType,
            sroName: transactionDetails?.agreement_information?.sroName,
            subdivisionHouseNo:
                transactionDetails?.agreement_information?.subdivisionHouseNo,
            address: {
                unitNo: transactionDetails?.transactionInformation?.unitNo,
                floorNo: transactionDetails?.transactionInformation?.floorNo,
            },
            unitCondition: transactionDetails?.transactionInformation?.unitcondition,
            unitStatus: "Vacant",

            dhcFeesOrDocumentHandlingCharges:
                transactionDetails?.transactionInformation
                    ?.DHC_fees_or_document_handling_charges,
            registrationFees:
                transactionDetails?.transactionInformation?.registration_fees,
            securityDeposit:
                transactionDetails?.transactionInformation?.security_deposit,
            paymentOfFurnitureCharges:
                transactionDetails?.transactionInformation
                    ?.Payment_of_furniture_fixture_charges_rs,
            parkingInfoTwoWheeler:
                transactionDetails?.others?.parking_information_of_two_wheeler,
            parkingInfoFourWheeler:
                transactionDetails?.others?.parking_information_of_four_wheeler,
            propertyDescription: transactionDetails?.others?.property_description,
            otherDetails: transactionDetails?.others?.other,
            marketPrice: transactionDetails?.transactionInformation?.market_price,
            declaredCircleRate:
                transactionDetails?.transactionInformation?.declared_circle_rate,
            compensation: transactionDetails?.transactionInformation?.compensation,
            bharlelMudrankSulkOrStampDutyPaid:
                transactionDetails?.transactionInformation
                    ?.bharlele_mudrank_shulkh_or_Stamp_Duty_paid,
            licensePeriod: transactionDetails?.transactionInformation?.licence_period,
            lockinPeriod: transactionDetails?.transactionInformation?.lock_in_period,
            rentLicenseFee:
                transactionDetails?.transactionInformation?.rent_licence_fees,
            rentRate: transactionDetails?.transactionInformation?.rent_rate_sq_feet,
            amenities: transactionDetails?.others?.amenities,
            saleRate: transactionDetails?.transactionInformation?.sale_rate_sq_feet,
            saleValue: transactionDetails?.transactionInformation?.saleValue,
            shera: transactionDetails?.transactionInformation?.shera,
            fitoutPeriod: transactionDetails?.transactionInformation?.fitoutPeriod,
            noticePeriod: transactionDetails?.transactionInformation?.noticePeriod,
            levy: transactionDetails?.transactionInformation?.levy,
            currentRentRate:
                transactionDetails?.transactionInformation?.currentRentRate,
            carpetArea: transactionDetails?.transactionInformation?.carpetArea,
            chargableArea:
                transactionDetails?.transactionInformation?.chargeableSquareFeet,
            escalationInLicenseFees:
                transactionDetails?.transactionInformation
                    ?.escalation_in_licence_fees_percentage,
            cam: transactionDetails?.transactionInformation
                ?.CAM_Or_Common_Area_Maintenance,
            partiesInformation: {
                buyer: getPartiesInfoPayloadForBuyer(),
                seller: getPartiesInfoPayloadForSeller(),
            },
            legacyPartiesInformation: {
                allBuyerName:
                    transactionDetails?.legacy_propertiesInformation?.buyers?.[0]
                        ?.personName,
                allSellerName:
                    transactionDetails?.legacy_propertiesInformation?.sellers?.[0]
                        ?.personName,
            },
            rightOfRefusalFloors:
                transactionDetails?.others?.rightOfFirstRefusalFloors,
            rightOfRefusalActive:
                transactionDetails?.others?.rightOfFirstRefusalActive === "Yes"
                    ? true
                    : false,
            rightOfRefusalExpiry: transactionDetails?.others?.expiry,
            remarks: transactionDetails?.others?.remarks,
            deletedFiles: deletedFileList.length > 0 ? filteredDeletedFiles : null
        }
        // Append data to FormData
        for (const key in data) {
            if (data[key] !== null && data[key] !== undefined) {
                if (typeof data[key] === "object" && !(data[key] instanceof Date)) {
                    formData.append(key, JSON.stringify(data[key]));
                } else {
                    formData.append(key, data[key]);
                }
            }
        }
        return formData;
    };

    const updateTransactionInfoSuccessMessage = () => {
        messageApi.open({
            type: "success",
            content: "Transaction Updated successfully",
        });
    };

    const saveTransactionInfoSuccessMessage = () => {
        messageApi.open({
            type: "success",
            content: "Transaction Created successfully",
        });
    };

    const saveTransactionInfoErrorMessage = (error) => {
        messageApi.open({
            type: "warning",
            content: error || "Something went wrong",
        });
    };

    const handleChangeTransactionsInfo = (element, e, dateVal, obj) => {
        if (element?.type === "datepicker") {
            form.setFieldsValue({
                ...form,
                [element?.name]: dateVal
                    ? dayjs(dateVal, "DD-MM-YYYY").add(5, "hour").add(30, "minute")
                    : "",
            });
            dispatch(
                setTransactionInfo({
                    name: element?.name,
                    value: dateVal ? dayjs(dateVal, "DD-MM-YYYY").add(5, "hour").add(30, "minute") : "",
                    obj: obj,
                })
            );
        } else if (
            element?.type === "inputNumber" ||
            element?.type === "dropdown"
        ) {
            if (element?.name === 'unitStatus' && e === 'Vacant' && formTransactionData?.agreement_information?.documentName !== 'Sale' &&
                formTransactionData?.agreement_information?.documentName !== 'Rent') {
                form.setFieldsValue({
                    ...form,
                    documentName: null,
                    documentType: null
                });
                dispatch(
                    setTransactionInfo({
                        name: "documentName",
                        value: null,
                        obj: "agreement_information",
                    })
                );
            }

            form.setFieldsValue({
                ...form,
                [element?.name]: e,
            });
            dispatch(
                setTransactionInfo({
                    name: element?.name,
                    value: e,
                    obj: obj,
                })
            );
        } else {
            form.setFieldsValue({
                ...form,
                [element?.name]: e.target.value,
            });
            dispatch(
                setTransactionInfo({
                    name: element?.name,
                    value: e.target.value,
                    obj: obj,
                })
            );
        }
    };

    const handleCalculateDerivedFields = (element, e) => {
        switch (element?.name) {
            case "documentType":
                if (e === "Rent") {
                    if (formTransactionData?.transactionInformation?.compensation) {
                        form.setFieldsValue({
                            ...form,
                            rent_licence_fees: Number(
                                formTransactionData?.transactionInformation?.compensation
                            ),
                            saleValue: 0,
                            sale_rate_sq_feet: 0,
                        });
                        dispatch(
                            setTransactionInfo({
                                name: "rent_licence_fees",
                                value: Number(
                                    formTransactionData?.transactionInformation?.compensation
                                ),
                                obj: "transactionInformation",
                            })
                        );
                        dispatch(
                            setTransactionInfo({
                                name: "saleValue",
                                value: 0,
                                obj: "transactionInformation",
                            })
                        );
                        dispatch(
                            setTransactionInfo({
                                name: "sale_rate_sq_feet",
                                value: 0,
                                obj: "transactionInformation",
                            })
                        );
                        if (
                            formTransactionData?.transactionInformation
                                ?.chargeableSquareFeet &&
                            Number(
                                formTransactionData?.transactionInformation
                                    ?.chargeableSquareFeet
                            )
                        ) {
                            form.setFieldsValue({
                                ...form,
                                rent_rate_sq_feet:
                                    formTransactionData?.transactionInformation?.compensation /
                                    formTransactionData?.transactionInformation
                                        ?.chargeableSquareFeet,
                            });
                            dispatch(
                                setTransactionInfo({
                                    name: "rent_rate_sq_feet",
                                    value:
                                        formTransactionData?.transactionInformation?.compensation /
                                        formTransactionData?.transactionInformation
                                            ?.chargeableSquareFeet,
                                    obj: "transactionInformation",
                                })
                            );
                        } else {
                            form.setFieldsValue({
                                ...form,
                                rent_rate_sq_feet: 0,
                            });
                            dispatch(
                                setTransactionInfo({
                                    name: "rent_rate_sq_feet",
                                    value: 0,
                                    obj: "transactionInformation",
                                })
                            );
                        }
                    } else {
                        form.setFieldsValue({
                            ...form,
                            rent_rate_sq_feet: null,
                            rent_licence_fees: null,
                        });
                        dispatch(
                            setTransactionInfo({
                                name: "rent_licence_fees",
                                value: null,
                                obj: "transactionInformation",
                            })
                        );
                        dispatch(
                            setTransactionInfo({
                                name: "rent_rate_sq_feet",
                                value: null,
                                obj: "transactionInformation",
                            })
                        );
                    }
                } else if (e === "Sale") {
                    if (formTransactionData?.transactionInformation?.compensation) {
                        form.setFieldsValue({
                            ...form,
                            saleValue:
                                formTransactionData?.transactionInformation?.compensation,
                            rent_licence_fees: 0,
                            rent_rate_sq_feet: 0,
                        });
                        dispatch(
                            setTransactionInfo({
                                name: "saleValue",
                                value:
                                    formTransactionData?.transactionInformation?.compensation,
                                obj: "transactionInformation",
                            })
                        );
                        dispatch(
                            setTransactionInfo({
                                name: "rent_licence_fees",
                                value: 0,
                                obj: "transactionInformation",
                            })
                        );
                        dispatch(
                            setTransactionInfo({
                                name: "rent_rate_sq_feet",
                                value: 0,
                                obj: "transactionInformation",
                            })
                        );
                        if (
                            formTransactionData?.transactionInformation
                                ?.chargeableSquareFeet &&
                            Number(
                                formTransactionData?.transactionInformation
                                    ?.chargeableSquareFeet
                            )
                        ) {
                            form.setFieldsValue({
                                ...form,
                                sale_rate_sq_feet:
                                    formTransactionData?.transactionInformation?.compensation /
                                    formTransactionData?.transactionInformation
                                        ?.chargeableSquareFeet,
                            });
                            dispatch(
                                setTransactionInfo({
                                    name: "sale_rate_sq_feet",
                                    value:
                                        formTransactionData?.transactionInformation?.compensation /
                                        formTransactionData?.transactionInformation
                                            ?.chargeableSquareFeet,
                                    obj: "transactionInformation",
                                })
                            );
                        } else {
                            form.setFieldsValue({
                                ...form,
                                sale_rate_sq_feet: 0,
                            });
                            dispatch(
                                setTransactionInfo({
                                    name: "sale_rate_sq_feet",
                                    value: 0,
                                    obj: "transactionInformation",
                                })
                            );
                        }
                    } else {
                        form.setFieldsValue({
                            ...form,
                            saleValue: null,
                            sale_rate_sq_feet: null,
                        });
                        dispatch(
                            setTransactionInfo({
                                name: "saleValue",
                                value: null,
                                obj: "transactionInformation",
                            })
                        );
                        dispatch(
                            setTransactionInfo({
                                name: "sale_rate_sq_feet",
                                value: null,
                                obj: "transactionInformation",
                            })
                        );
                    }
                } else {
                    form.setFieldsValue({
                        ...form,
                        saleValue: null,
                        sale_rate_sq_feet: null,
                        rent_licence_fees: null,
                        rent_rate_sq_feet: null,
                    });
                    dispatch(
                        setTransactionInfo({
                            name: "saleValue",
                            value: null,
                            obj: "transactionInformation",
                        })
                    );
                    dispatch(
                        setTransactionInfo({
                            name: "sale_rate_sq_feet",
                            value: null,
                            obj: "transactionInformation",
                        })
                    );
                    dispatch(
                        setTransactionInfo({
                            name: "rent_licence_fees",
                            value: null,
                            obj: "transactionInformation",
                        })
                    );
                    dispatch(
                        setTransactionInfo({
                            name: "rent_rate_sq_feet",
                            value: null,
                            obj: "transactionInformation",
                        })
                    );
                }
                break;
            case "compensation":
                if (
                    formTransactionData?.transactionInformation?.documentType === "Rent"
                ) {
                    if (
                        e &&
                        Number(e) &&
                        formTransactionData?.transactionInformation?.chargeableSquareFeet &&
                        Number(
                            formTransactionData?.transactionInformation?.chargeableSquareFeet
                        )
                    ) {
                        form.setFieldsValue({
                            ...form,
                            rent_rate_sq_feet:
                                Number(e) /
                                Number(
                                    formTransactionData?.transactionInformation
                                        ?.chargeableSquareFeet
                                ),
                            rent_licence_fees: e,
                        });
                        dispatch(
                            setTransactionInfo({
                                name: "rent_rate_sq_feet",
                                value:
                                    Number(e) /
                                    Number(
                                        formTransactionData?.transactionInformation
                                            ?.chargeableSquareFeet
                                    ),
                                obj: "transactionInformation",
                            })
                        );
                        dispatch(
                            setTransactionInfo({
                                name: "rent_licence_fees",
                                value: e,
                                obj: "transactionInformation",
                            })
                        );
                    } else {
                        form.setFieldsValue({
                            ...form,
                            rent_licence_fees: e,
                            rent_rate_sq_feet: 0,
                        });
                        dispatch(
                            setTransactionInfo({
                                name: "rent_licence_fees",
                                value: e,
                                obj: "transactionInformation",
                            })
                        );
                        dispatch(
                            setTransactionInfo({
                                name: "rent_rate_sq_feet",
                                value: 0,
                                obj: "transactionInformation",
                            })
                        );
                    }
                } else if (
                    formTransactionData?.transactionInformation?.documentType === "Sale"
                ) {
                    if (
                        e &&
                        Number(e) &&
                        formTransactionData?.transactionInformation?.chargeableSquareFeet &&
                        Number(
                            formTransactionData?.transactionInformation?.chargeableSquareFeet
                        )
                    ) {
                        form.setFieldsValue({
                            ...form,
                            sale_rate_sq_feet:
                                Number(e) /
                                Number(
                                    formTransactionData?.transactionInformation
                                        ?.chargeableSquareFeet
                                ),
                            saleValue: e,
                        });
                        dispatch(
                            setTransactionInfo({
                                name: "sale_rate_sq_feet",
                                value:
                                    Number(e) /
                                    Number(
                                        formTransactionData?.transactionInformation
                                            ?.chargeableSquareFeet
                                    ),
                                obj: "transactionInformation",
                            })
                        );
                        dispatch(
                            setTransactionInfo({
                                name: "saleValue",
                                value: e,
                                obj: "transactionInformation",
                            })
                        );
                    } else {
                        form.setFieldsValue({
                            ...form,
                            sale_rate_sq_feet: 0,
                            saleValue: e,
                        });
                        dispatch(
                            setTransactionInfo({
                                name: "saleValue",
                                value: e,
                                obj: "transactionInformation",
                            })
                        );
                        dispatch(
                            setTransactionInfo({
                                name: "sale_rate_sq_feet",
                                value: 0,
                                obj: "transactionInformation",
                            })
                        );
                    }
                }
                break;
            case "chargeableSquareFeet":
                if (
                    formTransactionData?.transactionInformation?.documentType === "Rent"
                ) {
                    if (
                        e &&
                        Number(e) &&
                        formTransactionData?.transactionInformation?.compensation &&
                        Number(formTransactionData?.transactionInformation?.compensation)
                    ) {
                        form.setFieldsValue({
                            ...form,
                            rent_rate_sq_feet:
                                Number(
                                    formTransactionData?.transactionInformation?.compensation
                                ) / Number(e),
                        });
                        dispatch(
                            setTransactionInfo({
                                name: "rent_rate_sq_feet",
                                value:
                                    Number(
                                        formTransactionData?.transactionInformation?.compensation
                                    ) / Number(e),
                                obj: "transactionInformation",
                            })
                        );
                    } else {
                        form.setFieldsValue({
                            ...form,
                            rent_licence_fees: 0,
                            rent_rate_sq_feet: 0,
                        });
                        dispatch(
                            setTransactionInfo({
                                name: "rent_licence_fees",
                                value: 0,
                                obj: "transactionInformation",
                            })
                        );
                        dispatch(
                            setTransactionInfo({
                                name: "rent_rate_sq_feet",
                                value: 0,
                                obj: "transactionInformation",
                            })
                        );
                    }
                } else if (
                    formTransactionData?.transactionInformation?.documentType === "Sale"
                ) {
                    if (
                        e &&
                        Number(e) &&
                        formTransactionData?.transactionInformation?.compensation &&
                        Number(formTransactionData?.transactionInformation?.compensation)
                    ) {
                        form.setFieldsValue({
                            ...form,
                            sale_rate_sq_feet:
                                Number(
                                    formTransactionData?.transactionInformation?.compensation
                                ) / Number(e),
                        });
                        dispatch(
                            setTransactionInfo({
                                name: "sale_rate_sq_feet",
                                value:
                                    Number(
                                        formTransactionData?.transactionInformation?.compensation
                                    ) / Number(e),
                                obj: "transactionInformation",
                            })
                        );
                    } else {
                        form.setFieldsValue({
                            ...form,
                            saleValue: 0,
                            sale_rate_sq_feet: 0,
                        });
                        dispatch(
                            setTransactionInfo({
                                name: "saleValue",
                                value: 0,
                                obj: "transactionInformation",
                            })
                        );
                        dispatch(
                            setTransactionInfo({
                                name: "sale_rate_sq_feet",
                                value: 0,
                                obj: "transactionInformation",
                            })
                        );
                    }
                }
                if (
                    formTransactionData?.transactionInformation?.market_price &&
                    Number(formTransactionData?.transactionInformation?.market_price)
                ) {
                    form.setFieldsValue({
                        ...form,
                        declared_circle_rate:
                            Number(
                                formTransactionData?.transactionInformation?.market_price
                            ) / Number(e),
                    });
                    dispatch(
                        setTransactionInfo({
                            name: "declared_circle_rate",
                            value:
                                Number(
                                    formTransactionData?.transactionInformation?.market_price
                                ) / Number(e),
                            obj: "transactionInformation",
                        })
                    );
                }
                break;
            case "market_price":
                if (
                    formTransactionData?.transactionInformation?.chargeableSquareFeet &&
                    Number(
                        formTransactionData?.transactionInformation?.chargeableSquareFeet
                    )
                ) {
                    form.setFieldsValue({
                        ...form,
                        declared_circle_rate:
                            Number(e) /
                            Number(
                                formTransactionData?.transactionInformation
                                    ?.chargeableSquareFeet
                            ),
                    });
                    dispatch(
                        setTransactionInfo({
                            name: "declared_circle_rate",
                            value:
                                Number(e) /
                                Number(
                                    formTransactionData?.transactionInformation
                                        ?.chargeableSquareFeet
                                ),
                            obj: "transactionInformation",
                        })
                    );
                }
                break;
            default:
                break;
        }
    };

    const transactionInfoValidationMessage = () => {
        messageApi.open({
            type: "warning",
            content: "Please check field validation",
        });
    };

    const [activeTab, setActiveTab] = useState("1");


    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    return (
        <>
            <div style={{ display: "flex" }}>
                {formTransactionData && "buildingInformation" in formTransactionData && (
                    <Form
                        form={form}
                        name="basic"
                        onFinish={async () => {
                            if (!validateTransactionInfo()) return;
                            if (isTransactionInfoEditable === true && id) {
                                const data = await dispatch(updateTransactionInfo(getUpdateTransactionPayload(formTransactionData))).unwrap();
                                if (data?.status === 201) {
                                    message.success("Vacant unit updated successfully");
                                    setTimeout(() => {
                                        dispatch(setTransactionId(null));
                                        dispatch(setTransactionInitialState());
                                        dispatch(setTransactionInfoEditable(false));
                                        navigate(`/property`);
                                    }, 1000);
                                } else {
                                    message.error(data?.response?.data?.error || "An unexpected error occurred.");
                                }
                            } else if (isTransactionInfoEditable === true && !id) {
                                dispatch(
                                    saveTransactionInfo({
                                        formData: getUpdateTransactionPayload(formTransactionData),
                                        propertyId: formTransactionData?.buildingInformation?.propertyId,
                                    })
                                )
                                    .unwrap()
                                    .then((data) => {
                                        if (data?.status === 201) {
                                            message.success("Vacant unit created successfully");
                                            setTimeout(() => {
                                                dispatch(setTransactionId(null));
                                                dispatch(setTransactionInitialState());
                                                dispatch(setTransactionInfoEditable(false));
                                                navigate("/property");
                                            }, 1000);
                                        } else {
                                            message.error(data?.response?.data?.error || "An unexpected error occurred.");
                                        }
                                    });
                            }
                        }}
                        onFinishFailed={() => {
                            transactionInfoValidationMessage();
                        }}
                        style={{ padding: "10px", flexGrow: 1 }}
                    >
                        <div
                            style={{
                                padding: "10px",
                                backgroundColor: "#fff",
                                borderRadius: "10px 10px 0 0",
                                width: "auto",
                                position: "sticky",
                                top: 0,
                                zIndex: 1000,
                            }}
                        >
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <h1 className="text-2xl font-[500] mb-4">
                                    <Button
                                        onClick={() => {
                                            dispatch(setTransactionId(null));
                                            dispatch(setTransactionInitialState());
                                            dispatch(setTransactionInfoEditable(false));
                                            navigate(-1);
                                        }}
                                        style={{ border: "none", backgroundColor: "transparent", boxShadow: "none" }}
                                    >
                                        <FaArrowLeft style={{ marginRight: "8px" }} />
                                    </Button>
                                    Vacant Unit
                                </h1>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Button disabled={true}>View Index-II</Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button disabled={true}>View Agreement</Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    {isTransactionInfoEditable === true && id && (
                                        <Button htmlType="submit">Update Unit</Button>
                                    )}
                                    {isTransactionInfoEditable === true && !id && (
                                        <Button htmlType="submit">Save Unit</Button>
                                    )}
                                    &nbsp;&nbsp;
                                    {isTransactionInfoEditable === false && (
                                        <Button onClick={() => dispatch(setTransactionInfoEditable(true))}>
                                            Edit Unit
                                        </Button>
                                    )}
                                    &nbsp;&nbsp;
                                </div>
                            </div>
                            {/* Tab section */}
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    borderBottom: "1px solid #ccc",
                                    padding: "10px 0 0",
                                    backgroundColor: "white",
                                }}
                            >
                                {/* Tab buttons with checkboxes */}
                                <div style={{ display: "flex", flexGrow: 1, justifyContent: "space-around" }}>
                                    {/* Transaction Information Tab */}
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        boxShadow: "none",
                                        borderTopColor: "#fff",
                                        borderRightColor: "#fff",
                                        borderLeftColor: "#fff",
                                        color: activeTab === "1" ? "#fb923c" : "#000",
                                        borderBottom: activeTab === "1" ? "2px solid #fb923c" : "2px solid #fff",
                                        borderBottomWidth: "3px",
                                        borderBottomStyle: "solid"
                                    }}>
                                        <Checkbox
                                            checked={["1", "2", "3", "4", "5", "6"].includes(activeTab)}
                                            style={{ color: activeTab !== "1" ? "#ccc" : "#fb923c" }}
                                            onChange={() => handleTabChange("1")}
                                        />
                                        <Button
                                            type={activeTab === "1" ? "#fb923c" : "default"}
                                            onClick={() => handleTabChange("1")}
                                            style={{
                                                border: "none",
                                            }}
                                        >
                                            Unit Information
                                        </Button>
                                    </div>

                                    {/* parties Information Tab */}
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        boxShadow: "none",
                                        borderTopColor: "#fff",
                                        borderRightColor: "#fff",
                                        borderLeftColor: "#fff",
                                        color: activeTab === "2" ? "#fb923c" : "#000",
                                        borderBottom: activeTab === "2" ? "2px solid #fb923c" : "2px solid #fff",
                                        borderBottomWidth: "3px",
                                        borderBottomStyle: "solid"
                                    }}>
                                        <Checkbox
                                            checked={["2", "3", "4", "5", "6"].includes(activeTab)}
                                            style={{ color: activeTab !== "2" ? "#ccc" : "#fb923c" }}
                                            onChange={() => handleTabChange("2")}
                                        />
                                        <Button
                                            type={activeTab === "2" ? "#fb923c" : "default"}
                                            onClick={() => handleTabChange("2")}
                                            style={{
                                                border: "none",
                                            }}
                                        >
                                            Parties Information
                                        </Button>
                                    </div>


                                    {/* Property Information Tab */}
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        boxShadow: "none",
                                        borderTopColor: "#fff",
                                        borderRightColor: "#fff",
                                        borderLeftColor: "#fff",
                                        color: activeTab === "4" ? "#fb923c" : "#000",
                                        borderBottom: activeTab === "4" ? "2px solid #fb923c" : "2px solid #fff",
                                        borderBottomWidth: "3px",
                                        borderBottomStyle: "solid"
                                    }}>
                                        <Checkbox
                                            checked={["4", "5", "6"].includes(activeTab)}
                                            style={{ color: activeTab !== "4" ? "#ccc" : "#fb923c" }}
                                            onChange={() => handleTabChange("4")}
                                        />
                                        <Button
                                            type={activeTab === "4" ? "#fb923c" : "default"}
                                            onClick={() => handleTabChange("4")}
                                            style={{
                                                border: "none",
                                            }}
                                        >
                                            Property Information
                                        </Button>
                                    </div>

                                    {/* Other Information Tab */}
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        boxShadow: "none",
                                        borderTopColor: "#fff",
                                        borderRightColor: "#fff",
                                        borderLeftColor: "#fff",
                                        color: activeTab === "5" ? "#fb923c" : "#000",
                                        borderBottom: activeTab === "5" ? "2px solid #fb923c" : "2px solid #fff",
                                        borderBottomWidth: "3px",
                                        borderBottomStyle: "solid"
                                    }}>
                                        <Checkbox
                                            checked={["5", "6"].includes(activeTab)}
                                            style={{ color: activeTab !== "5" ? "#ccc" : "#fb923c" }}
                                            onChange={() => handleTabChange("5")}
                                        />
                                        <Button
                                            type={activeTab === "5" ? "#fb923c" : "default"}
                                            onClick={() => handleTabChange("5")}
                                            style={{
                                                border: "none",
                                            }}
                                        >
                                            Other Information
                                        </Button>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        boxShadow: "none",
                                        borderTopColor: "#fff",
                                        borderRightColor: "#fff",
                                        borderLeftColor: "#fff",
                                        color: activeTab === "6" ? "#fb923c" : "#000",
                                        borderBottom: activeTab === "6" ? "2px solid #fb923c" : "2px solid #fff",
                                        borderBottomWidth: "3px",
                                        borderBottomStyle: "solid"
                                    }}>
                                        <Checkbox
                                            checked={activeTab === "6"}
                                            style={{ color: activeTab !== "6" ? "#ccc" : "#fb923c" }}
                                            onChange={() => handleTabChange("6")}
                                        />
                                        <Button
                                            type={activeTab === "6" ? "#fb923c" : "default"}
                                            onClick={() => handleTabChange("6")}
                                            style={{
                                                border: "none",
                                            }}
                                        >
                                            Add Buildings Images
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Tab content */}
                        <div style={{
                            width: "100%",
                            padding: "20px ",
                            borderRadius: "0 0 10px 10px",
                            backgroundColor: "white",
                        }}
                        >
                            {activeTab === "2" && (
                                <>
                                    <Title level={4}>Parties Information</Title>
                                    <Row span={12}>
                                        <Col span={12} style={{ textAlign: "center" }}>
                                            Seller
                                        </Col>
                                    </Row>
                                    <Divider
                                        style={{
                                            backgroundColor: "black",
                                            marginTop: "0",
                                            marginBottom: "5px",
                                            position: "relative",
                                        }}
                                    />
                                    <Row span={24}>
                                        <Col span={11} style={{ marginLeft: '16px' }}>
                                            <FormElement
                                                type="textArea"
                                                label="All Seller Name"
                                                name="allSellerName"
                                                width={"92%"}
                                                placeholder="All Seller Name"
                                                value={
                                                    formTransactionData?.sellerInfo?.sellers?.length > 0
                                                        ? formTransactionData?.sellerInfo?.sellers[0]?.sellerType === 'Individual'
                                                            ? formTransactionData?.sellerInfo?.sellers[0]?.name
                                                            : formTransactionData?.sellerInfo?.sellers[0]?.sellerType === 'Company'
                                                                ? formTransactionData?.sellerInfo?.sellers[0]?.representativeName
                                                                : null
                                                        : null

                                                }
                                                disabled={id ? true : false}
                                                onChange={(e) => {
                                                    if (!id) {
                                                        dispatch(
                                                            setLegacyPartiesInfo({
                                                                name: "sellers",
                                                                value: e.target.value,
                                                                obj: "legacy_propertiesInformation",
                                                            })
                                                        );
                                                    }
                                                }}
                                            />
                                            {savedSellersList?.length > 0 && (
                                                <Collapse
                                                    accordion
                                                    items={savedSellersList}
                                                    defaultActiveKey={[showSellerPartiesInfoKey]}
                                                    onChange={(key) => {
                                                        handleShowPartiesInfo(key?.[0], "sellerInfo");
                                                    }}
                                                    style={{ marginBottom: "20px", width: "92%" }}
                                                />
                                            )}
                                            {showPartiesInfoSellerRadioGroup && (
                                                <Radio.Group
                                                    onChange={(e) => {
                                                        setPartiesSaveSellerInfo({
                                                            ...partiesSaveSellerInfo,
                                                            sellerType: e.target.value,
                                                        });
                                                        setSellerType(e.target.value);
                                                        setShowPartiesInfoSellerForm(true);
                                                    }}
                                                    disabled={!isTransactionInfoEditable}
                                                    value={sellerType}
                                                    style={{
                                                        position: "absolute",
                                                        display: "inline-flex",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <Radio key={"Company"} value="Company">
                                                        Company
                                                    </Radio>
                                                    <Radio key={"Individual"} value="Individual">
                                                        Individual
                                                    </Radio>
                                                </Radio.Group>
                                            )}
                                            <Button
                                                style={{ float: "right" }}
                                                disabled={
                                                    !isTransactionInfoEditable || showPartiesInfoSellerForm
                                                }
                                                onClick={() => {
                                                    if (showPartiesInfoSellerForm) return;
                                                    setSellerType(null);
                                                    handleAddPartiesInfo(
                                                        "sellerInfo",
                                                        formTransactionData?.sellerInfo?.length
                                                    );
                                                    setPartiesSaveSellerInfo({});
                                                    form.setFieldsValue({
                                                        ...form,
                                                        companyName: null,
                                                        representativeName: null,
                                                        representativeEmail: null,
                                                        representativeAadhar: null,
                                                        representativeContact: null,
                                                        representativePan: null,
                                                        representativeRole: null,
                                                        name: null,
                                                        pan: null,
                                                        aadhar: null,
                                                        email: null,
                                                        contact: null,
                                                    });
                                                }}
                                            >
                                                Create
                                            </Button>
                                            {showPartiesInfoSellerForm && (
                                                <div
                                                    style={{
                                                        maxHeight: "1000px",
                                                        marginTop: "70px",
                                                    }}
                                                >
                                                    {sellerType === "Company" && (
                                                        <Col span={10}>
                                                            <FormElement
                                                                type="dropdown"
                                                                label="Company Name"
                                                                required={true}
                                                                name="companyName"
                                                                placeholder="Company Name"
                                                                showSearch={true}
                                                                children={getCompanyListingEle()}
                                                                onSearch={handleSearchDropdownOptions}
                                                                createOptionLabel="Create Company"
                                                                createRecord={() => {
                                                                    dispatch(setNavigatedFrom("Transaction"));
                                                                    dispatch(setCompanyId(null));
                                                                    navigate("/companyDetails");
                                                                }}
                                                                value={partiesSaveSellerInfo?.companyName}
                                                                onChange={(e, obj) =>
                                                                    setPartiesSaveSellerInfo({
                                                                        ...partiesSaveSellerInfo,
                                                                        companyName: e,
                                                                        companyId: obj?.key,
                                                                    })
                                                                }
                                                            />
                                                            <Title level={5}>Representative Person</Title>
                                                            <FormElement
                                                                type="input"
                                                                label="Name"
                                                                name="representativeName"
                                                                placeholder="Name"
                                                                value={partiesSaveSellerInfo?.representativeName}
                                                                onChange={(e) =>
                                                                    setPartiesSaveSellerInfo({
                                                                        ...partiesSaveSellerInfo,
                                                                        representativeName: e.target.value,
                                                                    })
                                                                }
                                                            />
                                                            <FormElement
                                                                type="input"
                                                                label="Role"
                                                                name="representativeRole"
                                                                placeholder="Role"
                                                                value={partiesSaveSellerInfo?.representativeRole}
                                                                onChange={(e) =>
                                                                    setPartiesSaveSellerInfo({
                                                                        ...partiesSaveSellerInfo,
                                                                        representativeRole: e.target.value,
                                                                    })
                                                                }
                                                            />
                                                            <FormElement
                                                                type="input"
                                                                label="Aadhar"
                                                                name="representativeAadhar"
                                                                placeholder="Aadhar"
                                                                value={partiesSaveSellerInfo?.representativeAadhar}
                                                                onChange={(e) =>
                                                                    setPartiesSaveSellerInfo({
                                                                        ...partiesSaveSellerInfo,
                                                                        representativeAadhar: e.target.value,
                                                                    })
                                                                }
                                                            />
                                                            <FormElement
                                                                type="input"
                                                                label="PAN"
                                                                name="representativePan"
                                                                placeholder="PAN"
                                                                value={partiesSaveSellerInfo?.representativePan}
                                                                onChange={(e) =>
                                                                    setPartiesSaveSellerInfo({
                                                                        ...partiesSaveSellerInfo,
                                                                        representativePan: e.target.value,
                                                                    })
                                                                }
                                                            />
                                                            <FormElement
                                                                type="input"
                                                                label="E-mail"
                                                                name="representativeEmail"
                                                                placeholder="E-mail"
                                                                value={partiesSaveSellerInfo?.representativeEmail}
                                                                onChange={(e) =>
                                                                    setPartiesSaveSellerInfo({
                                                                        ...partiesSaveSellerInfo,
                                                                        representativeEmail: e.target.value,
                                                                    })
                                                                }
                                                            />
                                                            <FormElement
                                                                type="input"
                                                                label="Contact"
                                                                name="representativeContact"
                                                                placeholder="Contact"
                                                                value={partiesSaveSellerInfo?.representativeContact}
                                                                onChange={(e) =>
                                                                    setPartiesSaveSellerInfo({
                                                                        ...partiesSaveSellerInfo,
                                                                        representativeContact: e.target.value,
                                                                    })
                                                                }
                                                            />
                                                        </Col>
                                                    )}
                                                    {sellerType === "Individual" && (
                                                        <Col span={10}>
                                                            <FormElement
                                                                type="input"
                                                                label="Name"
                                                                required={true}
                                                                name="name"
                                                                placeholder="Name"
                                                                value={partiesSaveSellerInfo?.name}
                                                                onChange={(e) =>
                                                                    setPartiesSaveSellerInfo({
                                                                        ...partiesSaveSellerInfo,
                                                                        name: e.target.value,
                                                                    })
                                                                }
                                                            />
                                                            <FormElement
                                                                type="input"
                                                                label="PAN"
                                                                name="pan"
                                                                placeholder="PAN"
                                                                value={partiesSaveSellerInfo?.pan}
                                                                onChange={(e) =>
                                                                    setPartiesSaveSellerInfo({
                                                                        ...partiesSaveSellerInfo,
                                                                        pan: e.target.value,
                                                                    })
                                                                }
                                                            />
                                                            <FormElement
                                                                type="input"
                                                                label="Aadhar"
                                                                name="aadhar"
                                                                placeholder="Aadhar"
                                                                value={partiesSaveSellerInfo?.aadhar}
                                                                onChange={(e) =>
                                                                    setPartiesSaveSellerInfo({
                                                                        ...partiesSaveSellerInfo,
                                                                        aadhar: e.target.value,
                                                                    })
                                                                }
                                                            />
                                                            <FormElement
                                                                type="input"
                                                                label="E-mail"
                                                                name="email"
                                                                placeholder="E-mail"
                                                                value={partiesSaveSellerInfo?.email}
                                                                onChange={(e) =>
                                                                    setPartiesSaveSellerInfo({
                                                                        ...partiesSaveSellerInfo,
                                                                        email: e.target.value,
                                                                    })
                                                                }
                                                            />
                                                            <FormElement
                                                                type="input"
                                                                label="Contact"
                                                                name="contact"
                                                                placeholder="Contact"
                                                                value={partiesSaveSellerInfo?.contact}
                                                                onChange={(e) =>
                                                                    setPartiesSaveSellerInfo({
                                                                        ...partiesSaveSellerInfo,
                                                                        contact: e.target.value,
                                                                    })
                                                                }
                                                            />
                                                        </Col>
                                                    )}
                                                    <div style={{ float: "right" }}>
                                                        <Button
                                                            onClick={() => {
                                                                handleSaveSellerPartiesInfo();
                                                            }}
                                                        >
                                                            Save
                                                        </Button>
                                                        <Button
                                                            style={{ marginLeft: "10px" }}
                                                            onClick={() => {
                                                                setShowPartiesInfoSellerRadioGroup(false);
                                                                setShowPartiesInfoSellerForm(false);
                                                                setPartiesSaveSellerInfo({});
                                                                form.setFieldsValue({
                                                                    ...form,
                                                                    companyName: null,
                                                                    representativeName: null,
                                                                    representativeEmail: null,
                                                                    representativeAadhar: null,
                                                                    representativeContact: null,
                                                                    representativePan: null,
                                                                    representativeRole: null,
                                                                    name: null,
                                                                    pan: null,
                                                                    aadhar: null,
                                                                    email: null,
                                                                    contact: null,
                                                                });
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                </>
                            )}


                            {activeTab === "1" && (
                                <>
                                    <Title level={4}>Unit Information</Title>
                                    <Row span={12} style={{ justifyContent: "space-between", marginRight: "150px" }}>
                                        <Col span={6}>
                                            {transInfoFormElementsCol1.map((element, index) => (
                                                <FormElement
                                                    key={index}
                                                    {...element}
                                                    onChange={(e, dateVal) => {
                                                        handleChangeTransactionsInfo(element, e, dateVal, "transactionInformation");
                                                    }}
                                                />
                                            ))}
                                        </Col>
                                        <Col span={6}>
                                            {transInfoFormElementsCol2.map((element, index) => (
                                                <FormElement
                                                    key={index}
                                                    {...element}
                                                    onChange={(e, dateVal) => {
                                                        handleChangeTransactionsInfo(element, e, dateVal, "transactionInformation");
                                                    }}
                                                />
                                            ))}
                                        </Col>
                                        <Col span={6}>
                                            {transInfoFormElementsCol3.map((element, index) => (
                                                <FormElement
                                                    key={index}
                                                    {...element}
                                                    onChange={(e, dateVal) => {
                                                        handleChangeTransactionsInfo(element, e, dateVal, "transactionInformation");
                                                    }}
                                                />
                                            ))}
                                        </Col>
                                    </Row>
                                </>
                            )}
                            {activeTab === "4" && (
                                <>
                                    <Title level={4}>Property Information</Title>
                                    <Row span={12} style={{ justifyContent: "space-between", marginRight: "150px" }}>
                                        <Col span={6}>
                                            {buildingInfoFormElementsCol1.map((element, index) => (
                                                <FormElement key={index} {...element} />
                                            ))}
                                        </Col>
                                        <Col span={6}>
                                            {buildingInfoFormElementsCol2.map((element, index) => (
                                                <FormElement key={index} {...element} />
                                            ))}
                                        </Col>
                                        <Col span={6}>
                                            {buildingInfoFormElementsCol3.map((element, index) => (
                                                <FormElement key={index} {...element} />
                                            ))}
                                        </Col>
                                    </Row>
                                </>
                            )}
                            {activeTab === "5" && (
                                <>
                                    <Title level={4}>Other Information</Title>
                                    <Row span={12} style={{ justifyContent: "space-between", marginRight: "150px" }}>
                                        <Col span={6}>
                                            {otherInfoFormElementsCol1.map((element, index) => (
                                                <FormElement
                                                    key={index}
                                                    {...element}
                                                    onChange={(e, dateVal) => {
                                                        handleChangeTransactionsInfo(element, e, dateVal, "others");
                                                    }}
                                                />
                                            ))}
                                        </Col>
                                        <Col span={6}>
                                            {otherInfoFormElementsCol2.map((element, index) => (
                                                <FormElement
                                                    key={index}
                                                    {...element}
                                                    onChange={(e, dateVal) => {
                                                        handleChangeTransactionsInfo(element, e, dateVal, "others");
                                                    }}
                                                />
                                            ))}
                                        </Col>
                                        <Col span={6}>
                                            {otherInfoFormElementsCol3.map((element, index) => (
                                                <FormElement
                                                    key={index}
                                                    {...element}
                                                    onChange={(e, dateVal) => {
                                                        handleChangeTransactionsInfo(element, e, dateVal, "others");
                                                    }}
                                                />
                                            ))}
                                        </Col>
                                    </Row>
                                </>
                            )}
                            {activeTab === "6" && (
                                <>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Title level={4}>Add Buildings Images</Title>
                                    </div>
                                    <Title level={5}>Note: Extensions allowed - jpg, png, jpeg</Title>
                                    <Divider style={{ backgroundColor: "black", marginTop: "0" }} />
                                    <Row span={12} style={{ columnGap: "10px", marginRight: "150px" }}>
                                        {isTransactionInfoEditable && (
                                            <Upload
                                                {...props}
                                                fileList={
                                                    isTransactionInfoEditable
                                                        ? transactionData?.propertyImages
                                                        : fileList
                                                }
                                                disabled={!isTransactionInfoEditable}
                                                preview={false}
                                                maxCount={14}
                                                showUploadList={false}
                                                multiple={false}
                                            >
                                                <Button
                                                    icon={<UploadOutlined />}
                                                    loading={uploading}
                                                    disabled={!isTransactionInfoEditable}
                                                >
                                                    Select File
                                                </Button>
                                            </Upload>
                                        )}
                                        <div style={{ display: "flex" }}>
                                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                                {transactionData?.propertyImages?.length > 0 ? (

                                                    transactionData?.propertyImages.map((file, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                position: "relative",
                                                                margin: "0px 10px 0px 10px",
                                                            }}
                                                        >
                                                            {isTransactionInfoEditable && (
                                                                <Checkbox
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: 3,
                                                                        left: 5,
                                                                        zIndex: 1,
                                                                    }}
                                                                    onChange={(e) => handleImageClick(file)}
                                                                    checked={selectedImage === file}
                                                                    disabled={!isTransactionInfoEditable}
                                                                />
                                                            )}
                                                            {isTransactionInfoEditable && (
                                                                <CloseOutlined
                                                                    style={{
                                                                        color: "black",
                                                                        position: "absolute",
                                                                        top: 3,
                                                                        right: 5,
                                                                        zIndex: 1,
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => handleDeleteImage(file)}
                                                                />
                                                            )}
                                                            {(file.originFileObj || file.url) && (
                                                                <Image
                                                                    width={200}
                                                                    height={150}
                                                                    preview={false}
                                                                    src={
                                                                        file.url ||
                                                                        URL.createObjectURL(file.originFileObj)
                                                                    }
                                                                    alt={`Uploaded image ${index + 1}`}
                                                                    style={
                                                                        file?.isProfile
                                                                            ? {
                                                                                border: "4px solid orange",
                                                                                padding: "2px",
                                                                                borderRadius: "8px",
                                                                            }
                                                                            : { borderRadius: "8px" }
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                    ))
                                                ) : (
                                                    <>
                                                        {!isTransactionInfoEditable && <h2>No Images to display</h2>}
                                                    </>
                                                )}
                                            </div>

                                            {isTransactionInfoEditable && (
                                                <div
                                                    style={{
                                                        marginLeft: 20,
                                                        flex: "1",
                                                        margin: "0px 10px 0px 10px",
                                                    }}
                                                >
                                                    {selectedImage ? (
                                                        <>
                                                            <Row>
                                                                {(selectedImage.url ||
                                                                    selectedImage.originFileObj) && (
                                                                        <Image
                                                                            width={320}
                                                                            height={280}
                                                                            src={
                                                                                selectedImage.url ||
                                                                                (selectedImage.originFileObj &&
                                                                                    URL.createObjectURL(
                                                                                        selectedImage.originFileObj
                                                                                    ))
                                                                            }
                                                                            alt="Selected Image Preview"
                                                                            style={{ borderRadius: "8px" }}
                                                                        />
                                                                    )}
                                                            </Row>
                                                        </>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                padding: "20px",
                                                                border: "1px dashed #d9d9d9",
                                                                borderRadius: "8px",
                                                                width: "320px",
                                                                height: "280px",
                                                            }}
                                                        >
                                                            Your image preview will display here
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </Row>
                                </>
                            )}
                        </div>
                    </Form>
                )}
            </div>
        </>
    );
}

export default VacantDetailsCard;
