import { buildCreateSlice, asyncThunkCreator } from "@reduxjs/toolkit";
import {
  getTransactionList as _getTransactionList,
  getAllTransactions as _getAllTransactions,
  getFilteredTransaction as _getFilteredTransaction,
  getTransactionDetailsData as _getTransactionDetailsData,
  updateTransactionDetailsData as _updateTransactionDetailsData,
  saveTransactionDetailsData as _saveTransactionDetailsData,
  getCompanyWithSearchableText as _getCompanyWithSearchableText,
  getAllDocumentMaster as _getAllDocumentMaster,
  deleteTransaction as _deleteTransaction,
} from "../services/TransactionDetailsAPI";
import { getPropertyDetailsData as _getPropertyDetailsData } from "../services/PropertyDetailsAPI";
import {
  FormatTransactionPartiesInfo,
  FormatTransactionImageFormat,
} from "../../utils/formatSearchData/FormatTransactionPartiesInfo";
import { FormatNewTransactionPropertyDetails } from "../../utils/formatSearchData/FormatNewTransactionPropertyDetails";
import { get } from "lodash";

const createSliceWithThunks = buildCreateSlice({
  creators: {
    asyncThunk: asyncThunkCreator,
  },
});

const initialState = {
  loading: false,
  transactionsDetailsData: [],
  isTransactionInfoEditable: false,
  currentPage: 1,
  pageSize: 10,
  transactionData: {
    buyerInfo: {
      allBuyerName: "",
      allRepresentativeName: "",
      buyers: [],
    },
    sellerInfo: {
      allSellerName: "",
      allRepresentativeName: "",
      sellers: [],
    },
  },
  transactionId: "",
  moreTransactionId: "",
  companies: [],
  documentNameMaster: [],
  documentTypeMaster: [],
  documentMaster: [],
  transactionsCnt: 0,
};

const transactionDetailsSlice = createSliceWithThunks({
  name: "transactionDetails",
  initialState,
  reducers: (create) => ({
    setTransactionInfoEditable: (state, action) => {
      state.isTransactionInfoEditable = action?.payload;
    },
    deleteTransaction: create.asyncThunk(
      async (transactionId, thunkAPI) => {
        try {
          await _deleteTransaction(transactionId);
          return transactionId;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {},
        rejected: (state, action) => {},
        fulfilled: (state, action) => {},
      }
    ),
    getTransactionList: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          let data = await _getTransactionList(param);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
          state.transactionsDetailsData = [];
        },
        rejected: (state, action) => {
          state.loading = false;
          state.transactionsDetailsData = [];
        },
        fulfilled: (state, action) => {
          state.transactionsDetailsData = action?.payload?.data?.data;
          state.transactionsCnt = action?.payload?.data?.count || 0;
          state.loading = false;
        },
      }
    ),
    setTransactionList: (state, action) => {
      state.transactionsDetailsData = action.payload;
      state.loading = false;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    getAllTransactions: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          let data = await _getAllTransactions(param);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
          state.transactionsDetailsData = [];
        },
        rejected: (state, action) => {
          state.loading = false;
          state.transactionsDetailsData = [];
        },
        fulfilled: (state, action) => {
          state.transactionsDetailsData = action?.payload?.data;
          state.transactionsCnt = action?.payload?.data?.count || 0;
          state.loading = false;
        },
      }
    ),
    getFilteredTransaction: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          let data = await _getFilteredTransaction(param);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
          state.transactionsDetailsData = [];
        },
        rejected: (state, action) => {
          state.loading = false;
          state.transactionsDetailsData = [];
        },
        fulfilled: (state, action) => {
          state.transactionsDetailsData = action?.payload?.data;
          state.transactionsCnt = action?.payload?.data?.count;
          state.loading = false;
        },
      }
    ),
    setTransactionInfo: (state, action) => {
      if (action?.payload?.obj === "agreement_information") {
        state.transactionData = {
          ...state.transactionData,
          agreement_information: {
            ...state.transactionData?.agreement_information,
            [action?.payload?.name]: action?.payload?.value,
          },
        };
      } else if (action?.payload?.obj === "transactionInformation") {
        state.transactionData = {
          ...state.transactionData,
          transactionInformation: {
            ...state.transactionData?.transactionInformation,
            [action?.payload?.name]: action?.payload?.value,
          },
        };
      } else if (action?.payload?.obj === "buildingInformation") {
        state.transactionData = {
          ...state.transactionData,
          buildingInformation: {
            ...state.transactionData?.buildingInformation,
            [action?.payload?.name]: action?.payload?.value,
          },
        };
      } else if (action?.payload?.obj === "others") {
        state.transactionData = {
          ...state.transactionData,
          others: {
            ...state.transactionData?.others,
            [action?.payload?.name]: action?.payload?.value,
          },
        };
      } else if (action?.payload?.name === "buyerType") {
        if (action?.payload?.value?.length === 0) {
          state.transactionData = {
            ...state.transactionData,
            buyerInfo: {},
          };
        } else {
          state.transactionData = {
            ...state.transactionData,
            buyerInfo: {
              ...state.transactionData?.buyerInfo,
              buyers: action?.payload?.value,
            },
          };
        }
      } else if (action?.payload?.name === "sellerType") {
        if (action?.payload?.value?.length === 0) {
          state.transactionData = {
            ...state.transactionData,
            sellerInfo: {},
          };
        } else {
          state.transactionData = {
            ...state.transactionData,
            sellerInfo: {
              ...state.transactionData?.sellerInfo,
              sellers: action?.payload?.value,
            },
          };
        }
      } else {
        state.transactionData = {
          ...state.transactionData,
          [action?.payload?.name]: action?.payload?.value,
        };
      }
    },
    updateTransactionInfo: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const transactionId =
            thunkAPI.getState().transactionDetails?.transactionId;
          const moreTransactionId =
            thunkAPI.getState().transactionDetails?.moreTransactionId;
          let data = await _updateTransactionDetailsData(
            param,
            transactionId,
            moreTransactionId
          );
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
        },
      }
    ),
    saveTransactionInfo: create.asyncThunk(
      async (transactionData, thunkAPI) => {
        // propertyId is passed as the first argument
        try {
          let data = await _saveTransactionDetailsData(transactionData); // Passing the propertyId to the function
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          // state.loading = true;
        },
        rejected: (state, action) => {
          // state.loading = false;
        },
        fulfilled: (state, action) => {
          // state.loading = false;
        },
      }
    ),
    setCurrentPage: (state, action) => {
      state.currentPage = action?.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action?.payload;
    },
    getTransactionDetailsData: create.asyncThunk(
      async (transactionId, thunkAPI) => {
        try {
          const propertyId = thunkAPI.getState().propertyDetails?.propertyId;
          const data = await _getTransactionDetailsData(
            transactionId,
            propertyId
          );
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
          state.transactionData = {};
        },
        rejected: (state, action) => {
          state.loading = false;
          state.transactionData = {};
        },
        fulfilled: (state, action) => {
          const transaction = action?.payload?.data?.[0] || {};
          state.transactionData = {
            ...transaction,
            buyerInfo: {
              buyers: FormatTransactionPartiesInfo(
                transaction?.propertiesInformation?.buyerData,
                "buyerInfo"
              ),
            },
            sellerInfo: {
              sellers: FormatTransactionPartiesInfo(
                transaction?.propertiesInformation?.sellerData,
                "sellerInfo"
              ),
            },
          };

          if (transaction?.agreement_information?.documentType === "Rent") {
            state.transactionData = {
              ...state.transactionData,
              transactionInformation: {
                ...state.transactionData?.transactionInformation,
                sale_rate_sq_feet: 0,
                saleValue: 0,
                rent_licence_fees: Number(
                  state.transactionData?.transactionInformation?.compensation
                ),
                rent_rate_sq_feet:
                  Number(
                    state.transactionData?.transactionInformation?.compensation
                  ) /
                  Number(
                    state.transactionData?.transactionInformation
                      ?.chargeableSquareFeet
                  ),
              },
            };
          } else if (
            transaction?.agreement_information?.documentType === "Sale"
          ) {
            state.transactionData = {
              ...state.transactionData,
              transactionInformation: {
                ...state.transactionData?.transactionInformation,
                rent_rate_sq_feet: 0,
                rent_licence_fees: 0,
                saleValue: Number(
                  state.transactionData?.transactionInformation?.compensation
                ),
                sale_rate_sq_feet:
                  Number(
                    state.transactionData?.transactionInformation?.compensation
                  ) /
                  Number(
                    state.transactionData?.transactionInformation
                      ?.chargeableSquareFeet
                  ),
              },
            };
          }

          if (
            state.transactionData?.transactionInformation?.market_price &&
            Number(
              state.transactionData?.transactionInformation?.market_price
            ) &&
            state.transactionData?.transactionInformation
              ?.chargeableSquareFeet &&
            Number(
              state.transactionData?.transactionInformation
                ?.chargeableSquareFeet
            )
          ) {
            state.transactionData = {
              ...state.transactionData,
              transactionInformation: {
                ...state.transactionData?.transactionInformation,
                declared_circle_rate:
                  Number(
                    state.transactionData?.transactionInformation?.market_price
                  ) /
                  Number(
                    state.transactionData?.transactionInformation
                      ?.chargeableSquareFeet
                  ),
              },
            };
          }

          if (transaction?.transactionInformation?.propertyImages?.length > 0) {
            state.transactionData = {
              ...transaction,
              propertyImages: FormatTransactionImageFormat(
                transaction?.transactionInformation?.propertyImages
              ),
            };
          }

          state.moreTransactionId =
            transaction?.moreTransactionId || state.moreTransactionId;
          state.loading = false;
        },
      }
    ),
    setTransactionInitialState: (state, action) => {
      state = initialState;
    },
    setTransactionId: (state, action) => {
      state.transactionId = action?.payload;
    },
    getPropertyDetailsData: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          let data = await _getPropertyDetailsData(param);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
          state.transactionData = {};
        },
        rejected: (state, action) => {
          state.loading = false;
          state.transactionData = {};
        },
        fulfilled: (state, action) => {
          state.transactionData = {
            ...state.transactionData,
            others: {
              property_description:
                action?.payload?.data?.[0]?.propertyDetail?.propertyDescription,
              remarks: action?.payload?.data?.[0]?.propertyDetail?.remarks,
            },
            legacy_propertiesInformation: {
              sellers: [],
              buyers: [],
            },
            buildingInformation: FormatNewTransactionPropertyDetails(
              action?.payload?.data?.[0]
            ),
          };
          state.loading = false;
        },
      }
    ),
    setLegacyPartiesInfo: (state, action) => {
      if (action?.payload?.obj === "legacy_propertiesInformation") {
        if (action?.payload?.name === "buyersallRepresentativeName") {
          state.transactionData = {
            ...state.transactionData,
            legacy_propertiesInformation: {
              ...state.transactionData.legacy_propertiesInformation,
              buyers: [
                {
                  ...state.transactionData.legacy_propertiesInformation
                    .buyers[0],
                  allRepresentativeName: action?.payload?.value,
                },
              ],
            },
          };
        } else if (action?.payload?.name === "sellersallRepresentativeName") {
          state.transactionData = {
            ...state.transactionData,
            legacy_propertiesInformation: {
              ...state.transactionData.legacy_propertiesInformation,
              sellers: [
                {
                  ...state.transactionData.legacy_propertiesInformation
                    .buyers[0],
                  allRepresentativeName: action?.payload?.value,
                },
              ],
            },
          };
        } else {
          state.transactionData = {
            ...state.transactionData,
            legacy_propertiesInformation: {
              ...state.transactionData.legacy_propertiesInformation,
              [action?.payload?.name]: [
                {
                  personName: action?.payload?.value,
                  panNumber: null,
                  aadharNumber: null,
                  contactNumber: null,
                  emailId: null,
                },
              ],
            },
          };
        }
      } else if (action?.payload?.obj === "sellerInfo") {
        state.transactionData = {
          ...state.transactionData,
          sellerInfo: {
            [action?.payload?.name]: action?.payload?.value,
          },
        };
      } else if (action?.payload?.obj === "buyerInfo") {
        state.transactionData = {
          ...state.transactionData,
          buyerInfo: {
            [action?.payload?.name]: action?.payload?.value,
          },
        };
      }
    },
    getCompanyWithSearchableText: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          let data = await _getCompanyWithSearchableText(param);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.companies = [];
        },
        rejected: (state, action) => {
          state.companies = [];
        },
        fulfilled: (state, action) => {
          const companiesArray = [];
          for (
            let index = 0;
            index < action?.payload?.data?.companies?.length;
            index++
          ) {
            const element = {
              label: action?.payload?.data?.companies?.[index]?.company,
              key: action?.payload?.data?.companies?.[index]?._id,
              value: action?.payload?.data?.companies?.[index]?._id,
            };
            companiesArray.push(element);
          }
          state.companies = companiesArray;
        },
      }
    ),
    getAllDocumentMaster: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          let data = await _getAllDocumentMaster();
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.documentNameMaster = [];
        },
        rejected: (state, action) => {
          state.documentNameMaster = [];
        },
        fulfilled: (state, action) => {
          const documentNameMasterArray = [];
          const documentTypeMasterArray = [];
          const documentTypeSet = new Set();

          action?.payload?.data?.forEach(item => {
            const documentNameElement = {
              label: item?.documentName,
              key: item?._id,
              value: item?.documentName,
            };

            documentNameMasterArray.push(documentNameElement);

            if (!documentTypeSet.has(item?.documentType)) {
              documentTypeSet.add(item?.documentType);
              const documentTypeElement = {
                label: item?.documentType,
                key: item?._id,
                value: item?.documentType,
              };
              documentTypeMasterArray.push(documentTypeElement);
            }
          });

          state.documentNameMaster = documentNameMasterArray;
          state.documentTypeMaster = documentTypeMasterArray;
          state.documentMaster = action?.payload?.data;
        },
      }
    ),
  }),
});

export const {
  getTransactionList,
  getAllTransactions,
  setTransactionInfoEditable,
  setTransactionInfo,
  updateTransactionInfo,
  setCurrentPage,
  setPageSize,
  getFilteredTransaction,
  getTransactionDetailsData,
  setTransactionInitialState,
  setTransactionId,
  getPropertyDetailsData,
  getCompanyWithSearchableText,
  saveTransactionInfo,
  setLegacyPartiesInfo,
  getAllDocumentMaster,
  deleteTransaction,
} = transactionDetailsSlice.actions;
export default transactionDetailsSlice.reducer;
