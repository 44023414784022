import React, { useState, useEffect } from "react";
import Title from "antd/es/typography/Title";
import { Form, Button, Divider, Input, message, Select } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addLocalityData,
  fetchLocalityById,
  updateLocalityData,
} from "../../../feature/slice/LocalitySlice";
import { fetchAllCitiesByStateId } from "../../../feature/slice/CitySlice";
import { fetchAllMicroMarketByCityId } from "../../../feature/slice/MicroMarketSlice";
import { fetchAllPincodesByMicroMarketId } from "../../../feature/slice/PincodeSlice";

const LocalityDetailsCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedMicroMarket, setSelectedMicroMarket] = useState(null);
  const [selectedPincode, setSelectedPincode] = useState(null);
  const { statesForDD } = useSelector((state) => state.masterDetails);
  const { citiesByStateId } = useSelector((state) => state.cityDetails);
  const { microMarketsByCityId } = useSelector(
    (state) => state.microMarketDetails
  );
  const { pincodesByMicroMarketId } = useSelector(
    (state) => state.pincodeDetails
  );
  const { localityDataById } = useSelector((state) => state.localityDetails);

  const { localityId } = location.state || {};

  useEffect(() => {
    if (localityId) {
      dispatch(fetchLocalityById(localityId));
    }
  }, [localityId, dispatch]);

  useEffect(() => {
    if (localityId && localityDataById) {
      setSelectedState(localityDataById.stateId);
      setSelectedCity(localityDataById.cityId);
      setSelectedMicroMarket(localityDataById.microMarketId);
      setSelectedPincode(localityDataById.pincodeId);

      dispatch(fetchAllCitiesByStateId(localityDataById.stateId));
      dispatch(fetchAllMicroMarketByCityId(localityDataById.cityId));
      dispatch(fetchAllPincodesByMicroMarketId(localityDataById.microMarketId));

      form.setFieldsValue({
        stateId: localityDataById.stateId,
        cityId: localityDataById.cityId,
        microMarketId: localityDataById.microMarketId,
        pincodeId: localityDataById.pincodeId,
        locality: localityDataById.locality,
      });
    }
  }, [localityId, localityDataById, dispatch, form]);

  const handleAdd = (values) => {
    dispatch(addLocalityData(values))
      .then(() => {
        message.success("Locality added successfully");
        navigate("/masterlist");
      })
      .catch((err) => {
        message.error("Failed to add Locality data, Locality already exists!");
        console.error("Failed to add Locality data:", err);
      });
  };

  const handleUpdate = (values) => {
    const {
      locality: newLocality,
      stateId: newStateId,
      cityId: newCityId,
      microMarketId: newMicroMarketId,
      pincodeId: newPincodeId,
    } = values;

    const originalStateId = localityDataById.stateId;
    const originalCityId = localityDataById.cityId;
    const originalMicroMarketId = localityDataById.microMarketId;
    const originalPincodeId = localityDataById.pincodeId;
    const originalLocality = localityDataById.locality;

    if (
      newLocality === originalLocality &&
      newStateId === originalStateId &&
      newCityId === originalCityId &&
      newMicroMarketId === originalMicroMarketId &&
      newPincodeId === originalPincodeId
    ) {
      message.warning(
        "No changes detected in Locality, state, city, microMarket, or pincode."
      );
      return;
    }

    dispatch(updateLocalityData({ localityId, localityData: values }))
      .then(() => {
        message.success("Locality updated successfully");
        navigate("/masterlist");
      })
      .catch((err) => {
        message.error("Failed to update Locality data!");
        console.error("Failed to update Locality data:", err);
      });
  };

  const handleSubmit = (values) => {
    if (localityId) {
      handleUpdate(values);
    } else {
      handleAdd(values);
    }
  };

  const handleStateChange = (value) => {
    setSelectedState(value);
    setSelectedCity(null);
    setSelectedMicroMarket(null);
    setSelectedPincode(null);
    dispatch(fetchAllCitiesByStateId(value));
    form.setFieldsValue({
      cityId: null,
      microMarketId: null,
      pincodeId: null,
      //locality: null,
    });
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
    setSelectedMicroMarket(null);
    setSelectedPincode(null);
    dispatch(fetchAllMicroMarketByCityId(value));
    form.setFieldsValue({
      microMarketId: null,
      pincodeId: null,
      // locality: null,
    });
  };

  const handleMicroMarketChange = (value) => {
    setSelectedMicroMarket(value);
    setSelectedPincode(null);
    dispatch(fetchAllPincodesByMicroMarketId(value));
    form.setFieldsValue({
      pincodeId: null,
      // locality: null,
    });
  };

  const handlePincodeChange = (value) => {
    setSelectedPincode(value);
  };

  return (
    <>
      <div
        style={{
          alignItems: "center",
          padding: "10px",
          marginBottom: "1rem",
          width: "100%",
          backgroundColor: "white",
          boxShadow: "0 8px 8px -8px rgba(0, 0, 0, 0.5)",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1rem",
            padding: "10px",
            marginBottom: "20px",
            width: "100%",
            position: "sticky",
            top: 0,
            zIndex: 1000,
            backgroundColor: "white",
            boxShadow: "0 8px 8px -8px rgba(0, 0, 0, 0.5)",
          }}
        >
          <Title level={4}>
            {localityId
              ? "Update Locality Information"
              : "Add Locality Information"}
          </Title>
          <Button onClick={() => navigate("/masterlist")}>Back</Button>
        </div>
        <Divider style={{ backgroundColor: "black", marginTop: "0" }} />
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          style={{ maxWidth: "400px", margin: "0 auto" }}
        >
          <Form.Item
            label="State Name"
            name="stateId"
            rules={[{ required: true, message: "Please select a state!" }]}
          >
            <Select
              placeholder="Select a State"
              onChange={handleStateChange}
              value={selectedState}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {statesForDD?.length > 0 ? (
                statesForDD?.map((state) => (
                  <Select.Option key={state._id} value={state._id}>
                    {state.state}
                  </Select.Option>
                ))
              ) : (
                <Select.Option disabled></Select.Option>
              )}
            </Select>
          </Form.Item>

          <Form.Item
            label="City Name"
            name="cityId"
            rules={[{ required: true, message: "Please select a city!" }]}
          >
            <Select
              placeholder="Select a City"
              onChange={handleCityChange}
              value={selectedCity}
              disabled={!selectedState}
              showSearch // Enable search
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {citiesByStateId?.length > 0 ? (
                citiesByStateId
                  .filter((city) => city.stateId._id === selectedState)
                  ?.map((city) => (
                    <Select.Option key={city._id} value={city._id}>
                      {city.city}
                    </Select.Option>
                  ))
              ) : (
                <Select.Option disabled></Select.Option>
              )}
            </Select>
          </Form.Item>

          <Form.Item
            label="MicroMarket Name"
            name="microMarketId"
            rules={[
              { required: true, message: "Please select a microMarket!" },
            ]}
          >
            <Select
              placeholder="Select a microMarket"
              onChange={handleMicroMarketChange}
              value={selectedMicroMarket}
              disabled={!selectedCity}
              showSearch // Enable search
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {microMarketsByCityId?.length > 0 ? (
                microMarketsByCityId
                  .filter((micromarket) => micromarket.cityId === selectedCity)
                  ?.map((micromarket) => (
                    <Select.Option
                      key={micromarket._id}
                      value={micromarket._id}
                    >
                      {micromarket.microMarket}
                    </Select.Option>
                  ))
              ) : (
                <Select.Option disabled></Select.Option>
              )}
            </Select>
          </Form.Item>

          <Form.Item
            label="Pincode"
            name="pincodeId"
            rules={[{ required: true, message: "Please select a pincode!" }]}
          >
            <Select
              placeholder="Select a Pincode"
              onChange={handlePincodeChange}
              value={selectedPincode}
              disabled={!selectedMicroMarket}
              showSearch // Enable search
              filterOption={(input, option) =>
                option.children
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {pincodesByMicroMarketId?.length > 0 ? (
                pincodesByMicroMarketId
                  .filter(
                    (pincode) => pincode.microMarketId === selectedMicroMarket
                  )
                  ?.map((pincode) => (
                    <Select.Option key={pincode._id} value={pincode._id}>
                      {pincode.pincode.toString()}{" "}
                      {/* Convert to string for display */}
                    </Select.Option>
                  ))
              ) : (
                <Select.Option disabled></Select.Option>
              )}
            </Select>
          </Form.Item>

          <Form.Item
            label="Locality"
            name="locality"
            rules={[{ required: true, message: "Please enter locality name!" }]}
          >
            <Input
              placeholder="Enter Locality Name"
              disabled={!selectedPincode}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              style={{ marginTop: "1rem" }}
            >
              {localityId ? "Update" : "Submit"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default LocalityDetailsCard;
