import { client } from "../../utils/apiClient";
import { asyncHandler } from "../../utils/asyncHandler";

const saveStateDetailsData = async (param) => {
  try {
    const [data, err] = await asyncHandler(client.post(`/api/create-state`, param, {
      headers: {
        "Content-Type": "application/json",
      },
    }));
    return data || err;
  } catch (err) {
    console.error(err);
  }
};

const getAllStatesData = async () => {
  try {
    const [data, err] = await asyncHandler(client.get(`/api/get-all-states-for-dd`));
    return data || err;
  } catch (err) {
    console.error(err);
  }
};

const getAllStatesWithPagination = async (page, pageSize) => {
  try {
    const [data, err] = await asyncHandler(
      client.get(`/api/get-all-states?page=${page}&pageSize=${pageSize}`)
    );
    return data || err;
  } catch (err) {
    console.error("Error fetching states:", err);
  }
};

const getStateById = async (id) => {
  try {
    const [data, err] = await asyncHandler(client.get(`/api/get-state/${id}`));
    return data || err;
  } catch (err) {
    console.error(err);
  }
};

const updateStateDetailsData = async (id, param) => {
  try {
    const [data, err] = await asyncHandler(client.put(`/api/update-state/${id}`, param, {
      headers: {
        "Content-Type": "application/json",
      },
    }));
    return data || err;
  } catch (err) {
    console.error(err);
  }
};

const deleteStateById = async (id) => {
  try {
    const [data, err] = await asyncHandler(client.delete(`/api/delete-state/${id}`));
    return data || err;
  } catch (err) {
    console.error(err);
  }
};

export {
  saveStateDetailsData,
  getAllStatesData,
  getAllStatesWithPagination,
  updateStateDetailsData,
  deleteStateById,
  getStateById,
};

