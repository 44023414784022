import { buildCreateSlice, asyncThunkCreator } from "@reduxjs/toolkit";
import { get } from "lodash";

import {
  getAllMicroMarketsWithPagination as _getAllMicroMarketsWithPagination,
  addMicroMarketData as _addMicroMarketData,
  updateMicroMarketData as _updateMicroMarketData,
  deleteMicroMarketById as _deleteMicroMarketById,
  getMicroMarketById as _getMicroMarketById,
  getAllMicroMarketByCityId as _getAllMicroMarketByCityId,
} from "../services/MicroMarketAPI";

const createSliceWithThunks = buildCreateSlice({
  creators: {
    asyncThunk: asyncThunkCreator,
  },
});

const initialState = {
  loading: false,
  microMarketMaster: [],
  microMarketById: [],
  microMarketsByCityId: [],
  microMarketId: null,
  totalMicroMarketCount: 0,
  currentPage: 1,
  pageSize: 10,
};

const MicroMarketSlice = createSliceWithThunks({
  name: "microMarket",
  initialState,
  reducers: (create) => ({
    setCurrentPage: (state, action) => {
      state.currentPage = action?.payload;
    },

    setPageSize: (state, action) => {
      state.pageSize = action?.payload;
    },

    fetchAllMicroMarketsWithPagination: create.asyncThunk(
      async ({ page, pageSize }, thunkAPI) => {
        try {
          const data = await _getAllMicroMarketsWithPagination(page, pageSize);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          return thunkAPI.rejectWithValue({ message });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.microMarketMaster = action.payload.data.data;
          state.totalMicroMarketCount = action.payload.data.total;
        },
      }
    ),

    addMicroMarketData: create.asyncThunk(
      async (microMarketData, thunkAPI) => {
        try {
          let data = await _addMicroMarketData(microMarketData);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.microMarketMaster.push(action.payload.data);
          state.microMarketId = action.payload.data._id;
        },
      }
    ),

    updateMicroMarketData: create.asyncThunk(
      async ({ microMarketId, microMarketData }, thunkAPI) => {
        try {
          const data = await _updateMicroMarketData(
            microMarketId,
            microMarketData
          );
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          return thunkAPI.rejectWithValue({ message });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          const index = state.microMarketMaster.findIndex(
            (microMarket) => microMarket._id === action.payload.data._id
          );
          if (index !== -1) {
            state.microMarketMaster[index] = action.payload.data;
          }
        },
      }
    ),

    deleteMicroMarket: create.asyncThunk(
      async (id, thunkAPI) => {
        try {
          const data = await _deleteMicroMarketById(id);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          return thunkAPI.rejectWithValue({ message });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.microMarketMaster = state.microMarketMaster.filter(
            (microMarket) => microMarket._id !== action.payload.data._id
          );
        },
      }
    ),

    fetchMicroMarketById: create.asyncThunk(
      async (id, thunkAPI) => {
        try {
          const data = await _getMicroMarketById(id);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          return thunkAPI.rejectWithValue({ message });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.microMarketById = action.payload.data;
        },
      }
    ),

    fetchAllMicroMarketByCityId: create.asyncThunk(
      async (cityId, thunkAPI) => {
        try {
          const data = await _getAllMicroMarketByCityId(cityId);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          return thunkAPI.rejectWithValue({ message });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.microMarketsByCityId = action.payload.data;
        },
      }
    ),
  }),
});

export const {
  fetchMicroMarketById,
  fetchAllMicroMarketByCityId,
  fetchAllMicroMarketsWithPagination,
  addMicroMarketData,
  updateMicroMarketData,
  deleteMicroMarket,
  setCurrentPage,
  setPageSize,
} = MicroMarketSlice.actions;

export default MicroMarketSlice.reducer;
