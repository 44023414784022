import React, { useEffect, useState } from "react";
import { Table, Typography, Pagination, Button, Modal, message, Spin, Tooltip, Select } from "antd";
import { Dropdown, Checkbox } from 'antd';
import { useNavigate } from "react-router";
import { Copy } from "react-feather";
import { MdDeleteForever } from "react-icons/md";
import "./Property.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getPropertiesList,
  setCurrentPage,
  setPageSize,
  setPropertyId,
  getFilteredProperty,
  setPropertyInitialState,
  setTransactionCnt,
  deleteProperty,
  setPropertyDetails,
} from "../../feature/slice/PropertyDetailsSlice";
import {
  setCurrentPage as _setTransactionsCurrentPage,
  setPageSize as _setTransactionsPageSize,
} from "../../feature/slice/TransactionDetailsSlice";
import { getLocalities, setFilters } from "../../feature/slice/FiltersSlice";
import { ReloadOutlined } from "@ant-design/icons";
import { bulkUploadProperties } from "../../feature/services/PropertyDetailsAPI";
import { CommonFilter, getDynamicTitle } from '../../components/filterCard/FilterCard'
import dayjs from "dayjs";
import utc from 'dayjs-plugin-utc';
import DownloadIcon from '../../assets/download.svg';
import BulkUpload from "../../assets/bulk.svg";
import AddProperty from "../../assets/add.svg";
import { MdTune } from 'react-icons/md';
import { DollarOutlined, HomeOutlined } from "@ant-design/icons";
import { amenitiesLists, buildingClassification } from '../propertyDetailsCard/PropertyDetailsCard'
import "../company/Company.css";
dayjs.extend(utc);

const Property = (props) => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { loading, propertyDetailsData, currentPage, pageSize, propertiesCnt } =
    useSelector((store) => store.propertyDetails);
  const { localitiesData, citiesData } = useSelector(
    (store) => store.filters
  );

  const filter = {
    propertyName: '',
    propertyNameFilter: 'contains',
    completionRatio: null,
    completionRatioFilter: 'equals',
    locality: [],
    city: [],
    noTransaction: null,
    noTransactionFilter: 'equals',
    startDate: null,
    endDate: null,
    viewMode: "Commercial",
    buildingStructure: null,
    buildingStructureFilter: 'contains',
    buildingClassification: null,
    buildingStatus: [],
    totalChargeableArea: null,
    totalChargeableAreaFilter: 'equals',
    proposedAvailabilityStartDate: null,
    proposedAvailabilityEndDate: null,
    gradeOfBuilding: null,
    gradeOfBuildingFilter: 'contains',
    developer: null,
    developerFilter: 'contains',
    totalTransactionsAmount: null,
    totalTransactionsAmountFilter: 'equals',
    lastUpdatedOnStartDate: null,
    lastUpdatedOnEndDate: null,
    updatedBy: null,
    updatedByFilter: 'contains',
    createdBy: null,
    createdByFilter: 'contains',
    buildingStats: null,
    buildingStatsFilter: 'contains',
    amenities: [],
    repPersonName: null,
    repPersonNameFilter: 'contains',
    repPersonContact: null,
    repPersonContactFilter: 'contains',
    repPersonEmail: null,
    repPersonEmailFilter: 'contains',
    projectReraId: null,
    projectReraIdFilter: 'contains',
  }
  const [messageApi, contextHolder] = message.useMessage();

  const [filterProperty, setFilterProperty] = useState(filter)

  const [tableField, setTableField] = useState({
    "ID": false,
    "Property Name": false,
    "Project RERA Id": false,
    "Locality": false,
    "City": false,
    "No of Transactions": false,
    "Updated By": false,
    "last Updated Date": false,
    "Completion Ratio": false,
    "Operation": false,
    "Delete": false,
    "Property Structure": true,
    "Property Classification": true,
    "Property Status": true,
    "Property Stats": true,
    "Total Chargeable Area": true,
    "Proposed Availability Date": true,
    "Grade Of Building": true,
    "Developer": true,
    "Amenities": true,
    "Total Transactions Amount": true,
    "Rep Person Name": true,
    "Rep Person Contact": true,
    "Rep Person Email": true,
    "Created By": true,
    "Created Date": true
  })

  const [visible, setVisible] = useState(false);


  // const handleRowClick = (record) => {
  //   handleResetPropertySearch();
  //   navigateTo(`/propertyDetails/${record?._id}`);
  //   dispatch(setPropertyId(record?._id));
  // };

  useEffect(() => {
    const propertyFilterStatus = JSON.parse(localStorage.getItem('propertyFilterStatus')) || filter;
    setFilterProperty(propertyFilterStatus);
    fetchProperties(1, 10, propertyFilterStatus);
    dispatch(setPropertyId(null));
    dispatch(setPropertyDetails({}));
    dispatch(setPropertyId(null));
    dispatch(getLocalities());
    const savedTableField = localStorage.getItem('tableFieldStatus');
    if (savedTableField) {
      setTableField(JSON.parse(savedTableField));
    }
  }, []);

  const fetchProperties = async (page, size, updatedFilterProperty = filterProperty) => {
    try {
      dispatch(setPropertyId(null));
      dispatch(setCurrentPage(page));
      dispatch(setPageSize(size));
      if (
        updatedFilterProperty?.propertyName ||
        updatedFilterProperty?.completionRatio ||
        updatedFilterProperty?.noTransaction ||
        updatedFilterProperty?.locality?.length > 0 ||
        updatedFilterProperty?.city?.length > 0 ||
        updatedFilterProperty?.startDate ||
        updatedFilterProperty?.endDate ||
        updatedFilterProperty?.viewMode ||
        updatedFilterProperty?.buildingAge ||
        updatedFilterProperty?.buildingStructure ||
        updatedFilterProperty?.buildingClassification.length > 0 ||
        updatedFilterProperty?.buildingStatus.length > 0 ||
        updatedFilterProperty?.totalChargeableArea ||
        updatedFilterProperty?.proposedAvailabilityStartDate ||
        updatedFilterProperty?.proposedAvailabilityEndDate ||
        updatedFilterProperty?.gradeOfBuilding ||
        updatedFilterProperty?.developer ||
        updatedFilterProperty?.totalTransactionsAmount ||
        updatedFilterProperty?.lastUpdatedOnStartDate ||
        updatedFilterProperty?.lastUpdatedOnEndDate ||
        updatedFilterProperty?.updatedBy ||
        updatedFilterProperty?.createdBy ||
        updatedFilterProperty?.buildingStats ||
        updatedFilterProperty?.repPersonName ||
        updatedFilterProperty?.repPersonContact ||
        updatedFilterProperty?.repPersonEmail ||
        updatedFilterProperty?.amenities.length > 0 ||
        updatedFilterProperty?.projectReraId
      ) {
        const body = {
          propertyName: updatedFilterProperty?.propertyName,
          propertyNameFilter: updatedFilterProperty?.propertyNameFilter,
          locality: updatedFilterProperty?.locality,
          city: updatedFilterProperty?.city,
          completionRatio: +updatedFilterProperty?.completionRatio || null,
          completionRatioFilter: updatedFilterProperty?.completionRatioFilter,
          noTransaction: +updatedFilterProperty?.noTransaction || null,
          noTransactionFilter: updatedFilterProperty?.noTransactionFilter,
          startDate: updatedFilterProperty?.startDate,
          endDate: updatedFilterProperty?.endDate,
          viewMode: updatedFilterProperty?.viewMode,
          buildingStructure: updatedFilterProperty?.buildingStructure,
          buildingStructureFilter: updatedFilterProperty?.buildingStructureFilter,
          buildingClassification: updatedFilterProperty?.buildingClassification,
          buildingStatus: updatedFilterProperty?.buildingStatus,
          totalChargeableArea: +updatedFilterProperty?.totalChargeableArea,
          totalChargeableAreaFilter: updatedFilterProperty?.totalChargeableAreaFilter,
          proposedAvailabilityStartDate: updatedFilterProperty?.proposedAvailabilityStartDate,
          proposedAvailabilityEndDate: updatedFilterProperty?.proposedAvailabilityEndDate,
          gradeOfBuilding: updatedFilterProperty?.gradeOfBuilding,
          gradeOfBuildingFilter: updatedFilterProperty?.gradeOfBuildingFilter,
          developer: updatedFilterProperty?.developer,
          developerFilter: updatedFilterProperty?.developerFilter,
          totalTransactionsAmount: +updatedFilterProperty?.totalTransactionsAmount,
          totalTransactionsAmountFilter: updatedFilterProperty?.totalTransactionsAmountFilter,
          lastUpdatedOnStartDate: updatedFilterProperty?.lastUpdatedOnStartDate,
          lastUpdatedOnEndDate: updatedFilterProperty?.lastUpdatedOnEndDate,
          updatedBy: updatedFilterProperty?.updatedBy,
          updatedByFilter: updatedFilterProperty?.updatedByFilter,
          createdBy: updatedFilterProperty?.createdBy,
          createdByFilter: updatedFilterProperty?.createdByFilter,
          buildingStats: updatedFilterProperty?.buildingStats,
          buildingStatsFilter: updatedFilterProperty?.buildingStatsFilter,
          amenities: updatedFilterProperty?.amenities,
          repPersonName: updatedFilterProperty?.repPersonName,
          repPersonNameFilter: updatedFilterProperty?.repPersonNameFilter,
          repPersonContact: updatedFilterProperty?.repPersonContact,
          repPersonContactFilter: updatedFilterProperty?.repPersonContactFilter,
          repPersonEmail: updatedFilterProperty?.repPersonEmail,
          repPersonEmailFilter: updatedFilterProperty?.repPersonEmailFilter,
          projectReraId: updatedFilterProperty?.projectReraId,
          projectReraIdFilter: updatedFilterProperty?.projectReraIdFilter,
          currentPage: page,
          pageSize: size,
        };
        dispatch(getFilteredProperty(body));
      } else {
        const propertyType =
          filterProperty.viewMode === "residential" ? "Residential" : "Commercial";
        const body = {
          pageSize: size,
          page,
          viewMode: propertyType,
        };
        dispatch(getPropertiesList(body));
      }
    } catch (error) {
      console.error("Failed to fetch properties:", error);
      messageApi.error("Failed to fetch properties");
    }
  };


  const handleDelete = (propertyId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this property?",
      onOk: async () => {
        try {
          const response = await dispatch(deleteProperty(propertyId)).unwrap();
          messageApi.success(response.message || "Property deleted successfully");

          setTimeout(() => {
            dispatch(setCurrentPage(1));
            fetchProperties(currentPage, pageSize);
          }, 3000);
        } catch (error) {
          messageApi.error(error.message || "Failed to delete property");
        }
      },
    });
  };

  const handleRowClick = (record) => {
    handleResetPropertySearch();
    navigateTo(`/propertyDetails/${record?._id}`);
    dispatch(setPropertyId(record?._id));
  };

  const handleCopyId = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        message.success("ID copied to clipboard");
      }).catch(err => {
        message.error("Failed to copy ID");
        console.error("Error copying to clipboard:", err);
      });
    } else {
      message.error("Clipboard API not supported");
    }
  };

  const applyFilter = (confirm) => {
    localStorage.setItem('propertyFilterStatus', JSON.stringify(filterProperty));
    dispatch(setCurrentPage(1));
    dispatch(setPageSize(10));
    fetchProperties(1, 10);
    confirm();
  }

  const resetFilter = (columnKey, defaultFilterValue, defaultFilterType, clearFilters, confirm) => {
    const updatedFilterProperty = { ...filterProperty, [columnKey]: defaultFilterValue, [`${columnKey}Filter`]: defaultFilterType };
    localStorage.setItem('propertyFilterStatus', JSON.stringify(updatedFilterProperty));
    setFilterProperty(updatedFilterProperty);
    dispatch(setCurrentPage(1));
    dispatch(setPageSize(10));
    fetchProperties(1, 10, updatedFilterProperty);
    clearFilters();
    confirm();
  };

  const columns = [
    {
      title: "ID",
      column: "ID",
      dataIndex: "_id",
      width: "3%",
      editable: false,
      hidden: tableField["ID"],
      render: (text) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            height: "100%",
            lineHeight: "32px",
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleCopyId(text);
          }}
        >
          <Copy size={20} className="text-secondary" />
        </div>
      ),
    },
    {
      title: () => getDynamicTitle("Property Name", filterProperty.propertyName),
      column: "Property Name",
      dataIndex: "propertyName",
      hidden: tableField["Property Name"],
      width: "20%",
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterProperty.propertyName}
          filterType={filterProperty.propertyNameFilter}
          onFilterChange={(value) => setFilterProperty({ ...filterProperty, propertyName: value })}
          onFilterTypeChange={(value) => setFilterProperty({ ...filterProperty, propertyNameFilter: value })}
          onApply={() => {

            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("propertyName", null, "contains", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'contains', label: 'Contains' },
            { value: 'startsWith', label: 'Starts with' },
            { value: 'endsWith', label: 'Ends with' },
          ]}
          placeholder="Enter the property name here"
        />
      ),
      filterSearch: true,
      render: (text, record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {text}
        </div>
      ),
    },
    {
      title: () => getDynamicTitle("Project RERA Id", filterProperty.projectReraId),
      column: "Project RERA Id",
      dataIndex: "projectReraId",
      hidden: tableField["Project RERA Id"],
      width: "20%",
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterProperty.projectReraId}
          filterType={filterProperty.projectReraIdFilter}
          onFilterChange={(value) => setFilterProperty({ ...filterProperty, projectReraId: value })}
          onFilterTypeChange={(value) => setFilterProperty({ ...filterProperty, projectReraIdFilter: value })}
          onApply={() => {

            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("projectReraId", null, "contains", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'contains', label: 'Contains' },
            { value: 'startsWith', label: 'Starts with' },
            { value: 'endsWith', label: 'Ends with' },
          ]}
          placeholder="Enter the Project RERA Id here"
        />
      ),
      filterSearch: true,
      render: (text, record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {text}
        </div>
      ),
    },
    {
      title: () => getDynamicTitle("Locality", filterProperty.locality),
      column: "Locality",
      dataIndex: "locality",
      hidden: tableField["Locality"],
      width: "10%",
      editable: true,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterProperty.locality || []}  // Initialize with an empty array if no filter value
          onFilterChange={(value) => {
            setFilterProperty({ ...filterProperty, locality: value });  // Update filter value
          }}
          onApply={() => {
            fetchProperties(1, 10);  // Fetch new data based on filter
            confirm();
          }}
          onReset={() => {
            const updatedFilterProperty = { ...filterProperty, locality: [] }
            setFilterProperty(updatedFilterProperty)
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchProperties(1, 10, updatedFilterProperty);
            clearFilters();
            confirm();
          }}
          inputType="select"  // Set input type to 'select'
          mode="multiple"  // Allow multiple selections
          placeholder="Select a locality"
          inputRef= 'locality'
          selectOptions={localitiesData?.map(locality => ({  // Map localities to options
            value: locality.value,
            label: locality.title,
          }))}
        />
      ),
      onFilter: (value, record) => record.locality.includes(value),  // Filter function
      render: (text, record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(record)}  // Row click handler
        >
          {text}
        </div>
      ),
    },
    {
      title: () => getDynamicTitle("City", filterProperty.city),
      column: "City",
      dataIndex: "city",
      hidden: tableField["City"],
      width: "10%",
      editable: true,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterProperty.city || []}
          onFilterChange={(value) => {
            const updatedFilterProperty = { ...filterProperty, city: value };
            setFilterProperty(updatedFilterProperty);
          }}
          onApply={() => {
            fetchProperties(1, 10);
            confirm();
          }}
          onReset={() => {
            const updatedFilterProperty = { ...filterProperty, city: null }
            setFilterProperty(updatedFilterProperty)
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchProperties(1, 10, updatedFilterProperty);
            clearFilters();
            confirm();
          }}
          inputType="select"
          mode="multiple"
          placeholder="Select a city"
          inputRef= 'city'
          selectOptions={citiesData?.map(city => ({
            value: city.value,
            label: city.title,
          }))}
        />
      ),
      render: (text, record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {text}
        </div>
      ),
    },
    {
      title: () => getDynamicTitle("No of Transactions", filterProperty.noTransaction),
      column: "No of Transactions",
      dataIndex: "noTransaction",
      hidden: tableField["No of Transactions"],
      width: "15%",
      editable: true,
      render: (text, record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {text}
        </div>
      ),
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          inputType="number"
          filterValue={filterProperty.noTransaction}
          filterType={filterProperty.noTransactionFilter}
          onFilterChange={(value) => setFilterProperty({ ...filterProperty, noTransaction: value })}
          onFilterTypeChange={(value) => setFilterProperty({ ...filterProperty, noTransactionFilter: value })}
          onApply={() => {
            fetchProperties(1, 10);
            confirm();
          }}
          onReset={() => {
            const updatedFilterProperty = { ...filterProperty, noTransaction: null, noTransactionFilter: 'equals' }
            setFilterProperty(updatedFilterProperty)
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchProperties(1, 10, updatedFilterProperty);
            clearFilters();
            confirm();
          }}
          filterTypeOptions={[
            { value: 'equals', label: 'Equal' },
            { value: 'lessthan', label: 'Less Than' },
            { value: 'greaterthan', label: 'Greater Than' },
          ]}
          placeholder="Enter the number of transactions here"
        />
      ),
      filterSearch: true,
    },
    {
      title: () => getDynamicTitle("Updated By", filterProperty.updatedBy),
      column: "Updated By",
      dataIndex: "updatedBy",
      hidden: tableField["Updated By"],
      width: "15%",
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterProperty.updatedBy}
          filterType={filterProperty.updatedByFilter}
          onFilterChange={(value) => setFilterProperty({ ...filterProperty, updatedBy: value })}
          onFilterTypeChange={(value) => setFilterProperty({ ...filterProperty, updatedByFilter: value })}
          onApply={() => {

            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("updatedBy", null, "contains", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'contains', label: 'Contains' },
            { value: 'startsWith', label: 'Starts with' },
            { value: 'endsWith', label: 'Ends with' },
          ]}
          placeholder="Enter the updated user name here"
        />
      ),
      filterSearch: true,
      render: (text, record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {text}
        </div>
      ),
    },

    {
      title: () => (
        <div className={filterProperty.lastUpdatedOnStartDate || filterProperty.lastUpdatedOnEndDate ? 'titleHighlight' : 'titleDefault'}>
          last Updated Date
        </div>
      ),
      column: "last Updated Date",
      dataIndex: "lastUpdatedOn",
      hidden: tableField["last Updated Date"],
      width: "10%",
      render: (lastUpdatedOn) =>
        lastUpdatedOn ? dayjs(lastUpdatedOn).utc().format("DD-MM-YYYY") : null,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          inputType="dateRange"
          startDate={filterProperty.lastUpdatedOnStartDate}
          endDate={filterProperty.lastUpdatedOnEndDate}
          onStartDateChange={(date) => setFilterProperty({ ...filterProperty, lastUpdatedOnStartDate: dayjs(date, "DD-MM-YYYY").add(5, "hour").add(30, "minute") })}
          onEndDateChange={(date) => setFilterProperty({ ...filterProperty, lastUpdatedOnEndDate: dayjs(date, "DD-MM-YYYY").add(5, "hour").add(30, "minute") })}
          onApply={() => {
            fetchProperties(1, 10, filterProperty);
            confirm();
          }}
          onReset={() => {
            const updatedFilterProperty = { ...filterProperty, lastUpdatedOnStartDate: null, lastUpdatedOnEndDate: null }
            setFilterProperty(updatedFilterProperty)
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchProperties(1, 10, updatedFilterProperty);
            clearFilters();
            confirm();
          }}
        />
      ),
      filterSearch: true,

    },
    {
      title: () => getDynamicTitle("Completion Ratio", filterProperty.completionRatio),
      dataIndex: "completionRatio",
      width: "5%",
      column: "Completion Ratio",
      hidden: tableField["Completion Ratio"],
      sorter: (a, b) => a.completionRatio - b.completionRatio,
      editable: true,
      render: (text) => (
        <div
          style={{
            height: "100%",
            lineHeight: "32px",
            cursor: "pointer",
          }}
        >
          {text}
        </div>
      ),
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          inputType="number"
          filterValue={filterProperty.completionRatio}
          filterType={filterProperty.completionRatioFilter}
          onFilterChange={(value) => setFilterProperty({ ...filterProperty, completionRatio: value })}
          onFilterTypeChange={(value) => setFilterProperty({ ...filterProperty, completionRatioFilter: value })}
          onApply={() => {
            fetchProperties(1, 10);
            confirm();
          }}
          onReset={() => {
            const updatedFilterProperty = { ...filterProperty, completionRatio: null, completionRatioFilter: 'equals' }
            setFilterProperty(updatedFilterProperty)
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchProperties(1, 10, updatedFilterProperty);
            clearFilters();
            confirm();
          }}
          filterTypeOptions={[
            { value: 'equals', label: 'Equal' },
            { value: 'lessthan', label: 'Less Than' },
            { value: 'greaterthan', label: 'Greater Than' },
          ]}
          placeholder="Enter the completion ratio here"
        />
      ),
      filterSearch: true,
    },
    {
      title: "Operation",
      column: "Operation",
      dataIndex: "operation",
      hidden: tableField["Operation"],
      width: "10%",
      align: "center",
      render: (_, record) => (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Tooltip title="Transaction">
            <Typography.Link
              onClick={() => {
                handleResetPropertySearch();
                dispatch(setPropertyId(record?._id));
                dispatch(setTransactionCnt(record?.numberOfTransaction));
                dispatch(_setTransactionsCurrentPage(1));
                dispatch(_setTransactionsPageSize(10));
                navigateTo(`/transactionListDetails/${record?._id}`);
              }}
            >
              <DollarOutlined />
            </Typography.Link>
          </Tooltip>

          <Tooltip title="Vacant Unit">
            <Typography.Link
              onClick={() => {
                handleResetPropertySearch();
                dispatch(setPropertyId(record?._id));
                dispatch(setTransactionCnt(record?.numberOfTransaction));
                dispatch(_setTransactionsCurrentPage(1));
                dispatch(_setTransactionsPageSize(10));
                navigateTo(`/vacantUnit/${record?._id}`);
              }}
            >
              <HomeOutlined />
            </Typography.Link>
          </Tooltip>
        </div>
      ),
    },

    {
      title: "Delete",
      column: "Delete",
      dataIndex: "delete",
      hidden: tableField["Delete"],
      width: "5%",
      editable: true,
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(record._id);
          }}
        >
          <MdDeleteForever size={20} className="text-secondary" />
        </div>
      ),
    },
    {
      title: () => getDynamicTitle("Property Structure", filterProperty.buildingStructure),
      column: "Property Structure",
      dataIndex: "buildingStructure",
      hidden: tableField["Property Structure"],
      width: "15%",
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterProperty.buildingStructure}
          filterType={filterProperty.buildingStructureFilter}
          onFilterChange={(value) => setFilterProperty({ ...filterProperty, buildingStructure: value })}
          onFilterTypeChange={(value) => setFilterProperty({ ...filterProperty, buildingStructureFilter: value })}
          onApply={() => {
            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("buildingStructure", null, "contains", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'contains', label: 'Contains' },
            { value: 'startsWith', label: 'Starts with' },
            { value: 'endsWith', label: 'Ends with' },
          ]}
          placeholder="Enter the Property Structure here"
        />
      ),
      filterSearch: true,
      render: (text, record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {text}
        </div>
      ),
    },
    {
      title: () => getDynamicTitle("Property Classification", filterProperty.buildingClassification),
      column: "Property Classification",
      dataIndex: "buildingClassification",
      hidden: tableField["Property Classification"],
      width: "12%",
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          placeholder="Select a property classification"
          filterValue={filterProperty.buildingClassification || null}
          onFilterChange={(value) => {
            const updatedFilterProperty = { ...filterProperty, buildingClassification: value };
            setFilterProperty(updatedFilterProperty);
          }}
          onApply={() => {
            fetchProperties(1, 10);
            confirm();
          }}
          onReset={() => {
            const updatedFilterProperty = { ...filterProperty, buildingClassification: null }
            setFilterProperty(updatedFilterProperty)
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchProperties(1, 10, updatedFilterProperty);
            clearFilters();
            confirm();
          }}
          inputType="select"
          mode="multiple"

          selectOptions={buildingClassification}
        />
      ),
      filterSearch: true,
      render: (text, record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {text}
        </div>
      ),
    },
    {
      title: () => getDynamicTitle("Property Status", filterProperty.buildingStatus),
      column: "Property Status",
      dataIndex: "buildingStatus",
      hidden: tableField["Property Status"],
      width: "12%",
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterProperty.buildingStatus || []}
          onFilterChange={(value) => {
            const updatedFilterProperty = { ...filterProperty, buildingStatus: value };
            setFilterProperty(updatedFilterProperty);
          }}
          onApply={() => {
            fetchProperties(1, 10);
            confirm();
          }}
          onReset={() => {
            const updatedFilterProperty = { ...filterProperty, buildingStatus: [] }
            setFilterProperty(updatedFilterProperty)
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchProperties(1, 10, updatedFilterProperty);
            clearFilters();
            confirm();
          }}
          inputType="select"
          mode="multiple"
          placeholder="Select a property status"
          selectOptions={[
            { value: 'Ready', label: 'Ready' },
            { value: 'Under Construction', label: 'Under Construction' },

          ]}
        />
      ),
      filterSearch: true,
      render: (text, record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {text}
        </div>
      ),
    },
    {
      title: () => getDynamicTitle("Property Stats", filterProperty.buildingStats),
      column: "Property Stats",
      dataIndex: "buildingStats",
      hidden: tableField["Property Stats"],
      width: "10%",
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterProperty.buildingStats}
          filterType={filterProperty.buildingStatsFilter}
          onFilterChange={(value) => setFilterProperty({ ...filterProperty, buildingStats: value })}
          onFilterTypeChange={(value) => setFilterProperty({ ...filterProperty, buildingStatsFilter: value })}
          onApply={() => {

            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("buildingStats", null, "contains", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'contains', label: 'Contains' },
            { value: 'startsWith', label: 'Starts with' },
            { value: 'endsWith', label: 'Ends with' },
          ]}
          placeholder="Enter the property stats here"
        />
      ),
      filterSearch: true,
      render: (text, record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {text}
        </div>
      ),
    },
    {
      title: () => getDynamicTitle("Total Chargeable Area", filterProperty.totalChargeableArea),
      column: "Total Chargeable Area",
      dataIndex: "totalChargeableArea",
      hidden: tableField["Total Chargeable Area"],
      width: "15%",
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          inputType="number"
          filterValue={filterProperty.totalChargeableArea}
          filterType={filterProperty.totalChargeableAreaFilter}
          onFilterChange={(value) => setFilterProperty({ ...filterProperty, totalChargeableArea: value })}
          onFilterTypeChange={(value) => setFilterProperty({ ...filterProperty, totalChargeableAreaFilter: value })}
          onApply={() => {
            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("totalChargeableArea", null, "equals", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'equals', label: 'Equal' },
            { value: 'lessthan', label: 'Less Than' },
            { value: 'greaterthan', label: 'Greater Than' },
          ]}
          placeholder="Enter the total chargeable area here"
        />
      ),
      filterSearch: true,
      render: (text, record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {text}
        </div>
      ),
    },

    {
      title: () => (
        <div className={filterProperty.proposedAvailabilityStartDate || filterProperty.proposedAvailabilityEndDate ? 'titleHighlight' : 'titleDefault'}>
          Proposed Availability Date
        </div>
      ),
      column: "Proposed Availability Date",
      dataIndex: "proposedAvailabilityDate",
      hidden: tableField["Proposed Availability Date"],
      width: "15%",
      render: (proposedAvailabilityDate) =>
        proposedAvailabilityDate ? dayjs(proposedAvailabilityDate).utc().format("DD-MM-YYYY") : null,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          inputType="dateRange"
          startDate={filterProperty.proposedAvailabilityStartDate}
          endDate={filterProperty.proposedAvailabilityEndDate}
          onStartDateChange={(date) => setFilterProperty({ ...filterProperty, proposedAvailabilityStartDate: dayjs(date, "DD-MM-YYYY").add(5, "hour").add(30, "minute") })}
          onEndDateChange={(date) => setFilterProperty({ ...filterProperty, proposedAvailabilityEndDate: dayjs(date, "DD-MM-YYYY").add(5, "hour").add(30, "minute") })}
          onApply={() => {
            fetchProperties(1, 10, filterProperty);
            confirm();
          }}
          onReset={() => {
            const updatedFilterProperty = { ...filterProperty, proposedAvailabilityStartDate: null, proposedAvailabilityEndDate: null }
            setFilterProperty(updatedFilterProperty)
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchProperties(1, 10, updatedFilterProperty);
            clearFilters();
            confirm();
          }}
        />
      ),
    },
    {
      title: () => getDynamicTitle("Grade Of Building", filterProperty.gradeOfBuilding),
      column: "Grade Of Building",
      dataIndex: "gradeOfBuilding",
      hidden: tableField["Grade Of Building"],
      width: "15%",
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterProperty.gradeOfBuilding}
          filterType={filterProperty.gradeOfBuildingFilter}
          onFilterChange={(value) => setFilterProperty({ ...filterProperty, gradeOfBuilding: value })}
          onFilterTypeChange={(value) => setFilterProperty({ ...filterProperty, gradeOfBuildingFilter: value })}
          onApply={() => {

            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("gradeOfBuilding", null, "contains", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'contains', label: 'Contains' },
            { value: 'startsWith', label: 'Starts with' },
            { value: 'endsWith', label: 'Ends with' },
          ]}
          placeholder="Enter the grade of building here"
        />
      ),
      filterSearch: true,
      render: (text, record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {text}
        </div>
      ),
    },

    {
      title: () => getDynamicTitle("Developer", filterProperty.developer),
      column: "Developer",
      dataIndex: "developer",
      hidden: tableField["Developer"],
      width: "10%",
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterProperty.developer}
          filterType={filterProperty.developerFilter}
          onFilterChange={(value) => setFilterProperty({ ...filterProperty, developer: value })}
          onFilterTypeChange={(value) => setFilterProperty({ ...filterProperty, developerFilter: value })}
          onApply={() => {

            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("developer", null, "contains", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'contains', label: 'Contains' },
            { value: 'startsWith', label: 'Starts with' },
            { value: 'endsWith', label: 'Ends with' },
          ]}
          placeholder="Enter the developer name here"
        />
      ),
      filterSearch: true,
      render: (text, record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {text}
        </div>
      ),
    },
    {
      title: () => getDynamicTitle("Amenities", filterProperty.amenities),
      column: "Amenities",
      dataIndex: "amenities",
      hidden: tableField["Amenities"],
      width: "12%",
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterProperty.amenities || []}
          onFilterChange={(value) => {
            const updatedFilterProperty = { ...filterProperty, amenities: value };
            setFilterProperty(updatedFilterProperty);
          }}
          onApply={() => {
            fetchProperties(1, 10);
            confirm();
          }}
          onReset={() => {
            const updatedFilterProperty = { ...filterProperty, amenities: [] }
            setFilterProperty(updatedFilterProperty)
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchProperties(1, 10, updatedFilterProperty);
            clearFilters();
            confirm();
          }}
          inputType="select"
          mode="multiple"
          placeholder="Select an amenities"
          selectOptions={amenitiesLists}
        />
      ),
      filterSearch: true,
      render: (amenities) => {
        if (!amenities || amenities.length === 0) return null;
        const displayAmenities = amenities?.length > 2
          ? amenities.slice(0, 2).join(', ') + ' ...'
          : amenities.join(', ');

        return (
          <Tooltip title={amenities?.join(', ')}>
            <span style={{ cursor: 'pointer' }}>{displayAmenities}</span>
          </Tooltip>
        );
      },
    },
    {
      title: () => getDynamicTitle("₹ Total Transactions Amount", filterProperty.totalTransactionsAmount),
      column: "Total Transactions Amount",
      dataIndex: "totalTransactionsAmount",
      hidden: tableField["Total Transactions Amount"],
      width: "15%",
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          inputType="number"
          filterValue={filterProperty.totalTransactionsAmount}
          filterType={filterProperty.totalTransactionsAmountFilter}
          onFilterChange={(value) => setFilterProperty({ ...filterProperty, totalTransactionsAmount: value })}
          onFilterTypeChange={(value) => setFilterProperty({ ...filterProperty, totalTransactionsAmountFilter: value })}
          onApply={() => {
            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("totalTransactionsAmount", null, "equals", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'equals', label: 'Equal' },
            { value: 'lessthan', label: 'Less Than' },
            { value: 'greaterthan', label: 'Greater Than' },
          ]}
          placeholder="Enter the total transaction here"
        />
      ),
      filterSearch: true,
      render: (text, record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {text}
        </div>
      ),
    },
    {
      title: () => getDynamicTitle("Rep Person Name", filterProperty.repPersonName),
      column: "Rep Person Name",
      dataIndex: "repPersonName",
      hidden: tableField["Rep Person Name"],
      width: "10%",
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterProperty.repPersonName}
          filterType={filterProperty.repPersonNameFilter}
          onFilterChange={(value) => setFilterProperty({ ...filterProperty, repPersonName: value })}
          onFilterTypeChange={(value) => setFilterProperty({ ...filterProperty, repPersonNameFilter: value })}
          onApply={() => {

            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("repPersonName", null, "contains", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'contains', label: 'Contains' },
            { value: 'startsWith', label: 'Starts with' },
            { value: 'endsWith', label: 'Ends with' },
          ]}
          placeholder="Enter the rep person name here"
        />
      ),
      filterSearch: true,
      render: (text, record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {text}
        </div>
      ),
    },
    {
      title: () => getDynamicTitle("Rep Person Contact", filterProperty.repPersonContact),
      column: "Rep Person Contact",
      dataIndex: "repPersonContact",
      hidden: tableField["Rep Person Contact"],
      width: "10%",
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterProperty.repPersonContact}
          filterType={filterProperty.repPersonContactFilter}
          onFilterChange={(value) => setFilterProperty({ ...filterProperty, repPersonContact: value })}
          onFilterTypeChange={(value) => setFilterProperty({ ...filterProperty, repPersonContactFilter: value })}
          onApply={() => {

            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("repPersonContact", null, "contains", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'contains', label: 'Contains' },
            { value: 'startsWith', label: 'Starts with' },
            { value: 'endsWith', label: 'Ends with' },
          ]}
          placeholder="Enter the rep person contact here"
        />
      ),
      filterSearch: true,
      render: (text, record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {text}
        </div>
      ),
    },
    {
      title: () => getDynamicTitle("Rep Person Email", filterProperty.repPersonEmail),
      column: "Rep Person Email",
      dataIndex: "repPersonEmail",
      hidden: tableField["Rep Person Email"],
      width: "10%",
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterProperty.repPersonEmail}
          filterType={filterProperty.repPersonEmailFilter}
          onFilterChange={(value) => setFilterProperty({ ...filterProperty, repPersonEmail: value })}
          onFilterTypeChange={(value) => setFilterProperty({ ...filterProperty, repPersonEmailFilter: value })}
          onApply={() => {

            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("repPersonEmail", null, "contains", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'contains', label: 'Contains' },
            { value: 'startsWith', label: 'Starts with' },
            { value: 'endsWith', label: 'Ends with' },
          ]}
          placeholder="Enter the rep person email here"
        />
      ),
      filterSearch: true,
      render: (text, record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {text}
        </div>
      ),
    },
    {
      title: () => getDynamicTitle("Created By", filterProperty.createdBy),
      column: "Created By",
      dataIndex: "createdBy",
      hidden: tableField["Created By"],
      width: "10%",
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterProperty.createdBy}
          filterType={filterProperty.createdByFilter}
          onFilterChange={(value) => setFilterProperty({ ...filterProperty, createdBy: value })}
          onFilterTypeChange={(value) => setFilterProperty({ ...filterProperty, createdByFilter: value })}
          onApply={() => {

            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("createdBy", null, "contains", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'contains', label: 'Contains' },
            { value: 'startsWith', label: 'Starts with' },
            { value: 'endsWith', label: 'Ends with' },
          ]}
          placeholder="Enter the created user name here"
        />
      ),
      filterSearch: true,
      render: (text, record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {text}
        </div>
      ),
    },
    {
      title: () => getDynamicTitle("Created Date", filterProperty.createdAt),
      column: "Created Date",
      hidden: tableField["Created Date"],
      dataIndex: "createdAt",
      width: "10%",
      render: (createdAt) =>
        createdAt ? dayjs(createdAt).format("DD-MM-YYYY") : null,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          inputType="dateRange"
          startDate={filterProperty.startDate}
          endDate={filterProperty.endDate}
          onStartDateChange={(date) => setFilterProperty({ ...filterProperty, startDate: dayjs(date, "DD-MM-YYYY").add(5, "hour").add(30, "minute") })}
          onEndDateChange={(date) => setFilterProperty({ ...filterProperty, endDate: dayjs(date, "DD-MM-YYYY").add(5, "hour").add(30, "minute") })}
          onApply={() => {
            fetchProperties(1, 10, filterProperty);
            confirm();
          }}
          onReset={() => {
            const updatedFilterProperty = { ...filterProperty, startDate: null, endDate: null }
            setFilterProperty(updatedFilterProperty)
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchProperties(1, 10, updatedFilterProperty);
            clearFilters();
            confirm();
          }}
        />
      ),
    },
  ];

  const handleResetPropertySearch = () => {
    dispatch(
      setFilters({
        name: "localities",
        value: [],
      })
    );
    const buildingNameBody = {
      value: null,
      name: "buildingName",
    };
    dispatch(setFilters(buildingNameBody));

    const documentNoBody = {
      value: null,
      name: "documentNo",
    };
    dispatch(setFilters(documentNoBody));
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const updatedTableField = { ...tableField, [value]: !checked };

    setTableField(updatedTableField);
    localStorage.setItem('tableFieldStatus', JSON.stringify(updatedTableField));
  };

  const handlePaginationChange = (page, size) => {
    fetchProperties(page, size);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const response = await bulkUploadProperties(file);
        const successMessage = response;
        successBulkUpload(successMessage);
        event.target.value = null;
        setTimeout(() => {
          dispatch(setCurrentPage(1));
          fetchProperties(1, pageSize);
          event.target.value = null;
        }, 3000);
      } catch (error) {
        console.error("Failed to upload file:", error);
        const errorMessage = error.message;
        failBulkUpload(errorMessage);
      }
    }
  };
  const successBulkUpload = (messageContent) => {
    messageApi.open({
      type: "success",
      content: messageContent,
    });
  };

  const failBulkUpload = (messageContent) => {
    messageApi.open({
      type: "error",
      content: messageContent,
    });
  };

  const items = columns.map((col) => ({
    key: col.dataIndex,
    label: (
      <Checkbox value={col.column}
        checked={!tableField[col.column]}
        onChange={handleCheckboxChange}
        style={{ marginTop: '10px' }}
      >
        {col.column}
      </Checkbox>
    ),
  }));

  const propertyTypes = [
    { value: 'Commercial', label: 'Commercial' },
    { value: 'Residential', label: 'Residential' },
    { value: 'Mixed', label: 'Mixed' },
    { value: 'Others', label: 'Others' },
  ];

  const handleChangePropertyType = (value) => {
    const updatedFilterProperty = { ...filterProperty, viewMode: value }
    setFilterProperty(updatedFilterProperty);
    localStorage.setItem('propertyFilterStatus', JSON.stringify(updatedFilterProperty));
    dispatch(setCurrentPage(1));
    dispatch(setPageSize(10));
    fetchProperties(1, 10, updatedFilterProperty);
  };

  return (
    <React.Fragment>
      {loading ? (
        <Spin spinning={loading} size="large" fullscreen />
      ) : (
        <>
          {contextHolder}
          <div style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            flexGrow: 1,
            height: "100%",

          }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginRight: "20px",
              }}
            >
              <div className="m-4 align-center property">
                <div className="pl-3">
                  <h1 style={{ fontSize: "18px", fontWeight: "bold" }}>Property</h1>
                </div>
                <div className="pl-2">
                  <Select className="w-full" style={{ minWidth: '125px' }} placeholder="Select a property type" defaultValue={filterProperty.viewMode} onChange={handleChangePropertyType}>
                    {propertyTypes.map((type) => (
                      <Select.Option key={type.value} value={type.value}>
                        {type.label}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="list-options m-4 flex align-center">
                {/* Existing content for the options bar */}

                <a href="/Building_Sample_Data.xlsx" download>
                  <Button className="mr-4 ml-4" color="primary">
                    <img src={DownloadIcon} alt="download" />
                    Download Sample
                  </Button>
                </a>
                <Button
                  className="mr-4 ml-4"
                  onClick={() => document.getElementById("bulkUploadInput").click()}
                >
                  <img src={BulkUpload} alt="bulk" />
                  Bulk Upload
                </Button>
                <input
                  type="file"
                  id="bulkUploadInput"
                  accept=".xlsx, .xls"
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
                <Button
                  className="mr-4 ml-4"
                  style={{ backgroundColor: "#fb923c", color: "#FFFFFF" }}
                  onClick={() => {
                    handleResetPropertySearch();
                    navigateTo(`/propertyDetails`);
                    dispatch(setPropertyId(null));
                    dispatch(setPropertyInitialState());
                  }}
                >
                  <img src={AddProperty} alt="add" />
                  Add Property
                </Button>
                <ReloadOutlined
                  style={{ cursor: "pointer" }}
                  title="Reload Property List"
                  onClick={() => {
                    dispatch(setCurrentPage(1));
                    dispatch(setPageSize(10));
                    localStorage.setItem('propertyFilterStatus', JSON.stringify(filter));
                    setFilterProperty(filter)
                    fetchProperties(1, 10, filter);
                  }}
                />

                <Dropdown
                  menu={{ items }}
                  visible={visible}
                  onClick={() => setVisible(!visible)}
                  trigger={['click']}
                  dropdownRender={(menu) => (
                    <div style={{ maxHeight: 400, overflowY: 'auto' }}>
                      {menu}
                    </div>
                  )}
                >
                  <MdTune style={{ fontSize: '24px', cursor: 'pointer', marginLeft: '12px' }} />
                </Dropdown>

              </div>
            </div>

            {/* Main table container with flex-grow */}
            <div className="m-4" style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', backgroundColor: '#FFFFFF', borderRadius: '8px' }}>
              <Table
                className="no-column-lines"
                bordered
                dataSource={propertyDetailsData}
                columns={columns}
                size="small"
                scroll={{ x: 300 }}
                loading={loading}
                pagination={false}
                rowKey={"_id"}
                style={{ flexGrow: 1 }}
              />

              {propertyDetailsData?.length > 0 && (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 20px 20px' }}>
                  <div style={{ margin: '10px 0' }}>
                    Showing {((currentPage - 1) * pageSize) + 1} - {Math.min(currentPage * pageSize, propertiesCnt)} / {propertiesCnt} Results
                  </div>

                  <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    showSizeChanger
                    onShowSizeChange={(current, size) => handlePaginationChange(current, size)}
                    onChange={(currPage, pageSize) => handlePaginationChange(currPage, pageSize)}
                    total={propertiesCnt}
                    style={{ margin: "10px 0" }}
                  />
                </div>
              )}
            </div>

          </div>
        </>
      )
      }
    </React.Fragment >
  );
};
export default Property;