import React, { useState, useEffect } from "react";
import Title from "antd/es/typography/Title";
import { Form, Button, Divider, Input, message, Select } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addPincodeData,
  fetchPincodeById,
  updatePincodeData,
} from "../../../feature/slice/PincodeSlice";
import { fetchAllCitiesByStateId } from "../../../feature/slice/CitySlice";
import { fetchAllMicroMarketByCityId } from "../../../feature/slice/MicroMarketSlice";

const PincodeDetailsCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedMicroMarket, setSelectedMicroMarket] = useState(null);
  const { statesForDD } = useSelector((state) => state.masterDetails);
  const { citiesByStateId } = useSelector((state) => state.cityDetails);
  const { microMarketsByCityId } = useSelector(
    (state) => state.microMarketDetails
  );
  const { pincodeDataById } = useSelector((state) => state.pincodeDetails);

  const { pincodeId } = location.state || {};

  useEffect(() => {
    if (pincodeId) {
      dispatch(fetchPincodeById(pincodeId));
    }
  }, [pincodeId, dispatch]);

  useEffect(() => {
    if (pincodeId && pincodeDataById) {
      dispatch(fetchAllCitiesByStateId(pincodeDataById.stateId));
      dispatch(fetchAllMicroMarketByCityId(pincodeDataById.cityId));
      setSelectedState(pincodeDataById.stateId);
      setSelectedCity(pincodeDataById.cityId);
      setSelectedMicroMarket(pincodeDataById.microMarketId);

      form.setFieldsValue({
        microMarketId: pincodeDataById.microMarketId,
        stateId: pincodeDataById.stateId,
        cityId: pincodeDataById.cityId,
        pincode: pincodeDataById.pincode,
      });
    }
  }, [pincodeId, pincodeDataById, form, dispatch]);

  const handleAdd = (values) => {
    dispatch(addPincodeData(values))
      .then(() => {
        message.success("Pincode added successfully");
        navigate("/masterlist");
      })
      .catch((err) => {
        message.error("Pincode already exists!");
        console.error("Failed to add Pincode data:", err);
      });
  };

  const handleUpdate = (values) => {
    const {
      pincode: newPincode,
      stateId: newStateId,
      cityId: newCityId,
      microMarketId: newMicroMarketId,
    } = values;
    const originalPincode = pincodeDataById.pincode;
    const originalStateId = pincodeDataById.stateId;
    const originalCityId = pincodeDataById.cityId;
    const originalMicroMarketId = pincodeDataById.microMarketId;

    if (
      newPincode === originalPincode &&
      newStateId === originalStateId &&
      newCityId === originalCityId &&
      newMicroMarketId === originalMicroMarketId
    ) {
      message.warning(
        "No changes detected in Pincode, state, city, or microMarket."
      );
      return;
    }

    dispatch(updatePincodeData({ pincodeId, pincodeData: values }))
      .then(() => {
        message.success("Pincode updated successfully");
        navigate("/masterlist");
      })
      .catch((err) => {
        message.error("Failed to update Pincode data!");
        console.error("Failed to update Pincode data:", err);
      });
  };

  const handleSubmit = (values) => {
    if (pincodeId) {
      handleUpdate(values);
    } else {
      handleAdd(values);
    }
  };

  const handleStateChange = (value) => {
    setSelectedState(value);
    setSelectedCity(null);
    setSelectedMicroMarket(null);

    dispatch(fetchAllCitiesByStateId(value));
    form.setFieldsValue({
      cityId: null,
      microMarketId: null,
      pincode: null,
    });
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
    setSelectedMicroMarket(null);
    dispatch(fetchAllMicroMarketByCityId(value));
    form.setFieldsValue({
      microMarketId: null,
      pincode: null,
    });
  };

  const handleMicroMarketChange = (value) => {
    setSelectedMicroMarket(value);
  };

  return (
    <>
      <div
        style={{
          alignItems: "center",
          padding: "10px",
          marginBottom: "1rem",
          width: "100%",
          backgroundColor: "white",
          boxShadow: "0 8px 8px -8px rgba(0, 0, 0, 0.5)",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1rem",
            padding: "10px",
            marginBottom: "20px",
            width: "100%",
            position: "sticky",
            top: 0,
            zIndex: 1000,
            backgroundColor: "white",
            boxShadow: "0 8px 8px -8px rgba(0, 0, 0, 0.5)",
          }}
        >
          <Title level={4}>
            {pincodeId
              ? "Update Pincode Information"
              : "Add Pincode Information"}
          </Title>
          <Button onClick={() => navigate("/masterlist")}>Back</Button>
        </div>
        <Divider style={{ backgroundColor: "black", marginTop: "0" }} />
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          style={{ maxWidth: "400px", margin: "0 auto" }}
        >
          <Form.Item
            label="State Name"
            name="stateId"
            rules={[{ required: true, message: "Please select a state!" }]}
          >
            <Select
              placeholder="Select a State"
              onChange={handleStateChange}
              value={selectedState}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {statesForDD?.length > 0 ? (
                statesForDD?.map((state) => (
                  <Select.Option key={state._id} value={state._id}>
                    {state.state}
                  </Select.Option>
                ))
              ) : (
                <Select.Option disabled></Select.Option>
              )}
            </Select>
          </Form.Item>

          <Form.Item
            label="City Name"
            name="cityId"
            rules={[{ required: true, message: "Please select a city!" }]}
          >
            <Select
              placeholder="Select a City"
              onChange={handleCityChange}
              value={selectedCity}
              disabled={!selectedState}
              showSearch // Enable search
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {citiesByStateId?.length > 0 ? (
                citiesByStateId
                  .filter((city) => city.stateId._id === selectedState)
                  ?.map((city) => (
                    <Select.Option key={city._id} value={city._id}>
                      {city.city}
                    </Select.Option>
                  ))
              ) : (
                <Select.Option disabled></Select.Option>
              )}
            </Select>
          </Form.Item>

          <Form.Item
            label="MicroMarket Name"
            name="microMarketId"
            rules={[
              { required: true, message: "Please select a microMarket!" },
            ]}
          >
            <Select
              placeholder="Select a microMarket"
              onChange={handleMicroMarketChange}
              value={selectedMicroMarket}
              disabled={!selectedCity}
              showSearch // Enable search
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {microMarketsByCityId?.length > 0 ? (
                microMarketsByCityId
                  .filter((micromarket) => micromarket.cityId === selectedCity) // Filter microMarkets based on selected state and city
                  ?.map((micromarket) => (
                    <Select.Option
                      key={micromarket._id}
                      value={micromarket._id}
                    >
                      {micromarket.microMarket}
                    </Select.Option>
                  ))
              ) : (
                <Select.Option disabled></Select.Option>
              )}
            </Select>
          </Form.Item>

          <Form.Item
            label="Pincode"
            name="pincode"
            rules={[
              { required: true, message: "Please enter the pincode!" },
              {
                pattern: /^[0-9]{6,}$/, // Allows 6 or more digits
                message: "Pincode must be at least 6 digits!",
              },
            ]}
          >
            <Input
              placeholder="Enter Pincode"
              disabled={!selectedMicroMarket}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              style={{ marginTop: "1rem" }}
            >
              {pincodeId ? "Update Pincode" : "Add Pincode"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default PincodeDetailsCard;
