import React, { useState } from "react";
import { Button, Table, Input, Pagination } from "antd";
import { MdDeleteForever } from "react-icons/md";
import { setCurrentPage } from "../../feature/slice/MasterSlice";
import AddProperty from "../../assets/add.svg";

const TabContent = ({
  title,
  data,
  columns,
  buttonLabel,
  onButtonClick,
  handleDelete,
  onRowClick,
  totalRecords,
  currentPage,
  pageSize,
  handleTableChange,
}) => {
  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  };

  const filteredData = data.filter((item) => {
    const itemData =
      item.state ||
      item.city ||
      item.microMarket ||
      item.locality ||
      (item.pincode !== undefined ? item.pincode.toString() : "") || // Convert pincode to string if it's a number
      "";
    return itemData.toLowerCase().includes(searchText.toLowerCase());
  });

  const totalFilteredRecords = filteredData.length;

  return (
    <div className="mt-4">
      <div className="flex justify-between items-center mb-2">
        <h2 style={{ fontSize: '16px', fontWeight: 'bold' }} >{title} List</h2>

        <div className="flex items-center">
          <Input
            placeholder="Search by name"
            value={searchText}
            onChange={handleSearch}
            style={{ marginRight: 16, width: 200 }}
          />
          <Button
            style={{ backgroundColor: "#fb923c", color: "#FFFFFF" }}
            onClick={onButtonClick}
          >
            {" "}
            <img src={AddProperty} alt="add" /> {buttonLabel}
          </Button>
        </div>
      </div>
      <div style={{ background: "#fff" }}>
        <Table
          dataSource={filteredData}
          columns={[
            ...columns,
            {
              title: "",
              dataIndex: "delete",
              width: 30,
              render: (_, record) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(record._id);
                  }}
                >
                  <MdDeleteForever size={20} className="text-secondary" />
                </div>
              ),
            },
          ]}
          rowKey="_id"
          onRow={onRowClick}
          style={{ cursor: "pointer" }}
          pagination={false}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
            background: "#fff",
          }}
        >
          <div style={{ textAlign: "left" }}>
            Showing {(currentPage - 1) * pageSize + 1} -{" "}
            {Math.min(currentPage * pageSize, totalRecords)} / {totalRecords}{" "}
            Results
          </div>

          <div>
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              showSizeChanger={true}
              pageSizeOptions={["10", "20", "50", "100"]}
              total={totalRecords}
              onChange={(page, size) => {
                handleTableChange({ current: page, pageSize: size });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default TabContent;
