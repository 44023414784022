import { client } from "../../utils/apiClient";
import { asyncHandler } from "../../utils/asyncHandler";

const getTransactionList = async (body) => {
  const endpoint = body?.propertyId
    ? `/api/get-property-transactions/${body.propertyId}?pageSize=${body.pageSize}&category=${body.category}&page=${body.currentPage || 1}`
    : `/api/get-all-transaction?pageSize=${body.pageSize}&page=${body.currentPage || 1}`;
  try {
    const response = await client.get(endpoint);
    const data = response.data;
    return { data, error: null };
  } catch (err) {
    return { error: err };
  }
};

const getAllTransactions = async (body) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-transaction?pageSize=${body.pageSize}&page=${body.page}`)
  );
  if (err) return err;
  return data;
};

const bulkUploadTransactions = async (file) => {
  const formData = new FormData();
  formData.append("file", file);


  try {
    const response = await client.post("/api/bulk-upload/transactions",
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return [response.data, null];
  } catch (error) {
    console.error("Error uploading file:", error.response ? error.response.data : error.message);
    return [null, error];
  }
};

const getFilteredTransaction = async (body) => {
  const postBody = {
    buildingName: body.buildingName ?? null,
    buildingNameFilter: body.buildingNameFilter ?? 'contains',
    documentNo: body.documentNo ?? null,
    documentNoFilter: body.documentNoFilter ?? 'equals',
    documentName: body.documentName ?? [],
    registrationStartDate: body.registrationStartDate ?? null,
    registrationEndDate: body.registrationEndDate ?? null,
    locality: body.locality ?? [],
    city: body.city ?? [],
    unitNo: body.unitNo ?? null,
    unitNoFilter: body.unitNoFilter ?? null,
    floorNo: body.floorNo ?? null,
    floorNoFilter: body.floorNoFilter ?? null,
    saleRate: body.saleRate ?? null,
    saleRateFilter: body.saleRateFilter ?? 'equals',
    completionRatio: body.completionRatio ?? null,
    completionRatioFilter: body.completionRatioFilter ?? 'equals',
    rentRate: body.rentRate ?? null,
    rentRateFilter: body.rentRateFilter ?? 'equals',
    compensation: body.compensation ?? null,
    compensationFilter: body.compensationFilter ?? 'equals',
    chargableArea: body.chargableArea ?? null,
    chargableAreaFilter: body.chargableAreaFilter ?? 'equals',
    createdAtStartDate: body.createdAtStartDate ?? null,
    createdAtEndDate: body.createdAtEndDate ?? null,
    buyerName: body.buyerName ?? null,
    buyerNameFilter: body.buyerNameFilter ?? 'contains',
    sellerName: body.sellerName ?? null,
    sellerNameFilter: body.sellerNameFilter ?? 'contains',
    createdBy: body.createdBy ?? null,
    createdByFilter: body.createdByFilter ?? 'contains',
    updatedBy: body.updatedBy ?? null,
    updatedByFilter: body.updatedByFilter ?? 'contains',
    lastUpdatedOnStartDate: body.lastUpdatedOnStartDate ?? null,
    lastUpdatedOnEndDate: body.lastUpdatedOnEndDate ?? null,
    documentType: body.documentType ?? [],
    saleValue: +body.saleValue ?? null,
    saleValueFilter: body.saleValueFilter ?? 'equals',
    rentLicenseFee: +body.rentLicenseFee ?? null,
    rentLicenseFeeFilter: body.rentLicenseFeeFilter ?? 'equals',
    currentRentRate: +body.currentRentRate ?? null,
    currentRentRateFilter: body.currentRentRateFilter ?? 'equals',
    carpetArea: +body.carpetArea ?? null,
    carpetAreaFilter: body.carpetAreaFilter ?? 'equals',
    propertyId: body?.id,
  };

  const [data, err] = await asyncHandler(
    client.post(
      `/api/advanced-search-transactions?pageSize=${body?.pageSize}&page=${body.currentPage}`,
      postBody
    )
  );

  if (err) {
    console.error('Error in API call:', err);
    return err;
  }

  console.log('Received data:', data);
  return data;
};

const deleteTransaction = async (transactionId) => {
  const [data, err] = await asyncHandler(client.delete(`/api/delete-transaction/${transactionId}`));
  if (err) return err;
  return data;
};


const getTransactionDetailsData = async (transactionId, propertyId) => {
  const endpoint = propertyId
    ? `/api/get-property-transactions-details/transaction/${transactionId}/property/${propertyId}`
    : `/api/get-transaction-detail/${transactionId}`;

  const [data, err] = await asyncHandler(client.get(endpoint));

  if (err) return err;
  return data;
};

const getCompanyWithSearchableText = async (companyText) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-companies-search/${companyText}`)
  );
  if (err) return err;
  return data;
};

const updateTransactionDetailsData = async (
  param,
  transactionId,
  moreTransactonId
) => {
  try {
    const response = await client.patch(
      `/api/update-transaction-detail/transaction/${transactionId}/moreTransaction/${moreTransactonId}`,
      param, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
    );
    return response;
  } catch (err) {
    console.error(err);
    return err;
  }
};

const saveTransactionDetailsData = async (data) => {
  try {
    const [res, err] = await asyncHandler(client.post(
      `/api/create-transaction/property/${data.propertyId}`,
      data.formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
    ));
    return res || err;
  } catch (err) {
    console.error(err);
    return err;
  }
};

const getAllDocumentMaster = async () => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-document-master`)
  );
  if (err) return err;
  return data;
};

const getTransactionDocument = async (data) => {
  const [res, err] = await asyncHandler(
    client.post(`/api/get-transaction-document`, data, {
      responseType: 'blob',
    })
  )
  if (err) return err;
  return res;
};

export {
  getTransactionList,
  getAllTransactions,
  getTransactionDetailsData,
  getFilteredTransaction,
  updateTransactionDetailsData,
  getCompanyWithSearchableText,
  saveTransactionDetailsData,
  getAllDocumentMaster,
  bulkUploadTransactions,
  deleteTransaction,
  getTransactionDocument
};
