import React, { useEffect } from "react";
import { Table, Typography, Pagination, Button, Spin } from "antd";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentPage,
  setPageSize,
  getTransactionList,
  setTransactionInitialState,
  setTransactionId,
} from "../../feature/slice/TransactionDetailsSlice";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { setFilters } from "../../feature/slice/FiltersSlice";
import { setPropertyId } from "../../feature/slice/PropertyDetailsSlice";
import AddProperty from "../../assets/add.svg";
import { FaArrowLeft } from "react-icons/fa";

const VacantUnit = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading, transactionsDetailsData, currentPage, pageSize, transactionsCnt } =
    useSelector((store) => store.transactionDetails);
  const { transactionFilters, localitiesData } = useSelector(
    (store) => store.filters
  );

  const columns = [
    {
      title: "Building Name",
      dataIndex: "buildingName",
      width: 250,
      editable: true,
      fixed: "left",
    },
    {
      title: "Available For",
      dataIndex: "documentType",
      width: 250,
      editable: true,
      fixed: "left",
    },
    {
      title: "Registration Date",
      dataIndex: "registrationDate",
      width: 250,
      editable: true,
      render: (registrationDate) =>
        registrationDate ? dayjs(registrationDate).format("DD-MM-YYYY") : null,
    },
    {
      title: "Seller Name",
      dataIndex: "allSellerName",
      width: 100,
      editable: true,
    },
    {
      title: "Unit No.",
      dataIndex: "unitNo",
      width: 150,
      editable: true,
    },
    {
      title: "Floor No.",
      dataIndex: "floorNo",
      width: 100,
      editable: true,
    },
    {
      title: "Sale Rate",
      dataIndex: "saleRate",
      width: 100,
      editable: true,
    },
    {
      title: "Rent Rate",
      dataIndex: "rentRate",
      width: 150,
      editable: true,
    },
    {
      title: "Chargeable Area",
      dataIndex: "chargeableSquareFeet",
      width: 250,
      editable: true,
    },
  ];

  useEffect(() => {}, [transactionsDetailsData]);

  useEffect(() => {
    dispatch(setPropertyId(null));
  }, [])

  useEffect(() => {
    if (id) {
      const localitiesDataForFilter = transactionFilters?.localities.map(
        (index) => localitiesData?.[index]?.title
      ) || [];
      const body = {
        documentNumber: transactionFilters?.documentNo,
        buildingName: transactionFilters?.buildingName,
        localities: localitiesDataForFilter,
        currentPage: currentPage,
        pageSize: pageSize,
        propertyId: id,
        category: "vacant",
      };
      dispatch(getTransactionList(body));
    }
  }, [dispatch, currentPage, pageSize, id, transactionFilters, localitiesData]);

  useEffect(() => {
    dispatch(setCurrentPage(1));
    dispatch(setPageSize(10));
  }, [dispatch]);

  const handleResetTransactionSearch = () => {
    dispatch(setFilters({ name: "localities", value: [] }));
    dispatch(setFilters({ name: "buildingName", value: null }));
    dispatch(setFilters({ name: "documentNo", value: null }));
  };

  const handlePaginationChange = (page, size) => {
    dispatch(setCurrentPage(page));
    dispatch(setPageSize(size));
  };

  const onShowSizeChange = (current, size) => {
    dispatch(setPageSize(size));
    dispatch(setCurrentPage(1)); 
  };

  return (
    <>
      {loading ? (
        <Spin spinning={loading} size="large" fullscreen />
      ) : (
        <div style={{ padding: "16px" }}>
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginBottom: "20px",
                gap: "16px",
              }}
            >
              <div className="header" style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Button
                  onClick={() => {
                    navigateTo(-1);
                    dispatch(setPropertyId(null));
                  }}
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FaArrowLeft style={{ marginRight: "8px" }} />
                </Button>
                <h1 style={{ fontSize: "18px", fontWeight: "bold" }}>Vacant Unit List</h1>
              </div>

              <div style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}>
                <Button
                  style={{ backgroundColor: "#fb923c", color: "#FFFFFF", display: "flex", alignItems: "center" }}
                  onClick={() => {
                    handleResetTransactionSearch();
                    navigateTo("/vacantDetails");
                    dispatch(setTransactionId(null));
                    dispatch(setTransactionInitialState());
                    dispatch(setPropertyId(id));
                  }}
                >
                  <img src={AddProperty} alt="add" style={{ marginRight: "8px" }} />
                  Add  Unit
                </Button>
              </div>
            </div>

            <div
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: "8px",
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                overflowX: "auto",
              }}
            >
              <Table
                className="no-column-lines"
                dataSource={Array.isArray(transactionsDetailsData) ? transactionsDetailsData : []}
                columns={columns}
                rowKey="transactionId"
                pagination={false}
                locale={{ emptyText: "No transactions found" }}
                style={{ minWidth: "600px", cursor: "pointer" }}
                onRow={(record) => ({
                  onClick: () => {
                    navigateTo(`/vacantDetails/${record.transactionId}`);
                  },
                })}
              />
              {transactionsDetailsData?.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                    alignItems: "center",
                  }}
                >
                  <div>
                    Showing {((currentPage - 1) * pageSize) + 1} - 
                    {Math.min(currentPage * pageSize, transactionsCnt)} / {transactionsCnt} Results
                  </div>
                  <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    showSizeChanger
                    onShowSizeChange={onShowSizeChange}
                    onChange={handlePaginationChange}
                    total={transactionsCnt}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VacantUnit;
