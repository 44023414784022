import { client } from "../../utils/apiClient";
import { asyncHandler } from "../../utils/asyncHandler";

const addMicroMarketData = async (param) => {
  try {
    const [data, err] = await asyncHandler(
      client.post(`/api/create-micromarket`, param, {
        headers: {
          "Content-Type": "application/json",
        },
      })
    );
    return data || err;
  } catch (err) {
    console.error(err);
  }
};

const getAllMicroMarketsWithPagination = async (page, pageSize) => {
  try {
    const [data, err] = await asyncHandler(
      client.get(`/api/get-all-micromarkets?page=${page}&pageSize=${pageSize}`)
    );
    return data || err;
  } catch (err) {
    console.error(err);
  }
};

const updateMicroMarketData = async (id, param) => {
  try {
    const [data, err] = await asyncHandler(
      client.put(`/api/update-micromarket/${id}`, param, {
        headers: {
          "Content-Type": "application/json",
        },
      })
    );
    return data || err;
  } catch (err) {
    console.error(err);
  }
};

const deleteMicroMarketById = async (id) => {
  try {
    const [data, err] = await asyncHandler(
      client.delete(`/api/delete-micromarket/${id}`)
    );
    return data || err;
  } catch (err) {
    console.error(err);
  }
};

const getMicroMarketById = async (id) => {
  try {
    const [data, err] = await asyncHandler(
      client.get(`/api/get-micromarket/${id}`)
    );
    return data || err;
  } catch (err) {
    console.error(err);
  }
};

const getAllMicroMarketByCityId = async (id) => {
  try {
    const { data } = await client.get(
      `/api/get-all-micromarkets-by-cityId?cityId=${id}`
    );
    return data;
  } catch (err) {
    console.error("Error fetching MicroMarkets by cityId:", err);
  }
};

export {
  addMicroMarketData,
  updateMicroMarketData,
  deleteMicroMarketById,
  getAllMicroMarketsWithPagination,
  getMicroMarketById,
  getAllMicroMarketByCityId,
};
