import { buildCreateSlice, asyncThunkCreator } from "@reduxjs/toolkit";
import { get } from "lodash";

import {
  addLocalityData as _addLocalityData,
  updateLocalityData as _updateLocalityData,
  deleteLocalityById as _deleteLocalityById,
  getAllLocalitiesWithPagination as _getAllLocalitiesWithPagination,
  getLocalityById as _getLocalityById
} from "../services/LocalityAPI";

const createSliceWithThunks = buildCreateSlice({
  creators: {
    asyncThunk: asyncThunkCreator,
  },
});

const initialState = {
  loading: false,
  localityMaster: [],
  localityDataById:[],
  localityId: null,
  totalLocalityCount: 0,
  currentPage: 1,
  pageSize: 10,
};

const LocalitySlice = createSliceWithThunks({
  name: "locality",
  initialState,
  reducers: (create) => ({
    setCurrentPage: (state, action) => {
      state.currentPage = action?.payload;
    },

    setPageSize: (state, action) => {
      state.pageSize = action?.payload;
    },

    fetchAllLocalitiesWithPagination: create.asyncThunk(
      async ({ page, pageSize }, thunkAPI) => {
        try {
          const data = await _getAllLocalitiesWithPagination(page, pageSize);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          return thunkAPI.rejectWithValue({ message });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.localityMaster = action.payload.data.data;
          state.totalLocalityCount = action.payload.data.total;
        },
      }
    ),

    addLocalityData: create.asyncThunk(
      async (localityData, thunkAPI) => {
        try {
          let data = await _addLocalityData(localityData);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.localityMaster.push(action.payload.data);
          state.localityId = action.payload.data._id;
        },
      }
    ),

    updateLocalityData: create.asyncThunk(
      async ({ localityId, localityData }, thunkAPI) => {
        try {
          const data = await _updateLocalityData(localityId, localityData);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          return thunkAPI.rejectWithValue({ message });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          const index = state.localityMaster.findIndex(
            (locality) => locality._id === action.payload.data._id
          );
          if (index !== -1) {
            state.localityMaster[index] = action.payload.data;
          }
        },
      }
    ),

    deleteLocality: create.asyncThunk(
      async (id, thunkAPI) => {
        try {
          const data = await _deleteLocalityById(id);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          return thunkAPI.rejectWithValue({ message });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.localityMaster = state.localityMaster.filter(
            (locality) => locality._id !== action.payload.data._id
          );
        },
      }
    ),

    fetchLocalityById: create.asyncThunk(
        async (localityId, thunkAPI) => {
          try {
            const data = await _getLocalityById(localityId);
            return data;
          } catch (err) {
            const message = get(err, "message", "Something Went Wrong!");
            return thunkAPI.rejectWithValue({ message });
          }
        },
        {
          pending: (state) => {
            state.loading = true;
          },
          rejected: (state) => {
            state.loading = false;
          },
          fulfilled: (state, action) => {
            state.loading = false;
            state.localityDataById = action.payload.data;
          },
        }
      ),

  }),
});

export const {
  fetchAllLocalitiesWithPagination,
  addLocalityData,
  updateLocalityData,
  deleteLocality,
  setCurrentPage,
  setPageSize,
  fetchLocalityById,
} = LocalitySlice.actions;

export default LocalitySlice.reducer;
