import React, { useState, useEffect } from "react";
import {
  Form,
  Col,
  Row,
  Divider,
  Select,
  Button,
  Spin,
  message,
  Collapse,
  Checkbox,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import Title from "antd/es/typography/Title";
import FormElement from "../../components/formElement/FormElement";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import {
  setDirectorInfoEditable,
  setDirectorsInfo,
  getAllDistinctCountryMaster,
  getAllDistinctStateByCountry,
  getAllDistinctDistrictByState,
  updateDirectorsInfo,
  setLoading,
  setDirectorInitialState,
  setDirectorId,
  saveDirectorsInfo,
  getDirectorDetailsData,
} from "../../feature/slice/DirectorDetailsSlice";
import { isEmail, isMobileNumber, isPAN, isPincode } from "../../utils/helpers";
import { FaArrowLeft } from "react-icons/fa6";

function DirectorDetailsCard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();

  const [showUserAddressInfoKey, setShowUserAddressInfoKey] = useState(0);
  const [isUserAddressInfoEditable, setIsUserAddressInfoEditable] =
    useState(null);
  const [savedUserAddressList, setSavedUserAddressList] = useState(null);
  const [savedUserAddressCnt, setSavedUserAddressCnt] = useState(0);
  const [messageApi] = message.useMessage();
  const {
    isDirectorInfoEditable,
    loading,
    directorDetails,
    countryMaster,
    districtMaster,
  } = useSelector((store) => store.directorDetails);
  const {
    stateMaster,
  } = useSelector((store) => store.masterAddressDetails);
  const { companyId } = useSelector((store) => store.companyDetails);

  const getCountryListingEle = () => {
    if (!countryMaster) return [];
    let data = [...countryMaster];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.value} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const getStateListingEle = () => {
    if (!stateMaster) return [];
    let data = [...stateMaster];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.value} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const getDistrictListingEle = () => {
    if (!districtMaster) return [];
    let data = [...districtMaster];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const UserAddressInfoFormElementsColForSave = [
    {
      type: "dropdown",
      label: "Country",
      name: "country",
      children: getCountryListingEle(),
      placeholder: "Country",
      disabled:
        isUserAddressInfoEditable !== showUserAddressInfoKey ||
        isUserAddressInfoEditable === null,
      value: directorDetails?.addresses?.[showUserAddressInfoKey]?.country,
    },
    {
      type: "inputNumber",
      label: "Postal Code",
      name: "postalCode",
      placeholder: "Postal Code",
      disabled:
        isUserAddressInfoEditable !== showUserAddressInfoKey ||
        isUserAddressInfoEditable === null,
      value: directorDetails?.addresses?.[showUserAddressInfoKey]?.postalCode,
    },
    {
      type: "input",
      label: "Street Address",
      name: "streetAddress",
      placeholder: "Street Address",
      disabled:
        isUserAddressInfoEditable !== showUserAddressInfoKey ||
        isUserAddressInfoEditable === null,
      value:
        directorDetails?.addresses?.[showUserAddressInfoKey]?.streetAddress,
    },
    {
      type: "dropdown",
      label: "State",
      name: "state",
      placeholder: "State",
      children: getStateListingEle(),
      disabled:
        isUserAddressInfoEditable !== showUserAddressInfoKey ||
        isUserAddressInfoEditable === null,
      value: directorDetails?.addresses?.[showUserAddressInfoKey]?.state,
    },
    {
      type: "dropdown",
      label: "District",
      name: "district",
      placeholder: "District",
      children: getDistrictListingEle(),
      disabled:
        isUserAddressInfoEditable !== showUserAddressInfoKey ||
        isUserAddressInfoEditable === null,
      value: directorDetails?.addresses?.[showUserAddressInfoKey]?.district,
    },
    {
      type: "input",
      label: "Street Address 2",
      name: "streetAddress2",
      placeholder: "Street Address 2",
      disabled:
        isUserAddressInfoEditable !== showUserAddressInfoKey ||
        isUserAddressInfoEditable === null,
      value:
        directorDetails?.addresses?.[showUserAddressInfoKey]?.streetAddress2,
    },
  ];

  useEffect(() => {
    if (id) {
      dispatch(getDirectorDetailsData(id));
      dispatch(setDirectorId(id));
      dispatch(setDirectorInfoEditable(false));
    } else {
      dispatch(setDirectorInfoEditable(true));
    }
    dispatch(getAllDistinctCountryMaster());
  }, []);

  const handleSaveCompanyAddressInfo = () => {
    setShowUserAddressInfoKey(null);
    setIsUserAddressInfoEditable(null);
  };

  useEffect(() => {
    const mappedData = directorDetails?.addresses?.map((item, index) => {
      return {
        key: index,
        label: "",
        children: (
          <React.Fragment>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
              <div>
                {/* Place for any title or description, if needed */}
              </div>
              <div>
                {(isUserAddressInfoEditable !== showUserAddressInfoKey ||
                  isUserAddressInfoEditable === null) && (
                    <Button
                      onClick={() => setIsUserAddressInfoEditable(index)}
                      disabled={!isDirectorInfoEditable}
                    >
                      Edit
                    </Button>
                  )}
                &nbsp;&nbsp;
                {isUserAddressInfoEditable === showUserAddressInfoKey && (
                  <Button
                    onClick={() => {
                      handleSaveCompanyAddressInfo(index);
                    }}
                    disabled={!isDirectorInfoEditable}
                  >
                    Save
                  </Button>
                )}
                &nbsp;&nbsp;
                <Button
                  onClick={() => {
                    handleDeleteDirectorInfo("addresses", index);
                  }}
                  disabled={!isDirectorInfoEditable}
                >
                  Delete
                </Button>
              </div>
            </div>

            <Row
              gutter={[16, 16]} // Add gutter for spacing between columns
              style={{ margin: "0 -8px" }} // Adjust margins for responsiveness
            >
              <Col xs={24} sm={12} md={8} lg={8}> {/* Responsive span */}
                {UserAddressInfoFormElementsColForSave.map((element, i) => {
                  if (i % 3 === 0) {
                    return (
                      <FormElement
                        key={i}
                        {...element}
                        onChange={(e, dateVal) => {
                          handleChangeUserAddressInfo(
                            element,
                            e,
                            dateVal,
                            index
                          );
                        }}
                      />
                    );
                  }
                })}
              </Col>
              <Col xs={24} sm={12} md={8} lg={8}> {/* Responsive span */}
                {UserAddressInfoFormElementsColForSave.map((element, i) => {
                  if ([1, 4, 7, 10, 13, 16, 19].includes(i)) {
                    return (
                      <FormElement
                        key={i}
                        {...element}
                        onChange={(e, dateVal) => {
                          handleChangeUserAddressInfo(
                            element,
                            e,
                            dateVal,
                            index
                          );
                        }}
                      />
                    );
                  }
                })}
              </Col>
              <Col xs={24} sm={12} md={8} lg={8}> {/* Responsive span */}
                {UserAddressInfoFormElementsColForSave.map((element, i) => {
                  if ([2, 5, 8, 11, 14, 17, 20].includes(i)) {
                    return (
                      <FormElement
                        key={i}
                        {...element}
                        onChange={(e, dateVal) => {
                          handleChangeUserAddressInfo(
                            element,
                            e,
                            dateVal,
                            index
                          );
                        }}
                      />
                    );
                  }
                })}
              </Col>
            </Row>
          </React.Fragment>
        ),
      };
    });
    setSavedUserAddressList(mappedData);
  }, [
    savedUserAddressCnt,
    showUserAddressInfoKey,
    isUserAddressInfoEditable,
    isDirectorInfoEditable,
    directorDetails,
    countryMaster,
    stateMaster,
    districtMaster,
  ]);

  const handleDeleteDirectorInfo = (type, index) => {
    if (type === "addresses") {
      let directorAddressInfo = [...directorDetails?.addresses];
      directorAddressInfo?.splice(index, 1);
      dispatch(
        setDirectorsInfo({
          name: type,
          value: directorAddressInfo,
        })
      );
      setSavedUserAddressCnt(savedUserAddressCnt - 1);
    }
  };

  const handleChangeUserAddressInfo = (element, e, dateVal, index) => {
    if (element?.name === "country") {
      dispatch(getAllDistinctStateByCountry(e));
    } else if (element?.name === "state") {
      dispatch(getAllDistinctDistrictByState(e));
    }

    const addressInfo = [...directorDetails.addresses];
    if (element?.type === "datepicker") {
      addressInfo[index] = {
        ...addressInfo[index],
        [element.name]: dateVal
          ? dayjs(dateVal).add(5, "hour").add(30, "minute")
          : "",
      };
    } else if (element?.type === "inputNumber" && e !== null) {
      addressInfo[index] = {
        ...addressInfo[index],
        [element.name]: e,
      };
    } else {
      if (e !== null) {
        if (element?.type === "dropdown")
          addressInfo[index] = {
            ...addressInfo[index],
            [element.name]: e,
          };
        else
          addressInfo[index] = {
            ...addressInfo[index],
            [element.name]: e.target.value,
          };
      }
    }
    dispatch(
      setDirectorsInfo({
        name: "addresses",
        value: addressInfo,
      })
    );
  };

  const handleShowAddressInfo = (key, type) => {
    if (key) {
      setShowUserAddressInfoKey(Number(key));
      return;
    }
    if (key === undefined && type === "addresses") {
      setShowUserAddressInfoKey(0);
      setIsUserAddressInfoEditable(null);
    }
  };

  const getUpdateDirectorPayload = (DirectorDetails) => {
    const formData = new FormData();

    DirectorDetails?.DIN && formData.append("DIN", DirectorDetails?.DIN);
    DirectorDetails?.PAN && formData.append("PAN", DirectorDetails?.PAN);
    DirectorDetails?.dateOfAppointment &&
      formData.append("dateOfAppointment", DirectorDetails?.dateOfAppointment);
    DirectorDetails?.directorDisqualified &&
      formData.append(
        "DirectorDisqualified",
        DirectorDetails?.directorDisqualified
      );
    DirectorDetails?.firstName &&
      formData.append("FirstName", DirectorDetails?.firstName);
    DirectorDetails?.middleName &&
      formData.append("MiddleName", DirectorDetails?.middleName);
    DirectorDetails?.lastName &&
      formData.append("LastName", DirectorDetails?.lastName);
    DirectorDetails?.phone && formData.append("phone", DirectorDetails?.phone);
    DirectorDetails?.email && formData.append("email", DirectorDetails?.email);
    DirectorDetails?.addresses &&
      formData.append("addresses", JSON.stringify(DirectorDetails?.addresses));
    DirectorDetails?.postalCodeId &&
      formData.append("postalcode", DirectorDetails?.postalCodeId);
    DirectorDetails?.roleInfo &&
      formData.append("userRoles", JSON.stringify(DirectorDetails?.roleInfo));
    return formData;
  };

  const saveDirectorInfoSuccessMessage = () => {
    messageApi.open({
      type: "success",
      content: "Director created successfully",
    });
  };

  const saveDirectorInfoErrorMessage = (error) => {
    messageApi.open({
      type: "warning",
      content: error || "Something went wrong",
    });
  };

  const updateDirectorInfoSuccessMessage = () => {
    messageApi.open({
      type: "success",
      content: "Director updated successfully",
    });
  };

  const directorInfoValidationMessage = () => {
    messageApi.open({
      type: "warning",
      content: "Please check field validation",
    });
  };

  const getOccupationTypeListingEle = () => {
    let data = [
      {
        label: "Individual",
        key: "Individual",
      },
      {
        label: "Manager/Secretary/Authorized Representative",
        key: "Manager/Secretary/Authorized Representative",
      },
      {
        label: "Serviceman",
        key: "Serviceman",
      },
      {
        label: "Self Employed",
        key: "Self Employed",
      },
      {
        label: "Company/LLP",
        key: "Company/LLP",
      },
      {
        label: "Director/Designated Partner",
        key: "Director/Designated Partner",
      },
      {
        label: "Professional",
        key: "Professional",
      },
      {
        label: "Business",
        key: "Business",
      },
      {
        label: "Homemaker",
        key: "Homemaker",
      },
      {
        label: "Student",
        key: "Student",
      },
      {
        label: "Others",
        key: "Others",
      },
      {
        label: "Employment",
        key: "Employment",
      },
    ];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const getBodyCorporateTypeListingEle = () => {
    let data = [
      {
        label: "Body Corporate",
        key: "Body Corporate",
      },
      {
        label: "Company",
        key: "Company",
      },
      {
        label: "Company incorporated outside India (CIOI)",
        key: "Company incorporated outside India (CIOI)",
      },
      {
        label: "LLP incorporated outside India (LIOI)",
        key: "LLP incorporated outside India (LIOI)",
      },
      {
        label: "LLP",
        key: "LLP",
      },
      {
        label: "Others",
        key: "Others",
      },
    ];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const getOPCTypeListingEle = () => {
    let data = [
      {
        label: "Nominee",
        key: "Nominee",
      },
      {
        label: "Member",
        key: "Member",
      },
    ];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const getSignatoryAssociationStatusListingEle = () => {
    let data = [
      {
        label: "Deactivated",
        key: "Deactivated",
      },
      {
        label: "Active",
        key: "Active",
      },
      {
        label: "Disqualified",
        key: "Disqualified",
      },
      {
        label: "Resigned",
        key: "Resigned",
      },
    ];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const getPersonTypeListingEle = () => {
    let data = [
      {
        label: "FO User",
        key: "FO User",
      },
      {
        label: "Signatory",
        key: "Signatory",
      },
    ];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const getGenderListingEle = () => {
    let data = [
      {
        label: "Male",
        key: "Male",
      },
      {
        label: "Female",
        key: "Female",
      },
      {
        label: "Other",
        key: "Other",
      },
    ];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const getIsDisqualifiedListingEle = () => {
    let data = [
      {
        label: "Yes",
        key: "Yes",
      },
      {
        label: "No",
        key: "No",
      },
    ];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const DirectorInfoFormElementsColForSave = [
    {
      type: "input",
      label: "DIN",
      name: "DIN",
      required: true,
      placeholder: "DIN",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.DIN,
    },
    {
      type: "input",
      label: "PAN",
      name: "PAN",
      placeholder: "PAN",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.PAN,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isPAN(value)) {
              return Promise.reject("Please input correct PAN!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: "datepicker",
      label: "Date of Appointment",
      name: "dateOfAppointment",
      placeholder: "DD-MM-YYYY",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.dateOfAppointment
        ? dayjs(directorDetails?.dateOfAppointment)
        : "",
      defaultValue: directorDetails?.dateOfAppointment
        ? dayjs(directorDetails?.dateOfAppointment)
        : "",
    },
    {
      type: "dropdown",
      label: "Director Disqualified",
      name: "directorDisqualified",
      placeholder: "Director Disqualified",
      children: getIsDisqualifiedListingEle(),
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.directorDisqualified,
    },
    {
      type: "input",
      label: "First Name",
      name: "firstName",
      required: true,
      placeholder: "First Name",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.firstName,
    },
    {
      type: "input",
      label: "Middle Name",
      name: "middleName",
      placeholder: "Middle Name",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.middleName,
    },
    {
      type: "input",
      label: "Last Name",
      name: "lastName",
      required: true,
      placeholder: "Last Name",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.lastName,
    },
    {
      type: "inputNumber",
      label: "Phone",
      name: "phone",
      placeholder: "Phone",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.phone,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isMobileNumber(value)) {
              return Promise.reject("Please input correct Phone Number!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: "input",
      label: "Email",
      name: "email",
      placeholder: "Email",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.email,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isEmail(value)) {
              return Promise.reject("Please input correct Email Address!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
  ];

  const MCAUserInfoFormElementsColForSave = [
    {
      type: "input",
      label: "Username",
      name: "username",
      placeholder: "Username",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.username,
    },
    {
      type: "input",
      label: "Company ID",
      name: "companyID",
      placeholder: "Company ID",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.companyID,
    },
    {
      type: "datepicker",
      label: "Cessation Date",
      name: "cessationDate",
      placeholder: "DD-MM-YYYY",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.cessationDate
        ? dayjs(directorDetails?.roleInfo?.cessationDate)
        : "",
      defaultValue: directorDetails?.roleInfo?.cessationDate
        ? dayjs(directorDetails?.roleInfo?.cessationDate)
        : "",
    },
    {
      type: "input",
      label: "Education Qualification",
      name: "educationQualification",
      placeholder: "Education Qualification",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.educationQualification,
    },
    {
      type: "input",
      label: "Birth Place",
      name: "birthPlace",
      placeholder: "Birth Place",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.birthPlace,
    },
    {
      type: "input",
      label: "Driving License Number",
      name: "drivingLicenseNumber",
      placeholder: "Driving License Number",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.drivingLicenseNumber,
    },
    {
      type: "inputNumber",
      label: "Mobile Number",
      name: "mobileNumber",
      placeholder: "Mobile Number",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.mobileNumber,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isMobileNumber(value)) {
              return Promise.reject("Please input correct Mobile Number!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: "datepicker",
      label: "Director Death Date",
      name: "directorDeathDate",
      placeholder: "Director Death Date",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.directorDeathDate
        ? dayjs(directorDetails?.roleInfo?.directorDeathDate)
        : "",
      defaultValue: directorDetails?.roleInfo?.directorDeathDate
        ? dayjs(directorDetails?.roleInfo?.directorDeathDate)
        : "",
    },
    {
      type: "input",
      label: "Father's First Name",
      name: "fathersFirstName",
      placeholder: "Father's First Name",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.fathersFirstName,
    },
    {
      type: "input",
      label: "Father's Middle Name",
      name: "fathersMiddleName",
      placeholder: "Father's Middle Name",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.fathersMiddleName,
    },
    {
      type: "input",
      label: "Father's Last Name",
      name: "fathersLastName",
      placeholder: "Father's Last Name",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.fathersLastName,
    },
    {
      type: "inputNumber",
      label: "Membership Number",
      name: "membershipNumber",
      placeholder: "Membership Number",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.membershipNumber,
    },

    {
      type: "inputNumber",
      label: "Role LIC Value",
      name: "roleLICValue",
      placeholder: "Role LIC Value",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.roleLICValue,
    },
    {
      type: "input",
      label: "Contribution Form",
      name: "contributionForm",
      placeholder: "Contribution Form",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.contributionForm,
    },
    {
      type: "input",
      label: "Body Corp Outside Indian Name",
      name: "bodyCorpOutsideIndianName",
      placeholder: "Body Corp Outside Indian Name",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.bodyCorpOutsideIndianName,
    },
    {
      type: "inputNumber",
      label: "Profit Sharing Percentage",
      name: "profitSharingPercentage",
      placeholder: "Profit Sharing Percentage",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.profitSharingPercentage,
    },
    {
      type: "input",
      label: "Nationality",
      name: "nationality",
      placeholder: "Nationality",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.nationality,
    },
    {
      type: "dropdown",
      label: "Gender",
      name: "gender",
      placeholder: "Gender",
      children: getGenderListingEle(),
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.gender,
    },
    {
      type: "datepicker",
      label: "Role Effective Date",
      name: "roleEffectiveDate",
      placeholder: "Role Effective Date",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.roleEffectiveDate
        ? dayjs(directorDetails?.roleInfo?.roleEffectiveDate)
        : "",
    },
    {
      type: "dropdown",
      label: "Occupation Type",
      name: "occupationType",
      placeholder: "Occupation Type",
      children: getOccupationTypeListingEle(),
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.occupationType,
    },
    {
      type: "dropdown",
      label: "Body Corporate Type",
      name: "bodyCorporateType",
      placeholder: "Body Corporate Type",
      children: getBodyCorporateTypeListingEle(),
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.bodyCorporateType,
    },
    {
      type: "dropdown",
      label: "Is Disqualified",
      name: "isDisqualified",
      placeholder: "Is Disqualified",
      children: getIsDisqualifiedListingEle(),
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.isDisqualified,
    },
    {
      type: "input",
      label: "UCIN",
      name: "ucin",
      placeholder: "UCIN",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.ucin,
    },
    {
      type: "input",
      label: "Role",
      name: "role",
      placeholder: "Role",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.role,
    },
    {
      type: "input",
      label: "Body Corp Inside India Name",
      name: "bodyCorpInsideIndiaName",
      placeholder: "Body Corp Inside India Name",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.bodyCorpInsideIndiaName,
    },
    {
      type: "input",
      label: "KMP Flag",
      name: "kmpFlag",
      placeholder: "KMP Flag",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.kmpFlag,
    },
    {
      type: "input",
      label: "Other Educational Qualification",
      name: "otherEducationalQualification",
      placeholder: "Other Educational Qualification",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.otherEducationalQualification,
    },
    {
      type: "input",
      label: "Company Name",
      name: "companyName",
      placeholder: "Company Name",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.companyName,
    },
    {
      type: "inputNumber",
      label: "CIN",
      name: "cin",
      placeholder: "CIN",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.cin,
    },
    {
      type: "input",
      label: "OPC Flag",
      name: "opcFlag",
      placeholder: "OPC Flag",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.opcFlag,
    },
    {
      type: "datepicker",
      label: "DOB",
      name: "dob",
      placeholder: "DOB",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.dob
        ? dayjs(directorDetails?.roleInfo?.dob)
        : "",
    },
    {
      type: "input",
      label: "Approved RLD",
      name: "approvedrId",
      placeholder: "Approved RLD",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.approvedrId,
    },
    {
      type: "input",
      label: "Monetary Contribution Value",
      name: "monetaryContributionValue",
      placeholder: "Monetary Contribution Value",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.monetaryContributionValue,
    },
    {
      type: "input",
      label: "OID Flag",
      name: "oidFlag",
      placeholder: "OID Flag",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.oidFlag,
    },
    {
      type: "input",
      label: "Authorization Status",
      name: "authorizationStatus",
      placeholder: "Authorization Status",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.authorizationStatus,
    },
    {
      type: "input",
      label: "Passport Number",
      name: "passportNumber",
      placeholder: "Passport Number",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.passportNumber,
    },
    {
      type: "input",
      label: "Resident of India",
      name: "residentOfIndia",
      placeholder: "Resident of India",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.residentOfIndia,
    },
    {
      type: "input",
      label: "User ID",
      name: "userId",
      placeholder: "User ID",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.userId,
    },
    {
      type: "input",
      label: "Director Flag",
      name: "directorFlag",
      placeholder: "Director Flag",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.directorFlag,
    },
    {
      type: "input",
      label: "Email Address",
      name: "emailAddress",
      placeholder: "Email Address",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.emailAddress,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isEmail(value)) {
              return Promise.reject("Please input correct Email Address!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: "input",
      label: "Share Holding Percentage",
      name: "shareHoldingPercentage",
      placeholder: "Share Holding Percentage",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.shareHoldingPercentage,
    },
    {
      type: "dropdown",
      label: "Person Type",
      name: "personType",
      placeholder: "Person Type",
      children: getPersonTypeListingEle(),
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.personType,
    },
    {
      type: "input",
      label: "Area Of Occupation",
      name: "areaOfOccupation",
      placeholder: "Area Of Occupation",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.areaOfOccupation,
    },
    {
      type: "input",
      label: "Citizen of India",
      name: "citizenOfIndia",
      placeholder: "Citizen of India",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.citizenOfIndia,
    },
    {
      type: "input",
      label: "Occupation",
      name: "occupation",
      placeholder: "Occupation",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.occupation,
    },
    {
      type: "dropdown",
      label: "OPC Type",
      name: "opcType",
      placeholder: "OPC Type",
      children: getOPCTypeListingEle(),
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.opcType,
    },
    {
      type: "input",
      label: "Other Occupation",
      name: "otherOccupation",
      placeholder: "Other Occupation",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.otherOccupation,
    },
    {
      type: "input",
      label: "Designation",
      name: "designation",
      placeholder: "Designation",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.designation,
    },
    {
      type: "dropdown",
      label: "Signatory Association Status",
      name: "signatoryAssociationStatus",
      placeholder: "Signatory Association Status",
      children: getSignatoryAssociationStatusListingEle(),
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.signatoryAssociationStatus,
    },
    {
      type: "input",
      label: "Current Designation Status",
      name: "currentDesignationStatus",
      placeholder: "Current Designation Status",
      disabled: !isDirectorInfoEditable,
      value: directorDetails?.roleInfo?.currentDesignationStatus,
    },
  ];

  const handleChangeMCAUserInfo = (element, e, dateVal) => {
    let roleInfoData = { ...directorDetails.roleInfo };
    if (element?.type === "datepicker") {
      form.setFieldsValue({
        ...form,
        [element?.name]: dateVal
          ? dayjs(dateVal, "DD-MM-YYYY").add(5, "hour").add(30, "minute")
          : "",
      });
      roleInfoData = {
        ...roleInfoData,
        [element.name]: dateVal
          ? dayjs(dateVal, "DD-MM-YYYY").add(5, "hour").add(30, "minute")
          : "",
      };
    } else if (element?.type === "inputNumber" && e !== null) {
      form.setFieldsValue({
        ...form,
        [element?.name]: e,
      });
      roleInfoData = {
        ...roleInfoData,
        [element.name]: e,
      };
    } else {
      if (e !== null) {
        form.setFieldsValue({
          ...form,
          [element?.name]: element?.type === "dropdown" ? e : e.target.value,
        });
        if (element?.type === "dropdown")
          roleInfoData = {
            ...roleInfoData,
            [element.name]: e,
          };
        else
          roleInfoData = {
            ...roleInfoData,
            [element.name]: e.target.value,
          };
      }
    }
    dispatch(
      setDirectorsInfo({
        name: "roleInfo",
        value: roleInfoData,
      })
    );
  };

  const handleChangeDirectorInfo = (element, e, dateVal) => {
    if (element?.type === "datepicker") {
      form.setFieldsValue({
        ...form,
        [element?.name]: dateVal
          ? dayjs(dateVal, "DD-MM-YYYY").add(5, "hour").add(30, "minute")
          : "",
      });
      dispatch(
        setDirectorsInfo({
          name: element?.name,
          value: dateVal ? dayjs(dateVal, "DD-MM-YYYY").add(5, "hour").add(30, "minute") : "",
        })
      );
    } else if (element?.type === "inputNumber" && e !== null) {
      form.setFieldsValue({
        ...form,
        [element?.name]: e,
      });
      dispatch(
        setDirectorsInfo({
          name: element?.name,
          value: e,
        })
      );
    } else {
      if (e !== null) {
        form.setFieldsValue({
          ...form,
          [element?.name]: element?.type === "dropdown" ? e : e.target.value,
        });
        dispatch(
          setDirectorsInfo({
            name: element?.name,
            value: element?.type === "dropdown" ? e : e.target.value,
          })
        );
      }
    }
  };

  const [currentTab, setCurrentTab] = useState('Director Information');
  const handleTabClick = (tabName) => {
    setCurrentTab(tabName);
  };

  return (
    <>
      {loading ? (
        <Spin spinning={loading} size="large" fullscreen />
      ) : (
        <>

          <div>
            <div style={{ padding: "10px ", width: "100%" }}>
              <Form
                style={{
                  width: "100%",
                  padding: "10px 20px",
                  backgroundColor: "white",
                  borderRadius: "8px",
                }}
                form={form}
                name="basic"
                onFinish={async () => {
                  if (isDirectorInfoEditable && id) {
                    const data = await dispatch(updateDirectorsInfo(getUpdateDirectorPayload(directorDetails))).unwrap();
                    if (data?.status === 201) {
                      message.success("Director updated successfully");
                      setTimeout(() => {
                        dispatch(setLoading(true));
                        navigate("/company");
                        dispatch(setDirectorInfoEditable(false));
                        dispatch(setDirectorId(null));
                        dispatch(setDirectorInitialState());
                      }, 1000);
                    } else {
                      message.error(data?.response?.data?.error || "An unexpected error occurred.");
                    }
                  } else if (isDirectorInfoEditable && !id) {
                    await dispatch(saveDirectorsInfo(getUpdateDirectorPayload(directorDetails)))
                      .unwrap()
                      .then((data) => {
                        if (data?.status === 201) {
                          message.success("Director created successfully");
                          setTimeout(() => {
                            dispatch(setLoading(false));
                            dispatch(setDirectorInfoEditable(false));
                          }, 1000);
                        } else {
                          message.error(data?.response?.data?.error || "An unexpected error occurred.");
                        }
                      });
                  }
                }}
                onFinishFailed={() => {
                  directorInfoValidationMessage();
                }}
              >
                <div
                  style={{
                    padding: "10px",
                    backgroundColor: "#fff",
                    borderRadius: "10px 10px 0 0",
                    width: "100%",
                    position: "sticky",
                    top: 0,
                    zIndex: 1000,
                  }}
                >
                  <h1 className="text-2xl font-[500] mb-4">
                    <Button
                      onClick={() => {
                        dispatch(setDirectorInfoEditable(false));
                        dispatch(setDirectorId(null));
                        dispatch(setDirectorInitialState());
                        navigate(`/directorsList/${companyId}`);
                      }}
                      style={{ border: 'none', backgroundColor: 'transparent', boxShadow: 'none' }}
                    >
                      <FaArrowLeft style={{ marginRight: '8px' }} />
                    </Button>
                    {isDirectorInfoEditable === false
                      ? "View"
                      : id
                        ? "Edit"
                        : "Add"} Director Information
                  </h1>
                  {/* Your tab content */}
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      padding: "10px 0 0 0",
                      width: "100%",
                      backgroundColor: "white",
                      borderBottom: "1px solid #ccc",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", flexWrap: "wrap" }}>
                      <Checkbox checked={["Director Information", "MCA User Role", "User Address"].includes(currentTab)}
                        onChange={() => setCurrentTab('Director Information')}
                        style={{
                          boxShadow: "none",
                          borderTopColor: "#fff",
                          borderRightColor: "#fff",
                          borderLeftColor: "#fff",
                          color: currentTab === "Director Information" ? "#fb923c" : "#fff",
                          borderBottomColor: currentTab === "Director Information" ? "#fb923c" : "#fff",
                          borderBottomWidth: "3px",
                          borderBottomStyle: "solid"
                        }}>
                        <Button
                          type="default"
                          onClick={() => handleTabClick("Director Information")}
                          style={{
                            boxShadow: "none",
                            borderColor: "#fff",
                          }}
                        >
                          Director Information
                        </Button>
                      </Checkbox>
                      <Checkbox checked={["MCA User Role", "User Address"].includes(currentTab)}
                        onChange={() => setCurrentTab('MCA User Role')}
                        style={{
                          boxShadow: "none",
                          borderTopColor: "#fff",
                          borderRightColor: "#fff",
                          borderLeftColor: "#fff",
                          color: currentTab === "MCA User Role" ? "#fb923c" : "#000",
                          borderBottomColor: currentTab === "MCA User Role" ? "#fb923c" : "#fff",
                          borderBottomWidth: "3px",
                          borderBottomStyle: "solid"
                        }}>
                        <Button
                          type="default"
                          onClick={() => handleTabClick("MCA User Role")}
                          style={{
                            boxShadow: "none",
                            borderColor: "#fff",
                          }}
                        >
                          MCA User Role
                        </Button>
                      </Checkbox>
                      <Checkbox checked={currentTab === 'User Address'}
                        type="default"
                        onClick={() => handleTabClick("User Address")}
                        style={{
                          hover: { color: "#fb923c" },
                          boxShadow: "none",
                          borderTopColor: "#fff",
                          borderRightColor: "#fff",
                          borderLeftColor: "#fff",
                          color: currentTab === "User Address" ? "#fb923c" : "#000",
                          borderBottomColor: currentTab === "User Address" ? "#fb923c" : "#fff",
                          borderBottomWidth: "3px",
                          borderBottomStyle: "solid"
                        }}
                        onChange={() => setCurrentTab('User Address')}>
                        <Button
                          type="default"
                          onClick={() => handleTabClick("User Address")}
                          style={{
                            boxShadow: "none",
                            borderColor: "#fff",
                          }}
                        >
                          User Address
                        </Button>

                      </Checkbox>
                      <div>
                        {isDirectorInfoEditable && id && (
                          <Button htmlType="submit" style={{ backgroundColor: "#fb923c", color: "#fff" }}>Update Director</Button>
                        )}
                        {isDirectorInfoEditable && !id && (
                          <Button htmlType="submit" style={{ backgroundColor: "#fb923c", color: "#fff" }}>Save Director</Button>
                        )}
                        {!isDirectorInfoEditable && (
                          <Button onClick={() => dispatch(setDirectorInfoEditable(true))} style={{ backgroundColor: " rgba(203, 203, 203, 0.3)", color: "#000" }}>
                            Edit Director
                          </Button>
                        )}
                      </div>
                    </div>

                  </div>
                </div>
                {/* Conditionally render content based on selected tab */}
                {currentTab === "Director Information" && (
                  <>
                    <Title level={4}>Director Information</Title>
                    <Row
                      gutter={[16, 16]} // Add gutter for spacing between columns
                      style={{
                        justifyContent: "space-between",
                        margin: "0 auto", // Center the Row and remove fixed margins
                      }}
                    >
                      <Col xs={24} sm={12} md={8} lg={6}> {/* Responsive column sizes */}
                        {DirectorInfoFormElementsColForSave.map((element, index) => {
                          if (index % 3 === 0) {
                            return (
                              <FormElement
                                key={index}
                                {...element}
                                onChange={(e, dateVal) => {
                                  handleChangeDirectorInfo(element, e, dateVal);
                                }}
                              />
                            );
                          }
                        })}
                      </Col>
                      <Col xs={24} sm={12} md={8} lg={6}> {/* Responsive column sizes */}
                        {DirectorInfoFormElementsColForSave.map((element, index) => {
                          if ([1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34, 37, 40, 43, 46, 49, 52, 55, 58, 61, 64, 67].includes(index)) {
                            return (
                              <FormElement
                                key={index}
                                {...element}
                                onChange={(e, dateVal) => {
                                  handleChangeDirectorInfo(element, e, dateVal);
                                }}
                              />
                            );
                          }
                        })}
                      </Col>
                      <Col xs={24} sm={12} md={8} lg={6}> {/* Responsive column sizes */}
                        {DirectorInfoFormElementsColForSave.map((element, index) => {
                          if ([2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35, 38, 41, 44, 47, 50, 53, 56, 59, 62, 65, 68].includes(index)) {
                            return (
                              <FormElement
                                key={index}
                                {...element}
                                onChange={(e, dateVal) => {
                                  handleChangeDirectorInfo(element, e, dateVal);
                                }}
                              />
                            );
                          }
                        })}
                      </Col>
                    </Row>
                  </>
                )}

                {currentTab === "MCA User Role" && (
                  <>
                    <Title level={4}>MCA User Role</Title>
                    <Row
                      gutter={[16, 16]} // Add gutter for spacing between columns
                      style={{
                        justifyContent: "space-between",
                        margin: "0 auto", // Center the Row and remove fixed margins
                      }}
                    >
                      <Col xs={24} sm={12} md={8} lg={6}> {/* Responsive column sizes */}
                        {MCAUserInfoFormElementsColForSave.map((element, index) => {
                          if (index % 3 === 0) {
                            return (
                              <FormElement
                                key={index}
                                {...element}
                                onChange={(e, dateVal) => {
                                  handleChangeMCAUserInfo(element, e, dateVal);
                                }}
                              />
                            );
                          }
                        })}
                      </Col>
                      <Col xs={24} sm={12} md={8} lg={6}> {/* Responsive column sizes */}
                        {MCAUserInfoFormElementsColForSave.map((element, index) => {
                          if ([1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34, 37, 40, 43, 46, 49, 52, 55, 58, 61].includes(index)) {
                            return (
                              <FormElement
                                key={index}
                                {...element}
                                onChange={(e, dateVal) => {
                                  handleChangeMCAUserInfo(element, e, dateVal);
                                }}
                              />
                            );
                          }
                        })}
                      </Col>
                      <Col xs={24} sm={12} md={8} lg={6}> {/* Responsive column sizes */}
                        {MCAUserInfoFormElementsColForSave.map((element, index) => {
                          if ([2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35, 38, 41, 44, 47, 50, 53, 56, 59, 62, 65].includes(index)) {
                            return (
                              <FormElement
                                key={index}
                                {...element}
                                onChange={(e, dateVal) => {
                                  handleChangeMCAUserInfo(element, e, dateVal);
                                }}
                              />
                            );
                          }
                        })}
                      </Col>
                    </Row>
                  </>
                )}

                {currentTab === "User Address" && (
                  <>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <Title level={4}>User Address</Title>
                      <Button
                        onClick={() => {
                          dispatch(
                            setDirectorsInfo({
                              name: "newAddress",
                              value: {},
                            })
                          );
                          setSavedUserAddressCnt(savedUserAddressCnt + 1);
                          setIsUserAddressInfoEditable(1);
                        }}
                        disabled={!isDirectorInfoEditable}
                      >
                        Add
                      </Button>
                    </div>
                    <br />
                    <br />
                    {savedUserAddressList?.length > 0 && (
                      <Collapse
                        accordion
                        items={savedUserAddressList}
                        defaultActiveKey={[showUserAddressInfoKey]}
                        onChange={(key) => handleShowAddressInfo(key, "addresses")}
                        style={{ marginBottom: "20px" }}
                      />
                    )}
                  </>
                )}
              </Form>
            </div>
          </div>
        </>
      )}
    </>
  );

}

export default DirectorDetailsCard;
