import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import TabContent from "../TabContent";
import {
  deleteState,
  fetchAllStatesWithPagination,
  setCurrentPage,
  setPageSize,
} from "../../../feature/slice/MasterSlice";

const StateTab = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();

  const {
    stateMaster,
    currentPage = 1,
    pageSize = 10,
    totalStateCount,
    loading,
  } = useSelector((state) => state.masterDetails);

  useEffect(() => {
    dispatch(fetchAllStatesWithPagination({ page: currentPage, pageSize }));
  }, [dispatch, currentPage, pageSize]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentPage(1));
      dispatch(setPageSize(10));
    };
  }, [dispatch]);

  const columns = [
    {
      title: "State Name",
      dataIndex: "state",
      key: "state",
      sorter: (a, b) => a.state.localeCompare(b.state),
    },
    {
      title: "Created On",
      dataIndex: "createdOn",
      key: "createdOn",
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Last Updated On",
      dataIndex: "lastUpdatedOn",
      key: "lastUpdatedOn",
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: "Updated By",
      dataIndex: "updatedBy",
      key: "updatedBy",
    },
  ];

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this state?",
      onOk: () => {
        dispatch(deleteState(id))
          .then(() => {
            dispatch(
              fetchAllStatesWithPagination({ page: currentPage, pageSize })
            );
          })
          .catch((err) => {
            console.error("Failed to delete state:", err);
          });
      },
    });
  };

  const handleRowClick = (record) => ({
    onClick: () =>
      navigateTo("/stateDetails", { state: { stateId: record._id } }),
  });

  const handleTableChange = (pagination) => {
    dispatch(setCurrentPage(pagination.current));
    dispatch(setPageSize(pagination.pageSize));
  };

  const fetchData = async ({ page, pageSize }) => {
    dispatch(fetchAllStatesWithPagination({ page, pageSize }));
  };

  return (
    <TabContent
      title="State"
      fetchData={fetchData}
      data={stateMaster}
      columns={columns}
      buttonLabel="Add State"
      onButtonClick={() => navigateTo("/stateDetails")}
      handleDelete={handleDelete}
      onRowClick={handleRowClick}
      currentPage={currentPage}
      pageSize={pageSize}
      totalRecords={totalStateCount}
      loading={loading}
      handleTableChange={handleTableChange}
    />
  );
};

export default StateTab;
