import { client } from "../../utils/apiClient";
import { asyncHandler } from "../../utils/asyncHandler";

const addPincodeData = async (param) => {
    try {
        const [data, err] = await asyncHandler(client.post(`/api/create-pincode`, param, {
            headers: {
                "Content-Type": "application/json",
            },
        }));
        return data || err;
    } catch (err) {
        console.error(err);
    }
};

const getAllPincodesWithPagination = async (page, pageSize) => {
    try {
        const [data, err] = await asyncHandler(client.get(`/api/get-all-pincodes?page=${page}&pageSize=${pageSize}`));
        return data || err;
    } catch (err) {
        console.error(err);
    }
};

const updatePincodeData = async (id, param) => {
    try {
        const [data, err] = await asyncHandler(client.put(`/api/update-pincode/${id}`, param, {
            headers: {
                "Content-Type": "application/json",
            },
        }));
        return data || err;
    } catch (err) {
        console.error(err);
    }
};

const deletePincodeById = async (id) => {
    try {
        const [data, err] = await asyncHandler(client.delete(`/api/delete-pincode/${id}`));
        return data || err;
    } catch (err) {
        console.error(err);
    }
};

const getPincodeById = async (id) => {
    try {
      const [data, err] = await asyncHandler(
        client.get(`/api/get-pincode/${id}`)
      );
      return data || err;
    } catch (err) {
      console.error(err);
    }
  };

const getAllPincodesByMicroMarketId = async (id) => {
    try {
      const { data } = await client.get(
        `/api/get-pincode-by-micromarketId?microMarketId=${id}`
      );
      return data;
    } catch (err) {
      console.error("Error fetching MicroMarkets by cityId:", err);
    }
};

export {
    getAllPincodesWithPagination,
    addPincodeData,
    updatePincodeData,
    deletePincodeById,
    getPincodeById,
    getAllPincodesByMicroMarketId,
};
