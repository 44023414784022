import React, { useState, useEffect } from "react";
import Title from "antd/es/typography/Title";
import { Form, Button, Divider, Input, message, Select } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addCityData,
  updateCityData,
  fetchCityById,
} from "../../../feature/slice/CitySlice";

const CityDetailsCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [isEditable, setIsEditable] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const { statesForDD } = useSelector((state) => state.masterDetails);
  const { cityDataById } = useSelector((state) => state.cityDetails);

  const { cityId } = location.state || {};

  useEffect(() => {
    if (cityId) {
      dispatch(fetchCityById(cityId));
    }
  }, [cityId, dispatch]);

  useEffect(() => {
    if (cityId && cityDataById) {
      setSelectedState(cityDataById.stateId);
      setIsEditable(true);
      form.setFieldsValue({
        city: cityDataById.city,
        stateId: cityDataById.stateId,
      });
    }
  }, [cityId, cityDataById, form]);

  const handleSubmit = (values) => {
    const { city: newCity, stateId: newStateId } = values;

    const originalCity = cityDataById.city || null;
    const originalStateId = cityDataById.stateId || null;

    if (newCity === originalCity && newStateId === originalStateId) {
      message.warning("No changes detected in city or state.");
      return;
    }

    if (cityId) {
      dispatch(updateCityData({ cityId, cityData: values }))
        .then(() => {
          message.success("City updated successfully");
          navigate("/masterlist");
        })
        .catch((err) => {
          message.error("Failed to update city data!");
          console.error("Failed to update city data:", err);
        });
    } else {
      dispatch(addCityData(values))
        .then(() => {
          message.success("City added successfully");
          navigate("/masterlist");
        })
        .catch((err) => {
          message.error("Failed to add city data, city already exists!");
          console.error("Failed to add city data:", err);
        });
    }
  };

  const handleStateChange = (value) => {
    setSelectedState(value);
    setIsEditable(true);
  };

  return (
    <>
      <div
        style={{
          alignItems: "center",
          padding: "10px",
          marginBottom: "1rem",
          width: "100%",
          backgroundColor: "white",
          boxShadow: "0 8px 8px -8px rgba(0, 0, 0, 0.5)",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1rem",
            padding: "10px",
            marginBottom: "20px",
            width: "100%",
            position: "sticky",
            top: 0,
            zIndex: 1000,
            backgroundColor: "white",
            boxShadow: "0 8px 8px -8px rgba(0, 0, 0, 0.5)",
          }}
        >
          <Title level={4}>
            {cityId ? "Update City Information" : "Add City Information"}
          </Title>
          <Button
            onClick={() => {
              navigate("/masterlist");
            }}
          >
            Back
          </Button>
        </div>
        <Divider style={{ backgroundColor: "black", marginTop: "0" }} />
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          style={{ maxWidth: "400px", margin: "0 auto" }}
        >
          <Form.Item
            label="State Name"
            name="stateId"
            rules={[{ required: true, message: "Please select a state!" }]}
          >
            <Select
              placeholder="Select a State"
              onChange={handleStateChange}
              value={selectedState}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {statesForDD?.length > 0 ? (
                statesForDD?.map((state) => (
                  <Select.Option key={state._id} value={state._id}>
                    {state.state}
                  </Select.Option>
                ))
              ) : (
                <Select.Option disabled></Select.Option>
              )}
            </Select>
          </Form.Item>

          <Form.Item
            label="City Name"
            name="city"
            rules={[{ required: true, message: "Please enter the city name!" }]}
          >
            <Input placeholder="Enter City Name" disabled={!isEditable} />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              style={{ marginTop: "1rem" }}
            >
              {cityId ? "Update" : "Submit"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default CityDetailsCard;
